.kitchen-faucets-page {
  .main {
    max-width: 1250px;
  }

  .layout--onecol {
    h2.h2 {
      font-size: 35px;
      border-bottom: 2px solid $delta-red;
      padding: 0 0 10px;
      margin: 0 0 20px;

      @include maxbp(640px) {
        text-align: left;
        font-size: 30px;
      }
    }

    .margin-bottom-80,
    .view-faucet-finish-block {
      margin: 0 0 $space-ten*8;
    }

    .margin-bottom-80 .catelog-fg-block.grid-col-md-3,
    .view-faucet-finish-block .catelog-fg-block.grid-col-md-3 {
      flex: 0 0 14.2%;
      max-width: 14.2%;
      padding: 0 5px;
    }
  }

  .layout--twocol-section {
    background: $gray-80;
    padding: 50px;
    margin: 0 0 $space-ten*8;
  }

  .layout--fourcol-section {
    .layout__region {
      margin: 0 0 50px;
      padding: 0 10px;

      @include maxbp(1000px) {
        margin: 0;
        flex: 0 1 50%;
      }

      > div {
        margin: 0 0 40px;
      }
    }
  }

  .catelog-four-grid.grid {
    @include maxbp(1000px) {
      display: block;

      .catelog-fg-block.grid-col-md-3 {
        width: 32.5%;
        max-width: none;
        margin: 0 auto 20px;
        display: inline-block;
      }
    }

    @include maxbp(450px) {
      .catelog-fg-block.grid-col-md-3 {
        width: 32% !important;
      }
    }
  }
}
