.pdp-listing-variation-wrapper {
  position: relative;
  padding: $space-ten+15;
  margin: 0;

  &::after {
    mix-blend-mode: multiply;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 247, 1) 40%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(40%, rgba(245, 245, 247, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 247, 1) 40%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 247, 1) 40%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 247, 1) 40%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 247, 1) 40%);
    content: "";
    display: block;
    height: 55px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
  }
}

.pdp-listing-variation {
  .pdp-listing-variation-title {
    h4.h4 {
      text-transform: none;
      font-weight: normal;
      font-size: 23px;
      letter-spacing: -1px;
      margin: 0 0 $space-ten*2;
    }
  }
}

.pdp-listing-variation-desc {
  margin: 0 0 $space-ten+5;

  .pdp-listing-variation-img,
  .pdp-listing-variation-block {
    display: inline-block;
    vertical-align: top;
  }

  .pdp-listing-variation-img {
    margin: 0 $space-ten 0 0;

    img {
      width: 110px;
    }
  }
}

.pdp-listing-variation-block {
  width: calc(100% - 130px);

  .pdp-prod-title {
    .pdp-prod-name {
      font-size: 16px;
      line-height: 22px;
      color: $gray-10;
      margin: 0 0 $space-ten/2;
    }
  }

  .collection-name-wrapper {
    .collection-name {
      font-size: 13px;
      margin: 0 0 3px;
    }
  }

  .model-name {
    .product-model {
      font-size: 13px;
      font-weight: bold;
      margin: 0 0 8px;
    }
  }

  .pdp-listing-prod-price {
    color: $gray-50;

    .pdp-prod-price {
      display: inline-block;
      font-size: 16px;
      color: $gray-50;
      font-family: $font-body;
    }
  }
}
