.filter-container {
  overflow: hidden;
  transition: all ease 0.3s;

  .custom-input {
    margin: 0;
    padding-bottom: 4px;
  }

  &.show-filter-container {
    max-height: 100000px !important;
    transition: all ease 0.5s;
  }
}
