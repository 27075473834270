.video-card {
  display: flex;
  align-items: center;
  padding: 10px;
  color: $white;
  background-color: $gray-30;
  font-size: $font-size-sm;
  line-height: 1.35;

  @include large {
    font-size: $font-size-lg;
    padding: 16px;
  }
}

.video-card__thumbnail {
  position: relative;
  display: block;
  max-width: 46%;
  flex-shrink: 0;
  margin-right: 16px;
  color: $white;
  cursor: pointer;

  @include large {
    margin-right: 32px;
  }

  .icon {
    position: absolute;
    z-index: 20;
    height: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-card__thumbnail__img {
  display: block;
  width: 100%;
}
