.sidebar-wrapper {
  ul {
    list-style: none;

    li {
      margin: 8px 0;

      ul {
        display: none;
      }
    }
  }
}

.section_title {
  text-transform: uppercase;
  padding-left: 0.5em;
}
