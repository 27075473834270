.water-saving-solutions {
  .left-sidebar {
    min-width: 20%;

    .menu {
      .menu__item {
        margin-bottom: 15px;

        a {
          padding: 10px 15px;
          text-decoration: none;
        }

        &.menu__item--active a {
          color: $delta-red;
        }
      }
    }
  }

  .region.region-content {
    margin-bottom: 40px;
  }

  .layout__region {
    padding: 20px;
  }
}
