.dropdown {
  position: relative;
  display: inline-block;

  &-content {
    max-height: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    position: absolute;
    z-index: 100000000;
    background: #f1f1f1;
    width: 130%;
    text-align: left;
    padding: 0 $space-ten+5;

    ul {
      li {
        margin: 0 0 $space-ten;

        > a {
          color: $gray-10;
          text-decoration: none;

          &:hover {
            color: $delta-red;
          }
        }
      }
    }
  }

  &:hover {
    > a {
      background: $delta-red;

      &::after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 17px;
      }
    }

    .dropdown-content {
      max-height: 1000px;
      transition: all ease 1s;
    }
  }

  &.open-active-lang-link {
    > a {
      background: $delta-red !important;

      &::after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 17px;
      }
    }

    .dropdown-content {
      max-height: 1000px;
      transition: all ease 1s;
    }
  }

  > .button--primary,
  > .active-lang-link {
    padding: 8px $space-ten*2 8px $space-ten+5;

    &::after {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      border: solid $white;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 2px;
      content: "";
      position: absolute;
      right: 8px;
      top: 15px;
      transition: all 0.4s;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }
}
