.order-placed {
  .order_number {
    font-family: $font-light;

    @include maxbp($medium) {
      font-size: 1.125rem;
      color: $gray-10;
    }
  }
}

.order_status_details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @include maxbp($medium) {
    flex-flow: column;
    margin-bottom: 12px;
  }

  .model-name {
    margin-right: 32px;
  }
}

.order_details_bottom_section {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 16px;

  .order-items {
    display: inline-block;
    float: right;
    margin-top: 16px;
    width: 25%;
  }

  .estimation .cta-link {
    background: none;
  }
}
