.your_orders {
  max-width: 955px;
  margin: 56px auto;
}

.track_orders_wrap {
  max-width: 920px;
  margin: 0 auto;

  .grid {
    justify-content: space-between;

    @include maxbp($medium) {
      margin: 0;
    }
  }

  .track_order,
  .customer-service {
    margin: 0;
  }

  .track_order {
    padding-left: 0;
  }

  .customer-service {
    padding-right: 0;
  }
}
