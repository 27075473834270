.product-model {
  color: $gray-50;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 $space-ten+5;

  @include maxbp($medium) {
    font-size: 12.64px;
    font-family: $font-bold;
  }
}
