.faq-block-wrapper {
  @include wrapper;

  padding-top: $space-ten*5 !important;
  max-width: $max-width-text;

  .faq-category-list,
  .faq-list {
    @include maxbp($medium) {
      max-width: 100%;
      flex: 0 0 100%;
      display: block;
    }
  }

  .faq-category-list {
    @include maxbp($medium) {
      margin: 0 0 $space-ten*3;
    }

    .faq-category-list-title {
      h6.h6 {
        font-weight: normal;
        text-transform: none;
        font-size: 20px;
        letter-spacing: 1px;
        margin: 0 0 $space-ten*2;
      }
    }

    .faq-category-list-desc {
      ul {
        padding: 0;
        margin: 0;
      }
    }
  }

  .faq-list {
    .faq-list-title {
      h6.h6 {
        font-weight: normal;
        text-transform: none;
        font-size: 20px;
        letter-spacing: 1px;
        font-family: $font-body;
        margin: 0 0 $space-ten*2;
      }
    }
  }
}
