.innovation-palm-shower {
  .two-column-section.paragraph-component {
    .layout--onecol {
      text-align: center;

      .img_element {
        margin: 0 0 -7px;
      }
    }

    .layout--twocol-section {
      background: #e5e5e5;
      display: block;

      .layout__region {
        width: 38%;
        display: inline-block;
        border-right: 1px solid #77777a;
        border-left: 1px solid #77777a;

        .img_element {
          margin: 0 0 -5px;
        }

        &.layout__region--second {
          margin: 0 0 0 -6px;
        }
      }
    }
  }

  .section-lead-in {
    .button {
      text-align: center;
      min-width: auto;
      padding: 10px 100px;
    }
  }
}
