.tabs--carousel {
  .tabs__nav {
    justify-content: stretch;
  }

  .tabs__nav li {
    flex-grow: 1;

    a {
      text-align: center;
    }
  }

  .tabs__link.is-active {
    position: relative;

    &::before {
      background-color: $delta-red;
    }

    &::after {
      position: absolute;
      left: 50%;
      margin-left: -6px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $delta-red;
      content: '';
    }
  }

  &--dark {
    color: $white;
  }
}

.tab--carousel__img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include maxbp($medium) {
    max-width: 70%;
  }
}

.tabs__secondary-content {
  text-align: center;
  padding: 16px;

  @include maxbp($medium) {
    display: none;
  }
}

.tabs--carousel__copy--mobile {
  padding-left: 24px;
  padding-right: 24px;

  @include medium {
    display: none;
  }
}
