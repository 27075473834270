.block[data-block-plugin-id="global_search_block"] {
  float: right;
}

.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
  background: $gray-90;
  border: 1px solid #d7d7d7;
  padding: $space-ten + 5 $space-ten $space-ten * 2;
  overflow: hidden;
  display: block;

  .ui-menu-item {
    > a {
      text-transform: uppercase;
      font-size: 15px;
      font-family: $font-bold;
      font-weight: normal;
      display: block;
      padding: 0;
      margin: 0 0 $space-ten !important;
      cursor: auto;
      cursor: pointer;
      text-decoration: none !important;

      &.ui-state-active {
        border: none;
        margin: 0;
        color: $gray-10;
        background: $gray-80;
      }

      > a {
        color: $gray-10;
        line-height: 20px;
        position: relative;
        text-transform: none;
        letter-spacing: normal;
        font-family: $font-body;
        text-decoration: none;
        display: block;
        padding: $space-ten/2 0;
        margin: 0 0 -10px !important;
        cursor: pointer;

        &::before {
          background: $gray-80;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: -$space-ten;
          height: 100%;
          width: 0;
          transition: all ease 0.5s;
        }

        &:hover {
          &::before {
            width: 120%;
          }
        }

        span {
          display: block;
          position: relative;
        }
      }
    }
  }
}

#find-a-part-autocomplete-results {
  position: relative;
}

#ui-id-1 {
  &.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
    background: $gray-90;
    border: 1px solid #d7d7d7;
    padding: $space-ten + 5 $space-ten $space-ten * 2;
    overflow: hidden;
    width: 100% !important;
    display: block;
    position: static;

    @include breakpoint($mega-menu-mobile) {
      position: absolute;
      min-height: 50vh;
      height: 100%;
      overflow-y: auto;
    }

    @include maxbp($old-desktop) {
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}

#ui-id-2 {
  &.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
    @include breakpoint($r-1100) {
      right: calc(50% - (700px / 2)) !important;
      left: auto !important;
      transform: translateX(100%);
      max-width: 400px;
      width: 100% !important;
      top: 99px !important;
    }
  }
}

#ui-id-1,
#ui-id-2 {
  &.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front {
    .ui-menu-item {
      > a {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 15px;
        font-family: $font-bold;
        font-weight: normal;
        display: block;
        padding: 0;
        margin: 0 0 $space-ten !important;
        cursor: auto;

        &.ui-state-active {
          background: none;
          border: none;
          margin: 0;
          color: $gray-10;
        }

        > a {
          color: $gray-10;
          line-height: 20px;
          position: relative;
          text-transform: none;
          letter-spacing: normal;
          font-family: $font-body;
          text-decoration: none;
          display: block;
          padding: $space-ten/2 0;
          margin: 0 0 -10px !important;
          cursor: pointer;

          &::before {
            background: $gray-80;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -$space-ten;
            height: 100%;
            width: 0;
            transition: all ease 0.5s;
          }

          &:hover {
            &::before {
              width: 120%;
            }
          }

          span {
            display: block;
            position: relative;
          }
        }
      }

      .view_all {
        border-bottom: 1px solid $gray-70;
        margin: $space-ten * 2 0;
        padding: 0 0 $space-ten * 2;

        span {
          display: block;

          a {
            color: $delta-red;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
            text-decoration: none;
            background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E"), "#", "%23");
            background-position: right center;
            background-repeat: no-repeat;
            padding: 0 $space-ten + 5 0 0;
            background-size: 10px;
            display: inline-block;
          }
        }

        svg {
          display: none;
        }
      }

      &:last-child() {
        .view_all {
          border: none;
          padding: 0;
          margin: $space-ten * 2 0 0;
        }
      }
    }
  }
}

.search-wrapper {
  .search-button {
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.5s;
  }

  .form-item {
    margin: 0;
  }

  &.search-active {
    .serach-input {
      padding: $space-ten $space-ten + 35 $space-ten $space-ten;
      background: $gray-90 -50px center;
      width: 300px;
      transition: all ease 0.7s;
      background-repeat: no-repeat !important;

      @include maxbp($std_desktop) {
        width: 180px;
      }

      @include maxbp($big-desktop) {
        width: 180px;
      }
    }

    .search-button {
      opacity: 1;
      visibility: visible;
    }
  }

  .search-submit-block {
    position: absolute;
    right: $space-ten + 5;
    top: $space-ten + 5;
    margin: 0;
    height: 24px;
  }

  .glyphicon-search {
    &::before {
      display: none !important;
    }
  }
}

// MOVE TO HEADER.SCSS
.right-header {
  float: right;

  @include maxbp($mega-menu-mobile) {
    float: none;

    @include clearfix;
  }

  .mega-nav {
    float: left;
    margin: $space-ten 0 0;

    @include maxbp($mega-menu-mobile) {
      float: none;
      margin: 0;
    }
  }
}

#find-part-form {
  position: relative;

  .form-item {
    margin-bottom: 0;
  }
}

.ui-widget-content a.autocomplete-view-all {
  color: #ac0535;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-decoration: none !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='%23ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E") !important;
  background-position: right center !important;
  background-repeat: no-repeat !important;
  padding: 0 15px 0 0 !important;
  background-size: 10px !important;
  display: inline-block !important;
}

.ui-widget-content a.autocomplete-view-all svg {
  display: none;
}
