.checkout_login {
  &-title {
    font-size: 2.888rem;
    font-family: $font-bold;
    letter-spacing: 0.5px;
    margin-bottom: 50px;
    text-align: center;
  }

  h6 {
    font-size: 1.6rem;
    font-family: $font-bold2;
    line-height: 1.12;
    margin-bottom: 24px;
    text-transform: capitalize;
    letter-spacing: 0;
  }

  .checkout-login {
    width: 100%;
    display: block;
  }

  .form-item__label--required::after {
    content: none;
  }
}

.checkout-user-login {
  max-width: 448px;
  margin: 0 auto;

  .forgot_pass {
    padding-bottom: 25px;
  }
}

.checkout-guest-order {
  max-width: 331px;
  margin: 0 auto;
  padding: 0;

  p {
    padding-right: 5px;
  }
}

.checkout-user-login,
.checkout-guest-order {
  input,
  button {
    max-width: 100%;
  }
}

.forgot_pass {
  color: $gray-10;
  line-height: 1.5;
  text-decoration: underline;
  font-size: 1rem;
  display: inline-block;
  float: right;

  &:hover {
    color: $delta-red;
  }
}

.rememberme {
  float: left;
  width: 50%;
  display: none !important;

  .form-item__label {
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.path-ecommerce .main {
  padding-bottom: 120px;
}

.checkout-guest-order.button--secondary {
  padding: 0.75rem 2.125rem;
  width: 100%;
  justify-content: center;
}

.pass {
  position: relative;

  .form-item__pwd-toggle {
    background-image: url("../images/icons/src/eye-closed.svg");
    background-position: 20px;
    background-repeat: no-repeat;
    text-indent: -99999px;
    width: 45px;

    &.is-showing-password {
      background-image: url("../images/icons/src/eye-open.svg");
    }
  }
}

.user_login {
  .checkout-login {
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    display: inline-block;
  }

  .cta-link {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

  h4 {
    text-transform: capitalize;
    margin-top: 22px;
    margin-bottom: 22px;
  }
}

.modal {
  margin-top: 80px;
}

@include breakpoint($grid-md) {
  .checkout_login-title {
    font-size: 2.281rem;
    margin-top: 32px;
  }

  .path-ecommerce .main {
    padding-bottom: 98px;
  }

  .checkout_login {
    padding: 0 16px;

    .grid {
      margin: 0;
    }
  }

  #block-useraccountmenu-2 nav.utility-nav li:first-child a {
    text-align: left;
  }

  .modal {
    margin: 65px auto;
  }

  .modal-container {
    z-index: 10001;
  }

  .user_login {
    h4 {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  .custom-input--checkbox .custom-input__label {
    padding-left: 25px;
  }

  .rememberme {
    width: 57%;
  }
}

@include maxbp($grid-md) {
  .checkout-user-login {
    padding: 0 16px;
  }

  .checkout-guest-order {
    max-width: 448px;
    padding: 36px 16px 0 16px;
  }

  .checkout_login {
    .grid {
      margin: 0 auto;
    }
  }

  .cart-head {
    text-align: center;
  }
}
