.wtb-logo-grid__item {
  position: relative;
  display: block;
  max-width: 100% / 2;
  flex: 0 0 (100% / 2);

  @include breakpoint($grid-lg) {
    max-width: 100% / 3;
    flex: 0 0 (100% / 3);
    padding: $space-ten*3;
  }

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    z-index: 100;
    box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07), inset 0 0 0 1px $gray-80;
  }
}
