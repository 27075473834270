$breadcrumb-active: $gray-10;

.breadcrumb {
  @include list-reset;
  @include wrapper;

  padding-left: 24px;
  padding-right: 24px;

  @include maxbp($large) {
    padding: 0 16px;
  }
}

.breadcrumb__item,
.breadcrumb__link,
.breadcrumb__link:link,
.breadcrumb__link:visited,
.breadcrumb__link:focus {
  font-size: $font-size-xs;
  color: $gray-10;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.breadcrumb__link {
  padding: 0 0 $space-ten/5;
  position: relative;
  margin: 0 8px 0 0;
  text-decoration: none;

  &:hover {
    color: $delta_red;
    text-shadow: 0 0 0.2px $delta_red;

    &::after {
      background: $delta_red;
    }
  }
}

.breadcrumb__item {
  display: inline-block;
  padding: 0 8px 0 0;
  //font-size: 12px;

  &::after {
    content: "/";
  }

  &:last-child {
    color: $breadcrumb-active;

    &::after {
      content: "";
    }
  }
}

.breadcrumb_link:active {
  color: $breadcrumb-active;
}

.breadcrumb-block {
  &.dark-breadcrumb {
    //background: $gray-10;
    //padding: $space-ten 0;
    //margin: $space-ten 0;

    .breadcrumb__item,
    .breadcrumb__link,
    .breadcrumb__link:link,
    .breadcrumb__link:visited,
    .breadcrumb__link:focus {
      color: $white;
    }

    .breadcrumb__link {
      &::after {
        background: $white;
      }

      &:hover {
        color: $delta_punch;
        font-size: 14px;
        font-weight: bold;

        &::after {
          background: $delta_punch;
        }
      }
    }
  }
}

.dark-theme,
.featured-banner--dark {
  .breadcrumb__item,
  .breadcrumb__link,
  .breadcrumb__link:link,
  .breadcrumb__link:visited,
  .breadcrumb__link:focus {
    color: $white;
  }

  .breadcrumb__link {
    &::after {
      background: $white;
    }

    &:hover {
      color: $delta_punch;

      &::after {
        background: $delta_punch;
      }
    }
  }
}

.breadcrumb-txt-color-black .breadcrumb .breadcrumb__item,
.breadcrumb-txt-color-black .breadcrumb .breadcrumb__item .breadcrumb__link {
  color: #000;
}
