/**
 * Breakpoint Configuration
 * @see https://github.com/Team-Sass/breakpoint/wiki
 *
 */

/////////////////////
// Global Breakpoints

// Avoid using in favor of atomic, content-specific, breakpoints.
// These should be used for generic code, like layouts and typography, only.
$xs: 320px;
$small: 480px;
$medium: 720px;
$large: 920px;
$xl: 1440px;
$ipad-only: 768px;

$std_desktop: 1400px;
$big-desktop: 1250px;
$mega-menu-mobile: 1100px;
$grid-xs: 320px;
$grid-sm: 575px;
$grid-md: 767px;
$grid-lg: 1000px;
$grid-xl: 1200px;

$r-1100: 1100px;
$r-800: 800px;
$r_600: 600px;
$r_400: 400px;
$old-desktop: 1024px;

// The max-width breakpoint is used when the design should be applied at whatever the
// largest breakpoint is regardless of actual pixel value. e.g. removing outer margin on body wrapper
$max-width: $xl;

$max-width-text: 1150px;

/// Mixin - xs Breakpoint
/// Allows easier @include xs {} syntax
@mixin xs {
  @include breakpoint($xs) {
    @content;
  }
}

/// Mixin - small Breakpoint
/// Allows easier @include small {} syntax
@mixin small {
  @include breakpoint($small) {
    @content;
  }
}

/// Mixin - medium Breakpoint
/// Allows easier @include medium {} syntax
@mixin medium {
  @include breakpoint($medium) {
    @content;
  }
}

/// Mixin - large Breakpoint
/// Allows easier @include large {} syntax
@mixin large {
  @include breakpoint($large) {
    @content;
  }
}

/// Mixin - xl Breakpoint
/// Allows easier @include xl {} syntax
@mixin xl {
  @include breakpoint($xl) {
    @content;
  }
}

/// Mixin - max-width Breakpoint
/// Allows easier @include max-width {} syntax
@mixin max-width {
  @include breakpoint($max-width) {
    @content;
  }
}

/// Mixin - small desktop Breakpoint
/// Allows easier @include small desktop {} syntax
@mixin small-desktop {
  @include breakpoint($small-desktop) {
    @content;
  }
}

// Mixin - max-width Breakpoint
@mixin maxbp($flex-pivot) {
  @media (max-width: $flex-pivot - 1px) {
    @content;
  }
}
