.plp-wrapper {
  width: 100%;

  @include breakpoint($grid-md) {
    display: flex;
  }

  .product-tile__overlay {
    .product-tile__cta {
      margin: 8px 0;
    }

    .price-weight {
      font-family: $font-bold;
    }
  }
}

.plp-sidebar {
  @include breakpoint($grid-md) {
    max-width: 236px; // TODO: Tmporary. Remove
    flex-shrink: 0;
    width: 100%;
  }
}

.plp-results {
  @include breakpoint($grid-md) {
    margin-left: 24px;
  }

  @include large {
    margin-left: 52px;
  }

  [class^="grid-col-"] {
    margin-bottom: 16px;
    padding-right: 8px;
    padding-left: 8px;
  }
}

.plp-results__header {
  margin: 24px 0 40px;

  @include breakpoint($grid-md) {
    margin: 0 0 44px;
  }

  .form-item--dropdown {
    margin-bottom: 0;

    @include breakpoint($grid-md) {
      width: 187px;
    }
  }

  @include maxbp($large) {
    .form-item--dropdown {
      padding: 0 6px;

      .form-item__select--sm {
        padding-top: 14px;
        padding-bottom: 14px;
        height: 56px;
      }
    }
  }
}

.plp-results__header__primary {
  font-size: 1.425rem;
  text-align: center;

  .h6 {
    display: inline;
    font-size: inherit;
  }

  // @media (min-width: $ipad-only) and (max-width: $old-desktop) {
  //   padding-left: 60px;
  // }

  @include breakpoint($large) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  @include breakpoint($grid-lg) {
    font-size: 1.601875rem;
  }

  @media (min-width: $ipad-only) and (max-width: $old-desktop) and (orientation: portrait) {
    padding-left: 60px;
    text-align: left;
  }
}

.plp-results__qty-wrap {
  margin-bottom: 24px;

  @include breakpoint($grid-md) {
    margin-bottom: 0;
  }

  @include maxbp($grid-md) {
    display: none;
  }
}

.plp-results__qty {
  font-size: inherit;
}

.plp-results__filters {
  //display: none;

  @include maxbp($grid-md) {
    display: block;
    text-align: center;
    margin: $space-ten + 5 0 0;

    .filter-chip {
      display: inline-block;
      margin: 0 $space-ten/2 $space-ten/2;
    }

    #clear_filters {
      display: block;
      margin: $space-ten 0 0;
    }
  }

  @include breakpoint($grid-md) {
    display: flex;
    align-items: center;
    margin-top: 0;
  }

  > * + * {
    margin-left: 8px;
  }

  .cta-link {
    .icon {
      margin-right: 4px;
    }
  }

  @media (min-width: $ipad-only) and (max-width: $old-desktop) and (orientation: portrait) {
    padding-left: 60px;
    padding-bottom: 25px;
  }
}

@include breakpoint($grid-md) {
  .plp-wrapper {
    .product-filter {
      width: 30%;
    }

    .region-content {
      width: 70%;

      .plp-results {
        width: 100%;
      }
    }
  }
}

@include breakpoint($large) {
  .plp-wrapper {
    .product-filter {
      width: 20%;
    }

    .region-content {
      width: 80%;

      .plp-results {
        width: 100%;
      }
    }
  }
}

.plp_product_title_link {
  text-decoration: none;
}

.flag_position {
  position: absolute;
  top: 0;
}

.product-filter__section .custom-input--checkbox .custom-input__native:disabled ~ .custom-input__label::before {
  background: transparent;
}

//Error message for No Results
#output .error-message {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
  color: red;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
}

* {
  box-sizing: border-box;
}

.plp-share-popup {
  display: none;
}

.plp-share-popup.active-plp-share-popup {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000000;
}

.plp-share-data {
  max-width: 600px;
  background: white;
  width: 90%;
  margin: auto;
  padding: 50px 30px 20px;
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  transition: all ease 1s;
}

.active-plp-share-popup .plp-share-data {
  top: 50px;
}

.plpshareclosebutton {
  position: absolute;
  background: $delta-red;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  text-align: center;
  cursor: pointer;
  display: block;
  font-size: 20px;
}

.plpshareclosebutton::after {
  content: "X";
  font-family: $font-roman;
  color: white;
}

#plpshareinput {
  width: 100%;
  border: 1px solid #d7d7d7;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 0 0 30px;
  font-size: 16px;
}

.plp-share-link {
  display: none;
}

.node--type-plp {
  .banner__footer {
    .plp-share-link {
      display: block;

      .addthis.addthis_button_compact {
        display: block;
      }
    }

    .addthis.addthis_button_compact {
      display: none;
    }
  }

  .outlet_card_section {
    padding: 70px 0;
    margin-bottom: 36px;
    margin-top: 56px;

    @include maxbp($large) {
      padding: 64px 0;
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  @include breakpoint($grid-md) {
    .main {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.plpsharecopybutton {
  background: $delta-red;
  color: white;
  border: none;
  padding: 8px 20px;
  font-size: 18px;
  cursor: pointer;
}

.share-this-page-plp {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M19.44 14.68a3.52 3.52 0 00-2.78 1.38L8 12.93A3.26 3.26 0 008 11l8.66-3.12a3.52 3.52 0 002.83 1.45 3.61 3.61 0 10-3.56-3.61 3.62 3.62 0 00.14.94L7.34 9.78a3.52 3.52 0 00-2.78-1.39 3.61 3.61 0 000 7.22 3.53 3.53 0 002.84-1.45l8.6 3.12a3.54 3.54 0 103.39-2.59z'/%3E%3C/svg%3E"),"#","%23");
  color: $delta-red;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: center left;
  text-decoration: none;
  padding: 0 0 0 20px;
  letter-spacing: 2px;
  font-weight: 700;
  font-family: $font-bold;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-position: -65px;
  }
}

.discontinued_products {
  @include medium {
    position: absolute;
    top: 0;
    left: 0;
  }

  .flag--grey-30 {
    background-color: #434447;
  }

  .slider_flag {
    @include maxbp($medium) {
      display: block;
      right: 0;
    }
  }
}

// Recommerce updated Recertified PLP changes

.recertified-page {
  .banner-content {
    .inline-text-link {
      font-family: inherit;
    }
  }

  .banner-block .page-title p {
    text-indent: -4px;
    font-size: 58.45px;
    letter-spacing: -0.2px;
    line-height: 65.76px;

    @include maxbp($large) {
      font-size: 41.05px;
      letter-spacing: 0.8px;
      line-height: 46.18px;
    }
  }
}

.region-banner {
  .views-element-container {
    padding: 0;
  }

  .paragraph-component {
    padding: 0;
  }

  @include maxbp($large) {
    .grid-container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @include large {
    .title-banner--text-centered {
      .grid-container {
        max-width: 846px;
      }
    }
  }

  .banner-content {
    letter-spacing: -0.2px;
  }
}

@include maxbp($medium) {
  .plp-wrapper {
    .product-tile {
      padding-left: 16px;
      padding-right: 16px;

      .no-line {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  .recertified-plp-page {
    .banner-block {
      .page-title {
        margin: 0 0 34px;

        p {
          font-size: 41.5px;
        }
      }

      .banner-content {
        letter-spacing: normal;
        margin-bottom: -24px;

        p {
          font-size: 16px;

          a {
            color: $delta-red;
          }
        }
      }
    }

    .main {
      padding: 0;
    }

    .plp-results {
      .plp-results__qty-wrap {
        text-align: left;
        padding-left: 16px;
        margin-top: -28px;
        font-size: 26px;
      }
    }

    .banner__footer {
      bottom: 46px;
    }

    .compare-popup {
      .com-pop-img {
        .slider_flag {
          display: block;
        }
      }
    }
  }

  .product-filter__section .accordion-def {
    border-bottom: 0 none;
  }
}

@include medium {
  .recertified-plp-page {
    .main {
      padding-top: 32px;
    }
  }

  .product-filter__section {
    .accordion-term.is-active::after,
    .accordion-term::after {
      right: 0;
    }

    .outlet_card_section .field_card_details > div {
      padding: 0 6px;
    }
  }
}

@include maxbp($grid-md) {
  .plp-results {
    .product-tile .no-line {
      flex-wrap: wrap;
    }
  }
}

.recertified-plp-page {
  .main {
    .plp-wrapper {
      .product-tile {
        .product-tile__overlay {
          .product-tile__title {
            margin-bottom: 4px;
            line-height: 17px;
          }

          .paragraph {
            margin-bottom: 12px;
            line-height: 22px;

            .plp_product_title_link {
              color: $gray-10;
              line-height: 17px;
            }
          }

          .plp_product_title_link {
            .product-tile__sku {
              margin-bottom: 16px;
            }
          }

          .discount-list-price {
            color: #b3b3b3;
            margin-bottom: 0 !important;
          }

          .product-tile__price {
            margin-top: 0;
            margin-bottom: 16px;
          }

          .product-tile__cta {
            a.button--secondary {
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}

.sort_elements {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint($large) {
    display: flex;
    justify-content: flex-end !important;
    padding-top: 20px;
  }

  @media (min-width: $ipad-only) and (max-width: $old-desktop) and (orientation: portrait) {
    padding-left: 30px;
  }

  @include breakpoint($grid-md) {
    justify-content: space-around;
  }

  @include maxbp($grid-md) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .form-item.form-item--dropdown {
    @include breakpoint($large) {
      margin-right: 30px;
    }

    @include maxbp($grid-md) {
      margin-top: 20px;
    }
  }

  .form-item__select--sm {
    height: 56px;
  }

  .plp-pagination-output {
    @include maxbp($grid-md) {
      order: -2;
    }
  }

  .form-item--dropdown:nth-of-type(1) {
    @include maxbp($grid-md) {
      order: 1;
    }
  }
}

.page-desktop-pagination {
  @media (min-width: $ipad-only) and (max-width: $old-desktop) and (orientation: portrait) {
    padding-top: 20px;
  }

  @include breakpoint($grid-md) {
    display: flex;
  }

  @include maxbp($grid-md) {
    display: none;
  }
}

.page-mobile-pagination {
  @include breakpoint($grid-md) {
    display: none;
  }

  @include maxbp($grid-md) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .mobile_pagination {
    @include maxbp($grid-md) {
      font-size: 20px;
      padding-right: 10px;

      &:nth-of-type(1) {
        padding-left: 20px;
      }

      &:nth-of-type(2) {
        padding-right: 20px;
      }
    }
  }
}

.pagination-bottom {
  @include breakpoint($large) {
    display: flex;
    justify-content: flex-end;
  }

  @include maxbp($large) {
    display: flex;
    justify-content: center;
  }
}

.pagination-dots {
  @include breakpoint($grid-md) {
    padding: 15px 9px 1px 9px;
  }
}

.pager__link {
  border: 0;

  @include maxbp($grid-md) {
    background: $delta-red;
    border: $delta-red;
    color: $white;
  }
}

.recertified-menu a {
  text-decoration: none;
}

.recertified-menu a.active {
  font-weight: 900;
}

.recertified-menu a.viewall {
  text-decoration: none;
  color: #ac0535 !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
}

.plp-results .product-tile .prod-finishes {
  display: flex;
  justify-content: left !important;
  flex-wrap: wrap;
}

.plp-results .product-tile .prod-finishes img.finish-class {
  padding: 0;
}

img.active.finish-class {
  border: 2px solid #ac0535;
  border-radius: 100%;
  padding: 2px;
}

@media (min-width: 767px) {
  .product-tile {
    transition: none !important;
  }
}
