.team-card {
  border-radius: 2px;

  .sm-red-arrow-link {
    font-weight: 700;

    &:hover {
      color: $delta-red;
    }
  }
}

.team-card--centered {
  text-align: center;
}

.team-card--interactive {
  @include transition(all, 0.3s, ease-out);

  &:hover {
    background-color: $white;
    box-shadow: 0 10px 32px 5px rgba(24, 25, 29, 0.1);
  }
}

.team-card__body {
  padding: 16px;
}

.team-card__subtitle {
  margin: -0.65rem 0 0;
  font-size: 0.875rem;
  font-style: italic;
  color: $gray-30;
}

.team-card__description {
  line-height: 1.5;
  margin: 0.5rem 0;
}
