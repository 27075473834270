.user-registration-form {
  max-width: 910px;
  margin: 0 auto;
  padding-bottom: 56px;

  legend.h2 {
    font-size: 1.125rem;
    line-height: 1.5;
    color: $gray-10;
    font-family: $font-body;
    letter-spacing: normal;
    text-transform: capitalize;
    font-weight: normal;
    padding-top: 10px;
  }

  .captcha,
  .g-recaptcha {
    margin-top: 0;
  }

  .password {
    width: 100%;
  }

  .custom-input {
    display: inline-block;
  }

  @include breakpoint($grid-md) {
    .password {
      width: 50%;
    }
  }

  .user-register-btn {
    max-width: 483px;
    justify-content: center;
    width: 100%;
  }

  .confirm-password + .confirm-password {
    display: none;
  }

  .user_pass {
    .password-validation + .password-validation {
      display: none;
    }
  }

  .user-notification label.option {
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.user_register_block {
  max-width: 910px;
  margin: 0 auto;
  font-size: 1.125rem;

  p.heading {
    font-family: $font-bold2;
    line-height: 1.5;
    font-size: 1.269rem;
    letter-spacing: -0.2px;
  }

  p.notes {
    font-size: 1rem;

    span {
      font-family: $font-bold2;
    }
  }

  @include maxbp($grid-md) {
    padding: 0 16px;
  }
}

.captcha-validate {
  height: 0;
  opacity: 0;
  margin: 0;
  border: 0;
  padding: 0;
}

.captcha + .form-item label {
  display: none;
}

.global-user-register-err {
  margin-bottom: 25px;
}

.no-line {
  text-decoration-color: transparent;
}

.user_registration .form-item--radio > div {
  display: inline-block;
  min-width: 80px;
}

.user_registration .js-form-item.form-item {
  @include maxbp($medium) {
    margin: 0 0 20px !important;
  }
}
