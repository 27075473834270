.video-banner {
  position: relative;

  .video {
    padding-top: 0;
    opacity: 0;
    z-index: -1;
  }

  &.is-playing .video {
    opacity: 1;
    z-index: 10;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-banner__play {
  padding: 0;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;

  .icon {
    @include medium {
      width: 100px;
      height: 100px;
    }

    @include large {
      width: 210px;
      height: 210px;
    }
  }
}

.video-banner__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xl;
  color: $white;
  text-align: center;
  background-position: center;
  background-size: cover;
  padding-left: 24px;
  padding-right: 24px;

  .is-playing & {
    opacity: 0;
  }

  @include large {
    padding-left: calc(50vw - 450px);
    padding-right: calc(50vw - 450px);
    font-size: $font-size-xxxl;
  }
}

.video-banner__title {
  color: inherit;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: $font-size-xxxl;

  @include medium {
    font-size: 2.88625rem;
  }

  @include large {
    font-size: 4.11rem;
  }
}

.video-banner__subtitle {
  margin-bottom: 1rem;
  display: none;

  @include medium {
    display: block;
  }
}
