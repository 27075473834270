.section-lead-in {
  text-align: center;
  margin-bottom: 100px;
  margin-top: 85px;

  @include maxbp($large) {
    margin-bottom: 60px;
    margin-top: 75px;
    padding: 0 16px;
  }

  h2 {
    margin-bottom: 38px;

    @include maxbp($large) {
      font-size: 26px;
      letter-spacing: 0.39px;
      line-height: 36.5px;
      padding: 0 0 30px;
      margin: 0;
    }
  }
}

.field_card_details {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-top: 30px;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  @include maxbp($large) {
    flex-direction: column;
    align-items: inherit;
  }
}

/* Recommerce faq section */

.outlets-faq {
  background: $gray-90;
  padding: 60px 90px 66px 90px;

  @include maxbp($large) {
    padding: 34px 16px 65px;
  }

  h2 {
    padding-bottom: 0;
    margin: 0;

    @include maxbp($large) {
      font-size: 26px;
      letter-spacing: 0.39px;
      line-height: 36.5px;
      padding: 0 0 60px;
    }
  }

  .views-element-container {
    padding: 0;
  }

  .faq-list-desc + p {
    display: none;
  }

  .cta-link {
    font-size: 18px;
  }
}

.card_component {
  position: relative;

  @include maxbp($large) {
    margin-bottom: 50px;
  }

  @include maxbp($medium) {
    margin-bottom: 0;
  }

  .field_card_heading {
    font-size: 25.63px;
    letter-spacing: -0.2px;
    line-height: 38.44px;
    font-family: $font-bold;
    margin-top: 16px;
  }

  .text-long {
    font-size: 18px;
    line-height: 27px;
    margin-top: 16px;

    a {
      color: $delta-red;
      border-bottom: 1px solid $delta-red;
      text-decoration: none;
    }

    p {
      @include maxbp($medium) {
        margin-bottom: 0;
      }
    }
  }

  img {
    margin: 0 auto;
  }

  .field_card_identifier {
    position: absolute;
    top: 0;
    font-size: 50px;
    line-height: 0.8;
    font-family: $font-bold2;
    color: $delta-red;
  }
}

.product_widget {
  .discount-price {
    text-decoration: line-through;
    color: #b3b3b3;
  }

  .price-weight {
    font-weight: 700;
  }

  .productslider_heading .h2 {
    margin: 0;
  }
}

.bg-color-padding {
  padding: 79px 90px 66px 90px;

  @include maxbp($large) {
    padding: 30px 20px 30px 20px;
  }
}

.productslider_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding: 0 0 37px;

  @include maxbp($large) {
    flex-flow: column;

    h2 {
      padding-bottom: 12px;
    }
  }

  .cta-link {
    font-size: 18px;
  }
}

.outlet_card_section {
  padding: 90px 90px 109px;

  @include maxbp($large) {
    padding: 52px 16px;
  }

  @include maxbp($medium) {
    padding-top: 96px;
    padding-bottom: 90.24px;
  }

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.left {
    text-align: left;
    justify-content: left;
  }

  .field_title {
    font-size: 36.49px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 41.05px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-family: $font-bold;

    @include maxbp($large) {
      font-size: 25.63px;
      letter-spacing: -0.2px;
      line-height: 38.44px;
      font-family: $font-bold;
      text-align: center;
    }

    @include maxbp($medium) {
      letter-spacing: 0.39px;
      padding-bottom: 55px;
    }

    & + .text-long {
      max-width: 820px;
      margin: 0 auto 30px auto;
      font-size: 18px;
      line-height: 27px;
    }
  }

  .field_card_details {
    > div .card_component img {
      @include maxbp($grid-lg) {
        max-width: 60%;
      }

      @include maxbp($medium) {
        max-width: 60%;
      }

      @include maxbp($grid-sm) {
        max-width: 100%;
      }
    }

    @include maxbp($large) {
      padding: 16px 24px;
      align-items: center;
    }

    @include maxbp($medium) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

a.outlets-new::before {
  content: "NEW";
  position: absolute;
  top: -3px;
  font-size: 12.64px;
  letter-spacing: -0.2px;
  line-height: 18.96px;
  color: $delta-red;
  right: -8px;
  font-family: $font-bold;

  @include maxbp($r-1100) {
    left: 68px;
    top: 5px;
    right: inherit;
  }
}

.Outlet-clp-page {
  .banner-block.banner-block-medium {
    height: 100%;
  }

  h2 {
    font-size: 36.49px;
    letter-spacing: 0.5px;
    line-height: 41.05px;
  }

  @include maxbp($large) {
    .banner-content {
      font-size: 16px;
    }

    .main {
      padding: 53px 0 30px;
    }

    h2,
    .bg-color-padding h2 {
      font-size: 25.63px;
      letter-spacing: -0.2px;
      line-height: 38.44px;
      font-family: $font-bold;
    }

    .banner-block .grid-container {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @include large {
    .banner-content {
      text-align: left;
      max-width: 450px;
    }
  }

  .main {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .product-tile-slider {
    margin: 0;

    & .slick-arrow.slick-prev {
      left: -10px;
    }

    & .slick-arrow.slick-next {
      right: -10px;
    }

    .product-tile-slider__item {
      .product-tile {
        .product-tile__body,
        .product-tile__overlay {
          a.no-line {
            h6.product-tile__title {
              font-family: $font-body;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  @include large {
    .banner-block .page-title p {
      font-size: 3.653125rem;
      text-indent: -4px;
    }

    .slick-slide {
      padding: 0 8px;
    }
  }

  @include maxbp($large) {
    footer {
      h2 {
        text-align: left;
      }
    }
  }

  @include medium {
    .region-banner {
      .banner__button-group {
        margin-top: 18px;

        .button {
          max-width: 224px;
          height: 48px;
        }
      }

      .banner-block .grid-container {
        padding-bottom: 48px;
      }
    }
  }

  .cta-link--sm {
    color: $delta-punch;
  }

  @include maxbp($medium) {
    .product-tile {
      border: 0 none;
    }
  }

  .section-lead-in {
    h2 {
      margin-bottom: 43px;

      @include maxbp($large) {
        margin-bottom: 0;
      }

      div {
        @include maxbp($medium) {
          padding-right: 32px;
          padding-left: 32px;
        }
      }
    }

    .button {
      max-width: 224px;
      height: 48px;
    }

    @include maxbp($medium) {
      margin-top: 78px;
    }
  }

  .banner-content a {
    color: $white;
  }

  h6.product-tile__title {
    font-size: 0.88875rem;
    line-height: 1.5;
    font-weight: 700 !important;
    font-family: $font-bold !important;
  }

  .banner-block .page-title p {
    margin: 0;
    font-size: inherit;
  }
}

.banner__footer {
  bottom: 25px;

  .cta-link--sm {
    font-size: 12.64px;
  }

  a {
    margin-right: 3px;
  }
}

.bg-color-padding h2 {
  margin-bottom: 0;
  font-size: 36.49px;
}

.Outlet-clp-page,
.recertified-plp-page {
  .footer-info .block-container {
    padding: 0;

    p {
      margin: 0;
      padding: 0;
    }
  }

  footer.footer {
    margin-top: 0;
  }
}

div#block-habitatforhumanity {
  background-color: $gray-90;
  padding: 16px 0;
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
}

.price-weight {
  font-weight: bold;
}

@include maxbp ($grid-md) {
  .product-tile .no-line {
    align-items: center;
  }
}

.field_menu_custom_class {
  display: none;
}

@include maxbp ($large) {
  .product_widget .productslider_heading .h2 {
    font-size: 26px;
    letter-spacing: 0.39px;
    line-height: 36.5px;
  }

  .product-tile-slider {
    padding: 0 25px;
  }
}

.discount-list-price {
  text-decoration: line-through;
  color: #d3d3d3;
}

.color-red {
  color: $delta-red;
  border-bottom: 1px solid $delta-red;
  text-decoration: none;
}

.pdp-prod-block .pdp-img-btn .cta-video {
  display: none;
}

.field_grid_column_layout {
  display: none;
}
