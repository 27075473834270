.pdp-fealist-wrapper {
  .pdp-fealist,
  .pdp-fea-img-wrapper {
    @include maxbp($large) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.pdp-fea-desc {
  font-size: 16px;
  margin: 0 0 $space-ten*5;
}

.pdp-fealist {
  ul {
    margin: 0;
  }

  li {
    position: relative;
    font-size: 18px;
    margin: 0 0 $space-ten*2;
    list-style-type: none !important;

    &::after {
      content: "";
      display: block;
      background: $delta-red;
      height: 5px;
      width: 5px;
      border-radius: 100%;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}

.pdp-fea-img-block {
  margin: 0 0 $space-ten*5;

  .pdp-fea-img-title {
    h6.h6 {
      font-size: 18px;
      font-family: $font-bold;
      margin: 0 0 $space-ten+5;
      text-transform: none;
      letter-spacing: 1px;
      font-weight: normal;
    }
  }

  .pdp-fea-img-list {
    margin: 0 0 $space-ten+5;

    .pdp-fea-img,
    .pdp-fea-img-name,
    .pdp-tooltip {
      display: inline-block;
      vertical-align: middle;
    }

    .pdp-fea-img {
      line-height: 0;

      img {
        width: 30px;
      }
    }

    .pdp-fea-img-name {
      h6.h6 {
        font-size: 16px;
        font-family: $font-light;
        text-transform: none;
        letter-spacing: normal;
        margin: 0 0 0 $space-ten/2;
      }
    }
  }
}
