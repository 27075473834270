.path-blogs {
  .banner-block {
    height: auto;

    .grid-container {
      @include maxbp($grid-md) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .page-title {
      color: $black !important;
      font-weight: 700;

      p {
        text-transform: uppercase;
        text-align: center;
        font: Bold 96px/134px $font-bold2;
        line-height: 1;

        @include maxbp($old-desktop) {
          font-size: 44px;
        }
      }
    }

    .subheader-content {
      font: 29px/29px $font-roman;
      font-weight: 500;
      text-transform: capitalize;
      text-align: center;
      letter-spacing: 1px;

      @include maxbp($old-desktop) {
        font-size: 22px;
      }
    }

    @include breakpoint($old-desktop) {
      .button-content {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        max-width: 750px;
        margin: 0 auto;
      }
    }

    .banner-content {
      color: $black;

      p {
        margin: 0 0 0 32px;

        @include maxbp($old-desktop) {
          margin: 20px;
          text-align: center;
        }
      }
    }

    .banner__button-group {
      display: flex;
      align-items: center;
      margin-top: 0;
    }
  }

  .views-element-container {
    .h3 {
      text-align: left;
      font: Bold 23px/28px $font-heading;
      letter-spacing: 0.21px;
      color: $black;
      opacity: 1;
      padding-top: 15px;

      a {
        text-decoration: none;
      }
    }

    .field-content {
      p {
        text-align: left;
        letter-spacing: 0.15px;
        opacity: 1;
        font-family: $font-roman;
        display: contents;
      }
    }

    .u-text-xs {
      text-transform: uppercase;
    }

    .pager__items {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .blazy--grid {
    .grid {
      padding: 0 0.625rem 2.25rem;
    }

    .views-field-field-article-image {
      img {
        width: 100%;
      }
    }
  }
}

//Article Page
.node--type-blog {
  .grid-container {
    @include breakpoint($large) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .main {
    padding-top: 20px;

    @include maxbp($r-1100) {
      padding: 0 10px 30px;
    }
  }

  .breadcrumb-block {
    @include breakpoint($large) {
      left: 0;
    }
  }

  .breadcrumb {
    @include maxbp($old-desktop) {
      padding: 0;
    }
  }

  .views-element-container {
    height: auto;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .banner-block {
    height: auto;
    background-color: $white !important;
    max-width: 750px;
    margin: 0 auto;
  }

  .page-title {
    max-width: 750px;
    margin: 0 auto;
    text-align: left;

    @include maxbp($old-desktop) {
      padding-bottom: 20px;
    }

    p {
      font-family: $font-bold;

      @include maxbp($old-desktop) {
        font-size: 41px;
        text-align: center;
      }
    }
  }

  .h2 {
    font-size: 1.75rem;

    @include maxbp($old-desktop) {
      text-align: center;
    }

    @include breakpoint($old-desktop) {
      padding-top: 20px;
    }
  }

  .blog_subtitle {
    font-size: 1.75rem;
    font-family: $font-light;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: none;

    @include maxbp($old-desktop) {
      text-align: center;
    }

    @include breakpoint($old-desktop) {
      padding-top: 20px;
    }
  }

  @include breakpoint($old-desktop) {
    #block-mainpagecontent {
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .text-long {
    p {
      margin-top: 10px;

      a {
        font-weight: 700;

        em {
          font-family: $font-roman;
        }
      }
    }
  }
}

.hide {
  display: none !important;
}
