.tooltip-button {
  display: inline-block;
  padding: 0;
  width: 20px;
  height: 20px;
  border: 0;
  background-color: transparent;
  appearance: none;
  outline: none; // TODO: Remove after taboutline is implemented

  > svg {
    display: block;
  }
}

.tooltip {
  min-width: 300px;
  color: $gray-10;
  border: solid 1px $gray-80;
  background-color: $white;
}

.tooltip__header {
  padding: 0.5rem 1rem;
  font-family: $font-bold2;
  background-color: $gray-80;
}

.tooltip__body {
  padding: 0.75rem 1rem;
}

.tippy-tooltip {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);
  border-radius: 2px;
  text-align: left;
}

.tippy-tooltip.dark-theme[x-placement^="top"] .tippy-arrow {
  border-top-color: $white;
}

.tippy-tooltip.dark-theme[x-placement^="bottom"] .tippy-arrow {
  border-bottom-color: $white;
}

.tippy-tooltip.dark-theme[x-placement^="left"] .tippy-arrow {
  border-left-color: $white;
}

.tippy-tooltip.dark-theme[x-placement^="right"] .tippy-arrow {
  border: 0 !important;
  border-right-color: #ccc;

  &::after,
  &::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    left: -8px;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 8px;
    margin-top: -8px;
  }

  &::before {
    left: -10px;
    border-color: rgba(255, 0, 0, 0);
    border-right-color: $gray-80;
    border-width: 9px;
    margin-top: -9px;
  }
}

.tooltip_pdp {
  @include breakpoint(1025px) {
    width: auto;
  }

  .tooltip__fullcontent {
    display: flex;

    .tooltip_image {
      align-self: center;
      width: 23%;
      padding-right: 10px !important;
    }
  }

  .title {
    text-transform: uppercase;
    margin: 0;
    font-family: $font-bold2;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 5px;

    @include maxbp(1024px) {
      font-size: 18px;
    }
  }

  .content {
    font-size: 13px;
    margin: 0;
    text-align: center;
    font-weight: 500;

    @include maxbp(1024px) {
      font-size: 15px;
    }
  }

  .warning-message {
    text-align: center;
    font-family: $font-bold2;
    font-size: 15px;
    line-height: 17px;
    padding-top: 3px;

    @include maxbp(1024px) {
      font-size: 17px;
    }
  }
}

.tooltip_pdp .tooltip_image + div {
  width: 73%;
}
