.innovation-image-grid {
  margin-bottom: 40px;

  @include large {
    margin-bottom: 80px;
    display: flex;
  }
}

.innovation-image-grid__item {
  display: block;
  width: 100%;

  @include medium {
    flex: 1 0 (100% / 3);
    max-width: (100% / 3);

    & + & {
      border-left: 1px solid $gray-50;
    }
  }
}

.innovation-related-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 100%;
    margin-top: 24px;
    justify-content: center;

    @include small {
      width: 400px;
    }
  }
}

.innovation-product-features {
  @include medium {
    display: flex;
    align-items: center;
  }
}

.innovation-product-features__content {
  font-size: $font-size-lg;

  h3 {
    text-align: left;
    margin-bottom: 24px;
  }

  ul {
    margin: 0 0 0 1em;
    padding: 0;
    list-style-type: disc;

    .list-item {
      padding: 0;

      + .list-item {
        margin-top: 16px;
      }
    }
  }

  @include medium {
    padding-left: 40px;
  }
}

.innovation-product-features__image {
  @include maxbp($medium) {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }
}

.innovation-upstile.node--type-innovation-detail-page {
  .innovation-navigation {
    margin: 0;
  }

  .tabs__link--local-tasks {
    padding: 10px 25px;
  }

  #Why_UPstile {
    .layout--twocol-section {
      text-align: left;

      .h2--light {
        font-size: 32px;
      }
    }

    margin-top: 70px;
  }

  #Materials_Needed {
    margin-top: 70px;
    background-color: #292929;
    color: $white;

    .h2--light {
      padding: 1.6em 0 1.2em;
      font-size: 32px;
      text-align: center;
      color: $white;
      margin: 0;
    }
  }

  #How_to_Select_Tiles {
    margin-top: 70px;
    text-align: left;
    padding: 0 15px;

    h2 {
      font-size: 32px;
      margin: 0 0 1em;
    }

    h3 {
      font-size: 24px;
    }
  }

  #Install {
    color: $white;

    .h5--light {
      color: $white;
      text-align: center;
      margin: 2em 0 1em;
      display: inline-block;
      width: 100%;
    }

    .two-column-section {
      background-color: #292929;
    }
  }

  #Overview {
    .isb__content {
      hr {
        display: none;
      }
    }
  }

  .layout--twocol-section {
    text-align: left;
    align-items: inherit;
  }

  .single-image-slider {
    max-width: 677px;
    margin: 0 auto 70px;
    padding-top: 0;
  }

  .single-image-slider .slick-arrow.slick-prev {
    left: -65px;
    outline: none;
    width: 50px;
    height: 50px;
  }

  .single-image-slider .slick-arrow.slick-next {
    right: -65px;
    outline: none;
    width: 50px;
    height: 50px;
  }
}

.bg-gray {
  background-color: #292929;
  color: $white;
}

.innovation-delta-custom-reflections {
  .layout--twocol-section.layout--twocol-section--50-50 > .layout__region--first,
  .layout--twocol-section.layout--twocol-section--50-50 > .layout__region--second {
    padding: 10px;
  }

  .node--type-innovation-detail-page .layout--twocol-section {
    align-items: start;
  }

  #FAQs {
    .figure__caption {
      font-style: normal;
    }

    h3 {
      font-size: 24px;
    }
  }
}

.innovation-tempassure {
  .node--type-innovation-detail-page .layout--twocol-section {
    align-items: start;
  }
}

.innovation-toilet-seat {
  .h5p-image-hotspot-popup.h5p-image.h5p-image-hotspot-has-header {
    width: 55% !important;
  }
}

.innovation-navigation {
  @include maxbp($large) {
    .tabs__nav {
      display: block;
      flex-direction: column;

      li {
        border-bottom: 1px solid $white;
        border-right: 0 none;
      }
    }

    .innovation-navigation {
      margin: 0 0 50px 0 !important;
    }
  }
}

@include maxbp($large) {
  .node--type-innovation-detail-page {
    .hotspot-banner {
      .modal__close {
        top: 5px;
        right: 5px;
      }

      .popup--left--content {
        padding-top: 15px;
      }

      .popup--right--content {
        padding-top: 15px;
        max-width: 100%;
        padding-left: 0;
      }
    }
  }
}

.node--type-innovation-detail-page {
  .popup--right--content {
    padding-left: 12px;

    a {
      display: inline;
    }
  }
}
