.design-experience-design-trust {
  .layout__region--first .video {
    padding-top: 0;
    padding-right: 60px;
    margin-bottom: 40px;
  }

  .views-element-container {
    margin-bottom: 40px;
  }

  .layout__region--second {
    .section-lead-in.paragraph-component {
      padding-left: 40px;
    }

    .text-long a {
      display: inline-block;

      img {
        width: 40px;
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .design-trust-news-projects {
    display: block;

    > div {
      display: inline-block;
      width: 33%;
      vertical-align: top;
      border: 1px solid $gray-70;
      padding: $space-ten;
      min-height: 530px;
      margin-top: 10px;

      @include maxbp($large) {
        width: 100%;
        min-height: auto;
      }
    }

    img {
      margin: 0 auto;
    }

    h3 {
      font-size: 28px;
      margin: 5px 5px 20px;
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1024px) {
    .layout__region--second .section-lead-in.paragraph-component {
      padding: 20px;
    }

    .layout__region--first {
      img {
        margin: 0 auto;
      }

      .video {
        padding-right: 0;
      }
    }

    .layout--twocol-section {
      margin-bottom: 40px;
    }

    .layout--twocol-section > .layout__region {
      flex: 0 1 100%;
    }
  }

  .design-trust-view-all-post {
    margin: 0 0 $space-ten*6;
  }

  .single-image-slider-out-caption {
    margin: 30px auto;
  }
}
