.icon {
  fill: currentColor;
}

.circle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $delta-red;
}
