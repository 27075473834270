// See http://refills.bourbon.io/components/#flashes.
$base-spacing: 1.5em !default;
$statuses: (
  "warning": #f9fb93,
  "error": #fbe3e4,
  "status": #cfefc2,
) !default;

@each $status-type, $color in $statuses {
  .status--#{$status-type} {
    background-color: $color;
    color: shade($color, 60%);
    display: block;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 2;
    text-align: center;

    a {
      color: shade($color, 70%);
      text-decoration: underline;

      &:focus,
      &:hover {
        color: shade($color, 90%);
      }
    }

    .placeholder {
      font-style: normal;
      font-weight: 800;
    }
  }
}
