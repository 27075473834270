.commonparts {
  .catalog-grid__item {
    width: calc(50% - 4px);

    @include large {
      width: calc(25% - 4px);
    }
  }
}

.commonparts-preview__grid {
  > div {
    @include medium {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include large {
      grid-template-columns: repeat(4, 1fr);
    }

    .product-tile {
      margin-bottom: 8px;
    }
  }
}

.commonparts-preview__item {
  display: none;

  &.is-active {
    display: block;
  }
}

.commonparts-preview__body {
  @include large {
    display: flex;
  }
}

.commonparts__figure {
  margin-bottom: 16px;

  @include large {
    flex-shrink: 0;
    width: 22.5%;
    margin-right: 24px;
    margin-bottom: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  figcaption {
    background-color: $delta-red;
    text-align: center;
    padding: 8px 0;
    color: $white;
    font-size: $font-size-lg;
    font-family: $font-bold2;
  }
}

.commonparts-preview__title {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid $gray-70;
}
