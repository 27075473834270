.cartride-page-wrapper {
  .catalog-grid {
    display: block;

    .catalog-grid__item {
      width: 24.5%;
      display: inline-block;
      vertical-align: top;
      padding: 0 1%;

      .cartridge-block {
        width: auto;
      }
    }
  }
}

@media (max-width: 766px) {
  .cartride-page-wrapper .catalog-grid .catalog-grid__item {
    width: 100%;
  }
}
