//
// Generic header styles:
// All arguments are optional. If not defined, the defaults below will be used
//

$color-heading: $gray-10;

/* stylelint-disable selector-list-comma-newline-after */

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0 0 0.5em;
  font-family: $font-heading;
  font-weight: 700;
  line-height: 1.1;
  color: $color-heading;
  text-transform: uppercase;
  letter-spacing: 2px;

  &__link {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &--regular {
    text-transform: inherit;
    letter-spacing: normal;
  }

  &--light {
    text-transform: inherit;
    font-family: $font-heading-light;
    font-weight: 300;
    letter-spacing: -0.015em;
  }

  &--oblique {
    font-family: $font-oblique;
    font-style: italic;
  }

  &--underline {
    text-decoration: underline;
  }
}

h1, .h1 {
  font-size: 2.88625rem;
}

h2, .h2 {
  font-size: 2.565625rem;
}

h3, .h3 {
  font-size: 2.280625rem;
}

h4, .h4 {
  font-size: 2.0275rem;

  small {
    font-size: 1.425rem;
  }
}

h5, .h5 {
  font-size: 1.801875rem;

  small {
    font-size: 1.26875rem;
  }
}

h6, .h6 {
  font-size: 1.601875rem;

  small {
    font-size: 1.125rem;
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  &--light {
    font-family: $font-body;
    font-weight: normal;
  }
}

/* stylelint-enable */
