.SocialName {
  font-size: 14.2px;
  line-height: 2.25;
  color: $gray-10;
  text-decoration: none;

  &:hover {
    font-weight: bold;
    color: $gray-30;
  }
}

.social-link {
  display: inline-flex;
  align-items: flex-start;
  font-size: 14.2px;
  line-height: 1.48;
  color: $gray-10;
  text-decoration: none;

  &:hover {
    font-weight: bold;
    color: $gray-30;
  }
}
