.card__title {
  //@include heading-large($font-size: 1.3rem);
  @include no-bottom;

  &-link {
    @include link;
  }
}

.card__subtitle {
  //@include heading-medium($font-size: 1rem);
  @include no-bottom;

  color: $gray-10;
}

.card__body {
  margin: 0.7em 0 1em;
}

.card__link {
  @include link;
}

.card__button {
  //@include button;
}

// Variations
.card--bg {
  background-color: $gray-80;
  padding: 1em;
}
