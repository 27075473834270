.tabs__link,
.tabs__link--local-tasks {
  //background: $gray-80;
  text-transform: uppercase;
  color: $gray-10;
  //padding: $space-ten $space-ten*4;
  padding: 10px 40px;
  display: flex;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 2px;
  font-family: $font-bold;
  position: relative;
  z-index: 2;
  text-align: center;
  align-items: center;

  &::before {
    background: $gray-80;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    transition: all ease 0.5s;
    width: 100%;
  }

  &:hover {
    color: $white;

    &::before {
      width: 100%;
      background: $delta-red;
    }
  }

  &.is-active {
    color: $white;

    &::before {
      background: $gray-10;
    }
  }

  @include maxbp($medium) {
    width: 100%;
    justify-content: center;
  }
}
