// Create a mixin for paragraph styles that can be implemented
// in components with other classnames.
@mixin paragraph($margin: 0 0 1em) {
  margin: #{$margin};
}

@mixin body-copy {
  font-family: $font-body;
  font-size: 16px;
  line-height: 1.5;
}

body {
  @include body-copy;

  color: $gray-10;
}

.paragraph {
  @include paragraph;

  &--bold {
    font-family: $font-bold2;
  }

  &--oblique {
    font-family: $font-oblique;
  }

  &--underline {
    text-decoration: underline;
  }
}

strong {
  font-family: $font-bold2;
  font-weight: 700;
}

em {
  font-family: $font-oblique;
  font-style: italic;
}

.blockquote {
  font-style: italic;
  border-left: solid 3px $color-border;
  margin-left: 1em;
  padding-left: 1em;
}

.hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: currentColor;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  p {
    @extend .paragraph;
  }

  blockquote {
    @extend .blockquote;
  }

  hr {
    @extend .hr;
  }
}
