.dtilb-wrapper {
  background: $gray-10;
  color: $white;
  text-align: center;
  padding: $space-ten*5 $space-ten*3;
  border-bottom: 2px solid $gray-50;

  .dtilb-data {
    width: 100%;
    max-width: 950px;
    margin: auto;
  }

  .dtilb-title {
    h4 {
      color: $delta-red;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-size: 37px;
      margin: 0 0 $space-ten*3;
    }
  }

  .dtilb-desc {
    margin: 0 0 $space-ten+15;

    p {
      margin: 0;
    }
  }

  .dtilb-cta-link {
    a.button {
      text-transform: none;
      letter-spacing: normal;
      font-family: $font-body;
      padding: 7px 20px;
    }
  }

  .dtilb-image {
    margin: 0 0 $space-ten*4;

    img {
      margin: auto;
    }
  }
}
