.featured-banner {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: $white;

  @include large {
    .grid-container {
      min-height: 700px;
    }
  }

  .grid-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    @include maxbp($large) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}

.featured-banner--light {
  color: $gray-10;
}

.featured-banner--dark {
  color: $white;
}

.featured-banner-content {
  font-size: $font-size-lg;
  text-align: center;

  @include large {
    text-align: left;
    max-width: 620px;

    .featured-banner--text-centered & {
      text-align: center;

      .button {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .featured-banner--content-centered & {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include xl {
    margin-left: 56px;
  }
}

.path-bathroom,
.path-kitchen {
  .banner-component_content {
    position: relative;
  }

  .featured-banner--dark + .featured-banner-content {
    @include breakpoint($old-desktop) {
      color: $white;
    }
  }

  .featured-banner--light + .featured-banner-content {
    @include breakpoint($old-desktop) {
      color: $black;
    }
  }

  .featured-banner-content {
    @include maxbp($old-desktop) {
      background: #e6e6e8;
      padding-top: 30px;
    }

    @include large {
      max-width: 100%;

      .featured-banner__title,
      .featured-banner__paragraph {
        max-width: 572px;
        text-shadow: 0 0 2px rgb(0, 0, 0);
      }
    }
  }

  .featured-banner__button-group {
    padding: 0 0 115px;

    @include maxbp($old-desktop) {
      padding: 0 0 50px;
    }
  }

  .views-element-container {
    .grid-container {
      @include maxbp($old-desktop) {
        margin-top: 40px;
        padding: 0;
      }
    }
  }
}

.featured-banner__intro {
  font-size: $font-size-xxxl;
  margin: 0 0 16px;
}

.featured-banner__title {
  margin-bottom: 35px;
  font-size: 2.565625rem;
  letter-spacing: 0.01em;
  color: inherit;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  @include large {
    font-size: 3.653125rem;
  }
}

.featured-banner--title-divider {
  .featured-banner__title {
    padding-bottom: 16px;

    &::after {
      position: relative;
      display: block;
      top: 17px;
      width: 33px;
      height: 3px;
      left: 50%;
      transform: translate(-50%);
      background-color: $delta-red;
      content: "";

      @include large {
        left: auto;
        transform: none;
        width: 64px;
      }
    }
  }
}

.featured-banner--title-divider.featured-banner--text-centered {
  .featured-banner__title {
    &::after {
      @include large {
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}

.featured-banner__subtitle {
  font-size: $font-size-xxxl;
  color: inherit;
  letter-spacing: 0;
  text-transform: none;

  @include large {
    font-size: 2.565625rem;
  }
}

.featured-banner__paragraph {
  margin-bottom: 24px;

  @include large {
    margin-bottom: 32px;
  }
}

.featured-banner__button-group {
  @include large {
    display: flex;

    .button {
      margin-right: 4px;
    }

    .featured-banner--text-centered & {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include maxbp($large) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    justify-content: center;
    margin-top: 8px;
  }
}
