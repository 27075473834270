.innovation-shower-experience {
  .breadcrumb__link {
    color: white;
  }

  .featured-innoslider__item {
    margin-bottom: 80px;
  }

  .featured-card {
    min-height: 390px;
    padding: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  }

  .design-experience-three-image-section {
    background: -moz-linear-gradient(top, #fff 0, #f4f4f4 92%, #f4f4f4 100%);
    background: -webkit-linear-gradient(top, #fff 0, #f4f4f4 92%, #f4f4f4 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#f4f4f4', GradientType=0);
    -webkit-box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    padding: 80px 100px;
    margin: 0 auto 70px;

    @include maxbp($medium) {
      padding: 80px 20px;
    }
  }

  .layout--twocol-section {
    .layout__region {
      background: #f5f5f7;
      text-align: center;
      flex: 0 1 49% !important;
      margin: 0 0.5% 50px;
      padding: 50px 0 100px;

      @include maxbp($grid-lg) {
        padding: 50px 10px 100px;
      }

      @include maxbp($r_600) {
        flex: 0 1 100% !important;
      }

      img {
        margin: auto;
      }

      h1 {
        text-transform: capitalize;
        font-weight: normal;
        letter-spacing: normal;
        font-size: 42px;
        margin: 40px 0 15px;

        strong {
          font-weight: normal;
        }
      }

      h4,
      h3 {
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        font-size: 20px;
        line-height: 26px;

        strong {
          font-weight: normal;
        }
      }
    }
  }

  .shower-spray-blocks {
    max-width: 1200px;
    margin: 0 auto;

    [class*=grid-col] {
      padding: 3px;
    }

    .aht-wrapper {
      padding: 0;

      .aht-title {
        padding: 15px 4px;

        p {
          margin: 0;
        }
      }

      .aht-hover-title {
        padding: 1px 4px 10px;

        p {
          margin: 0;
        }
      }

      .aht-hover-desc {
        p {
          margin: 0;
        }
      }

      .aht-text-data .aht-links-wrapper {
        bottom: 5px;
      }

      .aht-text-data {
        padding: 20px 15px 5px;
      }
    }
  }

  .main {
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .innovation-secondary-banner {
    background: black;
    min-height: auto;

    .isb__content {
      padding: 30px 7% 80px 0 !important;

      @include maxbp($large) {
        padding: 50px !important;
      }

      p {
        margin: 0;
      }

      hr {
        background: #ac0535;
        margin: 40px 0 0;
      }

      h3 {
        font-size: 40px;
        font-weight: normal;
      }
    }
  }

  .section-lead-in h3 {
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-size: 23px;
    margin: 0 0 10px;

    strong {
      font-weight: normal;
    }
  }

  .blog-card__category {
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-family: $font-roman;
  }

  .dtilb-wrapper .dtilb-title a {
    text-decoration: none;
  }

  .text-align-center.shower-spray-title {
    font-size: 30px;
    margin: 40px 0 60px;
    font-weight: normal;
  }

  @include maxbp($grid-lg) {
    .featured-title-grid.grid {
      display: block;
      width: 300px;
      margin: auto;
    }

    .grid-col-sm-5th {
      max-width: none;
      padding: 0;
    }

    .featured-innoslider__item {
      margin-bottom: 20px;
    }
  }

  .banner {
    @include maxbp($old-desktop) {
      background-position: right !important;

      &::before {
        background-color: transparent !important;
      }

      .banner__headline {
        text-align: left;
      }
    }

    .banner__title {
      padding: 0;

      p {
        margin: 0;
      }

      @include maxbp($large) {
        padding-bottom: 20px;
      }

      &::after {
        top: 0;

        @include maxbp($large) {
          left: 17px !important;
        }
      }
    }

    .banner-content {
      .u-text-base {
        font-weight: 800;

        @include breakpoint($mega-menu-mobile) {
          font-size: 32px !important;
        }

        @include maxbp($old-desktop) {
          font-size: 26px !important;
        }
      }
    }
  }
}
