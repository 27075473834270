.u-text-center {
  text-align: center !important;
}

.u-text-underline {
  text-decoration: underline !important;
}

.u-text-normal {
  font-family: $font-body !important;
  font-weight: 400 !important;
}

.u-text-bold {
  font-family: $font-bold2 !important;
  font-weight: 700 !important;
}

.u-text-oblique {
  font-family: $font-oblique !important;
  font-style: italic !important;
}

.u-text-base {
  font-size: $font-size-base !important;
}

.u-text-xs {
  font-size: $font-size-xs !important;
}

.u-text-sm {
  font-size: $font-size-sm !important;
}

.u-text-lg {
  font-size: $font-size-lg !important;
}

.u-text-xl {
  font-size: $font-size-xl !important;
}

.u-text-xxl {
  font-size: $font-size-xxl !important;
}

.u-text-xxxl {
  font-size: $font-size-xxxl !important;
}

.u-text-screenreader {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
