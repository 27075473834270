.blog-card {
  display: block;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, rgba($gray-10, 0) 0%, rgba($gray-10, 0.7) 70%);
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    content: '';
  }

  &:hover::after {
    //background: linear-gradient(180deg, rgba($gray-10, 0.7) 0%, rgba($gray-10, 0.7) 100%);
    //background-image: none;
    background-color: rgba($gray-10, 0.4);
  }

  img {
    display: block;
    width: 100%;
  }
}

.blog-card__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: $font-size-xxl;
  line-height: 1.15;
  padding: 20px;
  color: $white;
  z-index: 20;

  @include large {
    font-size: 1.801875rem;
    padding: 24px;

    .blog-card--large & {
      font-size: 2.280625rem;
    }
  }
}

.blog-card__description {
  display: block;
}

.blog-card__category {
  font-size: $font-size-base;
  color: inherit;
}
