
.innovation-card {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: $white;
  box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.12), inset 0 0 0 1px $gray-80;
  text-decoration: none;

  &:hover {
    .innovation-card__overlay {
      transform: translate(0, -100%);
    }
  }
}

.innovation-card__img {
  display: block;
  width: 100%;
}

.innovation-card__title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 12px 16px;
  font-size: $font-size-base;
  text-transform: none;
  letter-spacing: 0;

  &::after {
    bottom: 100%;
    left: 24px;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: $white;
    border-width: 12px;
    margin-left: 0;
  }
}

.innovation-card__plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid $gray-70;
  border-radius: 50%;
  flex-shrink: 0;
}

.innovation-card__overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 100%;
  right: 0;
  left: 0;
  height: 100%;
  transition: transform 0.3s 0.03s cubic-bezier(0.41, 0.38, 0.36, 1);

  .innovation-card__title {
    &::after {
      display: none;
    }
  }

  p {
    margin: 0 16px;
    font-size: $font-size-sm;
    line-height: 1.35;
  }
}

.innovation-card__overlay__content {
  background-color: $white;
  padding-bottom: 48px;
}

.innovation-card__inverse-arrow {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: 24px;

  &::before {
    position: absolute;
    width: 44px;
    left: 0;
    bottom: 0;
    border-bottom: 24px solid white;
    border-right: 24px solid transparent;
    display: block;
    content: "";
  }

  &::after {
    position: absolute;
    left: 24px;
    bottom: 0;
    right: 0;
    border-bottom: 24px solid white;
    border-left: 24px solid transparent;
    display: block;
    content: '';
  }
}
