.sidebar-level-menu {
  .sidebar-main {
    margin: 0;
    padding: 0;

    > li {
      &:last-child {
        margin: 0;
      }
    }

    li {
      margin: 0 0 $space-ten+5;

      a {
        font-family: $font-bold2;

        &.sidebar-active {
          color: $delta-red;
        }
      }
    }

    .sidebar-sub {
      li {
        margin: $space-ten 0;

        a {
          font-family: $font-body;
        }
      }
    }
  }
}
