.intro-banner {
  background-color: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 412px; // TODO: Remove
  text-decoration: none;

  @include large {
    min-height: 700px;
  }
}

.intro-banner__logo {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  height: 134px;
  margin-bottom: 0;
  text-align: center;
  color: inherit;
  background-image: url("/../../images/hero-wave.svg");
  background-repeat: repeat-x;
  background-position: center center;
  background-size: auto 100%;

  @include large {
    height: 207px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 307px;

    @include large {
      width: 480px;
    }
  }
}

.intro_banner__technology {
  @extend .h2;

  display: block;
  position: relative;
  z-index: 2;
  font-size: 1.801875rem;
  margin-top: -1.1em;
  margin-bottom: 32px;
  color: $white;

  @include large {
    margin-bottom: 40px;
    font-size: 2.280625rem;
  }
}

.intro-banner__subtitle {
  color: $white;
  font-family: $font-roman;
  font-weight: normal;
  font-size: $font-size-xxxl;
  text-transform: none;
  letter-spacing: -0.01em;

  &::before {
    margin-bottom: 16px;
    position: relative;
    left: 50%;
    display: block;
    width: 64px;
    height: 3px;
    transform: translate(-50%);
    background-color: $delta-red;
    content: "";
  }

  em {
    font-family: $font-bold;
    font-weight: 700;
    margin-right: 0.1ch;
  }

  @include large {
    font-size: 2.0275rem;
  }
}
