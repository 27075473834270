.cartridge-wrapper {
  @include wrapper;

  padding-top: $space-ten*5 !important;

  .cartridge-block {
    float: left;
    width: 25%;
  }
}
