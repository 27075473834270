.innovation-secondary-banner {
  background: none;
  position: relative;
  background-color: $black;
  min-height: 560px;
}

.isb__image {
  @include maxbp($large) {
    display: block;

    img {
      width: 100%;

      @include maxbp($medium) {
        width: 200%;
        max-width: none;
      }
    }
  }
}

.isb__content {
  color: $white;
  font-size: $font-size-lg;
  padding: 40px 24px;

  p {
    width: 100%;
  }

  @include large {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 24px;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: $gray-30;
    margin: 0 0 32px;
  }

  h3 {
    color: inherit;
    width: 100%;

    &::after {
      display: block;
      width: 64px;
      height: 3px;
      margin-top: 16px;
      background-color: $delta-red;
      content: '';
    }
  }
}

.has-section-margin {
  margin-bottom: 40px;

  @include large {
    margin-bottom: 80px;
  }
}

.node--type-innovation-detail-page,
.innovation-in2ition,
.innovation-shower-experience {
  .innovation-secondary-banner {
    display: block;
    overflow: hidden;
    padding: 0;
    background-repeat: no-repeat;
    background-color: #292929;
    background-position: center left;

    @include maxbp($medium) {
      background-image: none !important;
      margin-bottom: 50px;
    }
  }

  .isb__content {
    position: static;
    width: 50%;
    float: right;
    padding: 20px 0 !important;

    @include maxbp($medium) {
      width: 100% !important;

      .video-card {
        display: block !important;

        .video-card__thumbnail {
          width: 100%;
          display: block;
          max-width: none;
          margin: 0;
        }

        .video-card__title {
          margin: 20px 0 0;
          text-align: center;

          em {
            position: static;
          }
        }
      }
    }

    a {
      color: $white;
    }
  }

  .isb__image {
    display: none;
  }

  @include maxbp($medium) {
    .isb__image.innovation-banner-image-mobile {
      display: block;
    }
  }
}
