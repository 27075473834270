.design-experience-inspired-design {
  .section-lead-in.paragraph-component {
    margin-bottom: 40px;

    img {
      margin: 0 auto;
    }

    .animation-link {
      font-size: 2.280625rem;
      font-weight: bold;
      font-style: italic;
    }
  }

  .inspire-design-guest-attendees {
    margin-bottom: 40px;

    h3,
    h3 strong {
      font-style: normal;
    }

    h3,
    h4 {
      font-size: 24px;
      margin: 20px;
      text-transform: none;

      a {
        text-decoration: none;
      }
    }
  }

  @media (min-width: 767px) {
    .layout__region .content {
      text-align: center;
    }
  }
}

.inspired-living {
  .blog-grid {
    display: block;

    & > div {
      width: 32.33%;
      float: left;
      padding: 0 1%;
      border: 1px solid #ccc;
      margin: 0 4px 13px 4px;
      min-height: 508px;
      display: inline-block;

      @include maxbp($mega-menu-mobile) {
        width: 100%;
        float: left;
        padding: 0 16px;
        margin: 15px 0;
        min-height: auto;
      }
    }

    &::after {
      background-image:
        -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(24, 25, 29, 0)),
          color-stop(70%, rgba(24, 25, 29, 0))
        );
      background-image: linear-gradient(180deg, rgba(24, 25, 29, 0) 0, rgba(24, 25, 29, 0) 70%);
    }

    &:hover::after {
      background-color: rgba(24, 25, 29, 0.4);
    }
  }

  .h2 {
    text-align: center;
  }

  .main-content .views-element-container {
    display: block;
    overflow: hidden;
    border-bottom: 1px solid #292929;
    margin: 0 0 30px;
    padding: 0 0 20px;
  }

  .blog-card__description {
    padding-top: 20px;
  }

  .blog-card__description a {
    text-decoration: none;
  }
}

.blog-grid.design-experience-three-image-section {
  display: block;
  overflow: hidden;

  .blog-grid__item {
    width: 32.6%;
    float: right;
    margin: 0 0 10px;

    @include maxbp($r-1100) {
      width: 100%;
      padding: 0;
    }

    &.blog-grid__item--large {
      width: 66.66667%;
      float: left;
      clear: none;
      margin: 0;
      padding-right: 4px;

      @include maxbp($r-1100) {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.node--type-innovation-detail-page {
  .banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0;
    width: 100%;

    @include large {
      min-height: 600px;
    }

    &::before {
      background-image: none;
    }
  }

  .breadcrumb {
    .breadcrumb__item {
      color: $white;
    }

    .breadcrumb__link {
      color: $white;
    }
  }

  .banner__title {
    padding-bottom: 0;

    p {
      margin: 0;
    }

    &::after {
      top: 0;
    }
  }

  @include maxbp($medium) {
    #Products {
      .h2 {
        text-transform: capitalize;
        text-align: center;
        font-size: 32px;
        padding: 15px 0 0;
      }
    }

    .faq-card {
      flex-direction: column;
    }

    .faq-card__index {
      width: 42px;
      padding: 10px 10px;
      margin-bottom: 0;
    }

    .faq-card__button {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @include breakpoint($grid-lg) {
    .banner .grid-container {
      padding-top: 104px;
      padding-bottom: 60px;
    }

    #Overview {
      .banner {
        min-height: 700px;

        .grid-container {
          padding-top: 120px;
          padding-bottom: 100px;
        }
      }
    }

    .banner-content {
      max-width: 50%;

      .u-text-base {
        font-size: 32px !important;
        font-weight: bold;
        line-height: 1;
      }
    }

    .main {
      padding: 0;
    }

    .innovation-image-grid {
      margin-bottom: 0;
    }

    #Products {
      .h2 {
        text-align: center;
        margin-top: 35px;
      }
    }

    .banner {
      padding-left: 35px;
    }

    .banner .breadcrumb-block {
      left: 6px;
    }
  }

  .isb__content {
    padding: 0 12px;

    .video-card {
      background: none;
      width: 100%;
      padding: 0;
      position: relative;

      .video-card__title {
        em {
          font-style: normal;
          position: absolute;
          top: 0;
          color: $delta-red;
          font-family: inherit;
        }
      }
    }

    .video-card__thumbnail {
      width: 58.33333333%;

      .icon {
        height: 8vw;
        width: 8vw;
        color: $delta-red;
      }
    }

    hr {
      background: $delta-red;
    }
  }

  .innovation-faq {
    .modal--faq .modal__body h6 {
      .u-text-base {
        font-weight: 600;
        font-size: 22px !important;
        margin-bottom: 15px;
        line-height: 1.4em;
      }
    }

    .modal--faq .modal--faq__figure {
      padding: 5px;
    }

    .faq-card__question {
      ul {
        padding: 0;
      }
    }
  }

  .faq-card__index {
    font-size: 2.88625rem;
    font-family: $font-light;
    font-weight: 300;
    color: rgba($gray-10, 0.2);
    line-height: 1.35;
  }

  .faq-card__button {
    border: 1px solid #eee;
    background: $white;
    color: $delta-red;
    outline: none;

    &:hover {
      background: $delta-red;
      color: $white;
    }
  }

  .tabs__link--local-tasks {
    &.active {
      color: $white;
      background-color: $delta-red;
    }

    &::before {
      background: none;
    }
  }

  .section-lead-in {
    .button {
      min-width: 320px;
      justify-content: center;
      padding: 0.75rem 0.8em;
    }
  }

  .di-prod-slider::before {
    background: -moz-linear-gradient(bottom, rgba(41, 41, 41, 0.5) 0, #292929 75%, rgba(41, 41, 41, 0.1) 90%, #292929 95%);
    background: -webkit-linear-gradient(bottom, rgba(41, 41, 41, 0.5) 0, #292929 75%, #292929 90%, #292929 95%);
    background: linear-gradient(to bottom, rgba(41, 41, 41, 0.5) 0, #292929 75%, #292929 90% #292929 95%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#80292929', endColorstr='#292929', GradientType=0);
  }

  .di-prod-slider {
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .review-slider {
    .rs-desc {
      .paragraph {
        font-style: italic;
      }
    }
  }

  @include large {
    &.innovation-touch2oxt-technology {
      #Products {
        .banner {
          min-height: 700px;
        }
      }
    }
  }
}

@include breakpoint($grid-lg) {
  .addison-bathroom-collection {
    .featured-banner .grid-container {
      display: block;
      padding-top: 15%;
    }
  }
}

.txt-red {
  color: $delta-red;
}

.txt-white {
  color: $white;
}

.bg-black {
  background-color: #000;
}

.bg-red {
  background-color: $delta-red;
}
