.company {
  .sub-menu-wrapper {
    margin-top: 20px;
  }

  .layout.layout--twocol-section--50-50 {
    margin: 40px auto;
  }

  .layout--threecol-section {
    .layout__region > div {
      border: 1px solid $gray-10;
      margin: 5px;
      padding: 10px;
    }
  }

  .img_element {
    display: block;

    @include breakpoint($r-1100) {
      padding-right: 20px;
    }
  }
}
