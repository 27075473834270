.download-card-grid {
  &.list-view {
    display: block;

    .download-card-grid__item {
      max-width: none;
      margin-bottom: 0;
    }
  }
}

.download-card-grid__item {
  margin-bottom: 24px;
}
