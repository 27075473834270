.path-sitemap {
  .main {
    padding-top: 18px;

    @include maxbp($medium) {
      padding: 18px 25px 40px;
    }

    a {
      text-decoration: none;
      color: $gray-30;
    }

    .sitemap-box-front {
      display: none;
    }

    .sitemap-message {
      h1 {
        text-transform: capitalize;
        color: $gray-30;
        font-family: $font-roman;
        font-weight: 500;
        letter-spacing: 0;
        font-size: 2.5rem;

        @include maxbp($medium) {
          font-size: $font-size-base *2;
        }
      }
    }

    .sitemap {
      ul.sitemap-menu:first-child {
        list-style-type: circle;
      }
    }

    .sitemap-box-menu {
      li > ul {
        li {
          list-style-type: square;
        }
      }
    }
  }
}
