.innovation-h20kinetic-showers {
  .video-on-tab-wrapper {
    float: left;

    .vot-tab-desc {
      color: white;
    }
  }

  #Overview .section-lead-in.paragraph-component.center {
    background: #18191d;
    text-align: center;
    color: white;
    padding: 50px 20px;

    h2.h2 {
      color: #ac0535;
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
    }

    .text-long {
      width: 68%;
      margin: auto;
    }

    p.text-align-center {
      margin: 0 auto 30px;
    }

    .embedded-entity img {
      margin: auto;
    }
  }
}
