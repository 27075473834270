.title-desc-link-image {
  text-align: center;

  @include wrapper;

  padding-top: $space-ten*3 !important;
  padding-bottom: $space-ten*4 !important;

  .tdli-title {
    h3.h3 {
      text-transform: none;
      font-size: 40px;
      font-weight: normal;
      margin: 0 0 $space-ten;
      letter-spacing: 1px;
    }
  }

  .tdli-more {
    a {
      padding: $space-ten $space-ten*2;
      padding: 7px $space-ten*6;
      text-transform: none;
      margin: 0 0 20px;
      font-weight: normal;
      font-family: $font-body;
      letter-spacing: normal;
    }
  }

  .tdli-desc {
    width: 55%;
    margin: auto;

    @include maxbp($large) {
      width: 100%;
    }
  }

  .tdli-image {
    img {
      margin: auto;
    }
  }
}
