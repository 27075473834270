.info-box {
  background-color: $gray-80;
  margin-bottom: 2%;
  padding: 1em;
  width: 100%;
}

.info-box__title {
  //@include heading-medium($font-size: 1rem);

  font-weight: 400;
  text-align: center;
}

.info-box__link {
  @include link;
}
