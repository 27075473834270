.serach-input {
  border-radius: 0;
  padding: $space-ten;
  border: none;
  border-left: 1px solid $gray-70;
  line-height: 30px;
  transition: all ease 0.5s;
  background: url("/themes/custom/delta_theme/images/icons/src/search.png") no-repeat 97% center !important;
  margin: 0;
  width: 260px;
  box-shadow: none !important;
  //width: 100%;

  @include maxbp($std_desktop) {
    width: 200px;
  }

  @include maxbp($big-desktop) {
    width: 180px;
  }

  &:active,
  &:focus {
    //padding: $space-ten;
    //background: $gray-90 -50px center;
    //width: 300px;
  }
}
