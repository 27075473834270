.mega-nav-wrapper {
  ul {
    li {
      a.new-link {
        display: inline-block;
        position: relative;

        sup.new-super-tag {
          color: #ac0535;
          right: -3px;
          text-transform: uppercase;
          top: -9px;
          font-weight: 700;
          font-family: $font-bold2;
          font-size: 9px;
        }
      }
    }
  }

  @include breakpoint($mega-menu-mobile) {
    .menu-prod-title {
      padding: $space-ten+25 0 $space-ten+5;
      border-bottom: 1px solid $gray-70;
      text-transform: capitalize;
      font-size: 20px;
      color: $gray-10;
      line-height: 22px;

      a {
        font-size: 20px !important;
        color: $gray-10 !important;
        margin: 0 !important;
        font-family: $font-body;
      }
    }

    .menu-prod-desc {
      font-size: 13px;
      margin: 0 0 $space-ten;
    }

    .collection-image {
      img {
        width: 100% !important;
      }
    }
  }

  > .mega-menu-nav {
    @include breakpoint($mega-menu-mobile) {
      > li.mega-menu-nav__item--with-sub {
        &.kitchen {
          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;

              &:first-child {
                width: 35%;
                margin: 0;
                padding: 0 2% 0 0;

                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }

              &:last-child {
                width: 34%;
                margin: 0;

                img {
                  width: auto;
                }
              }

              > .mega-menu-nav--sub-2 {
                > li {
                  width: 32.33%;
                  margin: 0;
                  padding: 0 20px 0 0;
                }
              }
            }
          }
        }

        &.two-5-col {
          > ul.mega-menu-nav--sub-1 {
            > li {
              &:first-child {
                width: 60%;
                margin: 0;
                padding: 0 2% 0 0;

                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }

              &:last-child {
                width: 39%;
                margin: 0;

                img {
                  width: auto;
                }
              }

              > .mega-menu-nav--sub-2 {
                > li {
                  width: 19%;
                  margin: 0;
                  padding: 0 20px 0 0;
                }
              }
            }
          }
        }

        &.two-4-col {
          > ul.mega-menu-nav--sub-1 {
            > li {
              &:first-child {
                width: 60%;
                margin: 0;
                padding: 0 2% 0 0;

                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }

              &:last-child {
                width: 39%;
                margin: 0;

                img {
                  width: auto;
                }
              }

              > .mega-menu-nav--sub-2 {
                > li {
                  width: 19%;
                  margin: 0;
                  padding: 0 20px 0 0;
                }
              }
            }
          }
        }

        &.3-col {
          .inline-text-link {
            display: block !important;
            text-align: right;
          }

          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;
              width: 32% !important;
              justify-content: space-between;
              padding: 0;
              margin: 0 10px 0 0;
            }

            > li:first-child {
              width: 15% !important;

              .menu-prod-title {
                margin: 0 0 $space-ten+3;
              }
            }

            > li:nth-child(2) {
              width: 15% !important;

              .menu-prod-title {
                margin: 0 0 $space-ten+3;
              }
            }
          }

          a.inline-text-link {
            display: inline-block !important;
          }
        }

        &.3-full-col {
          .inline-text-link {
            display: block !important;
            text-align: right;
          }

          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;
              width: 32% !important;
              justify-content: space-between;
              padding: 0;
              margin: 0;
            }
          }

          a.inline-text-link {
            display: inline-block !important;
          }
        }

        &.4-col {
          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;
              width: 23.5%;
              float: left;

              &.support-resources {
                .menu-prod-desc {
                  ul {
                    list-style-type: disc;

                    @include maxbp($big-desktop) {
                      margin-left: 0.3rem;
                    }
                  }
                }
              }

              .menu-prod-title {
                border-bottom: none;
              }

              .mega-menu-nav__item--sub a {
                font-size: 12px;
              }

              &:last-child {
                .menu-prod-title {
                  border-bottom: 1px solid #d7d7d9;
                  margin-bottom: 10px;
                }
              }
            }
          }

          &:hover {
            .mega-menu-nav--sub-1 {
              .mega-menu-nav__item--sub {
                display: block;
              }
            }
          }

          li.mega-menu-nav__item--sub {
            display: none;
          }

          .layout {
            display: flex;
            justify-content: center;
            padding: $space-ten*3 0;

            .layout__region {
              width: 32%;
              padding: 0 1%;
            }
          }

          .text-long ul li {
            margin-bottom: 0.2em;
            padding-left: 0;
            list-style: disc;
            margin-left: 1em;
          }
        }

        &.two-2-col {
          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;

              &:first-child {
                width: 35%;
                margin: 0;
                padding: 0 2% 0 0;

                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }

              &:last-child {
                width: 34%;
                margin: 0;

                img {
                  width: auto;
                }
              }

              > .mega-menu-nav--sub-2 {
                > li {
                  width: 32.33%;
                  margin: 0;
                  padding: 0 20px 0 0;
                }
              }
            }
          }
        }

        &.bathroom {
          > ul.mega-menu-nav--sub-1 {
            > li {
              &:first-child {
                width: 60%;
                margin: 0;
                padding: 0 2% 0 0;

                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }

              &:last-child {
                width: 39%;
                margin: 0;

                img {
                  width: auto;
                }
              }

              > .mega-menu-nav--sub-2 {
                > li {
                  width: 19%;
                  margin: 0;
                  padding: 0 20px 0 0;
                }
              }
            }
          }
        }

        &.for-professionals {
          > ul.mega-menu-nav--sub-1 {
            > li {
              &:first-child,
              &:nth-child(2) {
                .menu-prod-title {
                  margin: 0 0 $space-ten+3;
                }
              }
            }
          }
        }

        &.commercial {
          .mega-menu-nav--sub-1 {
            > li:first-child {
              > ul .nav-prod-img {
                margin: 0 0 $space-ten+5;
                text-align: center;

                img {
                  max-width: 100px;
                  width: 109px;
                  height: 109px;
                  object-fit: contain;
                }
              }
            }

            .inline-text-link {
              display: inline-block;
            }

            .mega-menu-nav__item--sub {
              a {
                font-size: 14.22px;
                line-height: 21.33px;
              }
            }

            .resources {
              ul.mega-menu-nav--sub-2 {
                display: flex;
                flex-direction: column;
              }

              .commercial-innovation-link {
                @include breakpoint($large) {
                  font-size: 14.22px;
                  line-height: 21.33px;
                  font-family: $font-bold2;
                  color: $gray-10;
                  margin: 12px 0 7px;
                }
              }
            }

            .mega-menu-nav--sub-3 {
              .mega-menu-nav__item--sub {
                a {
                  font-size: 12.64px;
                  line-height: 18.96px;
                  color: $gray-30;
                }
              }
            }
          }

          > ul.mega-menu-nav--sub-1 {
            @include breakpoint($large) {
              > li {
                &:first-child {
                  width: 53%;
                  margin: 0 24px 0 0;

                  > ul {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                  }
                }

                &:nth-child(2) {
                  width: 16%;
                  margin: 0 24px 0 0;
                }
              }
            }
          }
        }

        &.service--parts {
          &:hover {
            .mega-menu-nav--sub-1 {
              .mega-menu-nav__item--sub {
                display: block;
              }
            }
          }

          li.mega-menu-nav__item--sub {
            display: none;
          }

          .layout {
            display: flex;
            justify-content: center;
            padding: $space-ten*3 0;

            .layout__region {
              width: 32%;
              padding: 0 1%;
            }
          }

          .text-long ul li {
            margin-bottom: 0.2em;
            padding-left: 0;
            list-style: square;
            margin-left: 1em;
          }
        }

        &:hover {
          > ul.mega-menu-nav--sub-1 {
            opacity: 1;
            display: block !important;

            .mega-menu-nav__item--sub-1 > .mega-menu-nav--sub {
              margin-top: 13px;
            }
          }

          &.kitchen {
            > ul.mega-menu-nav--sub-1 {
              display: flex !important;
              justify-content: center;
            }
          }

          &.two-2-col {
            > ul.mega-menu-nav--sub-1 {
              display: flex !important;
              justify-content: center;
            }
          }
        }

        > ul.mega-menu-nav--sub-1 {
          position: absolute;
          width: 100%;
          left: 0;
          z-index: 100;
          top: 96%;
          padding: 0 2%;
          opacity: 0;
          max-width: 1440px;
          margin: 0 auto;
          right: 0;
          display: none !important;

          &::before {
            border-top: 1px solid #e6e6e8;
            border-bottom: 1px solid #e6e6e8;
            -webkit-box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);
            box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 100vw;
            background: #fff;
            z-index: -1;
          }

          @include maxbp($max-width) {
            padding: 0 $space-ten*2;
          }

          > li {
            display: inline-block;
            vertical-align: top;
            padding-bottom: $space-ten*4 !important;

            h2 {
              letter-spacing: 0;
              padding: 35px 0 15px;
              border-bottom: 1px solid #d7d7d9;
              text-transform: capitalize;
              font-size: 20px;
              color: #18191d;
              line-height: 22px;
            }
          }

          #find-part-form input {
            max-width: 100%;
            padding: 1rem 0.75rem;
          }

          .form-item__label {
            position: relative;
            left: unset;
            top: unset;
            font-size: 1rem;
            margin-bottom: 1em;
          }
        }
      }

      > li.mega-menu-nav__item {
        &.design--innovation {
          .inline-text-link {
            display: block !important;
            text-align: right;
          }

          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;
              width: 32% !important;
              justify-content: space-between;
              padding: 0;
              margin: 0;
            }
          }

          a.inline-text-link {
            display: inline-block !important;
          }
        }

        &.for-professionals {
          .inline-text-link {
            display: block !important;
            text-align: right;
          }

          > ul.mega-menu-nav--sub-1 {
            text-align: center;

            > li {
              text-align: left;
              width: 32% !important;
              justify-content: space-between;
              padding: 0;
              margin: 0 10px 0 0;
            }

            > li:first-child {
              width: 15% !important;
            }

            > li:nth-child(2) {
              width: 15% !important;
            }
          }

          a.inline-text-link {
            display: inline-block !important;
          }
        }
      }

      @include maxbp($large) {
        .menu-prod-desc {
          display: none;
        }
      }
    }
  }

  .mega-menu-nav {
    @include breakpoint($mega-menu-mobile) {
      display: block;
      margin: 0;
      padding: 0;

      &:not(.mega-menu-nav--sub) {
        background: none;

        > li {
          > a {
            text-decoration: none;
            font-size: 18px;
            font-weight: normal;
            display: block;
            color: $gray-10;
            position: relative;
            line-height: 1.6;
            padding: 7px $space-ten $space-ten+5 $space-ten;

            @include maxbp($std_desktop) {
              font-size: 17px;
            }

            @include maxbp($big-desktop) {
              padding: $space-ten+2 $space-ten $space-ten+4;
              font-size: 15px;
            }

            &::after {
              background: $delta-red;
              height: 4px;
              width: 0;
              content: "";
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: all ease 0.5s;
            }

            &.active,
            &:active,
            &:hover {
              &::after {
                width: 100%;
              }
            }
          }
        }
      }

      li.mega-menu-nav__item--with-sub {
        > .mega-menu-nav--sub-1 {
          background: white;
        }

        .mega-menu-nav--sub-1 {
          .mega-menu-nav__item--sub {
            display: none;

            a {
              color: $gray-10;
              font-family: $font-bold2;
              text-decoration: none;
              font-size: 14px;
              margin: 0 0 7px;
              display: block;

              &.inline-text-link {
                color: $delta-red;
                position: relative;
                display: inline-block;
                letter-spacing: 2px;
                margin: 5px 0 0;
                padding: 0 12px 0 0;
                text-transform: uppercase;

                &::after {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 4px 0 4px 6px;
                  border-color: transparent transparent transparent $delta-red;
                  content: "";
                  display: block;
                  position: absolute;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }

              &.nav-prod-img {
                margin: 0 0 $space-ten+5;
                text-align: center;

                img {
                  max-width: 100px;
                  width: 100%;
                  //margin: auto;
                }
              }
            }
          }

          &.mega-menu-nav--sub-3 {
            .mega-menu-nav__item--sub {
              display: block;

              a {
                font-weight: normal;
                font-family: $font-body;
                font-size: 12px;
                display: inline-block;
                position: relative;
                margin: 0 0 2px;

                &::after {
                  background: $delta-red;
                  content: "";
                  display: block;
                  height: 1px;
                  width: 100%;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  opacity: 0;
                  transition: all ease 1s;
                }

                &:hover {
                  color: $delta-red;
                  font-family: $font-bold2;

                  &::after {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        &:hover {
          .mega-menu-nav--sub-1 {
            .mega-menu-nav__item--sub {
              display: inline-block;
              vertical-align: top;
            }

            &.mega-menu-nav--sub-3 {
              .mega-menu-nav__item--sub {
                display: block;

                a.new-link {
                  padding: 0 23px 0 0;

                  &::before {
                    color: #ac0535;
                    position: absolute;
                    right: 0;
                    text-transform: uppercase;
                    top: -1px;
                    font-weight: bold;
                    font-family: $font-bold2;
                    font-size: 9px;
                  }
                }
              }
            }
          }
        }
      }

      &__item {
        display: inline-block;
        letter-spacing: normal;
        margin: 0 $space-ten*2 0 0;

        @include maxbp($std_desktop) {
          margin: 0 $space-ten 0 0;
        }

        @include maxbp($big-desktop) {
          margin: 0 6px 0 0;
        }
      }
    }
  }

  @include breakpoint($r-1100) {
    .for-professionals {
      .nav-prod-img {
        img {
          width: auto !important;
        }
      }
    }
  }
}

.fullwidth_header {
  width: 100%;
  border-bottom: 1px solid #d7d7d9;
  position: relative;

  .header-wrapper {
    border-bottom: none;
    position: static;
  }
}

@include breakpoint($mega-menu-mobile) {
  .mega-nav-wrapper > .mega-menu-nav > li.outlet.mega-menu-nav__item--with-sub > ul.mega-menu-nav--sub-1 {
    text-align: center;

    li {
      text-align: left;
      margin-right: 48px;

      &:last-child {
        margin-right: 0;
      }
    }

    .collection-image {
      img {
        max-width: 447px;
      }
    }

    .mega-nav-wrapper .mega-menu-nav .outlet.mega-menu-nav__item--with-sub .mega-menu-nav--sub-1 .mega-menu-nav__item--sub a.nav-prod-img {
      margin: 10px 0 15px;
      text-align: center;
    }
  }
}
