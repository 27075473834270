// EmailSweepstakes Form

.form-emailsweepstakes {
  @include breakpoint($large) {
    max-width: 564px;
    margin: 0 auto;
    padding: 0 5%;
  }

  @include maxbp($large) {
    padding: 0 15px;
  }

  .form-item {
    @include maxbp($medium) {
      margin-bottom: 24px;
    }
  }

  .u-text-xxl {
    line-height: 38px;
    color: $gray-50;
    text-align: center;
    font-size: 32px !important;
  }

  label[for=edit-agree],
  label[for=edit-certify-i-am-18] {
    display: contents;
  }

  .captcha {
    padding-bottom: 20px;
  }

  #edit-processed-text {
    padding: 30px 0 60px;
    text-align: center;

    a {
      color: $delta-red;
    }
  }
}
