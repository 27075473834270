.design-experience-dream-home {
  .layout--twocol-section--50-50 {
    .layout__region {
      @include breakpoint($r-1100) {
        padding: 50px 90px 25px;

        .title-desc-link-image {
          text-align: left;
        }
      }

      @include maxbp($old-desktop) {
        padding: 0;
        flex: 0 1 100%;

        .title-desc-link-image {
          text-align: left;
        }
      }
    }

    .layout__region--first {
      @include breakpoint($r-1100) {
        align-items: center;
        display: flex;
      }
    }
  }

  .layout {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    .layout {
      padding: 10px;
    }
  }

  .single-image-slider {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  .slider-img-caption {
    .slider-img-desc {
      background: rgba(0, 0, 0, 0.35);
      padding: 20px;
    }

    .slider-img-link {
      padding-top: 15px;
    }
  }

  .featured-banner.featured-banner-- {
    .featured-banner-content {
      max-width: 100%;
      text-align: center;
    }

    .grid-container {
      background: rgba(255, 255, 255, 0.45);

      h4 {
        text-transform: capitalize;
        letter-spacing: 0;

        @include maxbp($old-desktop) {
          font-size: 24px;
        }
      }

      @include maxbp($old-desktop) {
        h1 {
          font-size: 28px;
        }
      }
    }

    .featured-banner__button-group {
      justify-content: center;
    }
  }

  //product innovation carousel
  @include maxbp($old-desktop) {
    .layout__region--content,
    #block-bannerregioncontent {
      .single-image-slider {
        .slider-img-block {
          img {
            height: 100%;
            width: auto;
            object-fit: cover;
          }
        }

        .slider-img-caption {
          left: 0;
          padding: 5%;
          bottom: 0;
        }
      }
    }

    .layout__region--content {
      .slider-img-caption {
        .slider-img-desc {
          background: none;
          padding: 0;
        }
      }
    }

    #block-bannerregioncontent {
      .slider-img-caption {
        .slider-img-desc {
          background: rgba(0, 0, 0, 0.35);
          padding: 15px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.design-experience-dream-home .layout--twocol-section {
  flex-wrap: inherit;
}

@media (max-width: 1023px) {
  .design-experience-dream-home .layout--twocol-section {
    flex-wrap: wrap;
  }
}
