.checkout_shipping {
  justify-content: center;
  margin-top: 35px;

  .form-item {
    @include maxbp($grid-md) {
      margin-bottom: 24px;
    }
  }

  h3 {
    font-size: 2.025rem;
    text-transform: capitalize;
    letter-spacing: 0;
  }

  .accordion-term {
    display: none;
  }

  .accordion-def {
    height: auto;
    overflow: inherit;
    max-height: 100%;
    opacity: 1;

    .notice {
      margin-top: 29px;
      margin-right: 32px;
      margin-left: 32px;

      h4 {
        font-size: 16px;
        color: $delta-red;
      }
    }
  }

  .form_actions {
    display: flex;
    flex-direction: row;

    a {
      margin-right: 20px;
      font-weight: normal;
    }

    .ship-continue-btn {
      padding: $space-ten * 2 $space-ten * 10;
      font-size: 18px;
    }
  }

  @include maxbp($medium) {
    flex-direction: column-reverse;

    .accordion {
      margin-top: 17px;
      margin-left: -16px;
      margin-right: -16px;
    }

    .accordion-def {
      height: 0;
      overflow: hidden;
      max-height: 0;
    }

    .ecommerce-steps {
      margin-left: -16px;
      margin-right: -16px;
    }

    .grid-col-sm-6 {
      margin-top: 15px;
    }

    .accordion-term {
      display: block;
      padding: 15px 15px;
      cursor: pointer;
      background: $gray-10;
      color: $gray-90;

      h6 {
        font-size: 0.888rem;
        display: inline-block;
        margin: 0;
        font-family: $font-bold2;
        padding-left: 20px;
        color: #fff;
      }

      span {
        float: right;
        font-size: 0.888rem;
        font-family: $font-bold2;
      }

      &::before {
        content: "+";
        position: absolute;
        left: 15px;
        font-size: 20px;
        line-height: 14px;
        top: -2px;
        bottom: 0;
        margin: auto;
        height: 14px;
      }

      &::after {
        content: none;
      }

      &.is-active {
        h6 {
          color: $white;
        }

        & + .accordion-def {
          margin: 0;
          height: 100%;
        }

        &::before {
          content: "-";
          font-size: 30px;
          height: 7px;
          line-height: 3px;
        }
      }
    }

    .shipping_address {
      .grid {
        flex-direction: column;
      }
    }

    .form_actions {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      a {
        margin-top: 20px;
        margin-right: 0;
      }
    }
  }

  .return-to-cart {
    display: none;
  }

  .shipping_address-title {
    @include breakpoint($ipad-only) {
      display: flex;

      .ship-extra-notes {
        padding-left: 14px;
      }
    }
  }
}

.shipping_option {
  .h2 {
    font-size: 1.269rem;
    text-transform: capitalize;
    letter-spacing: 0;
    padding-top: 40px;
  }

  .option {
    font-size: 1rem;
    color: $gray-10;
    top: 0;
    left: 20px;
  }

  .form-item {
    margin-bottom: 12px;
  }
}

.shipping_address {
  #edit-shipping-address {
    font-size: 1.269rem;
    text-transform: capitalize;
    letter-spacing: 0;
    padding-top: 40px;
    font-family: $font-bold2;
  }
}

.shipping_product {
  background-color: $gray-90;
  padding: 32px;

  @include maxbp($r-1100) {
    padding: 16px;
  }

  h3 {
    font-size: 1.269rem;
    font-family: $font-bold2;
    border-bottom: 1px solid $gray-70;
    padding-bottom: 8px;
  }
}

.shopping-cart-item {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  border-bottom: 1px solid $gray-70;
  padding-bottom: 20px;
  margin-bottom: 5px;

  .text-right {
    width: 80px;
    text-align: right;
  }

  .shipping_product {
    &_img {
      max-width: 60px;

      img {
        width: 100%;
      }
    }

    &_details {
      width: calc(100% - 150px);
      padding: 0 20px 0 35px;

      @include maxbp($r-1100) {
        width: calc(100% - 100px);
        padding: 0 12px 0 12px;
      }

      h4 {
        text-transform: capitalize;
        font-size: 1rem;
        letter-spacing: 0;
        font-family: $font-light;
        font-weight: inherit;
      }

      div:first-of-type {
        font-weight: bold;
        font-family: $font-body;
        letter-spacing: 1px;
      }

      .sku {
        font-size: 0.788rem;
        letter-spacing: 2px;
        color: $gray-50;
      }

      .qty,
      .price {
        font-size: 0.788rem;
        color: $gray-10;
      }
    }
  }

  .flag {
    transform: translate(0, 0);
    margin-bottom: 10px;
    min-width: 131px;
  }

  &.recertified-flag {
    .text-right {
      margin-top: 37px;
      text-align: right;
    }

    .price {
      display: flex;

      .strikePrice {
        margin-right: 10px;
        text-decoration: line-through;
        margin-left: 5px;
        color: #77777a;
      }
    }
  }
}

.order-items {
  .tecart_ordersxt-right {
    text-align: right;
  }

  .shade,
  .shipping_total {
    span {
      width: 50%;
      display: inline-block;
      margin: 2px -2px;
    }

    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }
  }
}

.shipping_total {
  font-size: 1.125rem;
  font-family: $font-bold2;
  border-top: 1px solid $gray-70;
  margin-top: 10px;
}

.ecommerce-steps {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  list-style: none;
  text-align: center;
  padding-left: 0;
  margin-bottom: 48px;

  li {
    border-bottom: 1px solid $gray-70;
    width: 100%;
    padding-bottom: 15px;
    font-size: 1.125rem;
    color: $gray-50;
    list-style: none !important;

    &.active {
      border-bottom: 3px solid $delta-red;
      font-family: $font-bold2;
      color: $gray-10;

      span {
        background-color: $delta-red;
        color: $white;
        height: 31px;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
      }
    }

    span {
      padding: 5px 10px;
      border-radius: 50%;
      background-color: $gray-90;
      margin-right: 10px;
    }

    @include maxbp($medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.888rem;
      padding-bottom: 5px;

      span {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.contact_info {
  .form-item {
    width: 50%;

    @include maxbp($old-desktop) {
      width: 100%;
    }
  }
}

@include maxbp($medium) {
  .ship-continue-btn {
    width: 100%;
    justify-content: center;
  }

  .return-to-cart {
    width: 100%;
    margin-top: 15px;
    justify-content: center;
  }
}

@include maxbp($r-1100) {
  .path-ecommerce .main {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.path-ecommerce-shipping-status {
  .status--status {
    display: none;
  }

  .layout-container {
    .main {
      padding-top: 85px;
      padding-bottom: 64px;

      @include maxbp($medium) {
        padding-top: 0;
      }

      .contact_info {
        .custom-input {
          .form-item:last-child {
            width: 100%;
            margin-top: -14px;
            margin-bottom: 52.11px;
            margin-left: -12px;

            @include maxbp($medium) {
              margin-top: 8px;
              margin-bottom: 40px;
              margin-left: -10px;
              letter-spacing: -0.2px;
              padding-right: 42px;
            }
          }
        }
      }

      @include maxbp($medium) {
        padding-bottom: 36px;
      }
    }
  }

  .status-error {
    display: block;
  }
}

.path-commerce-shipping-wrapper,
.path-commerce-billing-wrapper {
  .main {
    padding-top: 35px;
  }
}

.path-commerce-shipping-wrapper {
  .layout-container {
    .main {
      padding-top: 35px;

      .region-content {
        .block {
          .checkout_shipping {
            margin-top: 55px;

            @include maxbp($medium) {
              margin-top: 44px;
            }

            .ecommerce-steps {
              margin-top: 5px;

              @include maxbp($medium) {
                margin-top: 16px;
                margin-bottom: 29px;
              }

              li {
                padding-bottom: 12px;

                @include maxbp($medium) {
                  padding-bottom: 3px;
                }
              }
            }
          }
        }
      }

      .mobile_padding {
        h3:nth-of-type(1) {
          @include maxbp($medium) {
            font-size: 25.63px;
            margin-bottom: 8px;
          }
        }

        h3:nth-of-type(2) {
          font-size: 20.25px;

          @include maxbp($medium) {
            margin-top: 14px;
          }
        }

        .shipping_option {
          #edit-shipping-option--wrapper {
            @include maxbp($medium) {
              margin-bottom: 11px;
            }

            legend.h2 {
              padding-top: 30px !important;
              margin-bottom: 15px;
            }

            .form-item--radio {
              .form-item {
                @include maxbp($medium) {
                  margin-bottom: 19px;
                }
              }
            }
          }
        }

        .shipping_address-title {
          div.h2 {
            @include maxbp($medium) {
              font-size: 18px;
              margin-bottom: 24px;
            }
          }
        }

        .shipping_address {
          .form-item {
            .form-item__label {
              .optional {
                color: $gray-50;
              }
            }

            @include maxbp($medium) {
              margin-bottom: 16px;
            }
          }

          .grid {
            .grid-col-sm-6 {
              margin-top: 0;
            }
          }
        }

        .ship-back-btn {
          a {
            margin-right: 25px;
          }

          .ship-continue-btn {
            padding: 24.14px 89.69px 21.86px 92.31px;
          }
        }

        p.push-bb {
          margin-top: 10px;
          margin-bottom: 30px;
          letter-spacing: -0.2px;
        }
      }

      .accordion-def {
        .shipping_product {
          padding-top: 23px;
          padding-bottom: 39.64px;

          @include maxbp($medium) {
            padding: 11px 16px 30px 16px;
          }

          h3 {
            margin-bottom: 0;
            padding-bottom: 6px;
          }

          .order-items {
            .shopping-cart-item {
              padding-top: 15px;

              @include maxbp($medium) {
                padding-top: 11px;
                padding-bottom: 15px;
              }

              .shipping_product_details {
                @include maxbp($medium) {
                  width: -webkit-fill-available;
                  padding: 0 0 0 8px;
                }

                .slider_flag {
                  @include maxbp($medium) {
                    display: block;
                  }
                }

                h4 {
                  @include maxbp($medium) {
                    line-height: 20px;
                  }
                }
              }
            }

            .shipping_total {
              .order-total-des {
                max-width: 165px;

                @include maxbp($medium) {
                  margin-top: 0;
                  margin-left: 0;
                }
              }
            }
          }
        }

        .notice {
          @include maxbp($medium) {
            margin-right: 16px;
            margin-left: 16px;
          }

          p {
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 0;
            letter-spacing: -0.2px;
          }
        }
      }
    }
  }
}

.path-commerce-billing-wrapper {
  .layout-container {
    .main {
      padding-bottom: 64px;

      @include maxbp($medium) {
        padding-bottom: 36px;
      }
    }
  }
}

.shipping_address-title {
  .h2 {
    font-size: 20px;
    text-transform: none;
    letter-spacing: 0;
    margin: 0 0 20px;
  }
}

.shipping-delay-notice {
  max-width: 83.33333%;
  margin: 20px auto;
}

.checkout_header {
  padding-top: 81.21px;
}

.checkout-header-main {
  justify-content: center;
}

div#block-shippingdelaynotice {
  margin-top: 70px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
