@mixin img {
  display: block;
  height: auto;
  max-width: 100%;
}

img,
picture {
  @include img;
}

figure {
  margin: 0; //override normalize
}

.figure__caption {
  font-style: italic;
}

.figure img,
.figure picture {
  width: 100%;
}
