.slp_tabs {
  .tabs__link,
  .tabs__link--local-tasks {
    z-index: 5;
  }
}

.no-results {
  text-align: center;
  margin-bottom: 40px;

  h4 {
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: normal;
  }

  @include maxbp($grid-md) {
    .button {
      padding: 0.75rem 15px;
    }
  }
}

#emptyResults {
  order: -2;

  hr {
    padding-bottom: 28px;
    max-width: 343px;
    border-color: #d5d5d5;
    margin: 0 auto;
  }
}

.container.search-result-page {
  width: 100%;
}

.pager__link + .pager__link {
  margin-left: 4px;
}

.all-tab-dropdown {
  select {
    width: auto;
  }
}

.align-right {
  text-align: right;
}

.path-search-results {
  @include maxbp($grid-md) {
    .grid-container {
      display: flex;
      flex-direction: column;
    }

    .srp-body__header {
      order: -1;
    }

    .productResult,
    .contentResult,
    .documentResult {
      order: 0;
    }

    .allResult {
      -webkit-box-ordinal-group: 1 !important;
      order: 0 !important;
    }
  }
}

#sortProductAll {
  width: 151px;
}
