.main-com-pop {
  text-align: center;

  .main-com-block {
    display: flex;
    border-bottom: 1px solid $gray-60;

    .main-com-title {
      text-align: right;
      float: left;
      width: 100px;
      background: $gray-10;
      padding: $space-ten $space-ten*2 $space-ten $space-ten;

      h6.h6 {
        color: $white;
        text-transform: none;
        letter-spacing: normal;
        font-family: $font-body;
        font-size: 14px;
        margin: 0;
        line-height: 20px;
      }

      .compare-prod-share,
      .compare-prod-print {
        a {
          text-transform: none;
          font-family: $font-body;
          letter-spacing: normal;
          font-size: 14px;
          margin: 0 0 $space-ten*4;
        }
      }
    }

    .main-com-desc {
      width: calc(100% - 100px);
      float: left;
      display: flex;
      justify-content: space-around;

      p {
        margin: 0;
      }

      .com-pop-img {
        margin: 0 auto $space-ten;
        position: relative;
        display: block;
        overflow: hidden;

        img {
          margin: auto;
        }

        .slider_flag {
          position: absolute;
          top: 0;

          .flag {
            @include maxbp($medium) {
              min-width: 160px;
              font-size: $font-size-sm;
              transform: translate(-66px, -19px) rotate(-45deg);
              transform-origin: 100% 0;
            }
          }
        }
      }

      .com_pop_title {
        font-size: 16px;

        h6.h6 {
          font-family: $font-bold;
          font-weight: normal;
          text-transform: none;
          letter-spacing: normal;
        }
      }

      .com_pop_desc {
        font-size: 18px;

        p {
          margin: 0 0 7px;
        }
      }

      .com_pop_model {
        font-size: 18px;

        p {
          margin: 0;
        }
      }
    }
  }
}
