.header {
  @include maxbp($mega-menu-mobile) {
    .dropdown {
      display: block;

      > a.active-lang-link {
        background: $gray-10;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-family: $font-bold;
        font-size: 13px;
        letter-spacing: 2px;

        &::after {
          display: none;
        }

        &:hover {
          background: $delta-red;

          &::before {
            content: "";
            height: 2px;
            background: $white;
            width: 16px;
            right: 20px;
            top: 23px;
          }
        }

        &::before {
          display: block;
          color: $white;
          height: 24px;
          width: 24px;
          position: absolute;
          right: 16px;
          text-align: center;
          top: $space-ten;
          content: "+";
          font-size: 25px;

          @include breakpoint($mega-menu-mobile) {
            display: none;
          }
        }
      }

      .dropdown-content {
        position: relative;

        li {
          list-style: none;

          a.lng-item {
            font-size: 18px !important;
            padding: 8px $space-ten !important;
          }
        }
      }
    }
  }
}

@include maxbp($mega-menu-mobile) {
  .fullwidth_header .header {
    [data-block-plugin-id='language_switch_block'] {
      .dropdown {
        .dropdown-content {
          max-height: 0;
          -webkit-transition: all ease 0.3s;
          transition: all ease 0.3s;
        }

        &.active {
          .dropdown-content {
            max-height: 1000px;
            -webkit-transition: all ease 1s;
            transition: all ease 1s;
          }

          .active-lang-link {
            background: #ac0535;

            &::before {
              top: 23px !important;
              height: 2px;
              content: "";
              background: $white;
              width: 16px;
            }
          }
        }

        .active-lang-link {
          background: #18191d;

          &::before {
            content: "+";
            height: 0;
            top: 0 !important;
            right: 24px;
          }
        }
      }
    }
  }
}

.mega-nav-wrapper {
  .mobile-utility {
    display: none;

    @include maxbp($mega-menu-mobile) {
      display: block;
    }

    .utility-wrapper {
      display: block;

      .utility.header-container {
        display: block;
        padding: 0;

        ul {
          display: block;

          li {
            display: block;
            margin: 0;

            &.utility-signin {
              a {
                display: block;
                max-width: none;
                text-align: left;
                color: white;
                text-transform: uppercase;
                text-decoration: none;

                picture.icon-img__image {
                  display: inline-block;
                  vertical-align: middle;
                }

                &::after {
                  content: "Sign in";
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }

            &.utility-cart {
              display: none;
            }
          }
        }
      }
    }
  }
}
