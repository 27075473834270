.toolkit-bottom-wrapper {
  padding: 0 0 0 35%;
  display: block;
  overflow: hidden;

  @include maxbp($large) {
    padding: 0;
  }

  .pdp-tks-detail {
    margin: 0;

    @include maxbp($large) {
      display: block;
    }

    .pdp-tks-doc-wrapper {
      padding: 0;

      &.grid-col-sm-7,
      &.grid-col-sm-5 {
        @include maxbp($big-desktop) {
          max-width: 50%;
          flex: 0 0 50%;
        }

        @include maxbp($large) {
          max-width: 100%;
          flex: 0 0 100%;
          width: 100%;
        }
      }

      &.grid-col-sm-7 {
        @include maxbp($big-desktop) {
          padding: 0 1% 0 0;
        }
      }
    }
  }
}

.pdp-ownership-toolkit-section {
  [data-bv-show="rating_summary"] .bv_main_container_row_flex {
    display: none !important;
  }

  .bv_button_component_container {
    button {
      display: none !important;
    }
  }

  .adv-pop-coll-link {
    a {
      color: $gray-10;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 20px;
      margin: 0 0 $space-ten+5;
      text-decoration: none;

      @include maxbp($medium) {
        font-size: 13px;
        margin: 0 0 $space-ten/2;
      }
    }
  }

  .collection-name-wrapper {
    display: none;
  }
}

.pdp-top-section {
  &.pdp-advt-top-section {
    padding: $space-ten*5 $space-ten*3 0 !important;
  }
}
