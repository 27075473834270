.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: $white;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-image: linear-gradient(-90deg, rgba($gray-10, 0) 0%, rgba($gray-10, 0.5) 100%);

    @include maxbp($large) {
      background-color: rgba($gray-10, 0.85);
      background-image: none;
    }
  }

  @include large {
    min-height: 700px;
  }

  .grid-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding-top: 70px;
    padding-bottom: 32px;

    @include large {
      padding-top: 120px;
      padding-bottom: 100px;
    }
  }

  .breadcrumb-block {
    position: absolute;
    top: 16px;
    left: 16px;
    right: 0;
    text-align: left;

    @include large {
      top: 20px;
      left: 24px;
    }
  }
}

.banner-content {
  font-size: 18px;
  text-align: left;

  @include large {
    text-align: left;
    max-width: 770px;
    //margin-left: 56px;
  }
}

.full-banner-caption {
  .banner-content {
    width: 100%;
  }
}

.title-banner--content-centered,
.banner--content-centered {
  .banner-content {
    text-align: center;

    @include large {
      text-align: center;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    margin-left: auto;
    margin-right: auto;
  }

  .banner__title ::after {
    display: none;
  }

  .banner__button-group {
    margin-left: auto;
    margin-right: auto;
  }
}

.banner__title {
  position: relative;
  padding-bottom: 36px;
  font-size: $font-size-xxxl;
  text-transform: none;
  letter-spacing: normal;
  color: inherit;
  margin-bottom: 0;

  @include large {
    margin-bottom: 20px;
    font-size: 2.565625rem;
  }

  &::after {
    position: relative;
    display: block;
    top: 18px;
    width: 33px;
    left: 50%;
    transform: translate(-50%);
    height: 3px;
    background-color: $delta-red;
    content: '';

    @include large {
      left: auto;
      transform: none;
      top: 36px;
      width: 64px;
    }
  }
}

.banner__headline {
  color: $delta-red;
  font-size: 2.565625rem;
  margin-bottom: 16px;

  @include large {
    font-size: 3.653125rem;
  }
}

.banner__paragraph {
  @include large {
    width: 80%;
  }
}

.banner__disclaimer {
  font-size: $font-size-sm;

  @include large {
    width: 52%;
  }
}

.banner__button-group {
  margin-top: 24px;

  @include large {
    margin-top: 32px;
    //margin-left: 56px;
  }

  @include maxbp($large) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    justify-content: center;
    margin-top: 8px;
  }
}

.page-node-type-home-page {
  .paragraph-component {
    margin-bottom: 8px;
  }

  .region.region-content {
    margin-bottom: -8px;
  }
}
