.margin-top-80 {
  margin-top: $space-ten * 8;
}

.margin-bottom-80 {
  margin-bottom: $space-ten * 8;
}

.width-50 {
  width: 50%;
  margin: auto;
}

.width-100 {
  width: 100%;
}

.webform-submission-form {
  .webform-button--submit {
    margin: 24px 0;
  }
}

.margin-width {
  max-width: $max-width-text;
  margin: 0 auto;
}

.red-500 {
  color: #c51638;
}

.views-element-container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}

.no-text-transform {
  text-transform: none;
}

.banner-block-small {
  min-height: unset;
}

.col-support {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 20px;
}

.custom-image-popup {
  display: none;
}

label[for="notapplicable"] {
  display: none;
}

.roiformbody .roiformfield {
  list-style: none;
}

// CMS end css code

.no-margin {
  margin: 0;
}

.pdp-rating [data-bv-show=rating_summary] .bv_main_container {
  background: transparent !important;
}

.faucet-campaign .spb-controls {
  display: none;
}

footer.footer {
  margin-top: 20px;
}

.home-intro-banner-wrapper {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home-intro-banner .hib-content {
  position: relative;
}

.home-intro-banner {
  .voiceiq_banner_img img {
    top: 0;
    bottom: 0;
  }
}
// Anchor tag with no underline and red color
.no-line-red {
  color: $delta-red;
  text-decoration-color: transparent;
}

.blogs-quote {
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.4em;
  color: #8a8a8a;
}

.blogs-h3 {
  margin: 0 0 0.5em;
  font-family: $font-bold;
  font-weight: 700;
  line-height: 1.1;
  color: #18191d;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.blog-cutline-style {
  font-family: $font-roman;
  font-style: italic;
  color: #464646;
}

.ac-model-number,
.ac-model-number-single {
  .auto-suggest {
    position: relative;
    display: none;
  }

  .model-num-list {
    position: absolute;
    background: $gray-90;
    border: 1px solid $gray-70;
    padding: 15px 10px 20px;
    overflow: hidden;
    top: -17px;
    width: 100%;
    z-index: 1;

    .row-sku {
      text-transform: uppercase;
      font-size: 15px;
      font-family: $font-bold;
      font-weight: 400;
      display: block;
      padding: 0;
      margin: 0 0 10px !important;
      cursor: auto;
      cursor: pointer;
      text-decoration: none !important;
    }
  }
}
//Product Tile Changes
.product-tile {
  padding-bottom: 25px;
  transition: none;

  &:hover {
    box-shadow: 0 0 9px rgba(24, 25, 29, 0.2);
  }

  .product-tile__body {
    @include breakpoint($ipad-only) {
      padding: 0 13px 0 15px;
      text-align: left;
    }

    .prod-finishes {
      display: flex !important;
      padding-top: 8px;
      flex-wrap: wrap;

      img {
        width: 32px;
        height: 32px;
        transform: scale(0.9, 0.9);
        border-radius: 100%;
        padding: 2px;

        &.active.finish-class {
          border: 1px solid #ac0535;
          margin: 0;
        }
      }

      a {
        @include maxbp($ipad-only) {
          width: auto;
        }
      }
    }

    .product-tile__sku {
      font-size: 14px;
      padding-top: 3px;

      a {
        display: block;
        font-family: $font-bold;
        font-weight: 700;
        font-size: 14px;
        color: $gray-50;
        letter-spacing: 2px;
      }
    }

    .paragraph {
      font-size: 14px;
    }
  }

  .no-line {
    @include maxbp($grid-md) {
      display: block;
    }
  }

  .js-add-to-compare {
    text-align: left;
    padding-top: 25px;
    color: $black;

    &:hover {
      color: $delta-red;
    }

    @include maxbp($grid-md) {
      display: none;
    }
  }

  .product-tile__image {
    @include breakpoint($ipad-only) {
      margin-bottom: 20px;
    }
  }

  .product-tile__price:not(.discount-list-price) {
    margin: 0 0 10px;
  }

  .discount-list-price {
    margin: 15px 0 0;
  }
}
