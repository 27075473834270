.adv-pdp-page-name {
  h5.h5 {
    font-weight: normal;
    letter-spacing: 0.5px;
    margin: 0 0 $space-ten*2;
  }
}

.pdp-where-to-buy-section {
  [data-bv-show="rating_summary"] .bv_main_container_row_flex {
    display: none !important;
  }

  .bv_button_component_container {
    button {
      display: none !important;
    }
  }
}

.pdp-dealers-wrapper {
  // background: $gray-90;
  text-align: center;
  padding: $space-ten*6 $space-ten*10 $space-ten*5;
  display: block;
  overflow: hidden;

  @include maxbp($medium) {
    padding: $space-ten*6 $space-ten*5 $space-ten*5;
  }
}

.more-dealers {
  font-family: $font-bold;
  font-weight: normal;
  font-size: 20px;
  margin: $space-ten*3 0 0;
  clear: both;

  > div {
    display: inline-block;
    vertical-align: middle;

    p {
      margin: 0;
    }

    a {
      font-size: 18px;
      font-weight: normal;
    }
  }
}
