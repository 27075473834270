.for-professional-page {
  .main {
    @include wrapper;

    .pro-text-button-box {
      display: flex;
      justify-content: space-between;

      @include maxbp($mega-menu-mobile) {
        display: block;
      }
    }

    .text-button-box {
      display: block;
      margin-left: 0;

      @include maxbp($mega-menu-mobile) {
        display: block;
        width: 100%;
        margin: 0 0 $space-ten * 2;
      }
    }

    .layout--twocol-section {
      @include maxbp($mega-menu-mobile) {
        display: block;
        padding-top: $space-ten;
      }
    }

    .pro-dropdown {
      margin: $space-ten * 3 0;

      @include maxbp($mega-menu-mobile) {
        text-align: center;
      }

      .pro-dropdown-title,
      .pro-dropdown-desc {
        display: inline-block;
        vertical-align: middle;

        @include maxbp(450px) {
          display: block;
        }
      }

      .pro-dropdown-title {
        h6.h6 {
          text-transform: none;
          font-weight: normal;
          font-size: 18px;
          margin: 0;
          letter-spacing: 0;
        }

        @include maxbp(450px) {
          margin: 0 0 15px 3px;
          text-align: left;
        }
      }

      .pro-dropdown-desc {
        .form-item {
          margin: 0;
        }
      }
    }

    .pro-fea-ins {
      padding-bottom: 20px;

      .pro-fea-prod {
        @include maxbp($mega-menu-mobile) {
          margin: 0 0 $space-ten * 3;
          //text-align: center;

          .image-desc-wrapper .id-image {
            img {
              max-width: 90%;
              margin: auto;
            }
          }
        }
      }

      .pro-ins-pro {
        @include maxbp($mega-menu-mobile) {
          //text-align: center;
        }

        .text-image-block {
          margin: 0 0 $space-ten * 5;

          @include maxbp($mega-menu-mobile) {
            margin: 0 auto $space-ten * 3;
          }

          .text-img-block-title {
            h5.h5 {
              text-transform: uppercase;
              font-size: 22px;
              letter-spacing: 1px;
              font-weight: normal;
              font-family: $font-bold;
            }
          }
        }
      }
    }

    .pro-tools {
      .pro-tool-ttl {
        text-align: center;

        h6.h6 {
          font-family: $font-body;
        }
      }

      .pro-tool-desc {
        .pro-tool-desc-block {
          margin: 0 0 $space-ten * 2;
        }
      }
    }

    .pro-featured {
      margin: $space-ten * 8 0 $space-ten * 5;

      @include maxbp($mega-menu-mobile) {
        margin: $space-ten * 3 0 $space-ten * 5;
      }

      .pro-featured-ttl {
        text-align: center;

        @include maxbp($mega-menu-mobile) {
          margin: 0 0 $space-ten * 3;
        }

        h6.h6 {
          font-family: $font-body;
        }
      }

      .pro-featured-desc {
        max-width: 80%;
        margin: auto;
        width: 100%;

        @include maxbp($mega-menu-mobile) {
          max-width: 100%;

          .product-slider {
            width: 80%;
            margin: auto;
          }
        }
      }
    }

    .pro-coverage {
      text-align: center;
      margin: 0 0 $space-ten * 2;

      p {
        font-size: 10px;
      }
    }
  }

  .pro-dropdown {
    .form-item__select {
      min-width: 230px;
    }
  }
}

@media only screen and (max-width: 766px) and (min-width: 720px) {
  .product-tile-slider {
    .product-tile .product-tile__body {
      max-width: 50%;
      margin-left: 10px;
    }
  }
}

@include maxbp($grid-md) {
  .product-tile .no-line {
    flex-wrap: wrap;
    width: 100%;
  }
}

//Custom Showering page

.custom-shower {
  color: $gray-30;

  .innovation-secondary-banner {
    background-image: url(/sites/delta/files/custom-shower-overview3.jpg);
  }

  .image-grid-container {
    padding-top: 40px;

    .field_grid_title {
      font-family: $font-heading;
      text-align: center;
      font-size: $space-double;
      font-weight: 800;
      padding-bottom: 25px;
      line-height: 1.1;

      @include maxbp($grid-md) {
        font-size: $font-size-xxxl;
      }
    }

    @include breakpoint($large) {
      .field_grid_sections {
        display: flex;
        justify-content: space-between;
      }
    }

    @include maxbp($large) {
      .field_grid_sections {
        text-align: center;
      }
    }

    .img_element {
      .link-part {
        text-align: center;
        padding: 15px 0;

        a {
          text-decoration: none;
          color: $gray-30;
        }
      }
    }
  }

  .field_section_content {
    .h2 {
      text-transform: capitalize;
      font-size: $space-double;
      text-align: center;
      margin-bottom: 0;
      letter-spacing: 0;
      color: $gray-30;

      @include maxbp($grid-md) {
        font-size: $font-size-xxxl;
      }
    }
  }

  .anchor-tag-section {
    padding-top: 30px;

    &:last-child {
      padding-top: 0;
    }
  }

  #Design_and_Resources {
    .text-align-center {
      margin-top: 30px;
    }

    article {
      a {
        text-decoration: none;
        color: $delta-red;
      }
    }
  }

  #Design_and_Innovation {
    @include breakpoint($large) {
      max-width: $max-width-text;
      margin: 0 auto;
    }
  }
}

//For Professional AccessPoint
.accesspoint_login {
  .accesspoint-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
