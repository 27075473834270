.product-filter {
  background-color: $white;
  display: none;

  @include maxbp($grid-md) {
    &.is-active {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 99999;
      overflow-y: scroll;

      .product-filter__mobile-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        margin: 0;
      }
    }
  }
}

.filter-controls {
  color: $delta-red;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: $font-bold;
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E"), "#", "%23");
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center right;
  padding: 0 15px 0 0;
  background-size: 12px;
  cursor: pointer;
}

.product-filter__title {
  font-size: $font-size-xxl;
  margin-bottom: 0;
  display: none;

  @include breakpoint($grid-md) {
    display: block;
  }
}

.product-filter__mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  background-color: $gray-10;
  margin: 0 6px $space-ten*3;

  .toggle-expand {
    position: static;

    [class^="bar"] {
      background-color: $white;
    }
  }

  @include breakpoint($grid-md) {
    display: none;
  }

  @include maxbp($grid-md) {
    margin: 0;
  }
}

.product-filter__mobile-title {
  color: $white;
  font-size: $font-size-lg;
  margin-bottom: 0;
}

.product-filter__body {
  //display: none;
  @include maxbp($grid-md) {
    opacity: 0;
    visibility: hidden;
    height: 0;

    .product-filter.is-active & {
      display: block;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }

  //@include maxbp($grid-md) {
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //}

  @include breakpoint($grid-md) {
    display: block !important;
  }
}

.product-filter__section {
  .filter-container {
    .icon-image.filter-icon-image {
      position: absolute;
      left: 25px;
      width: 22px;
      margin: auto;
      transform: translateY(-67%);
      top: 50%;

      img {
        width: 100%;
        max-height: none;
      }
    }

    .custom-input.custom-input--checkbox {
      .custom-input__label {
        display: inline-block;
      }

      &.custom-filter-icon {
        padding: 0 0 6px;
        height: auto !important;

        .custom-input__label {
          padding-left: 55px;
          display: inline-block;
        }
      }
    }
  }

  .cta-link {
    margin-top: 10px;
  }

  .accordion-term {
    padding: 16px;
    border-top: 0;

    @include maxbp($grid-md) {
      &.is-active {
        background-color: $gray-80;
      }
    }

    &.is-active + .accordion-def {
      margin: 10px 16px 12px;
    }

    @include breakpoint($grid-md) {
      padding: 20px 0;

      &.is-active + .accordion-def {
        margin: -2px 0 12px;
        padding-bottom: 12px;
      }
    }
  }

  .accordion-def {
    border-bottom: 1px solid $gray-70;
  }
}

.product-filter__section__title {
  font-size: $font-size-sm;
  margin-bottom: 0;
}

.product-filter__section__subtitle {
  font-size: $font-size-base;
  line-height: 1.5;
  text-transform: none;
  letter-spacing: 0;

  &:not(:first-child) {
    margin-top: 12px;
  }
}
