// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

.featured-innoslider {
  margin-bottom: 40px;

  @include large {
    padding: 0 24px;
  }

  .slick-arrow {
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    filter: grayscale(100%) brightness(0);
    transition: filter 0.15s ease-in-out;

    &:hover {
      filter: grayscale(0) brightness(1);
    }
  }

  .slick-arrow.slick-prev {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M17.2660028,24L18.6315789 22.5310873 8.99548257 12 18.6315789 1.6938288 17.2660028 0 6 12z'/%3E%3C/svg%3E"), "#", "%23");
    left: -8px;
    transform-origin: 50% 50%;
  }

  .slick-arrow.slick-next {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M7.36557613,0L6 1.46891274 15.6360964 12 6 22.3061712 7.36557613 24 18.6315789 12z'/%3E%3C/svg%3E"), "#", "%23");
    right: -8px;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    width: 32px;
    height: 32px;
  }
}

.featured-innoslider__item {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 40px;
  outline: none;
  margin-top: 20px;

  &.slick-current.slick-active {
    margin-top: 0;
  }

  @include large {
    &.slick-center {
      transform: scale(1.04);
      transform-origin: 50% 0;
      transition: transform 0.3s 0.2s ease-in-out;
    }
  }
}

.featured-innoslider-video {
  .video {
    padding-top: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.featured-card {
  position: relative;
  padding: 16px 16px 32px;
  font-size: $font-size-sm;
  background-color: $white;
  box-shadow: 0 2px 8px 0 rgba(24, 25, 29, 0.07), inset 0 0 0 1px $gray-80;
  min-height: 520px;

  img {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
}

.featured-card__title {
  font-family: $font-body;
  font-size: 16px;
  letter-spacing: 0;
  text-transform: none;
}
