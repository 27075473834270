.design-innovation-page {
  .di-welcome-text {
    background: $gray-90;
    border: 1px solid $gray-70;
    text-align: center;
    margin: 0 0 $space-ten*3;
    padding: 24px 12%;
    font-size: 18px;

    p {
      max-width: $max-width-text;
      padding: $space-ten*3 0;
      font-size: 18px;
      width: 70%;
      margin: 0 auto;
    }
  }

  .di-inspired-living {
    margin: $space-ten*9 0 $space-ten;
    text-align: center;

    .di-inspired-living-title {
      h4.h4 {
        font-weight: normal;
        text-transform: none;
        margin: 0 0 $space-ten;
      }
    }

    .di-inspired-living-desc {
      margin: 0 0 $space-ten*3;

      p {
        margin: 0 auto;
      }
    }
  }

  .di-slider {
    margin: $space-ten*3 0;
  }

  .di-three-blocks-wrapper {
    background: #fff;
    background: -moz-linear-gradient(top, #fff 0, #f4f4f4 92%, #f4f4f4 100%);
    background: -webkit-linear-gradient(top, #fff 0, #f4f4f4 92%, #f4f4f4 100%);
    background: linear-gradient(to bottom, #fff 0, #f4f4f4 92%, #f4f4f4 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#f4f4f4', GradientType=0);
    -webkit-box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    border-top: 1px solid #eee;
    padding: $space-ten*10 0;

    .di-three-blocks-list {
      max-width: $max-width-text;
      margin: auto;

      > div {
        padding: 0 $space-ten/2;
      }
    }

    .di-three-block-right {
      .image-caption-link-wrapper .img-cap-text-wrapper .img-cap-title h5.h5 {
        font-size: 28px;
      }
    }
  }

  .single-image-slider {
    @include breakpoint($grid-md) {
      .slider-img-block-wrapper {
        .slider-img-block {
          img.img {
            height: 100%;
            width: auto;
            object-fit: cover;
          }
        }

        .slider-img-caption {
          left: 10px !important;
          padding: $space-ten + 5;
          bottom: 17px !important;
        }
      }

      .slick-arrow.slick-next {
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='%23fff' d='M5.82 14L4.5 12.6 8.85 8 4.5 3.4 5.82 2l5.68 6-5.68 6z'/></svg>");
      }

      .slick-arrow.slick-prev {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M10.18,2,11.5,3.4,7.15,8l4.35,4.6L10.18,14,4.5,8Z'/%3E%3C/svg%3E");
      }
    }

    @include maxbp($grid-md) {
      .slick-arrow {
        top: 25%;
        bottom: auto;
      }

      .slider-img-block-wrapper .slider-img-caption {
        background: #d7d7d9;
        position: static !important;
        padding: 20px;
        color: #18191d;
        max-width: none !important;

        h6.h6 {
          color: $gray-10;
        }
      }
    }

    @include maxbp(500px) {
      .slick-arrow {
        top: 20%;
      }
    }

    @include maxbp(450px) {
      .slick-arrow {
        top: 15%;
      }
    }

    @include maxbp(370px) {
      .slick-arrow {
        top: 10%;
      }
    }
  }

  .main {
    padding: 0;
  }

  @include maxbp($grid-md) {
    .blog-grid.design-experience-three-image-section {
      padding: 0 15px;
    }

    [data-block-plugin-id="paragraph_field:node:field_page_component:2:landing_page"] {
      .slider-img-block {
        img {
          height: 250px;
          width: auto;
          object-fit: cover;
        }
      }

      .slider-img-caption {
        left: 0;
        padding: 5%;
        bottom: 0;
      }
    }

    [data-block-plugin-id="paragraph_field:node:field_page_component:3:landing_page"] {
      .single-image-slider .slider-img-block-wrapper {
        .slider-img-caption {
          color: #18191d;
          position: relative;
          top: 0;
          left: 12px;
          padding-top: 20px;
        }

        .slider-img-title h6.h6 {
          color: #18191d;
        }
      }
    }
  }
}

.inspired-living {
  .blog-grid {
    grid-template-rows: none;
  }
}

.di-blogs {
  .field-values-in {
    .img_element,
    .section-lead-in.paragraph-component {
      max-width: 750px;
      width: 100%;
    }

    .section-lead-in.paragraph-component {
      margin: $space-ten*2 0 $space-ten;
    }
  }
}
