html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

.main {
  @include wrapper;

  display: block; // Fix for IE weirdness
}

ul {
  list-style: none;
}

hr {
  border: 0;
  border-top: 1px solid $gray-70;
}

// List Style for Drupal CMS

.content {
  ul {
    li {
      list-style: disc;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  ul,
  ol {
    &.grid {
      li {
        list-style: none;
      }
    }
  }

  .tabs__nav {
    li {
      list-style: none;
    }
  }
}

.list-item,
.text-long ol li,
.text-long ul li {
  margin-bottom: 0;
  padding-left: 0;
}

.text-long ol li {
  list-style: decimal;
}

.text-long ul li {
  list-style: disc;
}

@include maxbp($medium) {
  .banner-block.only-mobile {
    display: table;
    width: 100%;
  }

  .banner-block.only-destop {
    display: none;
  }
}

@include medium {
  .banner-block.only-mobile {
    display: none;
  }

  .banner-block.only-destop {
    display: table;
    width: 100%;
  }
}

@include large {
  .breadcrumb {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
