.collection-name {
  color: $gray-10;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 20px;
  margin: 0 0 $space-ten+5;

  @include maxbp($medium) {
    font-size: 13px;
    margin: 0 0 $space-ten/2;
  }
}
