// pranali, temporary hide of mobile cart count
// needs to be updated in mobile header
@include breakpoint($mega-menu-mobile) {
  .fullwidth_header .block-commerce-cart {
    display: none;
  }
}

.icon-img {
  padding: 6px 8px 0;
  position: relative;

  &:hover {
    background: $delta-red;
  }

  a {
    display: inline-block;
    max-width: 16px;
  }
}

.cart--cart-block {
  display: inline-block;
  vertical-align: middle;

  .figure__image {
    background: url("/themes/custom/delta_theme/images/icons/src/cart.svg") no-repeat;
    width: 20px;
    height: 20px;
    background-size: 100%;
  }
}

.cart_count {
  display: inline-block;
  vertical-align: middle;
  color: $white;
  font-size: 13px;
  background-color: $delta-punch;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  height: 18px;
  min-width: 28px;
  line-height: 16px;
  text-align: center;
}

.icon-img + .cart_count {
  margin-right: 8px;
}

.cart-icon:visited,
.cart-icon {
  color: $gray-10;

  @include breakpoint($mega-menu-mobile) {
    color: $white;
  }
}
