.image-caption-link-wrapper {
  position: relative;
  display: inline-block;

  .img-cap-image {
    line-height: 0;

    &::after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 36%, rgba(0, 0, 0, 0.8) 100%);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .img-cap-text-wrapper {
    position: absolute;
    bottom: 0;
    color: $white;
    width: 100%;
    left: 0;
    padding: 0 $space-ten*2 $space-ten;

    .img-cap-link {
      text-align: center;
      margin: 0 0 $space-ten+5;

      .link {
        color: $white;
        text-decoration: none;
      }
    }

    .img-cap-title {
      h5.h5 {
        font-family: $font-body;
        color: $white;
        text-transform: none;
        letter-spacing: normal;
        font-size: 40px;
        font-weight: 100;
      }
    }
  }
}
