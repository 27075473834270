.qidl-block {
  p {
    margin: 0;
  }

  .qidl-quote {
    p {
      font-size: 25px;
      color: $gray-30;
      line-height: 27px;
      margin: 0 0 5px;
    }
  }

  .qidl-img {
    margin: 0 0 $space-ten;
  }

  .qidl-link {
    margin: $space-ten*2 0 0;

    a {
      padding: $space-ten/2 $space-ten*5;
      letter-spacing: 0;
      font-family: $font-body;
      text-transform: none;
    }
  }
}
