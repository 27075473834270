.pdp-prod-label-txt,
.pdp-prod-label {
  display: inline-block;
  vertical-align: middle;
}

.pdp-prod-label-txt {
  font-size: 16px;

  p {
    margin: 0;
  }
}
