.pdp-tks-detail-title {
  h6.h6 {
    text-transform: none;
    font-weight: normal;
    font-size: 23px;
    letter-spacing: -1px;
  }
}

.pdp-tks-doc {
  margin: 0 0 $space-ten*5;

  .pdp-tks-doc-desc {
    ul {
      padding: 0;
      margin: 0;
    }
  }
}

.pdp-tks-design {
  max-width: 600px;

  .pdp-tks-design-block {
    display: inline-block;

    .link {
      display: block;
      background: $gray-70;
      font-size: 16px;
      color: $gray-10;
      font-weight: bold;
      text-decoration: none;
      padding: $space-ten+5 $space-ten*6;
      margin: 0 0 $space-ten/2;
    }
  }
}

.pdp-tks-buttons {
  .button {
    width: 350px;
    text-align: center;
    display: block;
    font-size: 18px;
    padding: $space-ten*2;
    margin: 0 0 $space-ten/2;
    max-width: 100%;

    @include maxbp($grid-xl) {
      width: 280px;
    }

    @include maxbp($large) {
      width: 100%;
    }
  }
}
