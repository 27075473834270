@media print {
  .utility-wrapper,
  .fullwidth_header,
  .footer,
  .path-product h1,
  .thumb-img-wrapper,
  .pdp-collection-link-wrapper,
  .pdp-rating-block,
  .pdp-prod-buttons,
  .pdp-img-btn,
  .pdp-tabs .tabs__nav,
  .pdp-prod-listing,
  .footer-info {
    display: none;
  }

  .main {
    padding: 0;
  }

  .tabs__tab {
    float: left;
    display: block;
    padding: 10px;
    page-break-inside: avoid;
    margin-top: 20px;
    height: 100%;
    max-height: 100%;
  }

  #tab--1,
  #tab--0 {
    display: block !important;
  }

  #tab--2,
  #tab--3,
  #tab--4 {
    display: none;
  }

  .Product-collection-wrap {
    display: block;
  }

  .col-md-6 {
    width: 50%;
  }

  .pdp-top-section {
    padding: 0 !important;
    max-width: 100% !important;
    margin-bottom: 15px;
  }

  .pdp-top-section > .grid-col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tab-accordion-title,
  .mobile-thumb-img,
  .mobile-pdp-prod-info-hdr {
    display: none;
  }

  .desktop-thumb-img,
  .desktop-pdp-prod-info-hdr {
    display: block;
  }

  .color-option-btn-wrapper,
  .pdp-prod-price-block {
    text-align: left;
  }

  .pdp-install-info {
    margin-top: 25px;
  }

  .pdp-prod-name {
    color: #18191d;
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 43px;
    font-family: $font-light;
    width: 100%;
    margin: 0 0 15px;
  }

  .collection-name {
    color: #18191d;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 20px;
    margin: 0 0 15px;
  }

  .product-model {
    color: #77777a;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 18px;
    margin: 0 0 15px;
  }

  .pdp-install-block .pdp-install-block-desc,
  .pdp-install-block .pdp-install-block-title {
    display: inline-block;
    font-size: 16px;
  }

  .pdp-install-block .pdp-install-block-title p::after {
    content: ":";
    display: inline-block;
    margin: 0;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  @page {
    size: auto;
    margin: 1cm 0;
    background: #fff;
    -webkit-print-color-adjust: exact;
  }

  body {
    -webkit-print-color-adjust: exact;
    color: #474747;
    background: #fff;
  }

  body .ui-widget.ui-widget-content.compare-products {
    position: relative !important;
  }

  button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close {
    display: none;
  }

  body .ui-widget.ui-widget-content.compare-products .ui-front.ui-dialog-content {
    width: 100% !important;
  }

  .pdp-listing-block {
    margin: 0;
  }

  .pdp-fealist-wrapper .pdp-fealist.grid-col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .pdp-fealist-wrapper .pdp-fea-img-wrapper.grid-col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .pdp-tab-doc-specs .grid-col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .compare_print {
    .dialog-off-canvas-main-canvas {
      display: none;
    }
  }

  .main-com-desc .main-com-desc-block {
    width: 33% !important;
    display: block !important;
  }
}

.status--error {
  display: none;
}
