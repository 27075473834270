body.showroom {
  .text-long {
    text-align: center;
    margin: 40px 0;
  }

  .designbeyond {
    margin: 40px 0;
  }

  .designbeyond .qidl-link {
    margin-bottom: 20px;
  }

  .rewards {
    text-align: center;
  }

  .section-lead-in {
    margin-bottom: 0;
  }
}
