.design-experience-design-beyond {
  .grid-col-md-3 {
    .img_element,
    img {
      width: 100%;
    }

    a {
      text-decoration: none;
    }

    .figure__caption {
      font-style: normal;
    }
  }

  .paragraph-component {
    margin-bottom: 50px;
  }

  .qidl-wrapper {
    margin: $space-ten*7 auto $space-ten*6 !important;

    .qidl-block {
      @include maxbp($ipad-only) {
        margin: 0 0 $space-ten*3;
      }
    }

    .qidl-quote {
      min-height: 85px;

      @include maxbp($ipad-only) {
        min-height: 10px;
        margin: 0 0 $space-ten;
      }
    }

    .qidl-desc {
      min-height: 220px;

      @include maxbp($ipad-only) {
        min-height: 10px;
      }
    }
  }

  .designbeyond .iwlt-wrapper {
    margin: $space-ten*5 auto !important;

    .iwlt-block {
      margin: 0 0 $space-ten*2;
      text-align: center;

      .iwlt-img {
        margin: $space-ten 0 0;
      }
    }
  }

  .banner-content {
    margin: 0 0 0 110px;
    max-width: 560px;

    @include maxbp($large) {
      margin: 0 0 0 0;
    }

    p {
      margin: 0;
    }

    h1.banner__title {
      padding: 0;
      margin: 0 0 $space-ten;

      &::after {
        display: none !important;
      }

      p {
        font-weight: normal;
        margin: 0;
      }
    }

    .banner__headline,
    .banner__paragraph,
    .banner__disclaimer {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .banner__button-group {
    margin: $space-ten*2 0 0 110px;

    @include maxbp($large) {
      margin: $space-ten*2 0 0;
    }

    a {
      padding: 7px 50px;
      text-transform: none;
      font-weight: normal;
      font-family: $font-body;
    }
  }
}
