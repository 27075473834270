.contact-form {
  .form-item__select {
    padding: 1rem 0.7rem;
  }

  .form-item {
    @include maxbp($grid-md) {
      margin-bottom: 24px;
    }
  }

  .first-last-name-wrapper {
    @include breakpoint($r-1100) {
      display: flex;

      .js-form-item:first-child {
        padding-right: 20px;
      }
    }
  }

  .city-state-zip-container {
    @include breakpoint($r-1100) {
      display: flex;

      .js-form-item {
        padding-right: 20px;

        &:first-child {
          flex: 0 0 50%;
          max-width: 50%;
        }

        &:nth-child(2) {
          flex: 0 0 25%;
          max-width: 25%;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .custom-input__native {
    position: relative;
    opacity: 1;
    z-index: 0;

    .h2 {
      font-size: 18px;
      text-transform: capitalize;
      font-family: $font-body;
      border-bottom: 0 none;
      letter-spacing: normal;
      font-weight: normal;
      margin-bottom: 21px;
    }

    .option {
      top: 0;
      left: 20px;
    }

    .form-item--radio {
      .form-item:first-child {
        margin-bottom: 14px;
      }
    }
  }

  .custom-input--checkbox .custom-input__label {
    cursor: pointer;
  }

  .hasDatepicker {
    background-image: url(/themes/custom/delta_theme/dist/../images/icons/src/calendar.svg);
    background-repeat: no-repeat;
    padding-right: 13px;
    background-position: 98%;
    cursor: pointer;
  }

  .custom-input--checkbox {
    padding-bottom: 20px;

    .custom-input__native {
      left: 12px;
      opacity: 0;
      top: 21px;
      position: absolute;
    }
  }

  .file-select-name {
    color: $black;
    font-weight: 800;
  }
}

.model-number-text {
  display: flex;
  align-items: center;
  font-size: $font-size-sm;
  color: $gray-30;

  .icon {
    margin-left: 4px;
    vertical-align: middle;
  }
}

.image-upload-cont {
  h3 {
    font-size: 1.269rem;
  }

  p {
    font-size: 1.125rem;
  }
}

.image-formate {
  color: $gray-30;

  p {
    font-family: $font-oblique;
  }
}

.image-upload {
  margin-top: 25px;
  margin-bottom: 12px;
  background: $gray-90;
  padding: 0 24px;

  label {
    border-bottom: 1px solid $gray-70;
    margin-bottom: 0;
  }

  .form-item {
    margin: 0;
  }

  &:last-child {
    label {
      border: 0 none;
    }
  }

  .status--error {
    display: block;
    font-size: 15px;
    color: #ff4949;
    margin: 0 0 10px 0;

    li {
      list-style: none;
    }
  }

  .upload-input__label {
    position: relative;
    border-bottom: 1px solid #d7d7d9;

    &::before {
      content: 'Choose File';
    }
  }

  .image_upload_here {
    .js-form-file {
      position: absolute;
      opacity: 0;
      left: 31px;
      top: 32px;
      width: 100%;
    }

    .file {
      a {
        @include maxbp($grid-md) {
          word-break: break-word;
        }
      }
    }
  }

  .remove1,
  .remove2,
  .remove3 {
    &::before {
      content: 'Remove';
    }
  }

  .button.js-form-submit.form-submit {
    position: absolute;
    left: 0;
    padding: 13px 29px;
    top: 19px;
    opacity: 0;
    z-index: 99;
  }

  .upload-input__native {
    z-index: 1;
    opacity: 1;

    .js-form-file.form-file {
      padding: 12px 15px;
      width: 167px;
      margin-top: 20px;
      opacity: 0;
      cursor: pointer;
    }

    // .file--mime-image-jpeg {
    //   display: none;
    // }
  }

  // .file--mime-image-png {
  //   opacity: 0;
  //   z-index: -1;
  // }

  .messages--error {
    font-size: 12px;
    margin-bottom: 10px;
    color: $error;
  }
}

.product-info-label {
  .form-item {
    margin-bottom: 0;
  }
}

#info-bubble {
  a {
    color: $gray-30;
    margin-top: 5px;
    display: inline-block;
    text-decoration: none;
  }
}

.captcha {
  label {
    position: relative;
    top: unset;
    left: unset;
    padding: 0;
  }
}

.product-purchased-date {
  position: relative;

  .form-item__textfield--date {
    background-image: url("../images/icons/src/calendar.svg");
    background-repeat: no-repeat;
    padding-right: 13px;
    background-position: 98%;
    cursor: pointer;
  }

  .container {
    padding: 0;
    width: 100% !important;
    margin-top: 20px;
  }

  .form-item__label {
    position: relative;
    top: 0;
    left: 0;
  }
}

#block-callus {
  margin-top: 30px;

  .textbox__content {
    > .paragraph {
      margin: 0;
    }
  }
}

.path-service-parts,
.path-customer-support {
  .page-title {
    color: inherit;
  }

  #block-pagetitle {
    display: none;
  }
}

.contact-submit {
  .button--primary {
    width: 365px;
    justify-content: center;

    @include maxbp($large) {
      width: 100%;
    }
  }
}

.product-registration-form {
  // .js input.form-autocomplete.ui-autocomplete-loading {
  //   background: none;
  // }
  .form-item__select {
    padding: 1rem 0.7rem;
  }

  .custom-input--checkbox .custom-input__label {
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .ac-model-number {
    padding-top: 10px;
  }

  .form-submit {
    margin-bottom: 56px;
    margin-top: 26px;

    @include maxbp(500px) {
      display: block;
      margin: 24px 0;
    }
  }

  .form-item {
    margin: 0 0 20px;
  }

  [class^=form-col] {
    padding-left: 0;
  }

  .form-row {
    margin-left: 0;
  }

  .table {
    background-color: $white;

    .parent_product_sku_purchase_date_table_add {
      display: none;
    }

    .table__heading-cell {
      display: none;
    }

    .webform-multiple-table--operations.webform-multiple-table--operations-two.table__cell {
      @include maxbp($old-desktop) {
        display: flex;
        padding: 0 13px;
        justify-content: center;
        width: 100%;
      }
    }
  }

  #product_sku_purchase_date_table {
    .form-date {
      @include breakpoint($old-desktop) {
        position: relative;
        top: 14px;
      }
    }

    .form-item__label {
      position: relative;

      @include breakpoint($old-desktop) {
        left: 0;
        padding-bottom: 15px;
      }

      @include maxbp($grid-md) {
        top: 0;
        left: 0;
      }
    }

    .webform-multiple-sort-weight {
      @include breakpoint($old-desktop) {
        position: relative;
        top: 15px;
      }
    }
    // .tabledrag-toggle-weight {
    //   display: none;
    // }
  }

  #edit-product-sku-purchase-date-items {
    .webform-multiple-table td {
      vertical-align: middle;
    }
  }

  .webform-multiple-add {
    .js-form-submit {
      background: $delta-red;
      color: $white;
      margin-bottom: 0;

      &:hover {
        background-color: #750010;
      }
    }

    .form-item {
      display: none;
      position: relative;
      top: 14px;
      padding-left: 30px;
    }
  }

  .product-purchase-date-label {
    padding-top: 30px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $gray-10;

    .form-item {
      padding-top: 10px;
    }
  }

  .product-purchase-date {
    .hasDatepicker {
      position: relative;
    }

    .ui-datepicker-trigger {
      position: absolute;
      right: 14px;
      top: 31px;
    }
  }

  .removecrossbutton {
    display: none !important;
  }

  .parent_product_sku_purchase_date_table_remove {
    .form-submit {
      margin: 0;
    }
  }

  .parent_product_sku_purchase_date_table_add {
    display: none;
  }

  .product-model-number-label {
    padding-top: 20px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $gray-10;
  }

  .product-info-header {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .model-purchase-date-header {
    font-size: 21px;
    border-bottom: 1px solid #ccc;
    font-weight: 600;
    color: $gray-50;
    letter-spacing: 1px;
  }
}

.path-registerme {
  .textbox__iconbox ul li {
    margin-bottom: 24px;
  }

  @include maxbp($large) {
    .product-registration-form {
      padding: 0 16px;

      .form-submit {
        width: 100%;
        max-width: 100%;
        justify-content: center;
      }
    }

    #block-productregistrationformheader {
      margin: 0 16px;
    }
  }
}
