.new-production-and-innovations {
  .page-title {
    margin: 50px 0 0;
    font-family: $font-bold;
    font-size: 40px;
  }

  main {
    padding-top: 30px;
  }

  .layout--onecol {
    &:first-child {
      background: #18191d;
      padding: 70px 10px;
      text-align: center;
      color: white;
      margin: 0 0 80px;

      h2 {
        display: none;
      }

      .designbeyond {
        text-align: center;
      }

      .qidl-wrapper {
        .qidl-quote {
          p {
            color: #ac0535 !important;
            font-family: $font-bold;
            font-size: 40px;
            margin: 0 0 30px;
          }
        }

        .qidl-desc {
          p {
            font-size: 17px;
            margin: 15px 0 0;
          }
        }
      }
    }

    &:last-child {
      max-width: 1150px;
      margin: 0 auto;
    }
  }

  .new-faucet-block {
    text-align: center;
    margin: 0 0 30px;

    h2.h2 {
      text-align: left;
      margin: 0 25px 15px;
      text-transform: none;
      font-weight: normal;
      font-size: 35px;
      letter-spacing: 0;
    }

    .qidl-wrapper {
      .qidl-block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px;

        > div {
          width: 100%;
        }
      }

      .qidl-img {
        order: 1;
      }

      .qidl-quote {
        order: 2;

        p {
          font-size: 18px;
          margin: 10px 0 15px;
          font-weight: bold;
        }
      }

      .qidl-desc {
        order: 3;
      }

      .qidl-link {
        order: 4;
      }
    }
  }
}
