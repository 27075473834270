.form-item {
  position: relative;
  color: $gray-10;
  margin-bottom: 24px;

  .icon--calendar {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.form-item__label {
  position: absolute;
  top: 1rem;
  left: 0.75rem;
  display: block;
  font-size: $font-size-lg;
  line-height: 1.5rem;
  color: $gray-10;
  font-weight: normal;
  padding: 0 8px 0 0;
  background-color: transparent;
  cursor: text;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  user-select: none;

  .form-item--disabled & {
    background-color: transparent !important;
  }

  .form-item--dropdown.form-item--disabled & {
    opacity: 0 !important;
    z-index: -1;
  }

  .form-item.has-focus &,
  .form-item.is-filled & {
    padding-left: 4px;
    opacity: 1;
    font-family: $font-bold2;
    font-size: $font-size-sm;
    background-color: $white;
    color: $gray-50;
    font-weight: 700;
    top: 0;
    transform: translate(0, -50%);

    span {
      opacity: 1;
    }
  }

  &--required {
    &::after {
      margin-left: +0.35ch;
      content: "*";
    }
  }
}

.form-item__textfield,
.form-text {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: $font-size-lg;
  font-family: inherit;
  line-height: 1.5rem;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $gray-60 inset;
  transition: box-shadow 0.15s ease-in-out;
  appearance: none;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $gray-10 inset;
  }

  &:disabled {
    box-shadow: none;
    background-color: $gray-90;
    cursor: not-allowed;
  }

  .form-item--error & {
    box-shadow: 0 0 0 2px $error inset;
  }
}

.error-label::before {
  box-shadow: 0 0 0 2px $error inset;
}

.form-item__textarea {
  height: 156px;
}

.form-item__textarea-counter {
  font-size: $font-size-sm;
  text-align: right;
  color: $gray-50;
  line-height: 1.375rem;
}

.form-item--error-message,
.check--error-message {
  display: block;
  font-family: $font-bold2;
  font-size: $font-size-sm;
  font-weight: 700;
  color: $error;
  margin-top: 4px;
}

//
// Password toggle
//

.form-item__pwd-toggle {
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  background-color: transparent;
  padding: 16px;
  appearance: none;
  cursor: pointer;
  outline: 0; // TODO: remove when taboutline is implemented.

  .icon--eye-closed {
    display: block;
  }

  .icon--eye-open {
    display: none;
  }

  &.is-showing-password {
    .icon--eye-closed {
      display: none;
    }

    .icon--eye-open {
      display: block;
    }
  }
}

.form-item__description {
  margin-top: 0.3em;
}

.form-fieldset {
  border: none;
  margin-bottom: 1.5em;
  padding: 0;
}

.form-legend {
  display: block;
  border: 0;
  font-family: $font-bold2;
  font-weight: 700;
  font-size: $font-size-lg;
  margin-bottom: 24px;

  @include breakpoint($grid-md) {
    font-size: $font-size-xl;
  }
}

.user-login-form {
  label.form-item__label {
    position: unset;
  }

  input.button {
    margin-bottom: 20px;
    background-color: $delta-red;
    color: $white;

    &:hover {
      background-color: $delta-merlot;
    }

    &:active {
      background-color: lighten($delta-merlot, 5%);
    }
  }
}

.find-product-form .js-form-item.has-focus span {
  color: #18191d;
  font-family: "Trade_Gothic_LT_Roman", sans-serif;
  font-weight: 100;
}

.find-product-form .js-form-item.has-focus label.form-item__label {
  padding-left: 0;
  transform: none;
}
