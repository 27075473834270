.download-badge {
  display: inline-flex;
  align-items: center;
  background-color: $gray-80;
  padding: 4px 8px;
  font-size: $font-size-sm;
  font-family: $font-bold;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;

  .icon {
    margin-right: 5px;
  }
}
