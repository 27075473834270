.our_Order_table {
  background-color: $gray-90;

  .title,
  .value {
    padding: 15px 28px;
    text-align: left;
  }

  .value {
    border-top: 1px solid $gray-70;
  }

  .title {
    font-family: $font-bold2;
    font-size: 1.125rem;
  }

  td:last-child {
    .title {
      padding-top: 42px;
    }
  }

  .product_info {
    .img {
      display: inline-table;
      vertical-align: middle;

      .img-responsive {
        max-width: 96px;

        @include maxbp($medium) {
          max-width: 50px;
        }
      }
    }

    .cart-detail {
      margin-left: 15px;
      display: inline-table;
      vertical-align: middle;
    }

    .sku {
      font-size: 0.788rem;
      color: $gray-50;
      letter-spacing: 2px;
      font-weight: 700;
    }

    .title strong {
      font-weight: 400;
      font-family: $font-light;
      color: $gray-10;
      font-size: 1.269rem;
      line-height: 1.5;

      @include maxbp($medium) {
        font-size: 1rem;
      }
    }
  }
}

@include maxbp($medium) {
  .order_status_section {
    .track_orders_wrap .track_order,
    .track_orders_wrap .customer-service {
      padding: 0;
      max-width: 100%;
    }
  }

  .our_Order_table {
    table,
    td {
      width: 100%;
    }

    td {
      display: block;
      padding: 15px;
    }

    .value {
      border: 0 none;
    }

    .title,
    .value {
      padding: 3px 10px;
    }

    td:last-child {
      .title {
        border-top: 1px solid $gray-70;
        padding-top: 20px;
      }
    }
  }

  .track_orders_wrap {
    .iconbox_title {
      padding: 0;
    }
  }
}

.our_Order_details {
  padding: 0 20px;

  table {
    width: 100%;
    display: block;

    td,
    th {
      width: 20%;
      text-align: left;
      vertical-align: top;

      &:first-child {
        width: 40%;
      }
    }
  }

  .cart-detail {
    .order_number {
      font-family: $font-light;
      font-size: 0.788rem;
      color: $gray-50;
      font-weight: bold;

      @include maxbp($medium) {
        margin: 0;
      }
    }
  }
}

.our_Order_details.our_Order_table {
  background: none;

  .title {
    padding-left: 0;
    padding-bottom: 8px;

    @include maxbp($medium) {
      padding: 0;
    }
  }

  table {
    border-collapse: collapse;
  }

  th {
    border-bottom: 1px solid $gray-70;
    padding-bottom: 5px;
  }

  tbody {
    td {
      border-bottom: 1px solid $gray-70;
      padding-top: 20px;
      padding-bottom: 45px;
    }
  }

  @include maxbp($medium) {
    thead {
      display: none;
    }

    table td,
    table th {
      width: 100%;
    }

    table tbody {
      display: block;
      width: 100%;
    }

    tbody td {
      border-bottom: 0 none;
      padding: 0;
      margin-left: 70px;
      font-size: 0.788rem;

      &:first-child {
        margin-left: 0;
      }
    }

    tbody tr {
      border-bottom: 1px solid $gray-70;
      padding: 20px 0;
      display: block;
      width: 100%;

      &:first-child {
        border-top: 1px solid $gray-70;
      }
    }
  }
}

.check_order_status {
  font-family: $font-oblique;
}
