.pdp-tab-doc-specs {
  .pdp-supp,
  .pdp-install-info {
    @include maxbp($medium) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.pdp-doc-title {
  h6.h6 {
    text-transform: none;
    font-size: 23px;
    font-weight: normal;
    letter-spacing: normal;
    border-bottom: 1px solid $gray-80;
    padding-bottom: 6px;

    @include maxbp($medium) {
      font-size: 20px;
    }
  }
}

.pdp-supp-desc {
  ul {
    padding: 0;

    li {
      margin: 0 0 7px;

      a {
        font-size: 16px;
        color: $delta-red;
      }
    }
  }
}

.pdp-install-info-img-text {
  margin: 0 0 $space-ten*3;
}

.pdp-install-fea-img,
.pdp-install-img-name {
  display: inline-block;
  vertical-align: middle;

  @include maxbp($small) {
    display: block;
  }
}

.pdp-install-fea-img {
  line-height: 0;
  max-width: 140px;
  min-width: 135px;

  @include maxbp($small) {
    min-width: 10px;
    margin: 0 0 $space-ten;
  }
}

.pdp-install-img-name {
  font-size: 16px;
  margin: 0;
  text-transform: none;
  font-weight: normal;

  p {
    margin: 0;
  }
}

.pdp-install-block {
  margin: 0 0 $space-ten;

  .pdp-install-block-title {
    font-family: $font-bold2;
    font-weight: normal;
    min-width: 145px;

    p {
      margin: 0;
      position: relative;
      display: inline-block;

      &::after {
        content: ":";
        margin: 0 0 0 -5px;
        display: inline-table;
      }
    }

    @include maxbp($small) {
      min-width: 10px;
      margin: 0 0 $space-ten;
    }
  }

  .pdp-install-block-title,
  .pdp-install-block-desc {
    display: inline-grid;
    font-size: 16px;

    @include maxbp($small) {
      display: block;
    }

    p {
      margin: 0;
    }
  }
}
