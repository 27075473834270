//////////////////////////
// General Variables
// Note: colors should go into
// components/_patterns/00-base/global/01-colors/_color-vars.scss
// and breakpoint related vars into
// components/_patterns/00-base/_breakpoints.scss

// Fonts
$font-body: "Trade_Gothic_LT_Roman";
$font-light: "TradeGothic_LT_Light";
$font-bold: "Trade_Gothic_LT_Bold";
$font-bold2: "Trade_Gothic_LT_Bold_2";
$font-roman: "Trade_Gothic_LT_Roman";
$font-oblique: "Trade_Gothic_LT_Oblique";
$font-heading: "Trade_Gothic_LT_Bold";
$font-heading-light: "TradeGothic_LT_Light";
$font-icomoon: "icomoon";

// Font sizes
$font-size-xs: 0.79rem;
$font-size-sm: 0.88875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.266rem;
$font-size-xxl: 1.42rem;
$font-size-xxxl: 1.602rem;

// Spacing
$space: 1rem;

// times
$space-double: $space*2;
$space-triple: $space*3;
$space-quadruple: $space*4;
$space-quintuple: $space*5;
$space-sextuple: $space*6;
$space-septuple: $space*7;

// divided
$space-one-half: $space/2;
$space-one-third: $space/3;
$space-one-fourth: $space/4;
$space-one-fifth: $space/5;
$space-one-sixth: $space/6;
$space-one-seventh: $space/7;
$space-one-eighth: $space/8;

$space-ten: 10px;
