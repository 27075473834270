.node--type-awards {
  .banner-block {
    height: 254px;
    display: table;
    width: 100%;

    .grid-container {
      position: static;
      display: table-cell;
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.region-banner {
  .banner-block {
    .grid-container {
      position: static;
    }
  }
}

@media (max-width: 1023px) {
  .node--type-awards .heading-image-description:nth-child(1) h3 sup {
    display: inline-block;
  }
}

@media (min-width: 920px) {
  .node--type-awards .banner-block.only-mobile {
    display: none !important;
  }
}

@media (max-width: 919px) {
  .node--type-awards .banner-block.only-destop {
    display: none !important;
  }
}
