.summary-card {
  @include breakpoint($old-desktop) {
    display: flex;
    align-items: center;
  }

  @include large {
    font-size: $font-size-lg;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include maxbp($medium) {
    h1,
    .h1 {
      font-size: 2.565625rem;
    }

    h2,
    .h2 {
      font-size: 2.280625rem;
    }

    h3,
    .h3 {
      font-size: 2.0275rem;
    }

    h4,
    .h4 {
      font-size: 1.801875rem;
    }
  }
}

.summary-card__img {
  @include maxbp($medium) {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: 24px;
    max-width: 50%;
  }

  // @include large {
  //   max-width: none;
  //   margin-right: 32px;
  // }

  @include xl {
    margin-right: 60px;
    max-width: 100%;
  }
}

.summary-card__content {
  @include medium {
    margin-top: 20px;
  }
}
