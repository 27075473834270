.com-pop-block {
  text-align: center;
  width: 31%;
  display: inline-block;
  vertical-align: top;
  padding: 0 1%;

  @include maxbp($large) {
    width: 100%;
    display: block;
    padding: 0;
    text-align: left;
    overflow: hidden;
    clear: both;
    margin: 0 0 $space-ten*5;
  }

  @include maxbp($grid-sm) {
    text-align: center;
  }

  .com-pop-img {
    margin: 0 auto $space-ten;
    position: relative;
    display: block;
    overflow: hidden;

    @include maxbp($large) {
      float: left;
      width: 250px;
    }

    @include maxbp($grid-sm) {
      float: none;
      width: 100%;
    }

    img {
      margin: auto;
    }

    .slider_flag {
      position: absolute;
      top: 0;

      .flag {
        @include maxbp($medium) {
          min-width: 160px;
          font-size: $font-size-sm;
          transform: translate(-66px, -19px) rotate(-45deg);
          transform-origin: 100% 0;
        }
      }
    }
  }

  .com-pop-data {
    @include maxbp($large) {
      float: right;
      width: calc(100% - 270px);
    }

    @include maxbp($grid-sm) {
      float: none;
      width: 100%;
    }

    .com_pop_title {
      font-size: 16px;

      h6.h6 {
        font-family: $font-bold;
        font-weight: normal;
        text-transform: none;
        letter-spacing: normal;
      }
    }

    .com_pop_desc {
      font-size: 18px;

      p {
        margin: 0 0 7px;
      }
    }
  }

  .com_pop_model {
    font-size: 18px;

    p {
      margin: 0;
    }
  }

  .com_pop_price {
    font-size: 18px;

    p {
      margin: 0 0 $space-ten+5;
      font-family: $font-bold;
      font-weight: normal;
    }
  }

  .com-pop-block-empty {
    height: 321px;
    font-size: 18px;
    color: $gray-60;
    border: 5px solid $gray-60;

    p {
      margin: -30px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 321px;

      &::before {
        content: "+";
        font-size: 150px;
        line-height: 140px;
      }
    }
  }
}
