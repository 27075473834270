//CampaignRegistration Form

.registration-sweepstakes {
  @include wrapper;

  h2 {
    text-transform: capitalize;
    color: $delta-red;
    letter-spacing: 1px;
    font-size: 32px;

    @include maxbp($large) {
      font-size: 26px;
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 0;
    color: $gray-30;

    a {
      text-decoration: underline;
      color: $gray-30;
    }
  }

  .review-section {
    color: $gray-30;
    max-width: 1146px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    @include maxbp($large) {
      display: block;
    }
  }

  .reviews_sweeps_step1 {
    p:last-child {
      font-size: 16px;
    }
  }

  .reviews_sweeps_step2 {
    b {
      font-size: 20px;
    }
  }
}

.g-recaptcha {
  @include maxbp($large) {
    padding: 20px 0 30px;
  }
}

label[for=edit-hdn-captcha] {
  display: none;
}

.form-item__select,
.form-item__textfield,
.form-text {
  @include maxbp($large) {
    margin-bottom: 15px;
  }
}

.btn-sweepstakes-reset {
  @include maxbp($medium) {
    margin-top: 20px;
  }
}

//Campaign Where to Buy Form
.where-to-buy-form {
  #edit-actions {
    padding: 20px 0 30px;
  }

  #edit-actions-reset {
    background: $gray-50;
    color: $white;

    &:hover {
      background: $delta-red;
    }
  }
}

.product-registration-sweepstakes-form {
  padding-bottom: 30px;

  @include maxbp($large) {
    padding: 0 15px;
  }

  label[for="edit-purchased-date"] {
    font-size: 18px;
  }

  .captcha {
    margin-top: 20px;
  }

  .form-submit {
    @include breakpoint($large) {
      margin: 24px 0 24px 10px !important;
    }
  }

  .product-purchased-date {
    padding: 0;
  }
}
