.footer {
  clear: both;
  padding: 0;
  background: $gray-80;

  &__wrapper {
    max-width: 1440px;
    padding: $space-ten * 3 0;
    margin: 0 auto;

    @include medium {
      display: flex;
    }

    > ul {
      list-style: none;
      padding-bottom: 1em;
      padding-top: 1em;
      padding-left: 25px;
      margin: 0;

      li {
        clear: both;

        a {
          &.social-link,
          &.footer-link {
            float: left;
            font-size: 0.889rem;
          }

          &.social-link {
            margin: 0 5px 10px 0 !important;
            width: 30px;

            & + .footer-link {
              float: left;
              width: calc(100% - 35px);
              line-height: 20px;

              @include maxbp($medium) {
                width: auto;
              }
            }
          }

          &:hover {
            color: $delta-red;
          }
        }
      }

      h2 {
        font-size: 0.889rem;
        font-family: $font-bold2;
        letter-spacing: normal;
        color: $gray-30;
        margin: 0;
        text-transform: none;
        line-height: 2;
        text-align: inherit;
      }

      @include medium {
        display: inline-flex;
        width: 20%;
        flex-direction: column;
      }

      h4 {
        margin: 0;
      }
    }
  }

  &_secondary {
    #copyright-block {
      p {
        margin-top: 10px;
      }
    }
  }

  .footer__wrapper {
    ul {
      .delta-outlet-custom-link {
        cursor: unset;
        font-size: 0.889rem;
        font-family: $font-bold2;
        letter-spacing: normal;
        color: $gray-30;
        margin: 19px 0 0 0;
        text-transform: none;
        line-height: 2;

        @include maxbp($medium) {
          margin: 0;
        }
      }
    }
  }
}

.commerce-footer-block {
  background: $gray-90;
  text-align: center;
  padding: $space-ten*2 0;

  .copyright {
    p {
      margin: 0;
      text-align: center;
    }
  }
}

#block-deltaoutlet {
  clear: both;

  @include medium {
    margin-top: 20px;
  }
}
