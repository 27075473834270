.video-popup-wrapper {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000000;

  .video-pop-data {
    max-width: 900px;
    width: 100%;
    left: 0;
    right: 0;
    top: $space-ten*5;
    position: relative;
    margin: auto;
  }

  video {
    width: 100%;
  }

  &.active-video-popup-wrapper {
    display: block;
  }
}
