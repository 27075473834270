.page-concierge {
  h1.banner__title::after {
    top: 0;
  }

  .quick-link-section {
    text-align: center;

    h3 {
      font-size: 24px;
      margin: 0 auto;
      text-transform: capitalize;
    }

    a {
      text-decoration: none;
      color: $delta-red;
      margin: 10px 0;
      display: block;
    }
  }

  .support_info {
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    max-width: 600px;
    font-size: 20px;
    text-transform: none;
    font-family: $font-body;
    color: #474747;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 50px;
  }

  .single-image-slider {
    .slider-img-title {
      .h6 {
        font-size: 60px;
        color: #fff;
      }
    }

    .slider-img-desc {
      .paragraph {
        font-size: 24px;
        text-shadow: none;
      }
    }
  }

  .concierge-hotspot {
    .h2 {
      font-size: 32px;
      text-transform: none;
      text-align: center;
      margin-top: 40px;
    }

    .hotspot-banner {
      margin: 0 auto;
      max-width: 540px;
    }
  }

  .catelog-fg-block {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-top: 25px;
  }

  @include maxbp($medium) {
    .catelog-fg-block {
      max-width: 100%;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding-right: 12px;
      padding-left: 12px;
    }

    .banner {
      .banner-content {
        max-width: 100%;

        p.u-text-base {
          text-align: center;
        }
      }
    }
  }

  .banner {
    .banner-content {
      max-width: 50%;
    }

    .banner__title .u-text-base {
      font-size: 2rem !important;
      margin-bottom: 5px;
    }
  }

  .banner__title {
    padding-bottom: 0;

    p.banner__paragraph + p.u-text-base {
      font-size: 20px !important;
      max-width: 400px;
    }
  }

  .main {
    padding-left: 0;
    padding-right: 0;
  }
}
