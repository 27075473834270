.thumb-img-wrapper-list {
  max-width: 33.33%;

  @include maxbp($r_600) {
    width: 100%;
  }

  .thumb-img-wrapper {
    padding: 0 $space-ten * 2;

    div.thumb-img-block {
      cursor: pointer;
      padding: 0 $space-ten/2;
      display: block;
    }

    div.thumb-img-block + div.thumb-img-block {
      display: block;
    }

    img {
      width: 100px;
      display: inline-block;
      height: 100px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .prod-big-img,
  .prod-big-img {
    margin: 0 0 $space-ten * 3;
    position: relative;
    overflow: hidden;

    .slider_flag {
      position: absolute;
      top: 0;
    }
  }
}

.desktop-thumb-img {
  display: block;

  @include maxbp($large) {
    display: none;
  }
}

.mobile-thumb-img {
  position: relative;
  overflow: hidden;
  display: none;

  .slick-list.draggable {
    &.fixheight {
      min-height: 350px;
    }

    video {
      min-height: 350px;
    }

    @include maxbp(600px) {
      &.fixheight {
        min-height: 300px;
      }

      video {
        min-height: 300px;
      }
    }

    @include maxbp($small) {
      &.fixheight {
        min-height: 250px;
      }

      video {
        min-height: 250px;
      }
    }
  }

  @include maxbp($large) {
    display: block;
  }

  .slider_flag {
    position: absolute;
    top: 0;
    z-index: 2;

    .flag {
      @include maxbp($medium) {
        min-width: 160px;
        font-size: $font-size-sm;
        transform: translate(-66px, -19px) rotate(-45deg);
        transform-origin: 100% 0;
      }
    }
  }

  .thumb-img-block.slick-slide {
    //display: none;
    text-align: center;

    img {
      margin: auto;
    }

    &.slick-current {
      //display: block;
    }
  }

  .slick-dots {
    text-align: center;

    li {
      display: inline-block;

      &.slick-active {
        button {
          background: $gray-10;
        }
      }

      button {
        font-size: 0;
        height: $space-ten + 6;
        width: $space-ten + 6;
        border-radius: 100%;
        padding: 0;
        border: 1px solid $gray-10;
        background: none;
        margin: 0 $space-ten/2;
        cursor: pointer;
      }
    }
  }
}

.slider-nav {
  .slick-list {
    width: 90%;
    margin: auto;
  }

  img {
    height: 80px;
    padding: $space-ten/2;
  }
}

.prod-slider {
  width: 100%;
  max-width: 550px;
}

.slick-arrow {
  font-size: 0;
  background: none;
  border: none;
  top: 40px;
  bottom: auto;
  margin: auto;
  position: absolute;
  z-index: 15;
  cursor: pointer;

  @include maxbp($large) {
    top: 0;
    bottom: 0;
  }

  &.slick-prev {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#18191d' d='M10.18,2,11.5,3.4,7.15,8l4.35,4.6L10.18,14,4.5,8Z'/%3E%3C/svg%3E"),"#","%23");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    left: 0;
  }

  &.slick-next {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#18191d' d='M5.82 14L4.5 12.6 8.85 8 4.5 3.4 5.82 2l5.68 6-5.68 6z'/%3E%3C/svg%3E"),"#","%23");
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    right: 0;
  }
}

.slider-video-wrapper {
  position: relative;

  video {
    position: absolute;
  }
}
