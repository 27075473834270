#block-footerdisclaimer {
  background-color: $gray-90;
  padding: 16px 0;
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;

  .paragraph {
    margin: 0;
  }

  p {
    margin: 0;
  }
}
