.for-professionals-marketing-sales {
  .main-content {
    max-width: 1150px;
    width: 100%;
    margin: auto;
  }

  .layout--onecol {
    .img_element {
      display: block;
      margin: 20px 0 0;

      img {
        margin: auto;
      }
    }

    .section-lead-in {
      h2.h2 {
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        margin: 25px 0 15px;
      }
    }
  }

  .designbeyond {
    margin: 30px 0 100px;

    .qidl-wrapper {
      display: block;

      > div {
        display: inline-block;
        width: 33%;
        margin: 0 0 40px;
        max-width: none;

        @include maxbp($medium) {
          width: 100% !important;
        }
      }

      .qidl-block {
        display: flex;
        flex-wrap: wrap;

        > div {
          width: 100%;
        }

        .qidl-quote {
          order: 2;
        }

        .qidl-img {
          order: 1;
        }

        .qidl-desc {
          order: 3;

          a img {
            margin: 15px 15px 0 0;
            display: inline-block;
          }
        }

        .qidl-link {
          order: 4;
        }
      }
    }
  }
}
