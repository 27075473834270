.image-with-link-block {
  position: relative;
  display: inline-block;

  .image-with-link-icon {
    position: absolute;
    top: 0;
  }

  .img-link-title {
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    left: 0;
    right: 0;
    bottom: 0;
    padding: $space-ten+5 $space-ten;
    text-align: center;

    a {
      text-decoration: none;
    }

    h5.h5 {
      color: $white;
      margin: 0;
      text-transform: none;
      font-size: 20px;
      font-family: $font-body;
      letter-spacing: normal;
    }
  }

  &.disabled {
    &::after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: white;
      opacity: 0.6;
    }
  }
}
