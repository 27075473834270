.auto-search-result {
  background: $gray-90;
  max-width: 400px;
  width: 100%;
  border: 1px solid #d7d7d7;
  padding: $space-ten+5 $space-ten $space-ten*2;
  overflow: hidden;
  display: block;

  a {
    text-decoration: none;
  }

  .as-block {
    border-bottom: 1px solid $gray-70;
    margin: 0 0 $space-ten+8;
    padding: 0 0 $space-ten+2;

    &:last-child {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

  .as-title {
    margin: 0 0 $space-ten+5;

    h3.h3 {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 15px;
      font-family: $font-bold;
      font-weight: normal;
      margin: 0;
    }
  }

  .as-desc-block {
    margin: 0 0 $space-ten;
    position: relative;
    padding: $space-ten/5 0;

    &::before {
      background: $gray-80;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -$space-ten;
      height: 100%;
      width: 0;
      transition: all ease 1s;
    }

    &:hover {
      &::before {
        width: 120%;
      }
    }

    p {
      margin: 0;
    }

    .as-item {
      color: $gray-10;
      line-height: 20px;
      margin: 0 0 $space-ten/2;
      position: relative;
    }

    .as-item-no {
      color: $gray-30;
      font-size: 15px;
      text-transform: uppercase;
      position: relative;
    }
  }

  .as-view {
    a {
      color: $delta-red;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
    }
  }
}
