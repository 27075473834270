.two-column-temp {
  @include wrapper;

  padding-top: $space-ten*5 !important;
  max-width: $max-width-text;

  .two-temp-category {
    .two-temp-category-title {
      h4.h4 {
        font-family: $font-body;
        font-weight: bold;
        font-size: 26px;
        letter-spacing: normal;
        margin: 0 0 $space-ten*4;
      }
    }

    .two-temp-category-desc {
      ul {
        padding: 0;

        li {
          margin: 0 0 $space-ten+5;

          a {
            font-weight: normal;
            font-family: $font-bold2;
            font-size: 18px;

            &:hover {
              border: none;
            }
          }
        }
      }
    }
  }
}
