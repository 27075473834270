.flag {
  display: inline-block;
  font-family: $font-bold2;
  font-size: $font-size-xs;
  font-weight: 700;
  line-height: 1rem;
  color: $white;
  text-align: center;
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  min-width: 140px;
  letter-spacing: -0.02em;

  @media (min-width: $ipad-only) {
    min-width: 212px;
    font-size: $font-size-sm;
    transform: translate(-88px, -32px) rotate(-44deg);
    transform-origin: 100% 0;

    &.banner-recertified {
      transform: translate(-102px, -46px) rotate(-44deg);
    }
  }

  &--gray-30 {
    background-color: $gray-30;
  }

  &--gray-50 {
    background-color: $gray-50;
  }

  &--delta-red {
    background-color: $delta-red;
  }
}

.flag-mobile {
  display: none;
}

.slider_flag {
  @include maxbp($grid-md) {
    display: none;

    &.flag-mobile {
      display: block;
      position: static;

      .flag {
        letter-spacing: 1px;
        padding: 6px 20px;
        min-width: 131px;
      }
    }
  }
}
