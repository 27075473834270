.service-parts__tablist {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: tablist;

  @include maxbp($large) {
    display: block;
  }

  li {
    @include medium {
      width: 25%;

      + li {
        margin-left: 2px;
      }
    }

    @include maxbp($large) {
      width: 100%;
      border-bottom: 3px solid white;
      margin: 0 !important;
    }
  }

  li.is-active ~ li {
    .service-part-link {
      cursor: default;
      pointer-events: none;
    }
  }
}

.service-part-tabs .service-parts__tablist li {
  list-style: none;
}

.service-parts__tabpanel {
  position: relative;

  .ajax-progress-throbber {
    position: absolute;
  }
}

.service-part-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 24px;
  background-color: $gray-80;

  &::before {
    display: block;
    background-color: $white;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    line-height: 36px;
    border-radius: 50%;
    text-align: center;
    color: $gray-10;
    counter-increment: tablist;
    content: counter(tablist);
  }

  &[aria-selected="true"] {
    background-color: $delta-red;
    color: $white;
    font-weight: 700;
    font-family: $font-bold;
    letter-spacing: 0.05em;
  }
}

.sp-tab-data-wrapper {
  background: $gray-90;
  padding: $space-ten*2 $space-ten $space-ten;

  .sp-nav-link {
    color: $delta-red;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin: $space-ten*5 0 0;
  }

  .sp-tab-data-title {
    display: inline-block;
    overflow: hidden;

    h3.h3 {
      text-transform: none;
      font-family: $font-body;
      font-weight: normal;
      font-size: 25px;
      letter-spacing: normal;

      @include maxbp($grid-sm) {
        margin: 0;
      }
    }
  }

  .sp-tab-data-title-wrapper {
    display: block;
    overflow: hidden;

    .sp-nav-link {
      margin: 0;
    }
  }

  .sp-matching-bottom {
    display: block;
    overflow: hidden;
    margin: $space-ten 0 0;
  }

  .sp-ttl-prod-wrapper {
    float: right;
    text-align: center;
    margin: 0 $space-ten*5 $space-ten 0;

    @include maxbp($grid-sm) {
      float: none;
      margin: $space-ten*2 0 $space-ten*2;
    }

    .sp-ttl-prd-mach-no {
      p {
        margin: 0;
        display: inline-block;
        vertical-align: bottom;
        padding: 0 2px;

        &.sps-ttl-prd-no {
          font-size: 32px;
          font-family: $font-bold;
          line-height: 35px;
        }

        &.sps-ttl-prod-no-text {
          font-size: 20px;
        }
      }
    }

    .sp-ttl-prd-view {
      margin: $space-ten/2 0 0;

      a {
        padding: $space-ten+5;
        letter-spacing: 1px;
        text-transform: none;
      }
    }
  }

  .sp-tab-data-desc {
    .image-with-link-block {
      .img-link-image {
        img {
          width: 100%;
        }
      }

      .image-with-link-icon {
        display: none;
      }
    }

    .accordion {
      .accordion-term {
        display: flex;
        justify-content: space-between;

        &.is-active {
          .acc-ans {
            a.acc-ans-link {
              color: $white;
            }
          }
        }

        .acc-ans {
          a.acc-ans-link {
            color: $delta-red;

            &::before {
              background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M13.41 12l7.78 7.78-1.41 1.41L12 13.41l-7.78 7.78-1.41-1.41L10.59 12 2.81 4.22l1.41-1.41L12 10.59l7.78-7.78 1.41 1.41z'/%3E%3C/svg%3E"), "#", "%23");
              content: "";
              width: 15px;
              height: 15px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .sp-tab-img-block {
      @include maxbp($grid-sm) {
        .image-with-link-block {
          width: 100% !important;
          padding: $space-ten*3 0 0;
          margin: $space-ten*3 0 0;
          border-top: 1px solid $gray-70;

          .img-link-image {
            display: inline-block;
            width: 50%;
            vertical-align: top;
          }

          .img-link-title {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 48%;
            padding: 20px;
            background: none;
            text-align: left;

            h5.h5 {
              color: $gray-10;
            }
          }
        }
      }

      &.sp-first-tab-img {
        .image-with-link-block {
          width: 49.5%;
        }
      }

      &.sp-forth-tab-img {
        .product-tile__price,
        .star-rating,
        .cta-link,
        .button.button--secondary {
          display: none;
        }
      }

      &.sp-second-tab-img,
      &.sp-third-tab-img,
      &.sp-forth-tab-img {
        .image-with-link-block,
        .sp-issue-place {
          width: 24.5%;
        }

        .sp-issue-place {
          display: inline-block;
          text-align: center;

          a {
            text-decoration: none;
            background: $gray-30;
            color: $white;
            display: block;
            font-family: $font-bold;
            text-transform: uppercase;
            margin: 0 0 $space-ten/2;
            padding: $space-ten;
          }
        }
      }
    }
  }
}

.service-tab-trigger > * {
  pointer-events: none;
}
