.blog-grid {
  @include large {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 4px;
  }
}

.blog-grid__item {
  @include maxbp($large) {
    margin-bottom: 4px;
  }
}

.blog-grid__item--large {
  @include large {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
