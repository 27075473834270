.text-list-link {
  color: $gray-10;
  text-decoration: none;
  border-bottom: 1px solid $gray-10;
  padding-bottom: $space-ten/5;
  transition: all ease 0.5s;

  &:hover {
    color: $delta-red;
    border-bottom: 1px solid $delta-red;
  }
}
