.fullwidth-banner .region-banner .views-element-container {
  max-width: none;
}

.banner-block {
  position: relative;
  background: url("/../../images/inner-banner.png");
  background-size: cover;
  // text-align: center;
  color: $gray-10;
  background-position: center;
  min-height: 300px;
  //max-width: 1600px;
  margin: 0 auto;

  &.banner-block-medium {
    height: 250px;
    min-height: none;

    @include maxbp($grid-md) {
      height: auto;
    }
  }

  &.banner-block-small {
    height: 200px;
    min-height: none;

    @include maxbp($grid-md) {
      height: auto;
    }
  }

  .grid-container {
    padding-top: 70px;
    padding-bottom: 100px;
    position: relative;

    @include medium {
      padding-top: 67px;
      padding-bottom: 60px;
    }
  }

  .page-title {
    margin: 0 0 $space-ten;

    // TODO: Temporarily hide qty inside Collection page title. Should be fixed with JS.
    span {
      display: none;
    }

    p {
      margin: 0;
      font-size: 50px;
    }
  }

  .breadcrumb-block {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    text-align: left;
  }

  .banner-desc {
    font-size: 18px;
    line-height: 1.5;
    max-width: 1156px;
    margin: 10px auto 0;
    height: 110px;

    @include maxbp($mega-menu-mobile) {
      margin-top: 32px;
      height: auto;
    }

    @include maxbp($medium) {
      width: 90%;
    }

    p {
      text-overflow: ellipsis;
      height: 100px;
      overflow: hidden;

      &:only-child {
        margin-bottom: 0;
      }

      @include maxbp($mega-menu-mobile) {
        height: auto;
      }
    }
  }
}

.title-banner.title-banner--text-centered.title-banner--content-centered {
  .banner-block {
    text-align: center;
  }
}

.banner__footer {
  position: absolute;
  bottom: 15px;
  right: 0;
  left: 0;
  text-align: right;
  font-size: 13px;
  max-width: 1440px;
  margin: auto;
  width: 97%;

  @include maxbp($medium) {
    bottom: 48px;
    text-align: center;
  }

  a {
    margin: 0 0 0 $space-ten*2;

    @include maxbp($medium) {
      margin: 0 $space-ten;
    }
  }
}

.dark-theme {
  .banner-block {
    //background: url("/../../images/inner-banner-dark.jpg") no-repeat center center;
  }

  .banner-content {
    color: $white;
  }
}

.page-node-type-home-page {
  .iqimage {
    background: url("/themes/custom/delta_theme/images/hero-wave.svg") no-repeat center center #000 !important;
  }
}

.introtext {
  h1 {
    font-family: trade-gothic-next-compressed, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #fff;
    font-size: 3em;
    text-align: center;
    line-height: 0.65em;
    z-index: 10;
    width: 100%;

    .tech {
      color: #c51638;
      font-size: 1em;
    }

    span {
      font-size: 0.35em;
    }
  }

  h2 {
    font-family: trade-gothic-next-compressed, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 2.5em;
    line-height: 1;
    text-align: center;
    padding-right: 0;
    position: absolute;
    bottom: 5vh;
    left: 50%;
    margin-left: -130px;
    color: #fff;
    text-transform: none;
  }
}

h1.dark-theme {
  color: $white;
}

h1.light-theme {
  color: $black;
}
