.commercial-innovation {
  .section-lead-in {
    margin: 0;
  }

  .layout--onecol {
    max-width: 1150px;
    margin: 0 auto;

    .banner-block.image {
      margin: 0 0 30px;
    }

    h6.innovation-heading {
      text-transform: none;
      letter-spacing: 0;
      font-family: $font-roman;
      font-size: 40px;
      font-weight: normal;
    }

    .designbeyond .qidl-wrapper {
      margin: 0 auto 3px;

      .qidl-desc h4 {
        font-size: 23px;
        margin: 0 0 10px;
      }

      .grid-col-md-4 {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}
