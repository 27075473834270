.grid-container {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.grid-container--medium {
  padding-left: 16px;
  padding-right: 16px;

  @include breakpoint($grid-md) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include breakpoint($grid-xl) {
    padding-left: 96px;
    padding-right: 96px;
  }

  .grid {
    margin-left: -20px;
    margin-right: -20px;
  }

  [class*=grid-col] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.grid-container--small {
  max-width: 960px;
}

.grid-example {
  [class*=grid-col] {
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: content-box;
    height: 50px;
    text-align: center;
    margin-bottom: 24px;
  }
}

$grid-bps: ("sm": $grid-sm, "md": $grid-md, "lg": $grid-lg, "xl": $grid-xl);

.grid-col {
  flex: 1 1 auto;
}

[class*=grid-col] {
  max-width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  padding-right: 12px;
  padding-left: 12px;
}

@for $i from 1 through 12 {
  .grid-col-#{$i} {
    flex: 0 0 100% / (12 / $i);
    max-width: 100% / (12 / $i);
  }
}

@each $prefix, $bp in $grid-bps {
  @for $i from 1 through 12 {
    .grid-col-#{$prefix}-#{$i} {
      @include breakpoint($bp) {
        flex: 0 0 100% / (12 / $i);
        max-width: 100% / (12 / $i);
      }
    }
  }
}

// Unconventional fractional columns that don't fit into 12 column model
// .grid-col-sm-8th
// .grid-col-sm-5th
// etc.
$fractions: (5, 8);

@each $prefix, $bp in $grid-bps {
  @each $fraction in $fractions {
    .grid-col-#{$prefix}-#{$fraction}th {
      @include breakpoint($bp) {
        flex: 0 0 100% / $fraction;
        max-width: 100% / $fraction;
      }
    }
  }
}
