.accordion-term {
  border-top: 1px solid $white;
  color: $gray-10;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 600;
  padding: $space-ten $space-ten*4 $space-ten $space-ten+5;
  letter-spacing: 2px;
  background: $gray-80;
  position: relative;

  &.accordion-term-left {
    padding: $space-ten $space-ten+5 $space-ten $space-ten*4;

    &::after {
      left: $space-ten+5;
      right: auto;
    }
  }

  &::after {
    content: "+";
    position: absolute;
    right: $space-ten+5;
    font-size: 20px;
    line-height: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 14px;
  }

  &:hover {
    color: $gray-10;
  }

  &.is-active {
    background: $gray-10;
    color: $white;

    &::after {
      content: "-";
      font-size: 30px;
      height: 7px;
      line-height: 3px;
    }

    & + .accordion-def,
    & + .accordion-def + .faq-list-wrapper {
      //height: auto;
      margin: $space-ten $space-ten+5;
      overflow: visible;
      max-height: 200000px;
      transition: max-height 15s ease-in;
      opacity: 1;
    }
  }

  &--light,
  &--light.is-active {
    background: $white;
    color: $gray-10;
  }
}

.accordion-left-icon {
  .accordion-term {
    padding: $space-ten $space-ten+5 $space-ten $space-ten*4;

    &::after {
      left: $space-ten+5;
      right: auto;
    }
  }
}

.accordion-def,
.faq-list-wrapper {
  //height: 0;
  //margin: 0 2em;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  opacity: 0;
}
