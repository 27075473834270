.innovation-advertising {
  .accordion-term.download-card-mobile-accordion {
    display: none !important;

    & + .accordion-def {
      max-height: inherit;
      opacity: 1;
    }
  }
}
