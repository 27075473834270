//  Empty
.fullwidth_header {
  position: fixed;
  top: 39px;
  background-color: rgba(255, 255, 255, 0.94);
  z-index: 9999;

  &.checkout-header-wrapper {
    top: 0;
    padding: $space-ten * 2 0;

    .header-wrapper {
      max-width: 1440px;
      min-height: 40px;
      padding: 0 24px;

      @include maxbp($large) {
        padding: 0 16px;
      }
    }

    .checkout-header-main {
      @include maxbp($large) {
        display: block;
      }
    }

    .checkout-header-text {
      h1 {
        font-weight: normal;
        font-size: 32px;
        letter-spacing: 0;
        margin: 0;
      }

      @include maxbp($large) {
        width: 100%;
        max-width: 50%;
        float: left;
      }
    }

    .checkout-header-logo {
      @include maxbp($large) {
        width: 100%;
        max-width: 50%;
        float: right;
        text-align: right;

        img {
          float: right;
        }
      }
    }
  }

  @include maxbp($mega-menu-mobile) {
    top: 0;
  }

  &.active-main-mobile-header {
    @include maxbp($mega-menu-mobile) {
      width: 100%;
      border-bottom: 1px solid #d7d7d9;
      position: fixed;
      height: 100vh;
      background: rgba(255, 255, 255, 1);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 19%, rgba(172, 5, 53, 1) 19%, rgba(24, 25, 29, 1) 20%, rgba(24, 25, 29, 1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(19%, rgba(255, 255, 255, 1)), color-stop(19%, rgba(172, 5, 53, 1)), color-stop(20%, rgba(24, 25, 29, 1)), color-stop(100%, rgba(24, 25, 29, 1)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 19%, rgba(172, 5, 53, 1) 19%, rgba(24, 25, 29, 1) 20%, rgba(24, 25, 29, 1) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 19%, rgba(172, 5, 53, 1) 19%, rgba(24, 25, 29, 1) 20%, rgba(24, 25, 29, 1) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 19%, rgba(172, 5, 53, 1) 19%, rgba(24, 25, 29, 1) 20%, rgba(24, 25, 29, 1) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 19%, rgba(172, 5, 53, 1) 19%, rgba(24, 25, 29, 1) 20%, rgba(24, 25, 29, 1) 100%);
      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#18191d', GradientType=0);
      overflow-y: visible;
      overflow-x: hidden;
    }

    .mobile-cart-block {
      //top: 11px !important;
    }
  }
}

.toolbar-fixed #fullwidth-header {
  position: relative;
  z-index: unset;
}

.header-wrapper {
  @include wrapper;

  position: relative;
  border-bottom: 1px solid $gray-70;
  display: block;
  min-height: 61px;

  @include maxbp($mega-menu-mobile) {
    position: relative;
    min-height: 10px;
    padding: 0;
  }

  .region-site-branding {
    float: left;

    @include maxbp($mega-menu-mobile) {
      float: none;
      text-align: center;

      a.logo__link {
        display: inline-block;
        position: relative;
        cursor: pointer;
        z-index: 10000000000;
      }
    }

    .logo {
      margin: $space-ten 0;

      @include maxbp($mega-menu-mobile) {
        float: none;
        margin: $space-ten 0 $space-ten/2;

        img {
          margin: auto;

          @include maxbp($small) {
            width: 140px;
          }

          @include maxbp(400px) {
            width: 120px;
          }
        }
      }
    }

    .block-commerce-cart {
      display: none;

      @include maxbp($mega-menu-mobile) {
        display: block;
        position: absolute;
        top: 12px;
        right: 16px;
        z-index: 100000000000;
        cursor: pointer;
      }

      .mobile-header-icons {
        display: inline-block;
        vertical-align: middle;
        margin: 5px 30px 0 0;
      }

      a.cart-icon {
        display: inline-block;
        vertical-align: middle;
      }

      .cart--cart-block {
        .figure__image {
          background: url("/themes/custom/delta_theme/images/icons/src/black-cart.png") no-repeat;
          background-size: 100%;
          width: 24px;
          height: 24px;
          top: 0;
          right: 0;
        }
      }
    }
  }

  header.header {
    max-width: none;
    margin: auto;
    padding: 0 !important;
    float: right;
    width: auto;

    @include maxbp($mega-menu-mobile) {
      float: none;
      top: 0;
      position: fixed;
      width: 100%;
      z-index: 1000000;
      bottom: 0;
      //overflow-y: scroll;
      //overflow-x: hidden;

      .block[data-block-plugin-id="global_search_block"] {
        float: none;
      }
    }

    > .block-container {
      padding: 0;

      > .contextual-region {
        position: static;
      }
    }

    #block-delta-theme-main-menu {
      position: static;
    }

    .block-container {
      @include maxbp($mega-menu-mobile) {
        padding: 0;
      }
    }

    @include maxbp($mega-menu-mobile) {
      float: none;
      top: 10px;
      position: absolute;
      width: 100%;
      z-index: 1000000;
      left: 0;
    }

    .main-nav-block {
      .search-wrapper {
        display: block;
        position: relative;
        float: right;

        .serach-input {
          height: 60px;
          margin: 0 0 0 10px;
          background-position: right center !important;
        }

        @include maxbp($mega-menu-mobile) {
          opacity: 0;
          visibility: hidden;
          //position: absolute;
          background: $gray-90;
          float: none;
          width: 100%;
          z-index: 1;
          top: 45px;
          transition: all ease 0.5s;

          .search-submit-block {
            position: absolute;
            right: 15px;
            top: 0;
            margin: 0;
            height: 100%;
            width: 50px;
            cursor: pointer;
            z-index: 1;

            .glyphicon.glyphicon-search {
              display: block;
              background-size: 30px !important;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23000000' d='M9.41 8l2.83 2.83-1.41 1.41L8 9.41l-2.83 2.83-1.41-1.41L6.59 8 3.76 5.17l1.41-1.41L8 6.59l2.83-2.83 1.41 1.41z'/%3E%3C/svg%3E") !important;
              cursor: pointer;
              height: 100%;
              width: 100%;
              background-repeat: no-repeat;
              background-position: center;
            }
          }

          .serach-input {
            width: 100%;
            border-left: none;
            margin: 0;
            background: none !important;
            padding: 0 55px 0 20px !important;
          }
        }

        &.mobile-search-active {
          opacity: 1;
          visibility: initial;
          display: block;
        }
      }

      .mobile-header-icons {
        display: none;
        position: absolute;
        top: 5px;
        right: $space-ten * 4;

        @include maxbp($mega-menu-mobile) {
          //display: block;

          .mobile-serach-icon-block,
          .mobile-cart {
            display: inline-block;
          }

          .mobile-serach-icon-block {
            margin: 0 $space-ten + 5 0 0;
            cursor: pointer;
          }
        }
      }
    }

    &.active-mobile-header {
      #block-languageswitchblock-2,
      #mobile-utility-menu,
      #block-useraccountmenu-2 {
        @include maxbp($mega-menu-mobile) {
          display: block;
        }
      }

      .mobile-header-icons {
        //top: 14px;
      }
    }

    #block-languageswitchblock-2 {
      @include maxbp($mega-menu-mobile) {
        margin: -10px 0 0;
        border-bottom: 1px solid #d7d7d9;

        .dropdown {
          a {
            display: block;
            text-align: left;
            padding: 8px 20px;
            font-size: 13px;

            &::before {
              font-family: $font-body;
              font-size: 30px;
            }
          }

          > a {
            padding: $space-ten + 5 20px;

            &::before {
              top: 0;
            }

            &:hover {
              &::before {
                top: 23px;
              }
            }
          }
        }
      }
    }

    #block-languageswitchblock-2,
    #mobile-utility-menu,
    #block-useraccountmenu-2 {
      display: none;

      .utility-nav {
        .utility-nav-wrapper {
          ul {
            padding: 0;
            margin: 0;
            background: $gray-10;

            li {
              border-bottom: 1px solid $gray-70;
              display: block;
              position: relative;

              a {
                color: $white;
                text-decoration: none;
                display: block;
                padding: $space-ten + 5 $space-ten * 2;
                font-family: $font-bold;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

// Recommerce updated Header changes
.global_header {
  .header-wrapper {
    padding: 0 24px;

    @include maxbp($large) {
      padding: 0 16px;
    }

    .toggle-expand {
      left: 16px;
    }
  }
}

.utility.block-container {
  padding: 0 16px;
}

.banner__footer {
  width: 100%;
  padding: 0 24px;
}

@include maxbp($r-1100) {
  .outlet {
    .menu-prod-desc {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .header-wrapper header.header .main-nav-block .mobile-header-icons {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .header-wrapper header.header .main-nav-block .mobile-header-icons {
    display: block !important;
  }
}
