// LI
.main-menu__item {
  border-top: 1px solid;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include breakpoint($main-menu-medium) {
    border-top: none;
    display: inline-block;

    // Only top level
    &:not(.main-menu__item--sub):hover {
      background-color: $black;
      transition: all 0.2s;

      & > .main-menu--sub {
        display: block;
      }
    }
  }

  &:hover {
    .main-menu__link::after {
      color: $gray-10;
    }
  }
}

// Expand Button
.expand-sub {
  background-color: $black;
  cursor: pointer;
  display: block;
  width: 20%;
  color: $white;
  position: relative;

  @include breakpoint($main-menu-medium) {
    display: none;
  }

  // Down triangle
  &::after {
    border: 15px solid;
    border-bottom-color: transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: "";
    display: block;
    margin-right: -12px;
    margin-top: -6px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 0;
  }

  // See main-menu.js
  &--open {
    background-color: $black;
    color: $gray-90;

    &::after {
      border-top-color: transparent;
      border-bottom: 15px solid;
      margin-top: -20px;
    }
  }
}

//
// Sub Navigation
//
// LI (Nested)
.main-menu__item--sub {
  @include breakpoint($main-menu-medium) {
    display: block;

    &:not(:first-child) {
      border-top: 1px solid $gray-50;
    }
  }
}
