.block-container {
  @include wrapper;
}

.toolbar-fixed {
  #utility-wrapper {
    position: relative;
    z-index: unset;
  }
}

#utility-wrapper {
  background-color: $gray-10;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  @include maxbp($mega-menu-mobile) {
    display: none;
  }

  .utility {
    text-align: right;

    > div,
    > nav {
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      margin: 0 0 0 $space-ten/2;
      vertical-align: middle;
    }

    // START LANGUAGE DROPDOWN
    .dropdown {
      position: static;

      .active-lang-link {
        padding: $space-ten $space-ten*3 $space-ten $space-ten;
        text-decoration: none;
        font-family: $font-bold;
        color: $white;
        letter-spacing: 2px;
        display: inline-block;

        &::after {
          right: 18px;
        }
      }

      a {
        position: relative;
      }

      .dropdown-content {
        width: auto;
        padding: 0 $space-ten*4;
        top: 100%;

        ul {
          padding: $space-ten 0;
        }

        li {
          margin: 0 0 $space-ten+3;
          list-style: none;

          a.lng-item {
            font-size: 18px;
            text-transform: none;
            position: relative;
            padding: 0 0 2px;
            transition: all ease 0.7s;
            line-height: 27px;

            &::after {
              content: "";
              display: block;
              height: 1px;
              position: absolute;
              width: 100%;
              bottom: 0;
              background: $delta-red;
              opacity: 0;
              transition: all ease 1s;
            }

            &:hover {
              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    // END LANGUAGE DROPDOWN

    nav.utility-nav {
      ul.utility-menu-nav {
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        margin: 0;
        margin: 0 $space-ten/2 0 0;

        &:last-child {
          margin: 0;
        }

        a {
          color: white !important;
          text-decoration: none;
          display: block;
          padding: 8px $space-ten+5;
          transition: all ease 0.5s;
          background: transparent;
          font-family: $font-bold;
          letter-spacing: 2px;

          &:hover {
            background: $delta-red;
          }
        }
      }
    }

    #block-useraccountmenu {
      nav.utility-nav {
        li {
          vertical-align: middle;

          &:last-child {
            display: none;
          }

          &:first-child {
            display: block;

            a.utility-menu-nav__link {
              height: 36px;
              width: 36px;
              font-size: 0;
              padding: 0;
              position: relative;

              &::after {
                background: url("/themes/custom/delta_theme/images/icons/src/white_account.svg") no-repeat center center;
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-size: 20px;
                line-height: 20px;
                content: "";
              }
            }
          }
        }
      }
    }

    #block-cart {
      transition: all ease 0.5s;
      background: transparent;
      padding: 7px;

      &:hover {
        background: $delta-red;
      }

      div.cart--cart-block {
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      figure.cart--cart-block {
        img {
          width: 20px;
        }
      }
    }
  }
}

#block-useraccountmenu-2 {
  nav.utility-nav {
    li {
      vertical-align: middle;

      &:last-child {
        display: none;
      }

      &:first-child {
        display: block;

        a {
          font-size: 0;
          padding: 0;
          position: relative;
          height: 36px;
          width: 36px;

          @include maxbp($mega-menu-mobile) {
            height: auto;
            width: auto;
            padding: $space-ten+5 $space-ten*2 $space-ten+5 $space-ten*5 !important;
          }

          &::before {
            background: url("/themes/custom/delta_theme/images/icons/src/white_account.svg") no-repeat center center;
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-size: 20px;
            line-height: 20px;
            content: "";

            @include maxbp($mega-menu-mobile) {
              background-position: left center;
              left: $space-ten*2;
            }
          }
        }
      }
    }
  }
}

.user-logged-in {
  .utility-nav {
    .dropdown-content {
      background: $delta-red;
      color: $gray-10;
    }
  }

  #utility-wrapper .utility .dropdown .dropdown-content {
    padding: 0 19px;
  }
}

.utility-nav .utility-nav-wrapper .dropdown {
  display: inline;
}

@include maxbp($mega-menu-mobile) {
  #block-useraccountmenu-2 {
    .utility-nav {
      position: relative;

      .dropdown-content {
        width: 84px;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        min-height: 100%;

        .utility-menu-nav {
          background-color: $delta-red !important;

          .utility-menu-nav__item {
            margin: 0;

            .logout-lnk {
              padding-left: 20px !important;

              &::before {
                display: none;
              }
            }
          }
        }
      }

      .expand-sub {
        display: none;
      }
    }
  }
}

.global_header {
  #utility-wrapper {
    z-index: 302;
  }

  .fullwidth_header {
    z-index: 301;
  }
}
