.filter-chip {
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;
  line-height: 14px;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  border-radius: 50px;
  background-color: $gray-80;
  border: 0;
  transition: color 0.1s ease-out;
  -webkit-font-smoothing: subpixel-antialiased;

  .icon {
    margin-left: 4px;
  }

  &:hover {
    background-color: $delta-red;
    color: $white;
  }
}
