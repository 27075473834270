.footer_secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  flex-direction: column-reverse;
  background-color: $white;

  @include large {
    flex-direction: row;
    text-align: center;
  }

  &__nav {
    list-style: none;
    display: flex;
    padding: 0;
    flex-flow: column;
    margin: 0;

    @include maxbp($large) {
      text-align: center;
      margin-top: 18px;

      nav {
        display: block;
      }
    }

    @include xs {
      align-items: center;
    }

    @include large {
      flex-flow: row;

      nav {
        display: flex;
      }
    }

    li {
      @include large {
        border-left: 1px solid $gray-60;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
      }

      @include maxbp($large) {
        margin: 0 0 $space-ten;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: $gray-50;
        font-size: 0.79rem;
        padding: 0 8px;
        letter-spacing: 1.5px;
        display: inline-block;
        line-height: normal;
        font-family: $font-bold;

        &:hover {
          color: $delta-red;
        }
      }
    }
  }

  .copyright {
    margin-right: 24px;
  }

  .copyright-text {
    font-size: 14px;
  }

  .copy-rights {
    padding: 5px 8px 10px 15px;
  }

  @include large {
    .copy-rights {
      padding: 0 5px 0 15px;
    }
  }
}
