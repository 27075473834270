.inline-menu {
  @include list-reset;
}

.inline-menu__item {
  margin: 0 1em 0.5em 0;

  @include large {
    display: inline;
    margin-bottom: 1em;
  }

  &:last-child {
    margin-right: 0;
  }
}

.inline-menu__link {
  @include link;

  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
}
