.checkout_billing {
  h5,
  #edit-additionalinfodes {
    font-size: 1.269rem;
    font-family: $font-bold2;
    text-transform: capitalize;
    letter-spacing: 0;
    margin-top: 40px;
  }

  #edit-additionalinfodes {
    margin-top: 10px;
  }

  .custom-input--checkbox {
    cursor: pointer;
  }

  .custom-input__label {
    font-size: 1rem;
    top: 0;
    left: 0;
    cursor: pointer;

    &::before,
    &::after {
      top: 12px;
    }
  }

  .form_actions {
    margin-top: 50px;
  }

  @include maxbp($medium) {
    .mobile_padding {
      h3 {
        font-size: 1.6rem;
      }
    }

    .form_actions {
      .button {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .circle-icon {
    background-color: $gray-10;
    vertical-align: middle;
  }

  .ecommerce-steps li {
    font-family: $font-bold2;
    color: $gray-10;
    border-bottom: 3px solid $black;
    list-style: none;
  }
}

.return_cart_btn {
  display: none;
}

.shipping-infor-address {
  margin-top: 50px;

  h3 {
    font-size: 1.269rem;
    font-family: $font-bold2;
    margin-bottom: 20px;
  }

  .shipping-info {
    margin-bottom: 16px;

    h6 {
      font-size: 1rem;
      font-family: $font-bold2;
      text-transform: capitalize;
      letter-spacing: 0;
    }

    p {
      margin: 0;
      font-size: 1rem;
    }
  }

  .cta-link {
    float: right;
    margin-top: 4px;
  }
}

.payment_card {
  width: 100%;
  height: 450px;
  overflow: auto;
  font-family: $font-light;

  html {
    width: 100% !important;
  }
}
