.faq-grid {
  @include medium {
    display: grid;
    grid-gap: -1px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  @include large {
    display: flex;
    flex-wrap: wrap;
  }

  .faq-card__img {
    display: none;
  }
}

.faq-card {
  position: relative;
  padding: 36px 24px 16px;
  font-size: $font-size-lg;

  .faq-card__question ul li {
    list-style: none;
  }

  @include maxbp($medium) {
    display: flex;
    justify-content: space-between;

    & + & {
      border-top: 1px solid $gray-60;
    }
  }

  @include medium {
    border: 1px solid $gray-60;
    padding-bottom: 100px;
  }

  @include large {
    font-size: $font-size-xl;
    width: 33%;
  }
}

.faq-card__index {
  font-size: 2.88625rem;
  font-family: $font-light;
  font-weight: 300;
  color: rgba($gray-10, 0.2);
  line-height: 1.35;

  @include maxbp($medium) {
    .faq-grid & {
      display: none;
    }
  }
}

.faq-card__answer.hidden {
  display: none;
}

.faq-card__button {
  width: 40px;
  height: 40px;
  padding: 0;
  background: $delta-red;
  border-radius: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  @include maxbp($medium) {
    margin-left: 12px;
    transform: scale(0.6);
    transform-origin: 100% 0;
  }

  @include medium {
    position: absolute;
    bottom: 16px;
    left: 24px;
  }
}

.modal--faq {
  .modal__close {
    display: none;
  }

  @include medium {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }

  .modal__body {
    margin-bottom: 0;

    @include medium {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .modal__body h6 {
    text-align: left;
  }

  .modal--faq__figure {
    padding-left: 24px;
    width: 35%;
    max-width: 35%;
    flex-shrink: 0;

    @include maxbp($medium) {
      display: none;
    }

    @include large {
      min-height: 356px;
    }
  }
}
