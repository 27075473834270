html.has-open-dialog {
  height: 100vh;

  body {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  display: none;
}

.modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  background-color: rgba($gray-10, 0.8);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  .modal-container[aria-hidden="true"] & {
    opacity: 0;
  }
}

.modal {
  position: relative;
  z-index: 1000;
  max-width: 896px;
  margin: 120px 24px 0;
  padding: 32px 24px;
  background: $white;
  opacity: 1;
  border-radius: 2px;
  box-shadow: 0 10px 32px 5px rgba(24, 25, 29, 0.1);
  transform: translate(0, 0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  .modal-container[aria-hidden="true"] & {
    transform: translate(0, -25%);
    opacity: 0;
  }

  @include medium {
    margin: 100px auto 0;
    padding: 52px 64px 64px;
  }
}

.modal--centered {
  text-align: center;
}

.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  bottom: 0;
  border: 0;
  background-color: transparent;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  svg {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
}

.modal .h3 {
  margin-bottom: 16px;
  font-size: 1.801875rem;

  @include medium {
    font-size: 2.280625rem;
    margin-bottom: 32px;
  }
}

.modal__body {
  overflow: hidden;
  margin-bottom: 24px;

  @include medium {
    margin-bottom: 32px;
  }

  p {
    margin-top: 0; // TODO: Remove when/if DFDELTA-89 is resolved
  }

  iframe {
    width: 100%;
  }
}

//
// Login Variant
//

.modal--login {
  max-width: 576px;

  .form-item {
    margin-bottom: 16px;
  }

  .button[type="submit"] {
    margin-bottom: 42px;
  }

  .modal__body {
    margin-bottom: 0;
  }
}

.modal--login__subtitle {
  font-family: $font-bold2;
  font-size: $font-size-xxxl;
  text-transform: capitalize;
  letter-spacing: normal;
  margin-top: 40px;
  margin-bottom: 24px;
}

.modal--login__options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal--video {
  padding: 0;

  .modal__body {
    position: relative;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25%;
  }

  .modal__close {
    top: -14px;
    right: -30px;
    color: white;
    z-index: 1003;
    outline: none;
  }

  @media (max-width: $medium) {
    .modal__close {
      right: -22px;
    }
  }

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
  }
}

.spb-popup-main-wrapper {
  border: none;
  top: 50% !important;
  transform: translateY(-50%);
}

@media (max-width: $large) {
  .spb-popup-main-wrapper {
    left: 5%;
    right: 5%;
  }
}

@media (min-width: $large) {
  .faucet-modal-popup {
    .modal-content-container {
      display: flex;

      > div {
        width: 46%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: $large) {
  .popup-cta.paragraph-component {
    img {
      display: none;
    }
  }
}

.faucet-modal-popup {
  padding: 30px;

  .Modal-popup-head {
    font-size: 32px;
    text-align: center;
  }

  .modal-content-container {
    padding-top: 20px;

    .button {
      margin-top: 20px;
      display: block;
      text-align: center;
    }
  }

  .popup-cta.paragraph-component {
    img {
      width: 100%;
    }
  }
}
