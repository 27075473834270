.text-button-box {
  background: $gray-30;
  color: $white;
  display: block;
  overflow: hidden;
  padding: $space-ten*2;
  margin: $space-ten*2;

  .tbb-text,
  .tbb-button {
    display: inline-block;
    vertical-align: top;

    @include maxbp($medium) {
      display: block;
    }
  }

  .tbb-text {
    width: calc(100% - 200px);

    @include maxbp($medium) {
      width: 100%;
    }
  }

  .tbb-button {
    width: 180px;
    text-align: right;

    @include maxbp($medium) {
      width: auto;
      text-align: center;
    }

    a {
      padding: $space-ten $space-ten+15;
      width: 165px;
      display: inline-block;

      @include maxbp($medium) {
        width: auto;
      }
    }
  }
}
