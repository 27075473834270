.service-parts-support-page.find-part-page {
  > div {
    margin: 0;
    border-bottom: none;
    padding: 0;
  }

  .fp-search {
    margin: 0 0 $space-ten*4;

    .fp-search-title {
      margin: 0 0 $space-ten+5;

      p {
        margin: 0;
        font-size: 24px;
      }
    }

    .fp-search-desc {
      border-top: 1px solid #d7d7d9;
      max-width: 400px;
      width: 100%;
      padding: $space-ten*2 0 0;

      input {
        width: 100%;
        font-size: 16px;
        //padding: 7px 15px;
        border-radius: 4px;
        border: 1px solid #d7d7d9;
      }
    }
  }

  .fp-experience-text {
    margin: 0 0 $space-ten*4;
  }

  .fp-experience-text,
  .fp-prod-part-text {
    p {
      font-size: 20px;
      margin: 0;
      line-height: 25px;
    }
  }
}
