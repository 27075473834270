.order_number {
  color: $gray-10;
  font-size: 1.125rem;
  letter-spacing: 2px;
  font-family: $font-bold;
  line-height: 18px;
  margin: 0 0 $space-ten+5;

  @include maxbp($medium) {
    font-size: 13px;
  }
}
