.innovation-secondary-banner {
  background: none;
  position: relative;
  background-color: $black;
  min-height: 560px;
}

.isb__image {
  @include maxbp($large) {
    display: block;

    img {
      width: 100%;
    }
  }
}

.isb__content {
  color: $white;
  font-size: $font-size-lg;
  padding: 40px 24px;

  @include large {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 24px;
  }

  hr {
    border: 0;
    width: 100%;
    height: 1px;
    background: $gray-30;
    margin: 0 0 32px;
  }

  h3 {
    color: inherit;

    &::after {
      display: block;
      width: 64px;
      height: 3px;
      margin-top: 16px;
      background-color: $delta-red;
      content: '';
    }
  }
}

.has-section-margin {
  margin-bottom: 40px;

  @include large {
    margin-bottom: 80px;
  }
}

.node--type-innovation-detail-page,
.innovation-in2ition {
  .innovation-secondary-banner {
    display: block;
    overflow: hidden;
    padding: 0 20px;
    background-repeat: no-repeat;
    background-color: #292929;
    background-position: center left;
    background-size: cover;
  }

  .isb__content {
    position: static;
    width: 50%;
    float: right;
    padding: 20px 0 !important;

    a {
      color: $white;
    }
  }

  .isb__image {
    display: none;
  }
}

@include large {
  .node--type-innovation-detail-page {
    .video-card {
      align-items: start;

      .video-card__title {
        width: 44%;
        display: inline-block;

        .video-card__thumbnail {
          width: 100%;
          display: block;
          max-width: 100%;
          font-size: 1rem;
        }
      }
    }
  }
}
