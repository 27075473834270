.page-hydrorain-two-in-one-handshower {
  .layout__region--second {
    .pdp-fealist {
      li {
        text-align: left;

        &::after {
          display: none;
        }

        a.inline-text-link {
          img {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }

    h2 {
      text-transform: capitalize;
      text-align: left;
    }
  }

  #Products {
    img {
      display: inline-block;
    }
  }
}
