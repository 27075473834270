.animation-link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: $gray-10;
  padding: 0 0 $space-ten/2;

  &::after {
    background: $delta-red;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 0;
    content: "";
    display: block;
    transition: all ease 0.5s;
  }

  &:hover {
    color: $delta-red;

    &::after {
      width: 100%;
    }
  }
}
