.video-on-tab-wrapper {
  width: 50%;
  text-align: center;

  .vot-tabs-wrapper {
    margin: $space-ten*2 0 0;

    .vot-tab-block {
      display: inline-block;
      background: $gray-30;
      position: relative;

      &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $delta-red transparent;
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all ease 0.5s;
        opacity: 0;
      }

      &.active-vot-tab-block {
        background: $delta-red;

        &::after {
          top: -10px;
          opacity: 1;
        }
      }

      a {
        color: $white;
        text-decoration: none;
        display: block;
        padding: $space-ten/2 $space-ten*6;
      }
    }
  }

  .vot-video-wrapper {
    .vot-video-block {
      display: none;

      &.active-vot-video-block {
        display: block;
      }
    }
  }

  .vot-tab-desc-wrapper {
    .vot-tab-desc {
      display: none;
      margin: 5px 0 0;
      font-family: $font-bold;

      &.active-vot-tab-desc {
        display: block;
      }
    }
  }
}
