// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

.summary-card-carousel {
  margin-bottom: 70px;

  @include medium {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include large {
    padding-left: 86px;
    padding-right: 86px;
  }

  .slick-arrow {
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    filter: grayscale(100%) brightness(0);
    transition: filter 0.15s ease-in-out;
    margin: 0;

    &:hover {
      filter: grayscale(0) brightness(1);
    }

    @include maxbp($medium) {
      display: none;
    }
  }

  .slick-arrow.slick-prev {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M17.2660028,24L18.6315789 22.5310873 8.99548257 12 18.6315789 1.6938288 17.2660028 0 6 12z'/%3E%3C/svg%3E"), "#", "%23");
    left: 8px;
    transform-origin: 50% 50%;

    @include large {
      left: 16px;
    }
  }

  .slick-arrow.slick-next {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M7.36557613,0L6 1.46891274 15.6360964 12 6 22.3061712 7.36557613 24 18.6315789 12z'/%3E%3C/svg%3E"), "#", "%23");
    right: 8px;

    @include large {
      right: 16px;
    }
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    width: 24px;
    height: 24px;

    @include large {
      width: 56px;
      height: 56px;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    position: absolute;
    top: calc(100% + 40px);
    left: 0;
    right: 0;

    button {
      display: block;
      width: 16px;
      height: 16px;
      font-size: 0;
      line-height: 0;
      border-radius: 50%;
      border: 1px solid $gray-60;
      margin-left: 5px;
      margin-right: 5px;
    }

    li.slick-active button {
      background-color: $delta-red;
    }
  }
}
