.image-desc-wrapper {
  .id-image {
    margin: 0 0 $space-ten+15;
  }

  .id-title {
    h4.h4 {
      font-size: 22px;
      letter-spacing: 1px;
      font-weight: normal;
    }
  }

  .id-prod-title {
    h5.h5 {
      font-size: 30px;
      letter-spacing: normal;
      font-weight: normal;
      text-transform: none;
      font-family: $font-body;
      margin: 0 0 $space-ten/2;
    }
  }

  .id-prod-more {
    a {
      text-transform: capitalize;
      font-family: $font-body;
      letter-spacing: 1px;
      font-size: 15px;
    }
  }
}
