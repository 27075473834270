/// Link
$color-link: $gray-10;
$color-link--hover: $gray-50;

@mixin link {
  color: $color-link;

  &:hover {
    color: $color-link--hover;
  }
}

.link {
  @include link;
}
