.cart {
  &__empty-page {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;

    h1 {
      font-size: 2.888rem;
      margin-top: 8px;

      @include maxbp($medium) {
        font-size: 35px;
        margin-top: 20px;
        text-align: center;
      }
    }

    .button {
      margin-top: 3.5em;

      @include maxbp($medium) {
        margin-top: 20px;
        padding: 15px 30px;
      }
    }
  }

  .ecommerce-cart {
    .icon--menu-close {
      stroke-width: 3px;
      stroke: $gray-10;
    }
  }
}

#cart-items {
  border-bottom: 1px solid $gray-70;
  width: 100%;

  th {
    font-family: $font-bold2;
    font-size: 1.125rem;
    line-height: 1.5;
    color: $gray-10;
    text-transform: capitalize;
  }

  thead {
    text-align: left;
  }

  tbody td {
    border-top: 1px solid $gray-70;
    padding: 16px 0;

    .img {
      display: inline-table;
      vertical-align: top;

      .img-responsive {
        max-width: 96px;
      }
    }

    .title {
      strong {
        font-weight: normal;
        font-family: $font-light;
        color: $gray-10;
        font-size: 1.269rem;
        line-height: 1.5;
      }
    }

    .sku {
      font-size: 0.788rem;
      color: $gray-50;
      letter-spacing: 2px;
      font-weight: bold;
    }

    .cart-detail {
      margin-left: 15px;
      display: inline-table;
      vertical-align: middle;
      width: 76%;
    }

    .qty-update {
      .update-order-item {
        position: absolute;
        left: 115px;
        top: -7px;
        padding: 0.75rem 0;
        color: $delta-red;
        font-family: $font-bold;
        font-size: 1.125rem;

        &:hover,
        &:focus {
          outline: 0 none;
        }

        + .ajax-progress {
          position: absolute;
        }

        &[disabled] {
          background-color: transparent !important;
          color: $delta-red !important;
        }
      }

      .js-form-item {
        margin: 0;
      }
    }

    .remove-qty {
      display: none;
    }
  }

  tbody td,
  thead th {
    &:first-child {
      width: 50%;

      @include maxbp($medium) {
        align-items: flex-start !important;
      }
    }

    &:last-child {
      text-align: right;
      padding-right: 0;
      position: relative;

      .icon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    }
  }

  thead th:last-child {
    padding-right: 35px;
  }

  .quantity-err {
    position: absolute;
    top: 40px;
    left: -28px;
    color: #ff4949;
    font-size: 14.22px;
    letter-spacing: -0.2px;
    line-height: 21.33px;

    @include maxbp($medium) {
      top: 0;
      left: 84px;
      width: 185px;
    }

    @include maxbp($r-1100) {
      top: 0;
      left: 84px;
      width: 185px;
    }
  }
}

.ajax-progress + .ajax-progress {
  display: none;
}

.numeric-stepper {
  position: relative;
}

.estimation {
  display: inline-block;
  float: left;
  width: 60%;

  p {
    font-size: 1.125rem;
    line-height: 27px;
    margin-bottom: 5px;
    margin-top: 23px;

    @include maxbp($medium) {
      margin-top: 14px;
      margin-bottom: 10px;
      line-height: 24px;
    }
  }

  .cta-link {
    background-image: url("../images/icons/src/red-cta-carrot.svg");
    background-repeat: no-repeat;
    background-position: 100% 2px;
    padding-right: 28px;
  }

  div.cta-link {
    margin-top: 18px;
    margin-bottom: 6px;
    background-image: none;

    @include maxbp($medium) {
      width: 100%;
    }
  }

  & + .cart_action_btn {
    display: none;
  }

  @include maxbp($mega-menu-mobile) {
    & + .cart_action_btn {
      display: inline-block;
      margin-top: 40px;
      width: 100%;

      .button {
        width: 100%;
        justify-content: center;

        &.button--secondary {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.ecommerce-cart {
  header {
    text-align: right;
    padding-bottom: 35px;
  }
}

.path-cart {
  #edit-actions {
    display: none;
  }

  .grid + div {
    display: inline-block;
    float: right;
    margin-top: 16px;
    width: 25%;
  }

  .main {
    display: table;
    padding-bottom: 65px;

    @include maxbp($medium) {
      padding-top: 23px;
    }
  }

  .flag {
    transform: translate(0, 0);
    margin-bottom: 10px;
    width: 131px;
    min-width: 131px;
    height: 28px;

    @include maxbp($medium) {
      display: block;
    }
  }

  @include maxbp($medium) {
    .slider_flag {
      display: block;
    }
  }

  .list-price {
    text-decoration: line-through;
    margin-right: 16px;
    color: #b3b3b3;

    @include maxbp($medium) {
      margin-right: 0;
      display: block;
    }
  }

  .views-element-container {
    padding: 0;
  }
}

.order-total-line {
  padding: 0;
  text-align: inherit;

  &-label {
    text-align: left;
  }

  &__total {
    border-top: 1px solid $gray-70;
    margin: 10px 0;
    padding-top: 10px;
    clear: both;
    display: inline-block;
    width: 100%;
  }

  &-value {
    float: right;
  }

  svg.icon--info {
    line-height: 2;
    position: relative;
    top: 3px;
  }

  .border-dark.text-right .icon--info {
    color: $gray-50;
  }
}

.path-cart .h1 {
  position: absolute;
}

@include maxbp($r-1100) {
  .path-cart .grid + div {
    width: 100%;
  }

  .estimation {
    width: 100%;
    display: block;

    p {
      font-size: 1rem;
    }
  }

  .ecommerce-cart header {
    .button {
      width: 100%;
      justify-content: center;

      &--primary {
        margin-top: 10px;
      }
    }
  }

  .path-cart .h1 {
    text-align: center;
    margin-top: 40px;
    position: relative;
    font-size: 2.281rem;
  }

  .path-cart .main {
    padding-left: 16px;
    padding-right: 16px;
  }

  #cart-items {
    tbody td {
      .title strong {
        font-size: 1rem;
      }

      .img .img-responsive {
        max-width: 50px;
      }

      &:first-child {
        flex-direction: row;
        display: flex;
        border-bottom: 0;
        align-items: center;
        padding-bottom: 0;
        width: auto;
      }

      &:last-child {
        .icon {
          top: 22px;
        }
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: row;
        border-top: none;
        padding-top: 0;
        padding-left: 70px;
        padding-bottom: 40px;
      }

      .qty-update .update-order-item {
        left: 0;
        top: 28px;
        font-size: 1rem;
      }

      .cart-detail {
        margin-bottom: 17px;
      }
    }

    thead th {
      &:first-child {
        flex-direction: row;
        display: flex;
        border-bottom: 0;
        align-items: center;
        padding-bottom: 0;
      }
    }

    th {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  .ecommerce-cart {
    .cart-head {
      float: none;
      text-align: center;

      h1 {
        @include maxbp($medium) {
          margin-bottom: 17px;
        }
      }
    }
  }
}

.qty_btn {
  border: 1px solid $gray-60;
  border-radius: 2px;
  display: inline-flex;

  .form-number {
    width: 48px;
    height: 32px;
    text-align: center;
  }

  label {
    display: none;
  }

  .btn-increment-decrement {
    display: inline-block;
    padding: 5px 0;
    background: $gray-70;
    width: 24px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    font-family: $font-bold2;
    line-height: 1.3;
  }
}

.cart-icon,
.cart-icon:visited {
  text-decoration: none;
}

.cart-head {
  float: left;
}
