// Toggle
.toggle-expand {
  @include link;

  @include breakpoint($main-menu-medium) {
    display: none;
  }

  display: inline-block;
  padding: $space;
  text-decoration: none;
  width: 100%;

  // See main-menu.js
  &--open {
    background-color: $gray-10;
  }
}

.toggle-expand__text {
  display: block;
  font-size: 0.9rem;
  font-weight: bold;

  .toggle-expand--open & {
    color: $white;
  }
}

// SVG icon
.toggle-expand__icon {
  height: 3rem;
  width: 3rem;
  margin: 0 auto;
}

.toggle-expand__open {
  display: inline-block;
  text-align: center;

  .toggle-expand--open & {
    display: none;
  }
}

.toggle-expand__close {
  display: none;
  text-align: center;

  &::before {
    color: $white;
    content: "X";
    display: block;
    font-size: 2.5rem;
    line-height: 2;
  }

  .toggle-expand--open & {
    display: inline-block;
  }
}
