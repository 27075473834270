.medium-grid-struture {
  @include wrapper;

  .grid {
    > div {
      background: $gray-70;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin: 0 0 $space-ten*2;
      background-clip: content-box;
    }
  }
}

.medium-grid-struture-text {
  @include wrapper;

  .grid {
    > div {
      margin: 0 0 $space-ten*3;
      border-bottom: 1px solid $gray-70;
      padding-bottom: $space-ten*3;
    }
  }
}
