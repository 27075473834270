.page-node-type-blog {
  div#block-banner-region-block {
    background: #f3f3f3;
    min-height: 200px;
    padding: 50px;
  }
}

.node--type-press-release .main-content--with-sidebar-left img {
  float: right;
}

.node--type-delta-video .field_delta_video {
  margin-bottom: 20px;
}

.node--type-home-page {
  .paragraph-component {
    margin-bottom: 10px;
  }

  .hotspot-banner {
    margin-bottom: 10px;
  }

  .homepage_video.video_wrapper {
    margin-bottom: 10px;
  }

  .last-component {
    margin-bottom: -10px;
  }
}

body.for-professional.landing_page.path-node.node--type-landing-page a.cta-link.has-icon--right {
  padding-bottom: 20px;
}

.home-page footer.footer,
.design-innovation footer.footer,
.node--type-innovation-detail-page footer.footer {
  margin-top: 22px;
}
