
.srp-header {
  text-align: center;
  padding: 24px 16px 40px;
  font-size: $font-size-lg;

  @include large {
    padding: 40px 24px;
    font-size: $font-size-xl;
  }
}

.srp-title {
  font-size: $font-size-xxxl;
  margin-bottom: 0.8em;

  @include large {
    font-size: 2.280625rem;
  }
}

.srp-header__description {
  margin-bottom: 1.4em;
}

.srp-form {
  position: relative;
  max-width: 416px;
  margin-left: auto;
  margin-right: auto;
}

.srp-form__field {
  box-shadow: 0 0 0 2px $gray-10 inset;
  padding: 1rem;
}

.srp-form__submit {
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  transform: translate(0, -50%);
  background: none;
  appearance: none;
  cursor: pointer;
}

.srp-body {
  background-image: linear-gradient(to bottom, $gray-90 0%, $white 200px);
}

.srp-body__header {
  padding: 32px 0;
  text-align: center;
}

.srp-result-summary {
  font-size: 1.602rem;
}

.srp-qty {
  font-weight: 700;
  font-family: $font-bold2;
}

.srp-search-term-highlight {
  font-family: $font-bold2;
  font-weight: 700;
  font-style: italic;
}

.srp-results__header {
  padding: 24px 0 40px;
  text-align: center;

  @include large {
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 32px 0 40px;
  }

  @include maxbp($large) {
    .pager {
      margin-top: 20px;
      display: inline-block;
    }
  }
}

.srp-result-dropdowns {
  .form-item__select.facetFinish {
    margin: 0 0 $space-ten*2;
  }

  @include maxbp($large) {
    max-width: 500px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;

    .form-item + .form-item {
      margin-top: 12px;
    }
  }

  .form-item {
    margin-bottom: 0;
  }

  @include large {
    display: flex;

    .form-item:first-child {
      width: 225px;
    }

    .form-item:nth-child(2) {
      width: 185px;
      margin-left: 8px;
    }
  }
}

.srp-cta {
  text-align: center;
  padding: 32px 0 40px;
  border-top: 1px solid $gray-70;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto;
  font-size: $font-size-lg;

  @include large {
    padding: 40px 0 56px;
    font-size: $font-size-xl;
  }

  @include maxbp($small) {
    .button {
      font-size: $font-size-sm;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}

.srp-cta__heading {
  font-size: 1.425rem;
  @include large {
    font-size: 1.601875rem;
  }
}

.srp-results-list {
  margin: -32px 0 0;
  padding: 0;
}

.srp-results-list__item {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid $gray-70;

  @include large {
    padding: 28px 0;
  }

  .icon {
    margin-right: 24px;
    flex-shrink: 0;
    color: $delta-red;
  }
}

.srp-resuls-list__link {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: $font-size-xxl;
  margin-bottom: 8px;

  @include large {
    font-size: $font-size-xxxl;
  }

  &:hover {
    color: $delta-red;
  }
}
//Ticket DFCA-789
#facetFinish{
  @include breakpoint(920px) {
    width: 200px;
  }
}
#sortProduct {
  @include breakpoint(920px) {
    width: 100px;
  }
}