.for-professionals-commercial-hospitality,
.for-professionals-commercial-multi-family {
  .section-lead-in,
  .designbeyond {
    margin-bottom: 40px;
  }

  .section-lead-in {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    .section-lead-in {
      padding: 20px;
    }

    .qidl-wrapper > div {
      margin-bottom: 50px;
    }
  }

  .qidl-desc {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.section-lead-in.left {
  p {
    text-align: left;
  }

  ul {
    text-align: left;
  }
}

.section-lead-in.center {
  p {
    text-align: center;
  }

  ul {
    text-align: center;
  }
}
