.company-media-page {
  .pdp-list-ttl-wrapper.border-top-bottom {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 20px 0 3px;
    margin: 0 0 25px;
  }

  .pdp-list-ttl-wrapper .pdp-list-title h3 {
    font-weight: normal;
    font-size: 27px;
    margin: 0 0 15px;
  }

  @include maxbp(1000px) {
    .pdp-coll-link {
      margin: 0 0 30px;
    }
  }
}
