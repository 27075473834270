.grid-container--wtb {
  @include maxbp($grid-lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

.wtb-logo-grid {
  display: flex;
  flex-wrap: wrap;
}

.wtb-sidebar-subtitle {
  margin-top: 30px;
  font-family: $font-bold2;
  font-weight: 700;
  font-size: $font-size-xl;
  text-transform: none;
  letter-spacing: -0.2px;
}

.button--wtb-sidebar-cta {
  @include breakpoint($grid-lg) {
    min-width: 320px;
    justify-content: center;
  }
}

.wtb-sidebar {
  .divider {
    border-style: solid;
    border-width: 1px 0 0;
    color: currentColor;
    margin-bottom: 24px;
  }
}

.wtb-sidebar__logo {
  display: block;
  width: 260px;
  margin-bottom: 30px;
}

.wtb-logo-grid + .divider {
  border-style: solid;
  border-width: 1px 0 0;
  color: currentColor;
  margin-bottom: 24px;
}

.wtb-section {
  padding-bottom: 30px;

  & + & {
    padding-top: 60px;
    border-top: 1px solid $gray-70;
  }
}

.wtb-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.wtb-list__item {
  display: block;

  & + & {
    margin-top: -1px;
  }
}

.wtb-list__link {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: $gray-10;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  border-color: $gray-70 transparent $gray-70 transparent;

  &:hover {
    box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);
    border-color: $gray-80;
  }

  @include breakpoint($grid-lg) {
    padding: 14px 16px;
  }
}
// Section Title
.field_online_retailers_title {
  text-transform: capitalize;
}
//Side content Image in Online Shopping
.wtb-sidebar.textbox_container {
  .wtb-logo-grid__item {
    width: 62%;
    padding: 0;

    &:hover {
      box-shadow: none;
    }
  }
}
