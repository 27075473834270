.form-item--checkboxes,
.form-item--checkbox__item {
  @include list-reset;
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

.custom-input {
  position: relative;
  display: block;
  margin-bottom: 4px;

  .tooltip-button {
    margin-left: 24px;
  }
}

.custom-input__native {
  position: absolute;
  z-index: -1;
  left: 0;
  opacity: 0;
}

.custom-input__label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  user-select: none;
}

.custom-input__native:disabled ~ .custom-input__label {
  color: $gray-50;
}

.custom-input--checkbox {
  .custom-input__label {
    padding-left: 30px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 2px;
      content: '';
    }

    &::before {
      //background: $gray-10;
      border: 1px solid $gray-60;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      opacity: 0;
      transform: translate(0, -50%) scale(0.3);
      transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
      background-size: 16px 16px;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#fff' d='M8.93 18.65L4 13.72l1.77-1.76 3.07 3.07L17.96 5l1.85 1.68L8.93 18.65z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }

  .custom-input__native:checked ~ .custom-input__label {
    &::before {
      background: $gray-10;
      border-color: $gray-10;
    }

    &::after {
      opacity: 1;
      transform: translate(0, -50%) scale(1);
    }
  }

  .custom-input__native:disabled ~ .custom-input__label {
    &::before {
      background: $gray-60;
      border-color: $gray-60;
    }
  }
}

.custom-input--radio {
  .custom-input__label {
    padding-left: 30px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      content: '';
    }

    &::before {
      border: 1px solid $gray-60;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      opacity: 0;
      transform: translate(0, -50%) scale(0.3);
      transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
      background-size: 16px 16px;
      background-color: $gray-10;
    }
  }

  .custom-input__native:checked ~ .custom-input__label {
    &::before {
      border-color: $gray-10;
    }

    &::after {
      opacity: 1;
      transform: translate(0, -50%) scale(0.7);
    }
  }

  .custom-input__native:disabled ~ .custom-input__label {
    &::before {
      background: $gray-60;
      border-color: $gray-60;
    }
  }
}

.custom-input--switch {
  .custom-input__label {
    padding-left: 40px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      display: block;
      width: 32px;
      height: 16px;
      border-radius: 10px;
      content: '';
    }

    &::before {
      border: 1px solid $gray-10;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      width: 12px;
      height: 12px;
      left: 3px;
      transform: translate(0, -50%);
      transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
      border-radius: 50%;
      background-color: $gray-10;
    }
  }

  .custom-input__native:checked ~ .custom-input__label {
    &::after {
      transform: translate(14px, -50%);
    }
  }

  .custom-input__native:disabled ~ .custom-input__label {
    &::before {
      background: $gray-80;
      border-color: $gray-80;
    }

    &::after {
      background: $gray-60;
    }
  }
}

//
// Finish thumbnail positioning
//

.custom-input--finish {
  margin-bottom: 8px;

  .finish-thumbnail {
    margin-left: -6px;
    margin-right: 8px;
  }

  .custom-input__native:disabled ~ .custom-input__label > .finish-thumbnail {
    opacity: 0.3;
  }
}

.custom-input--feature-icon {
  margin-bottom: 8px;

  .feature-icon {
    margin-left: -6px;
    margin-right: 8px;
  }

  .custom-input__native:disabled ~ .custom-input__label > .feature-icon {
    opacity: 0.3;
  }
}

.webform-submission-form .subscriber-optin {
  .h2 {
    display: none;
  }

  label {
    padding-left: 15px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: -12px;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      content: '';
      cursor: pointer;
    }

    &::before {
      //background: $gray-10;
      border: 1px solid $gray-60;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::after {
      opacity: 0;
      transform: translate(0, -50%) scale(0.3);
      transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
      background-size: 16px 16px;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#fff' d='M8.93 18.65L4 13.72l1.77-1.76 3.07 3.07L17.96 5l1.85 1.68L8.93 18.65z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }

  .form-checkbox {
    opacity: 0;
    position: relative;
    top: 16px;
  }

  .form-checkbox:checked ~ label {
    &::before {
      background: $gray-10;
      border-color: $gray-10;
    }

    &::after {
      opacity: 1;
      transform: translate(0, -50%) scale(1);
    }
  }
}

.outdated-element {
  display: none;
}

.hide-webform-thx div.webform-confirmation__message {
  display: none;
}
