.footer-link {
  font-size: 14.2px;
  line-height: 2.25;
  color: $gray-10;
  text-decoration: none;

  &:hover {
    font-weight: bold;
    color: $gray-30;
  }
}
