.order_status_section {
  @include wrapper;

  @include maxbp($r_600) {
    padding: 0;
  }

  .page-title {
    font-family: $font-bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.888rem;
    margin-bottom: 56px;

    @include maxbp($medium) {
      font-size: 2.281rem;
      margin-bottom: 36px;
    }
  }

  .iconbox_title {
    text-transform: capitalize;
  }
}

@include maxbp($medium) {
  .your_orders {
    .iconbox_title {
      font-size: 1.425rem;
      padding: 0 16px;
    }
  }
}

.track__button {
  a {
    width: 100%;
    justify-content: center;
  }
}

.track_order {
  max-width: 448px;
  margin: 0 auto;
}

.customer-service {
  margin: 0 auto;

  @include maxbp($medium) {
    margin: 50px auto;
  }

  .textbox_title + .textbox_subtitle {
    font-size: 1.375rem;
    color: $delta-red;
  }
}
