.path-form-ccpa-requests {
  .banner-block {
    .u-text-xs.text-align-center {
      line-height: 2;
    }
  }

  .delta-ccpa-form {
    .h2 {
      font-size: 25.38px;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-family: $font-bold;
      margin-bottom: 16px;
      padding-top: 13px;

      &::after {
        margin-left: +0.35ch;
        content: '*';
      }
    }

    .option.form-item__label {
      top: 0;
      left: 20px;

      @include maxbp($grid-md) {
        position: relative;
        top: -22px;
        left: 20px;
        line-height: 1;
      }
    }

    fieldset {
      margin-bottom: 0;

      @include maxbp($grid-md) {
        padding: 0.35em 0.75em 0.625em 0;
      }
    }

    .form-item {
      @include maxbp($grid-md) {
        margin-bottom: 24px;
      }
    }

    #edit-user-association-checkboxes,
    #edit-residency--wrapper,
    #edit-type-of-request--wrapper {
      .form-item {
        @include breakpoint($mega-menu-mobile) {
          margin-bottom: 13px;
        }

        @include maxbp($grid-md) {
          margin-bottom: 0;
        }
      }
    }

    #edit-contact-information,
    #edit-address-of-residence {
      padding-top: 20px;

      h6 {
        text-transform: capitalize;
      }
    }

    #edit-contact-container,
    #edit-address-container {
      @include large {
        display: flex;
        flex-wrap: wrap;

        .form-item {
          width: 50%;
          padding-right: 20px;

          &:nth-child(even) {
            padding-right: 0;
          }
        }
      }
    }

    #edit-actions {
      @include large {
        display: flex;
        justify-content: center;
      }
    }

    .form-item.filledelement .form-item__label {
      padding-left: 4px;
      opacity: 1;
      font-family: $font-bold2;
      font-size: 0.88875rem;
      background-color: $white;
      color: $gray-50;
      font-weight: 700;
      top: 0;
      transform: translate(0, -50%);
    }
  }

  .main.popupopen {
    opacity: 0.4;
    z-index: 99;
    pointer-events: none;
  }

  #edit-validated-message {
    display: none !important;
  }

  #edit-validated-message.no-for-california {
    display: block !important;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    width: 47%;
    position: fixed;
    left: 28%;
    top: 41%;
    opacity: 1;
    z-index: 100;

    @include maxbp($old-desktop) {
      width: 65%;
      left: 18%;
      top: 32%;
      line-height: 2;
    }

    .status--status {
      background-color: $gray-80;
      margin-bottom: 0;
      font-weight: 700;
      padding: 50px;
      font-size: 22px;

      @include maxbp($old-desktop) {
        font-size: 16px;
      }
    }

    .webform-message__link {
      display: inline-flex;
      line-height: 24px;
      font-size: 24px;
      width: 100%;
      justify-content: flex-end;
    }
  }

  #edit-validated-message.popupmessage {
    display: none !important;
  }

  .CCPA-Info {
    h6 {
      text-transform: capitalize;
      padding-top: 30px;
    }
  }
}

// .path-webform.bodynoscroll {
//   opacity: 0.5;
// }
//
// .bodynoscroll #edit-validated-message.no-for-california {
//   transform: scale(1); /* prefix me */
//   opacity: 1;
//
//   /* Clickable */
//   pointer-events: auto;
// }
