.form-row {
  margin-left: -12px;
  margin-right: -12px;
  flex-wrap: wrap;

  @include large {
    display: flex;
  }
}

[class^="form-col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.form-col {
  flex: 0 0 100%;
  max-width: 100%;
}

.form-col--sm {
  @include large {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.form-col--md {
  @include large {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
