.product-slider {
  .slick-arrow {
    top: 0;
    bottom: 0;
    margin: auto;

    &.slick-prev {
      left: -80px;

      @include maxbp($medium) {
        left: -35px;
      }
    }

    &.slick-next {
      right: -80px;

      @include maxbp($medium) {
        right: -35px;
      }
    }
  }
}
