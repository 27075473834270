.catalog-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  @include medium {
    margin-bottom: 80px;
  }
}

.catalog-grid__item {
  margin-bottom: 8px;

  img {
    display: block;
    width: 100%;
  }

  @include small {
    width: calc(50% - 4px);
  }
}
