@include maxbp($medium) {
  .order_status_section {
    .order_details_bottom_section {
      display: flex;
      flex-flow: column-reverse;

      .order-items {
        width: 100%;
      }

      .estimation {
        text-align: center;
      }
    }
  }
}
