.review-slider {
  max-width: $max-width-text;
  width: 100%;
  margin: auto;
  background: $white;
  position: relative;

  h4.h4 {
    font-family: $font-body;
    letter-spacing: normal;
    font-size: 26px;
    margin: 0;
  }

  .rs-left-section,
  .rs-right-section {
    padding: 0;
  }

  .rs-left-section {
    border-right: 1px solid $gray-80;

    .rs-left-top-block {
      border-bottom: 1px solid $gray-80;
      text-align: center;
      margin: 0 0 $space-ten*4;

      p {
        margin: 0;
      }

      .rs-rating,
      .rs-author-data,
      .rs-date {
        border-right: 1px solid $gray-80;
        padding: $space-ten*2 0;
      }

      .rs-author-data {
        text-align: center;

        .rs-author,
        .rs-source-link {
          display: inline-block;
          vertical-align: middle;
          margin: 0 $space-ten/2;
        }

        .rs-author {
          p {
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        .rs-source-link {
          a {
            color: $delta-red;
            text-decoration: none;
          }
        }
      }

      .rs-date {
        border: none;

        p {
          font-size: 14px;
          color: $gray-50;
          margin: 4px 0 0;
        }
      }

      .rs-rating {
        text-align: center;

        .star-rating {
          margin: 5px auto 0;
        }
      }
    }

    .rs-left-bottom-block {
      padding: 0 $space-ten*3;

      .rs-desc {
        margin: $space-ten*3 0 0;
      }

      .rs-all-review-link {
        a {
          padding: $space-ten $space-ten*2;
        }
      }
    }
  }

  .rs-right-section {
    .rs-prod-image {
      padding: $space-ten;
      border-bottom: 1px solid $gray-80;
    }

    .rs-prod-data {
      padding: $space-ten*3 $space-ten*4;

      .rs-prod-desc {
        font-weight: bold;
        font-size: 14px;
        line-height: 25px;
        margin: $space-ten 0;

        p {
          margin: 0;
        }
      }

      .rs-prod-link {
        a {
          text-decoration: none;
          color: $delta-red;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
