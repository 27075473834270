.pr-left-block {
  > div {
    margin: 0 0 $space-ten+5;
    padding: 0 0 $space-ten+5;
    border-bottom: 1px solid $gray-80;

    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  p {
    margin: 0;
  }
}

.pr-search-block {
  margin: 0 0 $space-ten+5;
  padding: 0 0 $space-ten+5;
  border-bottom: 1px solid $gray-80;

  .pr-search-title {
    h4.h4 {
      text-transform: none;
      letter-spacing: normal;
      font-size: 20px;
      font-weight: normal;
    }
  }
}

.region.region-left-sidebar {
  padding: 0 $space-ten*3 0 0;
}

.side-bar-menu {
  margin: 0 0 $space-ten+5;
  padding: 0 0 $space-ten+5;
  border-bottom: 1px solid $gray-80;
}

.pr-contact {
  .pr-contact-title {
    h5.h5 {
      font-weight: normal;
      font-size: 16px;
      letter-spacing: normal;
    }
  }

  .pr-contact-desc {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 0 $space-ten/2;
  }

  .pr-contact-block {
    font-size: 13px;
    margin: 0 0 $space-ten*2;
    line-height: 20px;

    .pr-contact-name {
      font-family: $font-bold;
    }
  }

  .pr-canada-title {
    h5.h5 {
      font-weight: bold;
      text-transform: none;
      margin: 0 0 $space-ten;
      letter-spacing: normal;
      font-size: 16px;
      font-family: $font-body;
    }
  }
}
