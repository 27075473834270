.faq-list-wrapper {
  border-top: 1px solid $gray-70;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: $gray-10;
  margin-bottom: 0 !important;

  a {
    color: $delta-red;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 2px;
    font-family: $font-body !important;
    text-decoration: none;
  }

  > div {
    padding: $space-ten+5 0 $space-ten+15;
  }
}

.faq-block {
  border: 1px solid $gray-60;
  border-bottom: none;
  //border-top: none;

  &:last-child {
    border-bottom: 1px solid $gray-60;
  }

  .faq-need-help {
    position: relative;
    padding: 17px 15px 25px 0;

    &::after {
      content: "";
      background-size: 10px 10px;
      height: 10px;
      width: 10px;
      display: block;
      position: absolute;
      top: 23px;
      right: 0;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }

  .faq-print-ans {
    position: relative;
    padding: 15px 0 25px 25px;

    &::after {
      content: "";
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
      display: block;
      position: absolute;
      top: 17px;
      left: 0;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ac0535' d='M21 9h3v10h-3v4.82c0 .18 0 .18-.17.18H8.77L8 22l11-.12v-6.59H5V22h3l.76 2H3.11C3 24 3 24 3 23.88V19H0V9h3V.11C3 0 3 0 3.1 0h13a.16.16 0 01.13.06l4.71 5.16a.2.2 0 01.06.15V9zm-2 0V6.27h-3a.53.53 0 01-.52-.58V2.15H5V9zm2 2h1v-1h-1z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }

  > .accordion-term {
    background: $white;
    //border-bottom: 1px solid $gray-60;
    border: none;
    letter-spacing: normal;
    font-family: $font-body;
    font-weight: 400;
    padding-top: $space-ten+2 !important;

    &.is-active {
      background: $gray-80;
      color: $gray-10;
      font-family: $font-bold;
      font-weight: normal;
    }
  }
}

.path-service-parts {
  .faq-list-desc {
    .faq-block {
      .faq-list-wrapper {
        .faq-need-help {
          a {
            font-size: 18px;
          }
        }

        .faq-print-ans {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}
