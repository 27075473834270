.pdp-prod-block {
  padding: 0 0 0 $space-ten+5;

  @include maxbp($r_600) {
    padding: 0;
  }
}

.pdp-collection-link-wrapper {
  margin: 0 0 $space-ten*2;
}

.pdp-rating-block {
  display: flex;
  margin: 0 0 $space-ten*3;

  @include maxbp($large) {
    display: block;
    text-align: center;

    [data-bv-show="rating_summary"] {
      text-align: center !important;
    }
  }

  > div {
    margin: 0 $space-ten+5 0 0;
    font-size: 14px;

    @include maxbp($small) {
      margin: 0 0 $space-ten;

      .star-rating {
        margin: auto;
      }
    }

    &.pdp-rating-value {
      a {
        color: $gray-10;
      }
    }

    &.pdp-text-links {
      .link {
        margin: 0 0 0 5px;

        &:first-child {
          position: relative;
          padding: 0 $space-ten 0 0;
          margin: 0 5px 0 0;

          &::after {
            content: "";
            background: $gray-60;
            display: block;
            top: 0;
            bottom: 0;
            height: 50%;
            margin: auto;
            width: 1px;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}

.pdp-recom {
  &.pdp-rating-block [data-bv-show=rating_summary] {
    text-align: left !important;

    @include maxbp($large) {
      margin-top: 30px;
    }
  }
}

.pdp-warning-wrapper {
  border-top: 1px solid $gray-70;
  padding: $space-ten*3 0;
}

.pdp-prod-desc-list {
  border-top: 1px solid $gray-70;
  padding: $space-ten*3 0;

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: 18px;
      position: relative;
      padding: 0 0 0 $space-ten+2;
      list-style: none !important;

      &::after {
        content: "";
        display: block;
        background: $delta-red;
        height: 5px;
        width: 5px;
        border-radius: 100%;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }
}

.color-option-btn-wrapper {
  border-top: 1px solid $gray-70;
  padding: 32px 0;

  .selected-col-name {
    font-size: 16px;

    span {
      font-family: $font-bold2;
      font-weight: normal;
    }
  }

  @include maxbp($large) {
    text-align: center;
  }

  .color-list {
    //display: flex;
    margin: 16px 0 0;

    a.color-option-btn {
      display: inline-block;
      margin: 0 $space-ten+5 0 0;
      border: 2px solid white;
      padding: 2px;
      border-radius: 100%;

      &.finish-selected {
        border: 2px solid $delta-red;
        border-radius: 100%;
        padding: 2px;
      }
    }
  }
}

.pdp-prod-price-block {
  border-top: 1px solid $gray-70;
  padding: 32px 0;

  @include maxbp($large) {
    text-align: center;
  }
}

.pdp-prod-quantity {
  margin: 0 0 $space-ten*2;
}

.pdp-prod-more-desc {
  margin: 0 0 $space-ten+15;

  p {
    margin: 0;
  }
}

.pdp-prod-label-wrapper {
  border-top: 1px solid $gray-70;
  padding: $space-ten*3 0;
}

.pdp-prod-buttons {
  margin: 0 0 $space-ten+15;

  .numeric-stepper {
    @include maxbp($large) {
      text-align: center;
      width: 100px;
      margin: auto;
    }
  }

  .pdp-btn-added-cart {
    height: 0;
  }

  > div {
    max-height: 200px;
    transition: all ease 1s;
    overflow: hidden;

    &.btn-hide {
      max-height: 0;
    }
  }

  a,
  input[type="submit"] {
    display: block;
    width: 65%;
    margin: 0 0 5px;
    padding-left: 2rem;
    padding-right: 2rem;

    @include maxbp($r-1100) {
      width: 80%;
    }

    @include maxbp($large) {
      width: 100%;
    }
  }
}

.pdp-img-btn {
  display: block;

  @include breakpoint($xl) {
    width: 65%;
    text-align: center;
  }

  @include maxbp($r-1100) {
    width: 81%;
  }

  @include maxbp($large) {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  a {
    padding-right: 4%;
    font-size: 16px;

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}
