.pdp-adv-top {
  .adv-pdp-prod-text {
    padding: 0 0 0 $space-ten+15;
  }

  @include maxbp($large) {
    display: block;

    .adv-pdp-prod-img-wrapper,
    .adv-pdp-prod-text {
      width: 100%;
      max-width: 100%;
    }
  }
}
