.pdp-top-section {
  @include wrapper;

  > .grid-col-sm-6 {
    @include maxbp($large) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  padding: $space-ten*5 $space-ten*10 0 !important;
  margin-bottom: $space-ten*8;

  @include maxbp($grid-xl) {
    padding: $space-ten*5 $space-ten*6 0 !important;
  }

  @include maxbp($r-1100) {
    padding: $space-ten*5 0 0 !important;
  }

  @include maxbp($large) {
    max-width: 600px;
  }

  .prod-slider-wrapper {
    .thumb-img-wrapper-list {
      max-width: none;
    }
  }
}

.desktop-pdp-prod-info-hdr {
  @include maxbp($large) {
    display: none;
  }
}

.mobile-pdp-prod-info-hdr {
  @include breakpoint($large) {
    display: none;
  }
}

.checkmark {
  background: url("../images/icons/src/check-mark.svg");
  background-repeat: no-repeat;
  background-position: 17px;
}

.pdp-fea-img-list a {
  text-decoration: none;
}

.pdp-prod-buttons {
  .field--name-quantity {
    .form-item {
      label.form-item__label {
        span {
          display: none;
        }
      }
    }
  }
}
