.table {
  border: 1px solid $gray-70;
  border-spacing: 0;
  background-color: $gray-90;
  margin: 1em 0;
  width: 100%;
}

.table-responsive {
  overflow: auto;
}

.table__heading-cell,
.table__cell,
.table__heading-subcell {
  border: none;
  padding: 0.75em;

  &:last-child {
    border-right: none;
  }
}

.table__cell {
  border-bottom: 1px solid $gray-70;
  border-right: 1px solid $gray-70;
}

.table__heading-cell,
.table__heading-subcell {
  border-bottom: 1px solid $white;
  border-right: 1px solid $white;
}

.table__heading-cell {
  color: $white;
  padding: 0.75em;
  text-align: left;
  font-family: $font-bold;
  font-size: 1.125rem;
  background-color: $gray-10;
}

.table__heading-subcell {
  color: $gray-10;
  padding: 0.75em;
  text-align: left;
  font-family: $font-bold;
  font-size: 1.125rem;
  background-color: $gray-70;
}

.table__row {
  &:nth-child(odd) {
    background-color: $white;
  }

  // Bottom Row - Non-BEM but always contained
  &:last-child {
    tbody & {
      th,
      td {
        border-bottom: none;
      }
    }
  }
}

.table__footer-cell,
.table__footer-cell:first-child {
  border-bottom: none;
  border-top: 1px solid $gray-70;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  table {
    @extend .table;
  }

  th {
    @extend .table__heading-cell;
  }

  td {
    @extend .table__cell;
  }

  tr {
    @extend .table__row;
  }

  tfoot th {
    @extend .table__footer-cell;
  }
}
