.showerdoors {
  .content-card__title {
    display: none;
  }

  .section-lead-in.paragraph-component.center {
    text-align: center;
    margin: 40px auto;
  }

  .img_element {
    display: block;
  }

  .layout--twocol-section--33-67 {
    .layout__region--first {
      flex: 0 0 50%;
    }

    .layout__region--second {
      padding: 60px;
      color: white;
      background: black;
      flex: 0 0 50%;

      .h2 {
        color: white;
        position: relative;
        margin-bottom: 60px;
        text-align: left;

        &::after {
          content: "";
          position: absolute;
          width: 50px;
          border-bottom: 4px solid $delta-red;
          bottom: -15px;
        }
      }

      .text-long {
        position: relative;

        &::after {
          content: "";
          width: 100%;
          border-bottom: 2px solid $delta-red;
          height: 2px;
          position: absolute;
        }
      }
    }
  }

  .layout--twocol-section--50-50 {
    max-width: 940px;
    margin: auto;
    width: 100%;

    .layout__region {
      .content-card {
        margin: 0 10px 20px;
      }
    }
  }

  .layout--threecol-section--33-34-33 {
    max-width: 940px;
    margin: auto;
    width: 100%;

    > div {
      flex: 0 1 33%;

      .content-card {
        margin: 0 10px;
      }
    }
  }

  .innovation-section {
    .faq-card__button {
      background: no-repeat;
      color: $delta-red;
      border: 1px solid #eee;
      border-radius: 0;

      &:hover {
        background: $delta-red;
        color: $white;
      }
    }
  }

  .margin-bottom-80 {
    text-align: center;
    margin-bottom: 80px;
  }
}
