.result__total {
  font-size: 1.42375rem;

  @include large {
    font-size: 1.601875rem;
  }

  b {
    font-family: $font-bold2;
    margin-right: 10px;
  }

  i {
    font-style: italic;
    font-family: $font-bold2;
    margin-left: 10px;
  }
}
