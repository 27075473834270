.login_popup {
  max-width: 576px;
  margin: 16px auto;
  z-index: 9999;
  background: $white;
  opacity: 1;
  border-radius: 2px;

  @include breakpoint($grid-md) {
    padding: 32px 56px 30px 56px;
  }

  @include maxbp($large) {
    width: auto !important;
    padding: 32px 24px;
    margin: 16px 16px 16px;
    overflow-y: scroll;
    max-height: 100%;
  }

  .ui-widget-header {
    background: $white;
    border: 0 none;
    width: 22px;
    height: 22px;
    right: 20px;
    position: absolute !important;
    top: 10px;
    z-index: 101;

    .ui-dialog-titlebar-close {
      border: 0 none;
      background: no-repeat;

      .ui-icon {
        background: url("../images/icons/src/close.svg");
        background-size: 100%;
        width: 22px;
        height: 22px;
      }
    }
  }

  .ui-dialog-content {
    max-height: 100% !important;
    padding: 0;
  }

  .user_login {
    h4 {
      font-size: 1.6rem;
      font-family: $font-bold2;
      letter-spacing: 0;
    }
  }
}

.ui-widget-overlay {
  z-index: 1001 !important;
  background-color: rgba(24, 25, 29, 0.8);
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0s;
}

.delta-login-form {
  .popup-user-login {
    label {
      position: relative;
      top: 0;
      left: 0;
      padding-bottom: 2px;
      font-size: 0.88875rem;
      color: rgb(119, 119, 122);
      font-family: $font-bold2;
    }

    .custom-input__label {
      &::before {
        border: 2px solid $gray-70;
        border-radius: 0;
        opacity: 0.7;
      }

      span {
        text-transform: capitalize;
        padding-left: 5px;
      }
    }
  }

  .form-item__pwd-toggle {
    display: none;
  }

  .checkout-login {
    font-family: $font-bold;
    margin-bottom: 42px;
  }

  .form-item {
    margin-bottom: 16px;
  }

  .form-checkbox + label {
    font-family: $font-body;
    font-size: 1rem;
    color: $gray-10;
    display: none;
  }

  .cta-link {
    font-size: 1rem;
    color: $delta-red;
  }

  .custom-input--checkbox {
    .form-item {
      margin-bottom: 0;
    }
  }

  .user-login-error {
    .login-error {
      display: inline-block;
      font-family: $font-bold2;
      font-size: 0.88875rem;
      font-weight: 700;
      color: $error;
      margin-top: 4px;

      & + .login-error {
        margin-left: 10px;
      }
    }
  }
}

// forgot pass
.forgot-password {
  &-desc {
    margin-bottom: 15px;
  }

  .forgot-pass-professional {
    a {
      font-family: $font-bold;
      text-decoration: none;
      font-weight: 700;
      font-size: 1rem;
      color: $delta-red;
      margin: 15px 0;
      display: inline-block;
    }
  }

  .validation-error {
    color: $error;
    font-weight: 700;
  }

  .checkout_login-title {
    text-align: left;
    margin-bottom: 20px;
  }
}
