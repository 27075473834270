.download-card {
  position: relative;

  &:hover {
    .download-card__overlay {
      display: flex;
    }
  }

  padding: 24px 0;
  border-bottom: 1px solid $gray-70;

  .download-badge {
    margin-bottom: 8px;
    position: absolute;
    top: 24px;
    left: 0;
  }
}

.download-card--padded {
  padding: 45px 0;
}

.download-card__figure {
  background: radial-gradient(circle, $white 0%, $gray-90 100%);
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding: 24px 0 12px;
}

.download-card__figure--icon {
  display: flex;
  padding: 44px 24px 24px;
}

.download-card__body {
  text-align: center;
  padding: 0 16px;
}

.download-card__title {
  font-size: $font-size-xl;
  font-family: $font-roman;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.35;
  margin-bottom: 8px;
  word-wrap: break-word;
}

.download-card__size {
  display: block;
}

.download-card__buttons {
  display: none;
  margin-top: 24px;

  .button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include maxbp($medium) {
    display: block;
  }

  a {
    @include maxbp($grid-sm) {
      display: block;
      margin: 0 0 5px;
    }
  }
}

// List view
.download-card-grid.list-view .download-card {
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid $gray-70;

  .download-card__figure {
    padding: 0;
    background: none;

    .icon {
      width: 52px;
      height: 52px;
    }
  }

  .download-badge {
    position: static;
  }

  .download-card__body {
    text-align: left;
  }

  .download-card__buttons {
    display: block;
  }

  .download-card__overlay {
    display: none !important;
  }
}

.download-card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  text-align: center;
  background-color: rgba($white, 0.95);
  border: 1px solid $gray-80;
  border-radius: 2px;
  box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);

  .download-card__title {
    font-size: $font-size-lg;
  }

  .download-card__overlay__buttons {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  .button {
    justify-content: center;
    margin-bottom: 4px;

    &:only-of-type {
      margin-top: -20px;
    }
  }
}
