.path-order-confirmation {
  h1 {
    display: none;
  }

  .layout-container {
    .main {
      @include maxbp($medium) {
        padding-top: 0;
        margin-top: -26px;
      }
    }
  }
}

.order_info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  h2 {
    font-family: $font-light;
    font-size: 2.888rem;
    text-transform: none;
    font-weight: normal;

    @include maxbp($medium) {
      font-size: 2.281rem;
      text-align: center;
    }
  }

  .order-desc {
    margin-bottom: 25px;
  }

  #order-status {
    margin-bottom: 25px;
  }

  .order-no {
    font-family: $font-bold;
    font-size: 1.125rem;
  }

  .continue-browsing {
    margin-top: 20px;
  }
}

.order_details {
  background-color: $gray-90;
  padding: 39px 32px 35px 33px;
  margin: 48px 10px;
  width: 33.33%;

  .name,
  .shipping-address,
  .billing-address,
  .contact-info {
    margin-bottom: 16px;

    span {
      display: block;
    }
  }

  @include maxbp($medium) {
    width: 100%;
    margin: 0;
    padding: 20px 15px;
  }
}

.order_details_wrap {
  display: flex;

  @include maxbp($medium) {
    flex-flow: column;
    margin-top: 40px;
  }
}

.order_details-title {
  font-family: $font-bold2;
  font-size: 1.269rem;
  line-height: 1.5;
  color: $gray-10;
  border-bottom: 1px solid $gray-70;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.order-total-des {
  font-size: 0.788rem;
  font-family: $font-oblique;
  color: $gray-30;
  margin: 5px;
  max-width: 175px;
}

.shipping_total {
  padding-top: 10px;
}

.order_info .customer {
  font-family: $font-bold2;
  font-size: 1rem;
  margin-bottom: 10px;
}

@include maxbp($mega-menu-mobile) {
  .main {
    padding: 53px 10px 30px;
  }

  .no-aside-space {
    .main {
      padding: 53px 0 30px;
    }
  }

  .main.main--with-sidebar-left aside {
    padding: 0 20px 10px;
  }

  .main-content.main-content--with-sidebar-left {
    padding-left: 20px;
    padding-right: 20px;
  }

  .right-left-space {
    .main {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.your_orders_details {
  .order-total-line__total {
    span {
      font-family: $font-bold2;
      font-size: 18px;
    }
  }
}

.our_Order_table {
  .value {
    .has-icon--right {
      background-image: url("../images/icons/src/carrot-right-red.svg");
      background-repeat: no-repeat;
      background-position: right;
      padding-right: 15px;
    }
  }
}

.order_status_section {
  .order-status-btn {
    width: 100%;
    justify-content: center;
  }
}
