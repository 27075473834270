/// Mixin - list-reset
/// Reset list item defaults when no margin, padding, list styles needed
@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul,
ol {
  padding-left: 1em;
}

ol {
  list-style-type: decimal;
}

.list-item {
  margin-bottom: 0.2em;
  padding-left: 1em;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  ol,
  ul {
    li {
      @extend .list-item;
    }
  }
}

.breadcrumb {
  background: none;

  li {
    list-style: none !important;
  }
}

.company-media-page .media-room-pagination-root .pager_items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.125rem;
}
