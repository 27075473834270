//
// General Layout
//

$sidebar-width: 33%;
$gutter: 24px;
$main-width: calc(100% - (#{$sidebar-width} + #{$gutter}));

.header {
  @include wrapper;

  margin-bottom: 4em;
}

//
// Layout Using Flexbox (IE11+).
// Remove this and uncomment Grid code below to use CSS Grid.
//

.main {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;

  @include large {
    flex-direction: row;
  }

  @include breakpoint($mega-menu-mobile) {
    padding-top: 40px;
  }
}

.main-sidebar {
  margin-bottom: $space-double;

  @include large {
    order: 2;
    width: $sidebar-width;
    margin-bottom: 0;
  }
}

.main-content {
  width: 100%;

  @include large {
    &--with-sidebar {
      margin-right: $gutter;
      width: $main-width;
    }
  }
}

//
// Layout Using CSS Grid (NO IE11 support)
// Remove Flexbox code above and uncomment this section to use CSS Grid.
//

// .main {
//   &--with-sidebar {
//     @include large {
//       display: grid;
//       grid-gap: $gutter;
//       grid-template-areas: "content sidebar";
//       grid-template-columns: $main-width #{$sidebar-width};
//     }
//   }
// }
//
// .main-sidebar {
//   grid-area: sidebar;
//   margin-bottom: $space-double;
// }
//
// .main-content {
//   grid-area: content;
//   width: 100%;
// }

.ajax-progress {
  &-throbber {
    background: rgba(254, 254, 254, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 49;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .throbber {
      background: url(../images/icons/src/delta_loading.gif) no-repeat;
      height: 40px;
      width: 40px;
      background-size: 100%;
    }
  }

  .message {
    font-size: 1rem;
    font-family: $font-bold2;
  }

  &-fullscreen {
    background: url(../images/icons/src/delta_loading.gif) no-repeat;
    height: 40px;
    width: 40px;
  }
}

.ui-widget.ui-widget-content {
  z-index: 1002;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.global_header {
  padding-top: 101px;

  @include maxbp($r-1100) {
    padding-top: 60px;
  }

  @include maxbp($r_400) {
    padding-top: 55px;
  }
}

.toolbar-fixed.user-logged-in {
  .global_header {
    padding-top: 0;
  }

  .fullwidth_header {
    top: 0;
  }
}
