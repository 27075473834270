.content-card {
  position: relative;
  display: block;
  color: $white;
}

.content-card__image {
  display: block;
  width: 100%;
}

.content-card__title {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 0 12px;
  position: absolute;
  //top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: $font-bold2;
  text-align: center;
  line-height: 1.5;
  //background-color: rgba($gray-10, 0.5);

  //@include medium {
  display: block;
  transform: none;
  top: auto;
  background-color: rgba($gray-10, 0.8);
  padding: 8px 5px;
  transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;

  .content-card:hover &,
  .content-card.is-active & {
    padding: 16px 5px;
    background-color: $delta-red;
  }
  //}
}

.node--type-landing-page {
  @media (max-width: 768px) {
    h2 {
      text-align: center;
    }
  }

  .catalog-grid__item {
    width: 49%;
    margin-bottom: 2%;

    @include maxbp(640px) {
      width: 100%;
    }
  }
}
