
// Project

// brand
$delta-merlot: #750010;
$delta-red: #ac0535;
$delta-punch: #e64555;

// grayscale
$gray-10: #18191d;
$gray-30: #434447;
$gray-50: #77777a;
$gray-60: #c5c5c7;
$gray-70: #d7d7d9;
$gray-80: #e6e6e8;
$gray-90: #f5f5f7;

// notifications
$error: #ff4949;
$error-light: #fff0f0;
$neutral: #4d96e7;
$neutral-light: #f5f9ff;
$success: #009f4c;
$success-light: #f2faf6;
$warning: #e7b04d;
$warning-light: #fff8ec;

// Pattern lab default overrides
$white: white;
$black: black;
$transparent : transparent;
