.innovation-detail-watersense {
  .innovation-secondary-banner {
    margin: 0 0 $space-ten*4;
  }

  .two-column-section {
    padding-bottom: 30px;

    .layout__region {
      &.layout__region--first {
        @include breakpoint($r-1100) {
          display: flex;
          width: 49%;
          margin: 0 20px 0 5px;
          flex: none;
        }
      }

      &.layout__region--second {
        @include breakpoint($r-1100) {
          display: flex;
          width: 49%;
          flex: none;
        }
      }
    }
  }

  .img_element {
    img {
      width: 100%;
    }
  }

  #Overview {
    .grid-container {
      padding: 0;

      .innovation-image-grid__item {
        @include maxbp($old-desktop) {
          flex: none;
          max-width: 100%;
          padding-bottom: 30px;
        }
      }
    }
  }
}
