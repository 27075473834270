.service-parts-support-page {
  @include wrapper;

  padding-top: $space-ten * 5 !important;
  padding-bottom: $space-ten * 5 !important;

  > div {
    margin: 0 0 $space-ten * 2;
    border-bottom: 1px solid $gray-80;
    padding: 0 0 $space-ten * 2;
  }

  .sps_page-title {
    padding: 0 0 $space-ten;
    margin: $space-ten 0 $space-ten + 5;
    border-bottom: 1px solid $gray-80;

    p {
      margin: 0;
    }

    h4 {
      text-transform: uppercase;
      font-size: 30px;
      margin: 0;
    }
  }

  .sps-repair-desc {
    a {
      font-weight: bold;
    }
  }

  .sps-search {
    font-size: 24px;

    .sps-search-desc {
      input {
        width: 100%;
        font-size: 16px;
        //padding: 7px $space-ten+5;
        border-radius: 4px;
        border: 1px solid $gray-70;
      }
    }
  }

  .sps-halpful-links {
    .sps-helpful-desc {
      li {
        padding: 0;
        margin: 0 0 0 25px;

        a {
          text-decoration: none;
        }
      }

      .sps-helpful-desc-title {
        h6.h6 {
          font-size: $font-body;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: normal;
        }
      }
    }
  }

  .sps-contact-method {
    .sps-con-data-title {
      h6.h6 {
        font-family: $font-body;
        letter-spacing: normal;
        font-size: 16px;
      }
    }

    .sps-help-line,
    .sps-help-line-no {
      p {
        margin: 0;
      }
    }

    .sps-help-line-no {
      p {
        font-weight: bold;
      }
    }

    .sps-press-data {
      .sps-press-title,
      .sps-press-desc {
        display: inline-block;
        vertical-align: top;

        p {
          margin: 0;
        }
      }

      .sps-press-title {
        width: 60px;
        font-weight: bold;
      }

      .sps-press-desc {
        width: calc(100% - 70px);
      }
    }
  }

  .service-parts-support-page {
    padding-top: 20px !important;
    padding-bottom: 0 !important;

    .sps-helpful-desc {
      padding-left: 0;
    }
  }

  .register-products h6 {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
  }
}

.sps-prod-identification {
  .img-link-image a {
    display: block;
  }
}

@include medium {
  .path-service-parts {
    .service-part-tabs {
      .accordion-term {
        .acc-ans {
          position: absolute;
          right: 43px;
        }
      }
    }
  }
}

.sp-tab-data-wrapper .sp-tab-data-desc .accordion .accordion-term .acc-ans a.acc-ans-link::before {
  background-position: 0 13px;
}

.service-parts-social-links {
  padding-top: 22px;

  p {
    text-align: left !important;
  }

  span {
    display: inline-block;
    text-align: left;
    margin: 0 3px;

    a img {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.how-to-videos-page {
  form.bef-exposed-form {
    max-width: 376px;
  }

  form.bef-exposed-form + .grid-container {
    padding: 0;

    ul {
      padding: 0;
    }
  }
}

form.bef-exposed-form {
  margin-top: 20px;
}
