.press-release {
  .pr-right-wrapper {
    .text-image-block {
      width: 32.5%;
      display: inline-block;
      min-width: 10px;
      padding: 0 $space-ten/2;
      margin: $space-ten*2 0 $space-ten*5;
      vertical-align: top;
      max-width: none;

      @include maxbp($grid-lg) {
        width: 49.5%;
        margin: $space-ten*2 0;
      }

      @include maxbp($grid-sm) {
        width: 49%;
      }

      @include maxbp($small) {
        width: 100%;
        text-align: left;

        img {
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}
