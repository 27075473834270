.u-color-delta-merlot {
  color: $delta-merlot !important;
}

.u-color-delta-red {
  color: $delta-red !important;
}

.u-color-delta-punch {
  color: $delta-punch !important;
}

.u-color-gray-10 {
  color: $gray-10 !important;
}

.u-color-gray-30 {
  color: $gray-30 !important;
}

.u-color-gray-50 {
  color: $gray-50 !important;
}

.u-color-gray-60 {
  color: $gray-60 !important;
}

.u-color-gray-70 {
  color: $gray-70 !important;
}

.u-color-gray-80 {
  color: $gray-80 !important;
}

.u-color-gray-90 {
  color: $gray-90 !important;
}

.u-color-error {
  color: $error !important;
}

.u-color-error-light {
  color: $error-light !important;
}

.u-color-neutral {
  color: $neutral !important;
}

.u-color-neutral-light {
  color: $neutral-light !important;
}

.u-color-success {
  color: $success !important;
}

.u-color-success-light {
  color: $success-light !important;
}

.u-color-warning {
  color: $warning !important;
}

.u-color-warning-light {
  color: $warning-light !important;
}

.u-color-white {
  color: $white !important;
}

.u-color-black {
  color: $black !important;
}

.u-color-transparent {
  color: $transparent !important;
}
