// A
.main-menu__link {
  color: $gray-10;
  display: block;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: $space $space-triple $space $space-double;
  text-decoration: none;
  font-weight: bold;

  &--with-sub {
    width: 80%;
  }

  @include breakpoint($main-menu-medium) {
    border-bottom: none;
    color: $gray-10;
    display: inline-block;
    font-size: 1.1rem;
    padding: $space $space-double $space $space;
    text-transform: none;
    position: relative;
    width: auto;

    &.active,
    &:active,
    &:hover {
      color: $gray-60;
    }

    &::after {
      color: $white;
      display: inline-block;
      content: ">";
    }

    &--sub::after {
      display: none;
    }

    &:hover {
      color: $white;

      &::after {
        color: $gray-50;
      }
    }
  }
}

//
// Sub Navigation Links
//
// A (Nested)
.main-menu__link--sub {
  color: $gray-10;
  padding-left: $space-triple;
  font-weight: normal;

  @include breakpoint($main-menu-medium) {
    color: $gray-50;
    display: block;
    padding: $space-one-third $space;
  }
}

.main-menu--sub-2 {
  background-color: $gray-80;
}

.main-menu__link--sub-2 {
  padding-left: $space-quadruple;
}
