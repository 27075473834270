.design-innovation-detail {
  color: #474747;

  .innovation-navigation {
    margin: 20px 0 0 0;
  }

  .banner-block h3 {
    text-transform: capitalize;
    font-weight: 400;
    font-family: $font-roman;
    letter-spacing: 0;
    color: #474747;
    font-size: 40px;
  }

  .image-description {
    max-width: 765px;
    width: 100%;
    padding: 0 0 0 20px;

    @include maxbp($old-desktop) {
      padding: 10px;
    }

    h3 {
      text-transform: capitalize;
      color: #474747;
      font-weight: 300;
      font-size: 24px;
      letter-spacing: 0;
      font-family: $font-roman;

      @include maxbp($old-desktop) {
        font-size: 32px;
      }
    }

    p {
      margin: 0 0 2em;

      a {
        color: #ac0535;
        text-decoration: none;
      }
    }

    em {
      font-family: $font-roman;
    }

    .fs-small {
      font-size: 13px;
    }
  }
}

.innovation-hydrachoice-body-sprays .innovation-secondary-banner {
  background-position: left;
}

.innovation-upstile .innovation-secondary-banner {
  min-height: unset;
}

.new-production-and-innovations #block-mainpagecontent {
  margin-bottom: 50px;
}

.new-production-and-innovations h2.h2 {
  text-align: center;
  margin-top: 40px;
}

.envision {
  overflow: hidden;

  iframe {
    height: 100vh !important;
  }
}
