.customer-support-page {
  .main {
    max-width: 100%;
  }

  .grey-gradient-background {
    width: 100%;

    &::before {
      width: 100%;
      left: 0;
    }
  }

  .title_description,
  .section_lead_in {
    p {
      font-size: 1.125rem;

      @include maxbp($grid-md) {
        font-size: 1rem;
      }
    }
  }

  h1 {
    color: $gray-10;
    background-color: transparent;
  }

  .field_card_heading {
    .paragraph {
      margin-bottom: 1.3rem;
    }
  }

  .field-values-in.field_card_details {
    & > div {
      width: 25%;
      padding-right: 0;
      padding-left: 0;

      @include maxbp($grid-md) {
        width: 100%;

        &:last-child {
          .card_component {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .card_component {
      padding: 20px;

      @include maxbp($grid-md) {
        border: 1px solid $gray-80;
        border-radius: 5px;
        box-shadow: 5px 5px 25px $gray-80;
        margin: 0 0 20px 0;
      }

      a {
        text-decoration: none;
        border: none;
      }

      .text-long {
        & > p {
          margin-bottom: 0;
          text-align: left;
        }

        & > ul {
          margin-top: 5px;
        }
      }

      .field_card_icon {
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .link-with-rightarrow {
        position: relative;
        text-align: center;
        display: inline-flex;
        padding-right: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;

        &::after {
          content: "";
          background-size: 10px 10px;
          height: 10px;
          width: 10px;
          color: $delta-red;
          margin: auto 0;
          display: block;
          position: absolute;
          top: 8px;
          right: 0;
          background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E"), "#", "%23");
          background-repeat: no-repeat;
        }
      }
    }
  }

  .designbeyond {
    .qidl-link {
      .button {
        white-space: unset;
      }
    }
  }

  .layout__region--content {
    .content > div {
      .title_description:first-child {
        .text-long {
          h2 {
            font-size: 2.875rem;
            margin-bottom: 16px;
          }

          p {
            font-size: 1.25rem;
            margin-bottom: 10px;
            color: $gray-10;
            background-color: transparent;

            @include maxbp($grid-md) {
              font-size: 1.125rem;
            }
          }
        }

        .outlet_card_section {
          padding: 0;
          margin-top: 20px;

          @include maxbp($grid-md) {
            display: block;
            margin-top: 0;

            .field_title {
              display: block;
              width: 100%;
              font-size: 2rem;
              padding-bottom: 1rem;
              letter-spacing: -0.2px;
              font-family: $font-bold2;
            }

            .text-long {
              display: block;
              width: 100%;
              font-family: $font-body;
              font-size: 1rem;
              color: $gray-10;
            }
          }
        }
      }
    }
  }

  .title_description {
    .grey-img-bg {
      @include maxbp($grid-md) {
        margin-bottom: 0;

        &::before {
          margin-top: 0;
        }

        .field_title {
          padding: 40px 0 20px 0;
        }

        .field_card_details {
          padding-bottom: 20px;
        }
      }

      .card_component {
        background-color: $white;
        border: 1px solid $gray-80;
        border-radius: 5px;
      }
    }
  }

  .two_column_section {
    max-width: 1440px;
    margin: 0 auto;

    .layout--twocol-section {
      align-items: stretch;
      margin-top: 40px;

      @include maxbp($grid-lg) {
        display: block;
      }

      .field_card_details {
        padding: 0;

        @include maxbp($grid-md) {
          padding-bottom: 0 !important;
        }
      }
    }

    .outlet_card_section {
      padding: 0;

      @include maxbp($grid-md) {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }

    .layout__region--first {
      @include maxbp($grid-lg) {
        margin-top: 0;
      }

      & > .block {
        background-color: $gray-90;
        padding: 70px 0;
        margin-left: 1rem;
        height: 100%;

        @include maxbp($grid-lg) {
          padding: 20px 0 40px;
          margin: 0;
        }
      }

      .qidl-block {
        max-width: 420px;
        text-align: center;
        margin: 0 auto;

        .qidl-link {
          a {
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }
    }

    .layout__region--second {
      .field-values-in.field_card_details {
        margin: 0;

        @include maxbp($large) {
          flex-direction: row;
        }

        @include maxbp($grid-md) {
          flex-direction: column;
        }

        & > div {
          width: 48%;
          max-width: 100%;
          flex: 0 0 48%;
          padding: 0;

          .card_component {
            @include maxbp($grid-md) {
              margin: 0 0 20px 0;
            }

            .text-long {
              text-align: left;
              padding-left: 0.5rem;

              ul {
                li {
                  padding-left: 0.1rem;
                  margin-bottom: 0;

                  @include maxbp($grid-md) {
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          @include maxbp($grid-md) {
            width: 100%;
          }
        }
      }
    }

    // & + .title_description {
    //   @include maxbp($grid-md) {
    //     margin-top: -40px;
    //   }
    // }
    // Teo - Fixed it in other way without using Negative Margin, if t doesn't work will recheck with your code
  }

  div[class^="grid-col"] {
    .card_component {
      height: 100%;
      padding: 20px 20px 0 20px;
      margin: 0;

      @include maxbp($grid-lg) {
        margin: 0 0 30px 0;
        padding: 0 20px 30px 20px;
        width: 100%;
      }

      @media (min-width: $grid-md) and (max-width: $grid-lg) {
        min-width: 383px;
        padding: 30px 20px;
      }

      // &:hover {
      //   box-shadow: 8px 7px 20px 0 $gray-80;
      //   border: 1px solid $gray-80;
      //   border-radius: 4px;
      // }
    }
  }

  .outlet_card_section {
    padding: 10px 0 40px;

    @include breakpoint($r-1100) {
      padding: 10px 16px 40px;
    }

    @include maxbp($grid-md) {
      padding: 0 !important; // using !important to override another statement that is using !important
      margin: 20px 0;
    }
  }

  .footer-info {
    background-color: $gray-90;
  }

  .footer {
    margin-top: 0;
  }
}

.customer-support-parts {
  .main {
    padding-bottom: 10px;
  }

  .grey-image-background.section-lead-in {
    @include maxbp($grid-md) {
      p br {
        content: '';

        &::after {
          content: ' ';
        }
      }
    }
  }

  section.footer-info {
    background-color: $white;
  }

  .title_description {
    .outlet_card_section {
      padding-bottom: 40px;
      max-width: 1440px;
      margin: 0 auto;

      .field_card_details {
        @include maxbp($large) {
          flex-direction: row;
          padding: 0;
        }

        @include maxbp($grid-md) {
          flex-direction: column;
          padding-bottom: 0 !important;
        }

        .grid-col-md-12 {
          @include maxbp($grid-lg) {
            max-width: 49%;
          }

          @include maxbp($grid-md) {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.contact-delta-customer-support {
  .main {
    @include breakpoint($large) {
      padding: 0;
      max-width: none;
    }

    @include breakpoint($large) {
      padding: 0;
    }
  }

  .layout--twocol-section--50-50 {
    @include breakpoint($large) {
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  // .outlet_card_section {
  //   @include breakpoint($large) {
  //     max-width: 1440px;
  //     margin: 0 auto;
  //   }
  // }
  .field_card_details {
    @include breakpoint($large) {
      max-width: 1440px;
      margin: 0 auto !important;
    }
  }

  .outlet_card_section.grey-img-bg {
    padding: 40px 0 !important;

    &::before {
      margin-top: -40px !important;
      left: 0;
      width: 100%;
    }

    & > .field_title {
      margin-bottom: 40px;

      @include maxbp($grid-lg) {
        margin: 0 !important;
        padding: 0 0 32px 0;
      }
    }

    .field_card_details {
      padding: 0 !important;

      @include maxbp($grid-lg) {
        justify-content: space-between;

        & > div {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(50% - 16px);
          margin-bottom: 20px;
        }

        .card_component {
          max-width: 100%;
        }
      }

      @include maxbp($large) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;

        & > div {
          flex: 0 0 100%;
          width: 100%;
          margin-bottom: 0;

          .card_component {
            margin-bottom: 20px;
          }
        }
      }

      @include breakpoint($grid-lg) {
        padding: 0 16px;
        margin-left: 0;
        margin-right: 0;

        & > div {
          max-width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(33.33% - 16px);
        }
      }
    }

    a.inline-text-link {
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-top: 1rem;
      display: block;
    }

    .card_component {
      padding-bottom: 2rem;

      @include maxbp($grid-md) {
        width: auto;
        height: auto;
      }

      @media (min-width: $grid-md) and (max-width: $grid-lg) {
        width: auto;
        height: auto;
      }

      .text-long {
        p {
          text-align: center !important;
          color: $gray-10;
          background-color: transparent;
        }
      }
    }
  }
}
