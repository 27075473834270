.registration-sweepstakes {
  text-align: center;

  .text-long {
    margin: 50px 0;

    a {
      display: inline-block;
      padding: 5px;
    }
  }

  .review-section {
    img {
      margin: 15px auto;
    }
  }
}
