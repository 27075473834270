// Grid Item With Divider
@mixin grid-divider($columns: '') {
  flex-grow: 0;

  @include large {
    margin-right: 3.5%;
    position: relative;

    &::after {
      background-color: $gray-60;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }

  // Columns
  @if $columns == 2 {
    &::after {
      right: -3.5%;
    }

    &:nth-child(2n) {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  @if $columns == 3 {
    &::after {
      right: -5.5%;
    }

    &:nth-child(3n) {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &:last-child {
    @include large {
      margin-right: 0;
    }

    &::after {
      display: none;
    }
  }
}

.grid__item {
  .grid--divider & {
    @include grid-divider;
  }

  .grid--divider.grid--columns-2 & {
    @include grid-divider(2);
  }

  .grid--divider.grid--columns-3 & {
    @include grid-divider(3);
  }
}
