.upload-wrapper {
  padding: 1.5rem;
  background-color: $gray-90;
}

.upload-input {
  position: relative;
}

.upload-input__native {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.upload-input__label {
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: $gray-50;

  &::before {
    display: inline-block;
    margin-right: 16px;
    padding: 0.75rem 30px;
    font-family: $font-bold;
    font-size: $font-size-base;
    line-height: 1.5;
    letter-spacing: 2px;
    color: $gray-10;
    text-transform: uppercase;
    background-color: $gray-70;
    border-radius: 2px;
    content: attr(data-label);
  }
}

.upload-input__status {
  font-family: $font-bold2;
  font-weight: 700;
  color: $gray-10;
}
