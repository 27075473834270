.pl {
  .patternLink {
    color: $black;
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }

  .sg-pattern {
    clear: left;
  }

  .icons-demo {
    display: grid;
    grid-gap: 48px 24px;
    grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
    margin-left: 30px;
    margin-right: 30px;

    & > div {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 128px;
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.12) 0 1px 2px;
    }

    p {
      position: absolute;
      bottom: -30px;
      font-size: 15px;
      margin: 0;
    }

    //.icon {
    //  height: 1rem;
    //  width: 1rem;
    //}
  }

  .sg-heading-box {
    padding: 40px 24px;

    &--black {
      background-color: #000;
    }

    > * {
      margin-bottom: 40px;
    }
  }

  [id^="organisms-product-tile"] {
    // .product-tile {
    //   width: 335px;

    //   @include medium {
    //     width: 260px;
    //   }
    // }

    .product-tile {
      width: 375px;

      @include medium {
        width: 390px;
      }
    }

    .product-tile--repair {
      @include medium {
        width: 330px;
      }
    }
  }
}
