.page-registrationsweeps {
  .layout__region.layout__region--second {
    padding: 0 0 0 30px;

    @include maxbp($large) {
      padding: 30px 0 0;
    }

    h3 {
      padding: 0 15px 0 0;
    }
  }

  .sweep-h3.u-text-base {
    font-family: $font-body;
    font-weight: 100;
    color: white;
  }

  .sweep-h2.h2 {
    font-family: $font-body;
    color: white;
  }

  @include breakpoint($r-1100) {
    .featured-banner-content {
      max-width: $max-width-text !important;
    }

    .sweep-h3.u-text-base {
      font-size: 26px !important;
      color: white;
    }

    .sweep-h2.h2 {
      color: white;
      font-size: 60px !important;
    }

    .sweep-banner-text {
      font-size: 21px;
    }
  }

  @include maxbp($large) {
    .layout.layout--twocol-section.layout--twocol-section--67-33 {
      display: block;
      padding: 0 20px;
    }

    table {
      width: 600px;
      margin: auto;
    }

    .dialog-off-canvas-main-canvas {
      overflow: visible !important;
    }
  }
}
