.di-prod-slider {
  @include wrapper;

  display: block;
  overflow: hidden;
  position: relative;

  &::before {
    background: linear-gradient(to top, rgba(41, 41, 41, 0.5) 0, #292929 75%, rgba(41, 41, 41, 0.1) 90%, #292929 95%);
    background: -ms-linear-gradient(top, rgba(41, 41, 41, 0.5) 0, #292929 75%, rgba(41, 41, 41, 0.1) 90%, #292929 95%);
    background: -webkit-linear-gradient(top, rgba(41, 41, 41, 0.5) 0, #292929 75%, #292929 90%, #292929 95%);
    background: linear-gradient(to top, rgba(41, 41, 41, 0.5) 0, #292929 75%, #292929 90%, #292929 95%);
    background: -moz-linear-gradient(top, rgba(41, 41, 41, 0.5) 0, #292929 75%, rgba(41, 41, 41, 0.1) 90%, #292929 95%);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slick-arrow {
    top: 0;
    bottom: 0;
    margin: auto;

    &.slick-prev {
      left: -60px;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ffffff' d='M17.2660028,24L18.6315789 22.5310873 8.99548257 12 18.6315789 1.6938288 17.2660028 0 6 12z'/%3E%3C/svg%3E"), "#", "%23");
    }

    &.slick-next {
      right: -60px;
      background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#ffffff' d='M7.36557613,0L6 1.46891274 15.6360964 12 6 22.3061712 7.36557613 24 18.6315789 12z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }

  .di-prod-slider-title {
    margin: $space-ten*8 0 $space-ten*12;
    position: relative;
    text-align: center;

    h2.h2 {
      text-transform: none;
      color: $white;
      font-weight: normal;
    }
  }

  .di-prod-slider-desc {
    width: 75%;
    margin: 0 auto $space-ten*8;
  }
}
