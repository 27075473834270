.pager__items {
  @include list-reset;

  display: flex;
  align-items: center;
  font-size: $font-size-lg;
}

.pager__items .pager__item {
  list-style: none;
}

.pager__item {
  & + & {
    margin-left: 4px;
  }
}

.pager__link,
.pager__link:link,
.pager__link:visited {
  display: block;
  color: $gray-10;
  text-decoration: none;
  width: 46px;
  height: 46px;
  line-height: 46px;
  background-color: $gray-70;
  border-radius: 2px;
  text-align: center;
  border: 0 none;

  &:hover {
    background-color: $gray-60;
  }

  &.is-active {
    background-color: $delta-red;
    color: $white;
  }
}

.pager__link--first,
.pager__link--next,
.pager__link--prev,
.pager__link--last {
  span {
    display: none;
  }
}

.pager__link--first::before {
  content: "<<";
}

.pager__link--prev::before {
  content: "<";
}

.pager__link--next::before {
  content: ">";
}

.pager__link--last::before {
  content: ">>";
}
