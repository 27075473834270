.sub-menu-wrapper {
  @include wrapper;

  ul.sub-menu {
    @include list-reset;

    //border-bottom: 1px solid $gray-70;
    display: flex;
    justify-content: center;
    align-items: normal;
    text-align: center;
    margin: 0;
    padding: 0;

    @include maxbp($grid-lg) {
      display: block;
    }

    li {
      border-right: 2px solid $white;
      background: $gray-80;
      position: relative;
      display: flex;

      &::before {
        background: $gray-80;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: -1;
        transition: all ease 0.5s;
        width: 100%;
      }

      &:hover {
        color: $white;

        &::before {
          width: 100%;
          background: $delta-red;
        }
      }

      a {
        //background: $gray-80;
        text-transform: uppercase;
        color: $gray-10;
        padding: $space-ten $space-ten*4;
        display: flex;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 2px;
        font-family: $font-bold;
        position: relative;
        z-index: 2;
        text-align: center;
        align-items: center;

        @include maxbp($grid-lg) {
          padding: $space-ten $space-ten*2;
        }

        &::before {
          background: $gray-80;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          z-index: -1;
          transition: all ease 0.5s;
          width: 100%;
        }

        &:hover {
          color: $white;

          &::before {
            width: 100%;
            background: $delta-red;
          }
        }

        &.sub-menu-active {
          color: $white;

          &::before {
            background: $gray-10;
          }
        }
      }

      @include maxbp($grid-lg) {
        display: block;
        margin: 0 0 2px;
      }
    }
  }
}
