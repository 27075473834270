.pdp-section {
  @include wrapper;

  .product-tile {
    margin: 0 0 $space-ten + 5;
  }

  @include maxbp($r_600) {
    padding: 0;
  }

  @include maxbp($r_400) {
    [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
      margin: auto !important;
    }
  }

  .numeric-stepper {
    margin-bottom: 16px;
  }

  @include large {
    .pdp-top-section {
      padding: 50px 68px 0 !important;
    }
  }
}

.pdp-tabs {
  margin: 0 0 $space-ten * 7;
  padding: 0 $space-ten * 10 !important;

  @include maxbp($r-1100) {
    padding: 0 6px !important;
  }

  @include maxbp($medium) {
    margin: 0 0 $space-ten * 5;
  }

  @include maxbp($grid-md) {
    .pdp-install-block-title {
      .paragraph {
        display: block;
      }
    }

    .tabs__tab {
      display: none;
    }

    .tabs__tab.is-active {
      display: block;
      max-height: 100%;
    }
  }

  .bv-cv2-cleanslate .bv-core-container-391 .bv-mbox-inline .bv-mbox-close {
    z-index: 99 !important;
  }
}

.pdp-prod-listing {
  background: $gray-90;
  padding: $space-ten * 6 $space-ten * 10 $space-ten * 5 !important;
  margin: 0 0 $space-ten * 5;

  @include maxbp($large) {
    padding: $space-ten * 6 $space-ten * 2 $space-ten * 5 !important;
  }

  @include maxbp($medium) {
    background: none;
    padding: 0 $space-ten * 2 $space-ten * 5 !important;
  }
}

.pdp-listing-block {
  border-top: 1px solid $gray-70;
  margin: 0 0 32px;
}

//Related Products Issues
@include maxbp($grid-md) {
  .pdp-rp,
  .pdp-fp,
  .pdp-list-desc {
    .product-tile {
      justify-content: space-evenly;
      width: 100%;

      .no-line {
        display: block;
        width: 30%;
      }
    }

    .product-tile__body {
      width: 50%;

      .no-line {
        display: block;
        width: 100%;
      }
    }
  }
}

.partlist-pager {
  text-align: center;
  float: right;
}

.partlist-listing {
  clear: both;
}

/* Recommerce design updated */
.warranty-section {
  display: flex;
  align-items: center;
  border-top: 1px solid $gray-70;

  .warranty-logo {
    min-width: 85px;
    margin-left: 15px;

    @include maxbp($large) {
      display: none;
    }
  }
}

.sale-list-price {
  display: flex;

  @include maxbp($large) {
    justify-content: center;
  }

  .sale-price {
    margin-left: 45px;
  }

  .list-price {
    color: #b3b3b3;

    .pdp-prod-price {
      text-decoration: line-through;
      color: #b3b3b3;
    }
  }
}

.pdp-recom {
  [data-bv-show="rating_summary"] .bv_main_container {
    #ratings-summary {
      display: none !important;
    }

    .bv_main_container_row_flex > .bv_main_container_row_flex:first-child {
      display: none !important;
    }
  }
}

.warranty-description {
  font-size: 18px;

  .color-red {
    text-transform: uppercase;
    font-size: 12.6px;
    border-bottom: 0;
    font-family: $font-bold;
    color: $delta-red;
    text-decoration: none;
    background-image: url("../images/arrow-4-long.svg");
    background-repeat: no-repeat;
    background-position: right;
    padding-right: 20px;

    @include maxbp($large) {
      display: block;
      text-align: right;
    }
  }
}

.installation-support {
  .title-desc-link-image {
    padding-top: 60px !important;
    padding-bottom: 64px !important;

    h3 {
      text-transform: uppercase;
      font-size: 36.49px;
    }
  }
}

@include maxbp($large) {
  .pdp-section {
    .pdp-prod-buttons {
      .numeric-stepper {
        text-align: center;
        width: 100px;
        margin: 0 auto 33px;

        .quantity-err {
          margin-top: 4px;
          color: $error;
          font-style: italic;
          position: absolute;
          display: block;
          width: 300px;
          top: 35px;
          padding-left: 0;
          left: -100%;
        }
      }
    }
  }
}

.quantity-err {
  color: #f00;
  font-size: 16px;
  line-height: 19px;
  font-family: $font-oblique;
  padding-left: 25.5px;
  font-style: italic;
  margin-top: 5px;
}

.pdp-rating {
  [data-bv-show=rating_summary] .bv_main_container .bv_button_buttonMinimalist {
    font-size: 14.22px !important;
  }
}

@include maxbp($r-1100) {
  .pdp-top-section > .grid-col-sm-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.pdp-top-section {
  .slider_flag {
    display: block;
  }
}

.product-tile__overlay {
  .paragraph {
    margin-bottom: 4px;
  }
}
