.slick-dots li {
  list-style: none !important;
}

.single-image-slider-out-caption {
  max-width: $std_desktop;
  text-align: center;
  margin: auto;
  width: 70%;

  .slick-dots {
    padding: 0;
    margin: $space-ten/2 0;

    li {
      display: inline-block;
      margin: 0 $space-ten/2;
      line-height: normal;
      vertical-align: top;

      &.slick-active {
        button {
          background: $delta-red;
          border: 1px solid $delta-red;
        }
      }

      button {
        height: $space-ten;
        width: $space-ten;
        border: 1px solid #ccc;
        padding: 0;
        border-radius: 100%;
        background: none;
        font-size: 0;
        cursor: pointer;
        outline: none;
      }
    }
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      position: absolute;
      top: $space-ten*8;
    }

    &.slick-prev {
      left: -50px;
    }

    &.slick-next {
      right: -50px;
    }
  }

  .slider-img-block-wrapper {
    position: relative;

    .slider-img-title {
      h6.h6 {
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        font-size: 25px;
        margin: 0 0 $space-ten;
      }
    }

    .slider-img-caption {
      width: 96%;
      margin: $space-ten*2 auto 0;
    }

    .slider-img-link {
      a {
        text-transform: none;
        font-family: $font-body;
        letter-spacing: normal;
        padding: $space-ten/2 $space-ten*2 8px;
      }
    }
  }
}
