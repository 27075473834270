.single-image-slider {
  max-width: $std_desktop;

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &.slick-prev {
      left: $space-ten*3;
    }

    &.slick-next {
      right: $space-ten*3;
    }
  }

  .slider-img-block-wrapper {
    position: relative;

    .slider-img-title {
      h6.h6 {
        color: $white;
        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        font-size: 25px;
        margin: 0 0 $space-ten;
      }
    }

    .slider-img-caption {
      position: absolute;
      bottom: $space-ten*7;
      color: $white;
      left: $space-ten*15;
      width: 100%;
      max-width: 500px;
    }

    .slider-img-link {
      a {
        text-transform: none;
        font-family: $font-body;
        letter-spacing: normal;
        padding: $space-ten/2 $space-ten*2 8px;
      }
    }
  }
}
