// Responsive Video using CSS only
.video {
  height: 0;
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 56.25%; // 56.25% = 16x9
  position: relative;

  &--full {
    padding-bottom: 75%; // 75% = 4x3
  }

  iframe {
    //height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    //width: 100%;
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: auto;

  .video-with-src {
    width: 100% !important;
  }

  .video-image-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    background: url("../../../images/video-background.jpg") no-repeat center;
    background-size: cover;
    top: 0;
    bottom: 0;
    z-index: 100;
    transition: all ease 1s;
    opacity: 1;

    &::after {
      background: rgba(0, 0, 0, 0.3);
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 101;
    }

    &.hide_video_image {
      opacity: 0;
      z-index: -1;
    }
  }

  .video-title {
    color: $white;
    font-size: 21px;
    text-align: center;
    font-family: $font-bold;
    position: absolute;
    bottom: $space-ten * 2;
    left: 0;
    right: 0;
    z-index: 102;
  }

  .video-button {
    cursor: pointer;
    color: white;
    font-size: 100px;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    width: 105px;
    height: 105px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 102;
  }
}

// Home page video

.homepage_video {
  margin: 0;
  display: block;
  position: relative;
  overflow: hidden;

  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .video-title-container {
    position: absolute;
    left: 0;
    animation-duration: 0.25s;
    animation-delay: 0.3s;
    text-align: center;
    top: 35%;
    z-index: 300;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    display: block;

    h2 {
      color: $white;
      font-size: 3vw;
    }

    h5 {
      font-size: 1.2vw;
      font-weight: 800;
      color: #ac0535;
    }

    @include maxbp($medium) {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
      margin: 0 auto;

      h2 {
        font-size: 2rem;
      }

      h5 {
        font-size: 1.2rem;
      }
    }
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }

  .home-panel-3-video {
    background-color: #000;
    width: 100%;
    z-index: 100;
  }

  .home-button_playback {
    background: 0;
    border: 0;
    // width: 8vw;
    // height: 8vw;
    // margin: 2vw 0;
    margin: 10px 0;
    display: inline-block;
    color: $white;
    outline: none;
    cursor: pointer;

    .icon--play {
      height: 8vw;
      width: 8vw;
    }
  }

  .backgroundimage {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay-container {
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 200;
    opacity: 0.5;
    pointer-events: none;
  }
}

.dumpling-making .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.dumpling-making .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
