/// Link
$color-link: $black;
$color-link--hover: $gray-50;

@mixin sidebarlink {
  color: $gray-10;
  text-decoration: none;
  font-family: $font-body;
  transition: all 0.3s ease;

  &:hover {
    color: $delta-red;
    border-bottom: 1px solid $delta-red;
    font-family: $font-bold2;
  }
}

.sidebarlink {
  @include sidebarlink;

  &--default {
    font-family: $font-body;
  }

  &--selected {
    color: $delta-red;
    font-family: $font-bold2;
  }

  &--bold {
    font-family: $font-bold2;
  }

  &--hover {
    color: $delta-red;
    border-bottom: 1px solid $delta-red;
    font-family: $font-bold2;
  }

  &--level-1 {
    font-size: 1.125rem;
    line-height: 27px;
  }

  &--level-2 {
    font-size: 1rem;
  }
}

.menu-list {
  ul {
    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// mediaroom / demo overrides
.side-bar-menu {
  ul.menu {
    padding: 0;
  }

  .menu--sub-1 {
    margin-left: 1rem;
    margin-top: 16px;
  }

  .menu__item {
    margin-bottom: 16px;

    .menu__link {
      font-size: 1.125rem;
      line-height: 27px;
      text-decoration: none;
      font-family: $font-body;
      font-weight: bold;

      &:hover {
        color: $delta-red;
        font-family: $font-bold2;
        // border-bottom: 1px solid #ac0535;
      }
    }

    &.menu__item--active {
      > a {
        color: $delta-red;
        font-family: $font-bold2;
      }

      &:hover {
        color: $delta-red;
        font-family: $font-bold2;
        border-bottom: 1px solid #ac0535;
      }
    }
  }

  .menu__item--sub {
    a {
      font-weight: 300 !important;
    }
  }
}

.award-info {
  img {
    max-width: 300px;
    float: right;
    margin: 0 0 2em 2em;
  }

  &::after {
    content: " ";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.page-node-type-awards,
.node--type-awards {
  .left-sidebar {
    min-width: 20%;

    .menu {
      .menu__item {
        margin-bottom: 15px;

        a {
          padding: 10px 15px;
          text-decoration: none;
        }

        &.menu__item--active a {
          color: $delta-red;
        }
      }
    }
  }
}

#block-awardsintroduction {
  margin-bottom: 30px;
}

.pr-contact {
  margin: 40px 0;
}

.pdp-btn-addcart label.form-item__label {
  display: none;
}

.pdp-btn-addcart .js-form-item.form-item {
  display: none;
}

.pdp-btn-addcart a.button > div:first-child > div:first-child {
  display: none;
}

.pr-canada-detail {
  margin-top: 40px;
}

.press-release {
  padding-top: 0;
}

.layout.layout--onecol {
  ul.tabs__nav {
    margin-bottom: 20px;
    border: none;
  }
}

.page-node-type-press-release {
  .embedded-entity img {
    float: right;
    padding: 20px 0 20px 20px;
  }
}

.page-node-type-landing-page {
  .left-sidebar {
    min-width: 250px;
  }

  .layout__region {
    padding: 10px;
  }
}

.media-room-sidebar {
  .menu__item.menu__item--active:hover {
    border-bottom: 0;
  }
}
