.our_Order_table {
  background-color: $gray-90;

  .title,
  .value {
    padding: 15px 28px;
    text-align: left;
  }

  .value {
    border-top: 1px solid $gray-70;
  }

  .title {
    font-family: $font-bold2;
    font-size: 1.125rem;
  }

  td:last-child {
    .title {
      padding-top: 42px;
    }
  }
}

@include maxbp($medium) {
  .order_status_section {
    .track_orders_wrap .track_order,
    .track_orders_wrap .customer-service {
      padding: 0;
      max-width: 100%;
    }
  }

  .our_Order_table {
    table,
    td {
      width: 100%;
    }

    td {
      display: block;
      padding: 15px;
    }

    .value {
      border: 0 none;
    }

    .title,
    .value {
      padding: 3px 10px;
    }

    td:last-child {
      .title {
        border-top: 1px solid $gray-70;
        padding-top: 20px;
      }
    }
  }

  .track_orders_wrap {
    .iconbox_title {
      padding: 0;
    }
  }
}
