.for-professional-product-resources {
  .layout__region.layout__region--content {
    text-align: center;
    max-width: $max-width-text;
    margin: 0 auto;
    width: 100%;

    > div {
      &.pr-top-section,
      &.pr-bottom-section {
        width: 32.5%;
        display: inline-block;
        padding: 0 0.5%;

        @include maxbp($medium) {
          width: 96%;
          display: block;
          margin: auto;
          text-align: center;
        }

        .catalog-grid {
          margin: $space-ten*5 0 $space-ten*3;

          @include maxbp($medium) {
            margin: $space-ten*2 0 0;
          }

          .catalog-grid__item {
            width: 100%;

            .title-desc-link-image {
              padding: 0 !important;
              display: flex;
              flex-wrap: wrap;
              text-align: left;

              > div {
                width: 100% !important;
              }

              .tdli-title {
                h3.h3 {
                  font-weight: normal;
                  letter-spacing: 0;
                  margin: 10px 0;
                  font-family: $font-body;
                }
              }

              .tdli-desc {
                margin: 0 0 15px;

                p {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      &.pr-top-section {
        .catalog-grid {
          .catalog-grid__item {
            .title-desc-link-image {
              .tdli-image {
                order: 1;
              }

              .tdli-title {
                order: 2;

                h3.h3 {
                  font-size: 24px;
                }
              }

              .tdli-desc {
                order: 3;
              }

              .tdli-more {
                order: 4;
              }
            }
          }
        }
      }

      &.pr-bottom-section {
        .catalog-grid {
          margin: 0 0 $space-ten*3;

          .catalog-grid__item {
            .title-desc-link-image {
              .tdli-title {
                h3.h3 {
                  font-size: 20px;
                }
              }

              .tdli-more {
                display: none;
              }

              .tdli-desc {
                img {
                  width: 60px;
                  float: left;
                  margin: 0 $space-ten 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
