.innovation-glassrinser {
  .section-lead-in {
    text-align: center;
    background: black;
    padding: 30px;

    .glassrinser-gif {
      margin: auto;
    }
  }
}
