// Tab Navigation
.tabs__nav {
  @include list-reset;

  //border-bottom: 1px solid $gray-70;
  display: flex;
  justify-content: center;
  align-items: normal;

  @include maxbp($grid-sm) {
    display: none;
  }

  @include maxbp($medium) {
    display: none;
  }

  li {
    border-right: 2px solid $white;
    background: $gray-80;
    position: relative;
    display: flex;

    &::before {
      background: $gray-80;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: -1;
      transition: all ease 0.5s;
      width: 100%;
    }

    &:hover {
      color: $white;

      &::before {
        width: 100%;
        background: $delta-red;
      }
    }
  }
}

// .tabs__nav:last-child {
//   li {
//     border-right: 0;
//   }
// }

// Tab Content (hidden only in full #tabs js version)
.tabs__tab {
  @include breakpoint($medium) {
    display: none;

    &.is-active {
      display: block;
    }
  }

  @include maxbp($medium) {
    height: 0;
    overflow: hidden;
    max-height: 0;
    transition: all ease 0.7s;
    margin: 0;

    &.is-active {
      height: auto;
      overflow: visible;
      max-height: 200000px;
    }
  }
}

.tabs__content {
  padding: 0 $space-ten+5;
}

.tab-accordion-title {
  display: none;
  background: $gray-80;
  color: $gray-10;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: $space-ten $space-ten+5;
  cursor: pointer;
  border-bottom: 2px solid $white;
  position: relative;
  font-family: $font-bold;

  &::after {
    content: "+";
    position: absolute;
    right: 15px;
    font-size: 20px;
    line-height: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 14px;
  }

  &.active-tab-title {
    background: $gray-10;
    color: $white;

    &::after {
      content: "-";
      font-size: 30px;
      height: 7px;
      line-height: 3px;
    }

    &:hover {
      background: $gray-10;
      color: $white;
    }
  }

  &:hover {
    background: $delta-red;
    color: $white;

    @include maxbp($medium) {
      background: $gray-80;
      color: $gray-10;
    }
  }

  @include maxbp($medium) {
    display: block;
  }
}

// a11y tabs overrides
.a11y-paragraphs-tabs__wrapper {
  .tabs-allowed {
    .tabs-tab-list {
      margin-bottom: 20px;
    }

    .tabs-panel {
      border: none;
    }
  }

  .tabs-trigger {
    margin: 0;
    padding: 10px 40px;

    &.is-selected,
    &.is-selected:hover {
      background: black;
      border-bottom: none;
      color: white;
    }
  }

  .accordeon-trigger {
    background: #e6e6e8;
  }

  .accordeon-trigger[aria-expanded="true"] {
    background-color: black;
    color: white;
  }
}
