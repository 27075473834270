.sidebar-navigation {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  li + li {
    margin-top: 8px;
  }

  a {
    display: block;
    text-decoration: none;
    padding: 4px 4px 4px 12px;

    &:hover {
      background-color: $gray-90;
    }

    &.is-active {
      color: $delta-red;
      background-color: $gray-90;
    }
  }
}
