.text-image-block {
  max-width: 400px;

  .text-img-block-title {
    a {
      text-decoration: none;
    }

    h5.h5 {
      text-transform: none;
      margin: 0 0 $space-ten/2;
      font-weight: bold;
      font-family: $font-body;
      letter-spacing: normal;
      font-size: 18px;
    }
  }

  .text-img-block-desc {
    margin: $space-ten 0 $space-ten+2;
    font-size: 14px;

    p {
      margin: 0;
    }
  }

  .text-img-block-more {
    a {
      padding: 7px $space-ten*2;
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
      font-family: $font-body;
    }
  }

  .text-img-block-cta-more {
    a {
      text-transform: capitalize;
      font-family: $font-body;
      letter-spacing: 1px;
      font-size: 15px;
    }
  }
}
