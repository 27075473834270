.product-tile {
  position: relative;
  display: flex;
  font-size: $font-size-base;
  padding-bottom: 16px;
  padding-top: 16px;
  border: 1px solid transparent;
  border-bottom: 1px solid $gray-70;

  .no-line {
    @include maxbp($grid-md) {
      display: flex;
    }
  }

  @include breakpoint($grid-md) {
    display: block;
    padding-bottom: 48px;
    font-size: $font-size-lg;
    text-align: center;
    transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
    border-color: transparent;
    overflow: hidden;
    padding-top: 0;

    &:hover {
      border: 1px solid $gray-80;
      border-radius: 2px;
      background-color: $white;
      box-shadow: 8px 7px 20px 0 rgba(24, 25, 29, 0.07);
    }
  }
}

.product-tile__image {
  display: block;
  max-width: 110px;
  margin-bottom: 32px;
  flex-shrink: 0;

  @include breakpoint($grid-md) {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    padding: 18px 18px 0;
  }
}

.product-tile__body {
  margin-left: 16px;

  @include breakpoint($grid-md) {
    margin-left: 0;
    padding: 0 20px;
  }
}

.product-tile__title {
  font-size: $font-size-sm;
  line-height: 1.5;

  a {
    text-decoration: none;
  }
}

.product-tile__body p {
  margin-bottom: 0.4em;
}

.product-tile__body .flag {
  margin-bottom: 1rem;

  @include breakpoint($grid-md) {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;
  }
}

.product-tile__sku {
  display: block;
  font-family: $font-bold;
  font-size: $font-size-xs;
  color: $gray-50;
  letter-spacing: 2px;

  @include breakpoint($grid-md) {
    font-size: $font-size-sm;
  }
}

.product-tile__price {
  display: block;
  font-size: $font-size-sm;
  color: $gray-30;
  margin: 10px 0;
}

.product-tile__overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 55px 12px 0;
  background-color: rgba($white, 0.95);

  @include breakpoint($grid-md) {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease-out;

    .product-tile:hover & {
      opacity: 1;
    }
  }
}

.product-tile__overlay .star-rating {
  margin-left: auto;
  margin-right: auto;
}

.product-tile__cta {
  margin: 32px 0;
}

.product-tile__cta .button {
  display: block;
  width: 100%;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.product-tile__cta .button + .button {
  margin-top: 2px;
}

.product-tile__overlay .cta-link {
  line-height: 18px;

  .icon {
    margin-right: 4px;
  }
}

//
// Repair Variant
//
.product-tile--repair {
  @include maxbp($grid-md) {
    padding-top: 12px;
  }

  &:hover {
    border: 1px solid transparent;
    background-color: transparent;
    box-shadow: none;
  }

  p {
    font-size: $font-size-sm;
    font-family: $font-oblique;
    font-style: italic;
  }

  .product-tile__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-tile__image {
    max-width: 120px;
    margin-bottom: 0;

    @include breakpoint($grid-md) {
      max-width: 160px;
      margin-bottom: 32px;
    }
  }
}

//
// Collection variant
//
.product-tile--collection {
  background: linear-gradient(to bottom, $white, $gray-90);
  padding: 24px 0;

  ul {
    max-height: 280px;
    overflow-y: scroll;
    margin: 0;
  }

  .product-tile__innovations .h6 {
    font-weight: normal;
    font-size: 15px;
  }

  .product-tile__innovations li {
    font-size: 16px;
    list-style: none !important;
  }

  @include maxbp($grid-md) {
    border-bottom: 0;
  }

  @include breakpoint($grid-md) {
    background: none;
    padding: 0 0 32px;
  }

  .product-tile__image {
    max-width: 180px;
    mix-blend-mode: multiply;
    margin-bottom: 0;

    @include breakpoint($grid-md) {
      margin-bottom: 32px;
      max-width: 330px;
    }
  }

  .product-tile__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-tile__overlay {
    text-align: left;

    @include breakpoint($grid-md) {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .product-tile__cta {
    margin: 24px 0 0;
  }
}

.product-tile__innovations {
  h6 {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
    margin-left: 19px;
  }

  ul {
    //height: 200px;
    height: 335px;
    overflow-y: auto;
  }

  li {
    text-align: left;
    list-style: none !important;
  }
}

.product-tile__innovation-list {
  margin: 0 0 24px;
  padding: 0;
  font-size: $font-size-base;

  li {
    display: block;
    margin: 4px 0;
  }

  a {
    color: $gray-10;
    text-decoration: none;
  }
}

@media (min-width: 767px) {
  .product-tile {
    font-size: 1.125rem;
  }
}
