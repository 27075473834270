.node--type-innovation-detail-page {
  .innovation-navigation {
    margin: 50px 0 100px 0;

    @include maxbp($medium) {
      margin: 0 0 50px;
    }
  }

  #Products .section-lead-in {
    margin: 80px 0;
  }

  .innovation-image-grid {
    justify-content: space-around;
  }

  .layout--twocol-section {
    align-items: center;
    text-align: center;

    .text-long {
      padding: 10px;

      a {
        display: inline-block;
      }
    }
  }

  @media (max-width: $large) {
    img {
      margin: 0 auto;
    }

    .layout--twocol-section {
      flex-direction: column;

      .text-long {
        margin-top: 40px;
      }
    }
  }
}

.innovation-voiceiq {
  .home-intro-banner {
    max-width: 1440px;
    margin: 0 auto;

    video {
      width: 100%;
    }
  }

  @include medium {
    .modal--faq {
      padding: 52px 64px 64px !important;
    }
  }

  .modal--faq .modal--faq__figure {
    width: auto;
  }

  .innovation-faq {
    ul {
      padding: 0;
    }
  }

  .faq-card__answer p + ul li {
    list-style: circle;
    margin-left: 12px;
  }
}

.page-voice-iq {
  .innovation-image-grid {
    display: block;
    text-align: center;

    .voice-box-img {
      width: 33%;
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      @include maxbp($r-1100) {
        width: 30%;
      }
    }

    img {
      display: inline-block;
    }
  }

  .views-element-container {
    max-width: none;
  }
}
