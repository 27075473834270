.innovation-mod-shower-door {
  .layout--twocol-section {
    .section-lead-in.paragraph-component.left {
      text-align: left;

      .button.button--primary {
        margin: 20px 0 0;
      }
    }
  }

  .layout.layout--twocol-section.layout--twocol-section--50-50 {
    padding: 40px 0;
  }

  .slider-img-block {
    text-align: center;

    img {
      margin: auto;
    }
  }
}
