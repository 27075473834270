.conceptdesign-page {
  .catalog-grid {
    @include maxbp($grid-lg) {
      margin-bottom: 10px;
      padding: 0 10px;
    }

    .catalog-grid__item {
      width: 100%;
      margin: 0;

      p {
        margin: 0;
      }
    }

    .title-desc-link-image {
      text-align: left;
      padding: 0 !important;

      .tdli-image {
        @include maxbp($grid-lg) {
          text-align: center;
        }

        a {
          cursor: auto;

          @include maxbp($grid-lg) {
            margin: auto;
            display: inline-block;
          }
        }
      }

      .tdli-more .button {
        @include maxbp($grid-sm) {
          white-space: inherit;
          padding: 10px;
        }
      }

      .tdli-title h3 {
        font-size: 30px;
        letter-spacing: 1px;
        margin: 10px 0;
      }

      .tdli-desc {
        width: 100%;
      }
    }
  }

  .layout__region.layout__region--first {
    &.layout__region--first {
      padding: 0 40px 0 0;

      @include maxbp($grid-lg) {
        padding: 0;
      }

      .catalog-grid .title-desc-link-image .tdli-title h3 {
        margin: 0;
      }
    }
  }

  .layout.layout--twocol-section.layout--twocol-section--50-50 {
    max-width: 1100px;
    margin: auto;
    display: block;

    .layout__region {
      display: inline-block;
      width: 49%;
      vertical-align: top;

      @include maxbp($grid-lg) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .featured-banner-content {
    p {
      margin: 0;
    }

    .featured-banner__title {
      font-size: 37px;
      font-family: $font-bold;
      max-width: 400px;
      width: 100%;
      line-height: 40px;

      p {
        margin: 0;
      }

      strong {
        text-transform: none;
        font-family: $font-bold;
        font-weight: normal;
      }
    }

    .button.button--primary {
      margin: 15px 0 0;
      padding: 7px 30px;
      text-transform: none;
      letter-spacing: normal;
      font-family: $font-roman;
    }
  }

  .video.paragraph-component {
    margin: 0 0 50px;
  }

  .design-revisit-block {
    margin: 60px auto 0;
    max-width: 850px;

    strong {
      font-family: $font-bold;
      font-weight: normal;
    }
  }

  .design-related-article {
    margin: 15px 0 0;

    @include maxbp($grid-lg) {
      text-align: left;
      margin: 15px 15px 0;
    }

    h2 {
      text-transform: none;
      font-weight: normal;
      letter-spacing: 0;
      font-size: 35px;
      margin: 0 0 25px;
    }

    a {
      color: $delta-red;
    }
  }
}
