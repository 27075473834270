.img_element {
  display: inline-block;

  .figure__link {
    position: relative;
  }
}

.arrowlink {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  background-color: $delta-red;
  padding: 0.75em 0.625em 0.563em;
  display: block;

  &--right {
    right: 0;
    left: inherit;
  }
}

.figure {
  &--zoom {
    a {
      overflow: hidden;
      display: block;

      img,
      .arrowlink {
        transition: all 0.5s;
      }

      .arrowlink::before {
        content: "\e934";
        color: $white;
        font-family: $font-icomoon;
        font-size: 1.5rem;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }

        .arrowlink {
          transform: scale(1.5) translateX(16%) translateY(-17%);

          &--right {
            transform: scale(1.5) translateX(-16%) translateY(-17%);
          }
        }
      }
    }

    .figure__caption {
      color: $gray-30;
      font-size: 0.8875rem;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}
