.professionals-training {
  .layout--twocol-section {
    margin: 40px auto;
    flex-wrap: nowrap;
    max-width: 1335px;

    @include maxbp($medium) {
      display: block;
    }

    .layout__region {
      margin: 0 auto;
      padding: 20px;

      p > img {
        display: inline;
      }

      @include maxbp($medium) {
        text-align: left;
      }

      &.layout__region--second {
        .content {
          a,
          .button.button--primary {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @include maxbp($medium) {
    .button.button--primary {
      display: block;
      white-space: initial;
    }

    h2 {
      text-align: left;
      font-weight: normal;
      font-size: 30px;
      letter-spacing: normal;
      margin: 10px 0 5px;
    }
  }
}
