.pdp-list-ttl-wrapper {
  display: block;
  overflow: hidden;

  .pdp-list-title {
    float: left;

    h3.h3 {
      font-size: 33px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .pdp-coll-link {
    float: right;
  }
}
