.page-title {
  font-family: $font-light;
  font-size: 2.5625rem;
  font-weight: 300;
  letter-spacing: 0;
  text-transform: none;
  color: $gray-10;

  @include medium {
    font-size: 3.653125rem;
  }
}

.dark-theme {
  .page-title {
    color: $white !important;
  }
}

.text-centered {
  text-align: center;
}
