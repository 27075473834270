.pdp-rough-value,
.pdp-handle-accent {
  .rv_hide_opn,
  .ha_hide_opn {
    display: none;
  }

  .rv_show_opn,
  .ha_show_opn {
    display: block;
  }

  .pdp-listing-variation-desc:not(:first-child) {
    overflow: hidden;
    max-height: 0;
    transition: all ease 0.2s;
    margin: 0;
  }

  &.more-rough-value,
  &.more-handle-accent {
    .rv_hide_opn,
    .ha_hide_opn {
      display: block;
    }

    .rv_show_opn,
    .ha_show_opn {
      display: none;
    }

    .pdp-listing-variation-desc:not(:first-child) {
      max-height: 1000px;
      transition: all ease 1s;
      margin: 0 0 $space-ten+5;
    }
  }
}
