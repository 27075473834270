.designBeyond-bobbyBerk {
  .container-fluid.media-text-space {
    margin: 30px auto;
    width: 60%;
  }

  .innovation-secondary-banner {
    background-size: auto !important;
  }

  p.bobby-berk-text {
    margin: 0 auto 45px;
    width: 96%;
    max-width: 700px;
  }

  .section-lead-in.paragraph-component.center {
    text-align: center;
  }
}
