.issue-type-tool-wrapper {
  position: relative;
  margin: 40px 0;

  @include maxbp($old-desktop) {
    margin: 40px 0;
  }

  &::before {
    content: "";
    background: linear-gradient($gray-90, $white);
    position: absolute;
    height: 100%;
    width: 4000px;
    left: -2000px;
    z-index: -1;
    padding: 50px 0;
    margin-top: -40px;
  }

  .catelog-four-grid {
    @include maxbp($old-desktop) {
      padding: 0;
      justify-content: space-between;
    }

    .catelog-fg-block {
      padding: 0;
      margin-bottom: 30px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: -23px;
      }

      .content-card {
        max-width: calc(100% - 23px);

        @include maxbp($old-desktop) {
          max-width: 100%;
        }

        .content-card__image {
          max-height: 326px;
          height: auto;
          width: auto;

          @include maxbp($old-desktop) {
            width: 100%;
            height: auto;
          }
        }

        .content-card__title {
          font-size: 1.125rem;

          @include maxbp($grid-lg) {
            font-size: 1rem;
          }
        }
      }

      @include maxbp($old-desktop) {
        margin-bottom: 24px !important;
        flex: 0 0 50%;
        max-width: 50%;
        flex-basis: calc(50% - 8px);

        &:nth-child(odd) {
          margin: 0 16px 16px 0 !important;
        }

        &:nth-child(even) {
          margin: 0 0 16px 0 !important;
        }
      }
    }

    .catelog-fg-block:not(:first-child):not(:last-child) {
      margin: 0 auto;
      padding: 0;
    }
  }

  .selected_room.content-card {
    .content-card__title {
      padding: 16px 5px;
      background-color: $delta-red;

      @include maxbp($old-desktop) {
        padding: 8px 5px;
      }

      &:hover,
      &.is-active {
        @include maxbp($old-desktop) {
          padding: 8px 5px;
        }
      }
    }
  }

  .issue-header {
    display: none;

    h4 {
      font-family: $font-bold2;
      text-transform: capitalize;
      letter-spacing: -0.4px;
      margin: 24px 0 32px 0;

      @include maxbp($old-desktop) {
        font-size: 1.42375rem !important;
        margin: 24px 0 22px;
      }
    }
  }

  .issue-wrapper {
    div[class^="issue-block-"] {
      display: none;
    }

    .display_selected_room {
      display: block !important;
    }

    .issue-block_container {
      margin-bottom: 7px;
    }

    .issue_head {
      font-size: 1.42rem;
      font-family: $font-bold;
      text-transform: uppercase;

      &::after {
        content: '\002B';
        height: 17px;
        font-size: 22px;
        font-family: $font-light;
      }

      &.is-active {
        background: $gray-80;
        color: $gray-10;

        &::after {
          content: '\2012';
          height: 6px;
        }
      }
    }

    .issue_parts {
      margin: 0;
    }

    .issue_parts-wrapper {
      display: none;
      margin-bottom: 40px;
      border-left: 1px solid $gray-70;
      border-right: 1px solid $gray-70;
      border-bottom: 1px solid $gray-70;

      .issue_resource {
        background-color: $gray-90;
        padding: 8px 15px 26px 15px;
        border-bottom: none;
        border-right: 1px solid $gray-70;

        @include maxbp($old-desktop) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        h6,
        a {
          font-size: 1.125rem;
          letter-spacing: 0.2px;
          text-transform: none;
          margin-bottom: 16px;
        }

        h6 {
          font-family: $font-bold2;
        }

        ul {
          list-style: outside;
        }

        @include maxbp($old-desktop) {
          h6,
          p,
          a,
          li {
            font-size: 1rem !important;
          }
        }
      }

      .issue_parts-container {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        background-color: $white;

        @include maxbp($old-desktop) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        [class*=grid-col] {
          text-align: center;
          padding: 36px;
          border-bottom: none;
          border-left: none;
          border-right: 1px solid $gray-70;
          background-color: $white;

          &:nth-child(4n) {
            border-right: none;
          }

          @include maxbp($big-desktop) {
            padding: 36px 24px;
          }

          @include maxbp($old-desktop) {
            padding: 16px;
            flex: 0 0 100%;
            max-width: 100%;

            span,
            p,
            a {
              font-size: 1rem !important;
            }

            img {
              max-height: 110px !important;
              min-height: 110px !important;
              max-width: 100% !important;
            }
          }

          @include maxbp($r-1100) {
            position: relative;
          }

          img {
            margin: auto;
            width: 100%;
            height: 100%;
            max-height: 215px;
            min-height: 215px;
            max-width: 100%;
            object-fit: contain;
          }
        }

        .issue_parts-partsname {
          font-size: 1.12rem;
          line-height: 1.32rem;
          margin-top: 32px;

          @include maxbp($old-desktop) {
            margin-bottom: 8px;
          }

          & + p {
            font-size: 0.875rem;
            line-height: 1rem;

            @include maxbp($r-1100) {
              margin-bottom: 38px;
            }

            @include maxbp($old-desktop) {
              margin: 8px 0;
            }
          }
        }

        .issue_parts-viewlink {
          display: inline-flex;

          @include maxbp($r-1100) {
            width: calc(100% - 72px);
          }

          @include maxbp($ipad-only) {
            width: 100%;
          }

          a.cta-link {
            position: relative;
            text-align: center;
            display: inline-flex;
            white-space: unset;
            margin: 0 auto;
            width: 100%;

            @include maxbp($big-desktop) {
              font-size: 0.875rem;
            }
          }

          .issue_parts-viewlinkText {
            position: relative;
            margin: 0 auto;
            width: 100%;
          }

          .itt_cta-arrow {
            content: "";
            background-size: 10px 10px;
            height: 10px;
            width: 10px;
            color: #ac0535;
            display: inline-block;
            background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='#ac0535' d='M9.5 6l-7 6V0z'/%3E%3C/svg%3E"), "#", "%23");
            background-repeat: no-repeat;
            margin-left: 4px;

            @include maxbp($big-desktop) {
              margin-left: 2px;
            }
          }
        }
      }
    }

    .accordion-term.is-active + .accordion-def + .issue_parts-wrapper {
      display: flex;

      @include maxbp($old-desktop) {
        flex-direction: column-reverse;
      }
    }
  }

  h4 {
    & + p {
      width: 60%;
      margin-top: 16px;
      margin-bottom: 32px;
      font-size: 1.125rem;

      @include maxbp($grid-lg) {
        width: 100%;
        font-size: 1rem;
      }
    }
  }
}
