.home-page {
  .paragraph-component .featured-banner--text-centered {
    height: 863px;
    justify-content: center;

    .featured-banner__title {
      p {
        margin: 0;
      }
    }

    &.last-component {
      align-items: center;
      justify-content: center;
    }
  }

  .intro-banner__subtitle::before {
    background-color: transparent;
  }

  .featured-banner {
    .grid-container {
      min-height: 100%;
    }
  }

  .hotspot-banner__content {
    display: block;
  }
}
