.pr-cat-collection-wrapper {
  .pr-right-title {
    h2.h2 {
      border: none !important;
      padding: 0 !important;
      margin: 0 0 $space-ten !important;
    }
  }

  .pr-right-desc {
    text-align: left !important;
  }

  .pr-cat-collection {
    font-family: $font-bold;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 $space-ten;

    @include maxbp($small) {
      font-size: 20px;
    }

    p {
      margin: 0;
    }
  }

  .pr-caat-coll-share {
    a {
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
      font-family: $font-body;
    }
  }
}
