// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function get-select-icon($color) {
  @return str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#{$color}' d='M14 5.83L12.6 4.5 8 8.85 3.4 4.5 2 5.83l6 5.67 6-5.67z'/%3E%3C/svg%3E"), "#", "%23");
}

.form-item--dropdown {
  .form-item__label > span {
    opacity: 0;
  }
}

.form-item__select {
  display: block;
  width: 100%;
  height: 56px;
  padding: 1rem 0.75rem;
  font-size: $font-size-lg;
  font-family: inherit;
  line-height: 1.5rem;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 0 1px $gray-60 inset;
  transition: box-shadow 0.15s ease-in-out;
  appearance: none;
  background: $white get-select-icon($gray-10) no-repeat right 0.75rem center;
  background-size: 20px 20px;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $gray-10 inset;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background: $gray-90 get-select-icon($gray-60) no-repeat right 0.75rem center;
    background-size: 20px 20px;
  }

  .form-item--error & {
    box-shadow: 0 0 0 2px $error inset;
  }
}

.form-item__select--sm {
  height: 40px;
  line-height: 24px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-size: 16px 16px;
}
