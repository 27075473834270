.phone_number {
  font-size: 1.375rem;
  font-family: $font-heading;
  color: $delta-red;
}

.link {
  &--textboxlink {
    line-height: 18px;
    font-size: 1.125rem;
    border-bottom: 1px solid $gray-10;
    text-decoration: none;
    margin-top: 0.75em;
    margin-bottom: 0.375em;
    display: table;

    &:hover {
      border-bottom: 1px solid $gray-50;
    }
  }
}

.textbox_title {
  font-size: 2.0275rem;
  font-family: $font-heading;
  letter-spacing: 0.5px;
  line-height: 36px;
  color: $gray-10;
  margin-bottom: 0;
}

.textbox_subtitle {
  font-size: 1.265625rem;
  line-height: 30px;
  text-transform: none;
}

.textbox__iconbox,
.textbox__links {
  ul {
    padding: 0;

    li {
      list-style: none;
      padding: 0;
    }
  }
}

.divider {
  border: 1px solid $gray-70;
  box-sizing: border-box;
  margin: 2em 0;
}

.textbox__content-small {
  font-size: 0.79rem;
}

.textbox_container,
.textbox-container {
  background-color: $gray-90;
  padding: 2em 1.5em 1.5em 1.5em;
}

.customer-service {
  text-align: left;

  .textbox_container + .textbox_container,
  .textbox-container + .textbox-container {
    margin-top: 30px;
  }
}
