.button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-family: $font-bold;
  font-size: $font-size-base;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 2px;
  padding: 0.75rem 3.125rem;
  border: 1px solid transparent;
  background-color: transparent;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  & .icon {
    margin-right: 8px;
  }

  // Disabled styles are the same on all variants, !important to make sure they're not overriden by :hover/:active
  &[disabled] {
    color: $gray-50 !important;
    background-color: $gray-70 !important;
    border-color: transparent !important;
    cursor: default !important;
  }
}

//
// Full-width
//

.button--block {
  display: block;
  width: 100%;
}

//
// Sizes
//

.button--lg {
  font-size: $font-size-lg;
  padding: 1.25rem 3.72rem;
}

.button--sm {
  font-size: $font-size-sm;
  padding: 0.5rem 3rem;
}

//
// Variants
//

.button--primary {
  background-color: $delta-red;
  color: $white;

  &:hover {
    background-color: $delta-merlot;
  }

  &:active {
    background-color: lighten($delta-merlot, 5%);
  }
}

.button--secondary {
  background-color: $gray-70;
  color: $gray-10;

  &:hover {
    background-color: $gray-50;
    color: $white;
  }

  &:active {
    background-color: darken($gray-50, 7%);
  }
}

.button--tertiary {
  background-color: $white;
  color: $delta-red;
  border-color: $delta-red;
  padding-left: 2rem;
  padding-right: 2rem;

  &:hover {
    background-color: $delta-red;
    color: $white;
  }

  &:active {
    background-color: lighten($delta-merlot, 5%);
  }
}

.button--icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $delta-red;
  color: $white;
  width: 72px;
  height: 72px;
  padding: 0;

  .icon {
    width: 45px;
    height: 45px;
    margin: 0;
  }
}
