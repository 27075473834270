.search-button {
  border-radius: 0;
  border: none;
  background: none;
  background: url("/themes/custom/delta_theme/images/icons/src/search.png") no-repeat;
  height: 24px;
  width: 24px;
  font-size: 0;
  cursor: pointer;
}
