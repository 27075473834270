.page-designtrustarchive {
  @include maxbp($r-1100) {
    .main-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .blog-card {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #ac0535;
    }

    &::after {
      content: none;
    }
  }

  .blog-card__content {
    position: relative;
    padding: 20px 0;
    color: #ac0535;

    .blog-card__category {
      text-transform: capitalize;
      font-style: italic;
      font-size: 20px;
      letter-spacing: 0;
    }
  }

  .layout--onecol {
    .grid-col-xl-6 {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 1024px) {
    .layout__region--first img {
      margin: 0 auto;
    }
  }

  @include maxbp(1000px) {
    .layout.layout--twocol-section.layout--twocol-section--33-67 .layout__region--second {
      padding: 0 0 0 40px;
      text-align: left;

      .h2 {
        text-align: left;
      }
    }
  }

  @include maxbp(630px) {
    .layout.layout--twocol-section.layout--twocol-section--33-67 .layout__region--second {
      padding: 0;

      .h2 {
        text-align: center;
      }
    }
  }
}
