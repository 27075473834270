.grid-container--clp {
  @include maxbp($grid-md) {
    margin-left: -24px;
    width: 100vw;
    padding: 0;
  }
}

.clp-header {
  text-align: center;
  margin: 16px 0;

  @include medium {
    margin: 32px 24px;
    font-size: $font-size-lg;
  }
}

.clp_subtitle {
  margin-top: 16px;
  font-size: $font-size-xxxl;

  @include medium {
    margin-top: 40px;
    font-size: 2.0275rem;
  }
}

@include maxbp($grid-md) {
  .collection-listing-grid-items,
  fieldset.product-tile {
    background: #f2f2f2;
    background: -moz-linear-gradient(top, #f2f2f2 0, #fff 15%, #fff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f2f2f2), color-stop(15%, #fff), color-stop(100%, #fff));
    background: -webkit-linear-gradient(top, #f2f2f2 0, #fff 15%, #fff 100%);
    background: -o-linear-gradient(top, #f2f2f2 0, #fff 15%, #fff 100%);
    background: -ms-linear-gradient(top, #f2f2f2 0, #fff 15%, #fff 100%);
    background: linear-gradient(to bottom, #f2f2f2 0, #fff 15%, #fff 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#ffffff', GradientType=0);
    border: 0 none;

    .product-tile__title {
      a {
        text-decoration: none;
      }
    }
  }

  .collection-listing {
    padding: 0;

    .grid {
      padding: 0;

      li {
        padding: 0;
      }
    }

    fieldset.product-tile {
      width: 100%;
      display: table;

      > div {
        display: table-cell;
        width: 50%;
        vertical-align: middle;
      }

      .product-tile__image {
        max-width: 179px;
      }

      .product-tile__title {
        margin-left: 25px;
      }
    }
  }

  .page-node-type-landing-page .layout__region {
    padding: 0;
  }
}

.kitchen-collection,
.bath-collection {
  .banner-block.image {
    .grid-container {
      display: table-cell;
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include medium {
    .main {
      padding-top: 0;

      [role="alert"] {
        ul {
          margin: 0;
        }
      }
    }
  }
}

.kitchen-collection {
  .banner-block.image {
    height: 206px;
  }
}

.bath-collection {
  .banner-block.image {
    height: 254px;
  }
}

.collection-listing-grid-items {
  .product-tile__body {
    text-align: center !important;
  }

  .product-tile__innovations {
    padding: 20px 10px;

    .product-tile__cta {
      position: absolute;
      width: 96%;
      left: 0;
      right: 0;
      margin: 10px auto !important;
      bottom: 0;
    }

    li {
      text-align: left;
      font-size: 16px;
      list-style: none !important;
    }

    .product-tile {
      padding: 50px 20px;
    }

    h6 {
      font-weight: normal;
      font-size: 15px;
    }
  }
}

#collection-gallery-modal {
  .modal--collection-gallery {
    top: 50px;
    max-width: 900px !important;
    //max-height: 500px !important;

    @include maxbp($grid-md) {
      width: 90% !important;
    }

    @include maxbp($large) {
      top: 100px;
    }
  }
}
