.case-study-grid {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.case-study-card {
  position: relative;
  background: linear-gradient(to bottom, $gray-90, $white);
  padding: 32px 0 64px;
  display: inline-block;
  min-width: 300px;

  @supports (display: grid) {
    min-width: auto;
    display: block;
  }
}

.case-study-card__img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.case-study-card__title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 12px 16px;
  background-color: $gray-90;
}

.case-study-card__download {
  position: absolute;
  top: 16px;
  right: 16px;
  color: $delta-red;
  text-decoration: none;
  text-align: center;
}

.case-study-card__download-size {
  display: block;
  color: $gray-10;
  font-size: $font-size-xs;
}
