// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

.delta-red {
  background-color: $delta-red;
}

.icon__dafault {
  font-size: 1.75rem;
  padding: 0.125em;
  border-radius: 50%;
  line-height: 1.2;

  &-medium {
    font-size: 3.5rem;
    border-radius: 50%;
  }

  &-large {
    font-size: 6.5rem;
    border-radius: 50%;
  }
}

.icon-image {
  flex-shrink: 0;
}

.icon_position-top {
  flex-flow: column;

  .icon-image {
    margin-bottom: 16px;
  }
}

.icon_position-center {
  flex-flow: column;
  align-items: center;
  text-align: center;

  .icon-image {
    margin-bottom: 16px;
  }
}

.iconbox_wrapper {
  display: flex;
  align-items: center;

  .icon-image {
    padding-right: 1em;
  }
}

.iconbox-top-list {
  display: flex;

  .iconbox_wrapper {
    padding: 0 10px;
  }
}

.icon-image__checkmark {
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: $delta-red;
  font-size: 0;
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{white}' d='M8.93 18.65L4 13.72l1.77-1.76 3.07 3.07L17.96 5l1.85 1.68L8.93 18.65z'/%3E%3C/svg%3E"), "#", "%23");
  background-size: 26px 26px;
  background-position: center;
}
