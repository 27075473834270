.ajax-progress-fullscreen {
  background-size: 100% !important;
}

.how-to-videos-page .section-lead-in.paragraph-component iframe {
  @include maxbp($grid-sm) {
    width: 90%;
    height: auto;
  }
}

.videogallery-page,
.how-to-videos-page {
  .views-exposed-form.bef-exposed-form {
    .form-item__label {
      display: none !important;
    }
  }
}

.views-exposed-form.bef-exposed-form {
  .form-item__label {
    display: none !important;
  }
}

.full-banner-caption {
  .banner-content {
    max-width: none;
  }
}
