.moderation-dashboard {
  .moderation-dashboard-region {
    #edit-submit {
      position: absolute;
      padding: 0 !important;
      opacity: 0;
      width: 100%;
      right: 27px;
      top: 48px;
      max-width: 2% !important;
    }

    input#edit-search {
      background: url("/themes/custom/delta_theme/images/icons/src/search.png") no-repeat 97% center;
      padding: 13px 40px 14px 20px !important;
    }
  }
}

@include maxbp($mega-menu-mobile) {
  .mega-nav {
    .mega-menu-nav__item--sub {
      display: block !important;
      position: relative;

      .moderation-dashboard {
        display: block;

        .moderation-dashboard-region {
          width: 98%;
          border-bottom: $gray-70 1px solid;
          margin: 0 0 20px 3px;

          &:last-child {
            border: none;
            margin: 0 0 0 3px;
          }

          h2.h2 {
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
          }

          .content {
            padding: 0 0 0 $space-ten;

            .text-long {
              a {
                padding: 0 !important;
                display: block !important;

                &.inline-text-link {
                  color: $delta-red !important;
                  position: relative;
                  display: inline-block !important;
                  padding: 0 15px 0 0 !important;

                  &::after {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 7px;
                    border-color: transparent transparent transparent $delta-red;
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }

            .form-item__label {
              position: static;
              margin: 5px 0 10px;
            }

            // input#edit-search {
            //   width: 96%;
            // }
          }
        }
      }
    }

    .mega-nav-wrapper#mega-nav-wrapper {
      position: relative;
      z-index: 999;
      height: 100%;
      overflow: visible;
      top: 50px;
      left: 0;
      width: 100%;
      opacity: 0;
      background: $gray-10;

      > .mega-menu-nav {
        background: $white;
        opacity: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: all ease 0.5s;
        padding: 0;

        > li {
          border-top: 1px solid $gray-70;
          display: block;
          position: relative;

          ul.mega-menu-nav--sub-1.mega-menu--sub-open {
            padding-top: $space-ten*2;
            padding-bottom: $space-ten*2;

            ul {
              padding-top: 0;
              padding-bottom: 0;
            }
          }

          &.mega-menu-nav__item--with-sub {
            //background: $gray-80;

            &.mega-menu--sub-parent-open {
              background: $gray-80;
            }

            > ul.mega-menu-nav--sub-1 {
              > li {
                > a {
                  font-weight: bold;
                }

                .inline-text-link {
                  display: none;
                }

                a {
                  color: $gray-10;
                  text-decoration: none;
                  display: block;
                  padding: $space-ten+10 $space-ten;

                  &.new-link {
                    display: inline-block;
                    padding: 0 23px 0 0;
                    position: relative;

                    &::before {
                      color: #ac0535;
                      position: absolute;
                      right: 0;
                      text-transform: uppercase;
                      top: -1px;
                      font-weight: bold;
                      font-family: $font-bold2;
                      font-size: 9px;
                    }
                  }

                  &.nav-prod-img,
                  &.collection-image {
                    @include maxbp($mega-menu-mobile) {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          > a {
            display: block;
            color: $gray-10;
            text-decoration: none;
            padding: $space-ten+5 $space-ten*2;

            &:hover,
            &:active {
              background: $gray-80;
            }
          }
        }
      }

      &.main-nav--open {
        @include maxbp($mega-menu-mobile) {
          opacity: 1;

          > .mega-menu-nav {
            display: block;
            max-height: 100000px;
            opacity: 1;
          }
        }
      }
    }

    .expand-sub {
      display: block;
      color: black;
      background: url("/themes/custom/delta_theme/images/icons/src/plus.svg") center center;
      height: 24px;
      width: 24px;
      position: absolute;
      right: $space-ten*2;
      text-align: center;
      top: 12px;

      &::after {
        display: none;
      }

      &.expand-sub--open {
        background: url("/themes/custom/delta_theme/images/icons/src/minus.svg");
      }
    }

    .mega-menu-nav--sub {
      max-height: 0;
      overflow: hidden;
      transition: all ease 1s;
      background: $gray-90;

      &.mega-menu--sub-open {
        max-height: 100000px;
      }
    }
  }
}

.toggle-expand {
  position: absolute;
  left: $space-ten*2;
  display: inline-block;
  cursor: pointer;
  top: 2px;
  width: auto;
  padding: 0;

  @include breakpoint($mega-menu-mobile) {
    display: none;
  }

  &.toggle-expand--open {
    background: none;

    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-4px, 6px);
      transform: rotate(-45deg) translate(-4px, 6px);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      -webkit-transform: rotate(45deg) translate(-4px, -8px);
      transform: rotate(45deg) translate(-4px, -8px);
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 24px;
    height: 2px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
}
