.cta-link {
  display: inline-flex;
  align-items: center;
  color: $delta-red;
  font-family: $font-bold;
  font-weight: 700;
  font-size: $font-size-base;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;

  &--xs {
    font-size: $font-size-xs;
  }

  &--sm {
    font-size: $font-size-sm;
  }

  &--lg {
    font-size: $font-size-lg;
  }

  &.has-icon--left .icon {
    margin-right: 8px;
  }

  &.has-icon--right .icon {
    margin-left: 8px;
  }

  // Arrow links are closer to text by design
  &.has-icon--right .icon--carrot-right {
    margin-left: 4px;
  }
}

.cta-link--arrow {
  position: relative;
  padding-right: 10px;

  &::after {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    content: "";
  }

  &.cta-link--lg {
    padding-right: 12px;

    &::after {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid currentColor;
    }
  }
}

.left-icon-link {
  display: inline-flex;
  align-items: center;
  color: $delta-red;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;

  i,
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.right-icon-link {
  display: inline-flex;
  align-items: center;
  color: $delta-red;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;

  span {
    margin: 0 $space-ten 0 0;
  }

  i,
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.dark-theme {
  .left-icon-link,
  .right-icon-link {
    color: $delta_punch;
  }
}

// default link color
a {
  color: black;
}
