.pdp-warning {
  font-size: 16px;
}

.pdp-warning,
.pdp-warning-tooltip {
  display: inline-block;
  vertical-align: top;
}

.pdp-warning-tooltip {
  button {
    padding: 2px 0 0;
  }
}
