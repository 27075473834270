.path-where-to-buy {
  .banner-component {
    text-align: center;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      min-height: inherit;
      font-size: 0;
    }

    ul {
      @include maxbp($old-desktop) {
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .banner-title {
    text-transform: uppercase;

    @include breakpoint($old-desktop) {
      font-size: 80px;
    }
  }

  .banner-content {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
  }

  .banner__button-group {
    .button {
      padding: 0;
    }

    .button--primary {
      @include breakpoint($old-desktop) {
        margin-right: 30px;
      }

      @include maxbp($grid-md) {
        margin-top: 15px;
        width: 100%;
      }

      a {
        color: $white;
        text-decoration: none;
        padding: 0.75rem 3.125rem;
        width: 100%;
      }
    }
  }

  #block-onlineshopping {
    padding: 0.75rem 3.125rem;

    @include maxbp($grid-md) {
      width: auto;
    }
  }

  .h2 {
    text-align: center;
  }

  #block-internationalcustomers {
    @include maxbp($grid-md) {
      padding-top: 20px;
    }
  }

  #wheretobuy-shoponline {
    .grid-container {
      padding: 0;
    }

    .grid-col-lg-7 {
      .wtb-section + .wtb-section {
        padding-top: 0;
        border-top: 0;
      }
    }
  }

  //PDP Where to Buy
  .pdp-find-local-delears-heading,
  .pdp-dealers-wrapper-heading {
    margin: 0 0 0.5em;
    font-family: $font-bold;
    font-weight: 700;
    line-height: 1.1;
    color: #18191d;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2.565625rem;
    text-align: center;

    @include maxbp($grid-md) {
      font-size: 30px;
    }
  }

  .pdp-top-section.pdp-advt-top-section {
    padding-top: 0 !important;
  }

  #block-onlineshopping.pdp-dealers-wrapper-heading {
    @include maxbp($grid-md) {
      padding: 0.75rem 1.125rem;
    }
  }
}
