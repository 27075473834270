.shower-spray-blocks {
  margin-bottom: $space-ten*3;
}

.aht-wrapper {
  position: relative;

  @include wrapper;

  display: block;
  overflow: hidden;

  .aht-image-data {
    background: $gray-80;

    img {
      width: 100%;
    }
  }

  &:hover {
    .aht-text-data {
      top: 0;
      opacity: 1;
    }
  }

  .aht-text-data {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all ease 0.8s;
    padding: $space-ten*2 $space-ten*3;
    text-align: center;

    .aht-hover-desc {
      height: calc(100% - 170px);
    }

    .aht-links-wrapper {
      position: absolute;
      bottom: 35px;
      left: 0;
      right: 0;
      margin: 0;
      height: 90px;

      .aht-link a {
        padding: 8px 10px;
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0;
        min-width: 180px;
        text-align: center;
        display: inline-block;
        margin: 5px 0 0;
      }
    }
  }

  .aht-title,
  .aht-hover-title {
    padding: $space-ten;

    h6.h6 {
      text-align: center;
      font-size: 18px;
      margin: 0;
      letter-spacing: 1px;
      font-weight: normal;

      p {
        margin: 0;
      }
    }
  }
}

.pl-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000000000;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.6s;

  &.active-pl-popup-wrapper {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .pl-popup-data {
    width: 100%;
    max-width: 600px;
    margin: $space-ten*5 auto 0;

    @include maxbp($r_600) {
      &.aht-popup-data {
        text-align: center;

        iframe {
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}
