.delta-mobile-app-page {
  .main-content {
    max-width: 970px;
    margin: auto;

    p a {
      text-decoration: none;
    }
  }
}
