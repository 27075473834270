.video-thumbnail {
  position: relative;
  display: block;
  margin-bottom: 24px;
  color: $white;

  .icon {
    position: absolute;
    z-index: 20;
    height: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(24, 25, 29, 0) 0%, rgba(24, 25, 29, 0.7) 100%);
    content: '';
  }
}

.video-thumbnail__img {
  display: block;
  width: 100%;
}

.video-thumbnail__title {
  position: absolute;
  bottom: 10px;
  z-index: 20;
  left: 12px;
  right: 12px;
  text-align: center;
  font-family: $font-bold2;
  font-size: $font-size-xl;
  font-weight: 700;
}
