.main-com-desc {
  .main-com-desc-block {
    width: 30%;
    display: inline-block;
    border-right: 1px solid $gray-60;
    padding: $space-ten;

    &.compare-pop-prod-2 {
      width: 50%;
    }

    &.compare-pop-prod-3 {
      width: 33%;
    }

    &:last-child {
      border: none;
    }

    @include maxbp($medium) {
      display: none;
      width: 100% !important;

      &.compare-prod-visible {
        display: block;
      }
    }
  }
}

.main-com-pop {
  text-align: center;

  .main-com-block {
    display: flex;
    border-bottom: 1px solid $gray-60;

    .main-com-title {
      text-align: right;
      float: left;
      width: 100px;
      background: $gray-10;
      padding: $space-ten $space-ten*2 $space-ten $space-ten;

      h6.h6 {
        color: $white;
        text-transform: none;
        letter-spacing: normal;
        font-family: $font-body;
        font-size: 14px;
        margin: 0;
        line-height: 20px;
      }

      .compare-prod-share,
      .compare-prod-print {
        a {
          text-transform: none;
          font-family: $font-body;
          letter-spacing: normal;
          font-size: 14px;
          margin: 0 0 $space-ten*4;
        }
      }

      .compare-prod-btn-action {
        display: none;

        @include maxbp($medium) {
          display: block;

          .compare-prod-btn-next,
          .compare-prod-btn-prev {
            text-align: center;
            cursor: pointer;
            background: white;
            margin: 0 0 10px;
            border-radius: 5px;

            button {
              width: 30px;
              height: 30px;
              border: none;
              background-color: transparent;
              color: white;
              background-repeat: no-repeat;
              background-size: 100%;
              padding: 0;
              background-position: center;
              background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='#ac0535' d='M9.79 3L15 8.5 9.79 14 8.5 13 12 9.28H2V7.71h10L8.5 4z'/%3E%3C/svg%3E"), "#", "%23");
            }
          }

          .compare-prod-btn-prev {
            transform: rotate(180deg);
          }
        }
      }
    }

    .main-com-desc {
      width: calc(100% - 100px);
      float: left;
      display: flex;
      justify-content: space-around;

      span.align-middle {
        img {
          display: inline-block;
          margin: 0 $space-ten;
          width: 50px;
        }
      }

      p {
        margin: 0;
      }

      .com-pop-img {
        margin: 0 auto $space-ten;
        position: relative;
        display: block;
        overflow: hidden;

        img {
          margin: auto;
        }

        .slider_flag {
          position: absolute;
          top: 0;

          .flag {
            @include maxbp($medium) {
              min-width: 160px;
              font-size: $font-size-sm;
              transform: translate(-66px, -19px) rotate(-45deg);
              transform-origin: 100% 0;
            }
          }
        }
      }

      .com_pop_title {
        font-size: 16px;

        h6.h6 {
          font-family: $font-bold;
          font-weight: normal;
          text-transform: none;
          letter-spacing: normal;
        }
      }

      .com_pop_desc {
        font-size: 18px;

        p {
          margin: 0 0 7px;
        }
      }

      .com_pop_model {
        font-size: 18px;

        p {
          margin: 0;
        }

        a {
          color: $gray-10;
        }
      }
    }
  }
}
