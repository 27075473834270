.home-intro-banner {
  background: #000;
  text-align: center;

  .hib-content {
    background-color: $black;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    min-height: 700px;
  }

  .hib-logo {
    min-height: 270px;
    position: relative;
    display: flex;
    align-items: center;
    align-self: stretch;
    height: 134px;
    margin-bottom: 0;
    text-align: center;
    color: inherit;
    background-repeat: repeat-x;
    background-position: center center;
    background-size: auto 100%;

    img {
      margin: auto;
    }
  }

  .hib-title {
    @extend .h2;

    display: block;
    position: relative;
    z-index: 2;
    font-size: 1.801875rem;
    margin-top: -1.1em;
    margin-bottom: 32px;
    color: $white;

    p {
      margin: 0;
    }

    @include large {
      margin-bottom: 40px;
      font-size: 2.280625rem;
    }
  }

  .hib-desc {
    color: $white;
    font-family: $font-roman;
    font-weight: normal;
    font-size: $font-size-xxxl;
    text-transform: none;
    letter-spacing: -0.01em;

    /*
    &::before {
      margin-bottom: 16px;
      position: relative;
      left: 50%;
      display: block;
      width: 64px;
      height: 3px;
      transform: translate(-50%);
      background-color: $delta-red;
      content: "";
    }
    */

    @include large {
      font-size: 2.0275rem;
    }
  }
}
