.faq-cat-listing {
  @include wrapper;

  text-align: center;
  padding: $space-ten*5 !important;

  .faq-cat-listing-title {
    h6.h6 {
      font-weight: normal;
      text-transform: none;
      font-size: 20px;
      letter-spacing: 1px;
      margin: 0 0 $space-ten*2;
    }
  }

  ul {
    li {
      margin: 0 0 $space-ten*2;
      list-style-type: none;

      @include breakpoint($ipad-only) {
        display: inline-block;
        width: 32%;
      }
    }
  }
}
