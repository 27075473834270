.press-release {
  @include wrapper;

  max-width: $max-width-text;

  @include maxbp($mega-menu-mobile) {
    padding: 0;
  }

  > div {
    @include maxbp($grid-md) {
      max-width: 100% !important;
      flex: 0 0 100%;
    }
  }

  .pr-right-wrapper {
    padding: 0;

    .pr-right-title {
      h2.h2 {
        text-transform: none;
        font-family: $font-body;
        font-weight: normal;
        letter-spacing: normal;
        border-bottom: 1px solid $gray-80;
        padding: 0 0 $space-ten+5;
        margin: 0 0 $space-ten*2;
        font-size: 40px;

        @include maxbp($grid-sm) {
          font-size: 30px;
          line-height: 35px;
          padding: 0 0 $space-ten;
        }

        @include maxbp($small) {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }

    .pr-right-desc {
      @include maxbp($small) {
        text-align: center;
      }
    }

    .image-with-link-block {
      width: 32.5%;
      display: inline-block;
      min-width: 10px;

      @include maxbp($grid-lg) {
        width: 49.5%;
      }

      @include maxbp($grid-sm) {
        width: 49%;
      }

      @include maxbp($small) {
        width: 100%;
        margin: auto;
        text-align: center;

        img {
          width: 100%;
          margin: auto;
        }
      }

      .img-link-title {
        padding: $space-ten+2 $space-ten;

        h5.h5 {
          font-size: 18px;
        }
      }

      .image-with-link-icon {
        display: none;
      }
    }
  }
}
//All Pages under Press Release
.path-taxonomy {
  header {
    h1 {
      margin-bottom: 0;
    }
  }

  .pager__items {
    margin-bottom: 40px;
  }
}

// For all the content related pages in press release
.node--type-press-release {
  .main-body-content {
    ul {
      padding-left: 20px;
    }

    li {
      list-style-type: disc;
      padding-left: 0 !important;
    }
  }
}

@include maxbp($large) {
  .node--type-press-release {
    .left-sidebar,
    .main-content--with-sidebar-left {
      padding: 0 16px;
    }
  }
}
