.alert {
  color: $gray-10;
  line-height: 21px;
  font-size: 0.88875rem;
  font-family: $font-bold2;
  padding: 0.313em 1em;
  border-radius: 2px;
  position: relative;

  @include medium {
    padding: 0.563em 1em;
  }

  & i {
    font-size: 1.188em;
    position: absolute;
    right: 8px;
    top: 22%;
    cursor: pointer;
    padding: 3px;
  }

  &--error {
    background-color: $error-light;
    border: 2px solid $error;
  }

  &--success {
    background-color: $success-light;
    border: 2px solid $success;
  }

  &--warning {
    background-color: $warning-light;
    border: 2px solid $warning;
  }

  &--neutral {
    background-color: $neutral-light;
    border: 2px solid $neutral;
  }
}
