.ear-saver-form {
  .form-item__label {
    position: absolute !important;
    top: 1rem !important;
  }

  .form-item.has-focus .form-item__label {
    top: 0 !important;
  }

  .form-item.is-filled .form-item__label {
    top: 0 !important;
  }
}
