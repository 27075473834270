// TODO: Move to a separate file
.list-grid-header {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;

  @include medium {
    margin-bottom: 24px;
  }

  select {
    min-width: 300px;
  }

  @include maxbp(420px) {
    display: block;
  }
}

.list-grid-header.no-sort-label .form-item__label {
  display: none;
}

.case-studies .accordion-def {
  overflow: unset !important;
}

.list-grid-toggle {
  position: relative;
  color: $delta-red;
  background-color: transparent;
  padding: 0 0 0 36px;
  border: 0;
  appearance: none;
  margin-left: auto;
  font-family: $font-bold;
  font-size: $font-size-lg;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  outline: none;
  cursor: pointer;

  @include maxbp($medium) {
    display: none;
  }

  @include maxbp(420px) {
    margin: 25px auto 0;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    display: block;
    width: 24px;
    height: 24px;
    content: '';
  }

  &.list-view::before {
    background-image: url("../images/icons/src/list.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &.grid-view::before {
    background-image: url("../images/icons/src/grid.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.download-card-mobile-accordion {
  & + .accordion-def {
    transition: none !important;
    margin-bottom: 16px;
  }

  @include medium {
    display: none;

    & + .accordion-def {
      opacity: 1;
      max-height: none;
      overflow: visible;
    }
  }
}

@include maxbp($medium) {
  .mobile_product_design_files {
    .accordion-def {
      opacity: 1;
      max-height: 100%;
    }

    .download-card-mobile-accordion {
      display: none;
    }
  }

  .list-grid-header select {
    min-width: 185px;
  }

  .form-item {
    margin: 0;
  }

  .list-grid-toggle {
    display: block;

    @include maxbp(420px) {
      margin: 25px auto 0;
    }
  }

  .product_design_files {
    .grid-container--medium {
      padding: 0;
    }
  }
}

.product-resources-pricing {
  @include medium {
    .main-sidebar {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .main-content--with-sidebar {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .download-card-mobile-accordion + .accordion-def {
      opacity: 1;
      max-height: none;
      overflow: inherit;
    }
  }

  #views-exposed-form-design-files-product-pricing .form-item__label {
    display: none;
  }

  .main-sidebar,
  .main-content--with-sidebar {
    padding-right: 12px;
    padding-left: 12px;
  }
}
