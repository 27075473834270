.campaign-wall-mount-footer {
  padding-top: 80px;

  p a {
    text-decoration: none;
  }

  .text-align-center {
    margin: 0;

    img {
      text-align: center;
      display: inline-block;
      padding-top: 15px;
    }
  }

  .text-center {
    display: flex;
    justify-content: center;
  }
}

//Customer Solutions
.customer-solution {
  .views-element-container {
    max-width: 100%;
    color: $gray-30;
  }

  .text-long {
    @include maxbp($large) {
      padding: 0 15px;
    }
  }

  .page-title {
    color: $gray-30;
    text-align: center;
    font-size: 40px;
    margin-top: 3em;
    font-family: $font-roman;

    @include maxbp($large) {
      font-size: 32px;
    }
  }

  .h4 {
    color: $gray-30;

    @include maxbp($large) {
      font-size: 26px;
    }
  }

  .u-text-main {
    color: $gray-30;
    font-size: 24px;
    margin-bottom: 0;

    @include maxbp($large) {
      font-size: 19px;
    }
  }

  .u-text-base {
    color: $gray-30;
    font-size: 20px;
    font-weight: 400;

    &:last-child {
      margin-bottom: 80px;
    }

    @include maxbp($large) {
      font-size: $font-size-base;
    }
  }

  .u-text-base a {
    color: $gray-30;
    font-size: 20px;
    text-decoration: none;
  }
}

//Campaign Newletter
.campaign-newsletter-page {
  color: $gray-30;

  h2 {
    color: $gray-30;
    text-transform: capitalize;
    font-size: $font-size-base * 2;
    font-family: $font-roman;
    font-weight: 300;
    padding-top: $space-ten*2;
    letter-spacing: 0;
  }
}

//Campaign Social Media
.campaign-social-media {
  .pdp-fealist {
    li::after {
      background: $gray-10;
    }
  }
}

//Campaign Wall Mount faucets
.campaign-wall-mount {
  .paragraph-component h3 {
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
  }

  .designbeyond {
    .grid-col-md-3 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      &:nth-child(2) {
        padding: 0;
      }

      .iwlt-link {
        .link {
          color: $delta-red;
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
  }
}

//Campaign KBIS
.campaign-KBIS {
  .field_innovation_page_component {
    .virtual-tour {
      .virual_booth_iframe {
        padding-bottom: 56.25%;
        position: relative;

        .text-align-center {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          margin: 0 auto;
          height: 80%;
        }
      }
    }

    #Design_Spotlight {
      background: #292929;

      .spotlight_design {
        h3 {
          padding-top: 70px;
        }

        p.text-align-center {
          width: 70.333333%;
          margin: 0 auto;
          color: $white;
        }

        .pivotal_kitchenimg {
          width: 70.333333%;
          margin: 0 auto;
        }
      }

      .field_cta_button_s_ {
        text-align: center;
        padding: 30px 0 70px;

        a {
          min-width: auto;
        }
      }
    }

    #Featured_Products {
      .qidl-wrapper {
        max-width: 100%;
      }

      [class*=grid-col] {
        width: 49%;
        flex: 49%;
        display: inline-block;
        flex-grow: 0;

        .paragraph {
          text-align: center;
        }

        .qidl-img {
          display: inline-block;
          text-align: center;
          width: 100%;

          img {
            display: inline-block;
          }
        }

        .qidl-link {
          text-align: center;
        }
      }
    }
  }
}

//KBIS Dorval
.kbis-dorval {
  .two-column-section.paragraph-component {
    padding-top: 50px;
  }

  .layout__region--second {
    .h2 {
      margin-bottom: 0;
      text-align: center;
    }

    .text-align-center {
      img {
        text-align: center;
        display: inline-block;
      }
    }

    .pdp-fealist {
      li {
        text-align: left;

        &::after {
          background: $gray-10;
        }

        .kbis-dorval-button {
          text-decoration: none;
          color: $delta-red;

          img {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .section-lead-in {
    border-bottom: 1px solid $gray-60;
  }
}

//Design Quiz Page
.design-quiz {
  .views-element-container {
    max-width: 100%;
  }

  .page-title {
    text-align: center;
    font-size: 24px;
    padding-top: 35px;
  }
}

//Campaign Price Book
.campaign-pricebook {
  .views-element-container {
    max-width: 100%;
  }

  .page-title {
    display: none;
  }

  .layout--twocol-section {
    @include maxbp($large) {
      display: block;
    }
  }

  .two-column-section {
    .field_left_section {
      text-align: center;
    }

    .bg-red {
      padding: $space*2;
      text-align: center;
      display: inline-block;

      @include maxbp($large) {
        display: block;
      }

      h1 {
        color: $white;
        font-size: 40px;
        text-transform: capitalize;
      }

      h3 {
        color: $white;
        font-size: 24px;
        text-transform: capitalize;
      }
    }

    .field_right_section {
      padding-top: $space*3;

      @include maxbp($large) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

//Campaign Voice IQ technology
.campaignvoiceIQ {
  .layout--twocol-section {
    padding-bottom: 60px;
  }

  .pdp-fealist {
    li {
      text-align: left;

      &::after {
        background: $gray-10;
      }

      &:last-child {
        a {
          text-decoration: none;
          color: $delta-red;

          img {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

//Campaign FarmhouseFaucets
.campaignsFarmHouseFaucets {
  .delta_logo {
    padding: 70px 0 20px 0;
  }

  #block-mainpagecontent {
    max-width: 1146px;
    margin: 0 auto;
  }

  p.text-center {
    font-size: 32px;
    color: $gray-30;
    margin: 32px 0 0 0;
  }

  .designbeyond {
    .grid {
      @include breakpoint($r-1100) {
        flex-wrap: nowrap;
      }
    }

    .grid-col-md-3 {
      flex: 100% !important;
      max-width: 100% !important;
      justify-content: center;
      display: flex;

      &:nth-child(2) {
        padding: 0;
      }

      .iwlt-img {
        img {
          height: 100%;
        }
      }

      .iwlt-link {
        text-align: center;
        padding: 10px;

        .link {
          color: $delta-red;
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
  }
}

//Brass bathroom faucets
.brass-bathroom-faucets {
  padding-top: 30px;

  .dialog-off-canvas-main-canvas {
    max-width: 1146px;
    margin: 0 auto;
  }

  .field_page_component > div:first-child {
    .layout--twocol-section {
      .layout__region--first {
        .figure__img {
          max-width: 150px;
          height: auto;
          margin: 0 auto 25px;
          display: block;
        }
      }

      .layout__region--second {
        text-align: center;
        padding-bottom: 20px;

        h2 {
          text-transform: capitalize;
          letter-spacing: 0;
        }
      }
    }
  }

  @include large {
    .field_page_component > div:first-child {
      .layout--twocol-section {
        position: relative;

        .layout__region--first {
          flex: 0 1 15%;
          position: absolute;
        }

        .layout__region--second {
          flex: 0 1 100%;

          .text-long {
            max-width: 800px;
            margin: 0 auto 15px;
          }
        }
      }
    }
  }

  @media (min-width: $ipad-only) and (max-width: $grid-lg) {
    .field_page_component {
      .layout--twocol-section {
        .layout__region {
          flex: 0 1 100%;
        }
      }
    }
  }

  @include maxbp($large) {
    .catalog-grid {
      justify-content: center;
    }

    .dialog-off-canvas-main-canvas {
      padding: 0 10px;
    }
  }

  .two-column-section .grid-col-md-12 {
    padding-right: 2px;
    padding-left: 2px;
  }

  .two-column-section .grid-col-md-6:last-child {
    padding-left: 0;

    .content-grid.catalog-grid:first-child {
      margin: 0 0 4px auto !important;
    }
  }

  .two-column-section .grid-col-md-6 {
    padding-right: 0;
    margin: 5px 0 0;
  }
}

.path-campaigns svg.product-registration-sweepstakes-form {
  padding-bottom: 0;
}
