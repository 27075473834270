.cartridge-block {
  width: 300px;

  .cartridge-img-wrapper {
    position: relative;

    .cartridge-img-block {
      position: relative;
      background: $gray-80;
      width: auto;

      img {
        margin: auto;
      }

      &:hover {
        .cartridge-hover-img {
          opacity: 1;
        }
      }

      .cartridge-hover-img {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all ease 0.8s;
        right: 0;
        margin: auto;
      }
    }

    &:hover {
      .cartridge-title {
        background: rgba(172, 5, 53, 0.7);
        padding: $space-ten+5 $space-ten*3;
      }
    }

    .cartridge-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(24, 25, 29, 0.7);
      padding: $space-ten $space-ten*3;
      transition: all ease 0.5s;

      h3.h3 {
        color: $white;
        font-family: $font-body;
        font-size: 16px;
        letter-spacing: normal;
        margin: 0;
      }
    }
  }

  .cartridge-desc {
    padding: $space-ten $space-ten*3;
    background: $gray-90;
  }
}
