.pdp-list-ttl-wrapper {
  display: block;
  overflow: hidden;

  @include maxbp($large) {
    text-align: center;
  }

  @include maxbp($medium) {
    margin: 0 0 $space-ten+25;
  }

  .pdp-list-title {
    float: left;

    @include maxbp($large) {
      float: none;
    }

    h3.h3 {
      font-size: 33px;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      @include maxbp($large) {
        font-size: 25px;
      }
    }
  }

  .pdp-coll-link {
    float: right;

    @include maxbp($large) {
      float: none;
    }
  }
}
