.collection-details__tabs {
  .tabs__nav {
    position: relative;
    justify-content: start;
    border-bottom: 0;

    @include medium {
      margin-top: -$space-ten*2;
      z-index: 10;
    }
  }

  .tabs__content {
    @include medium {
      padding: 0;
    }
  }

  @include maxbp($medium) {
    .tabs__nav {
      display: block;
    }

    .tab-accordion-title {
      display: none;
    }
  }
}

.output-wrapper {
  position: relative;

  .ajax-progress-throbber {
    position: absolute;
  }
}

.collection-grid {
  .product-tile {
    margin: 0 0 $space-ten*3;
    padding: 0;
  }
}

.collection-details__dropdowns {
  margin: $space-ten*2 0 $space-ten*6;

  @include maxbp($large) {
    max-width: 500px;
    margin: 24px auto;

    .form-item + .form-item {
      margin-top: 12px;
    }
  }

  .form-item {
    margin-bottom: 0;
  }

  @include large {
    display: flex;
    justify-content: space-between;

    .form-item {
      width: 225px;
      margin-right: 10px;
    }
  }

  .form-item__select--sm {
    height: 56px;
  }
}

.collection-details__section {
  margin: $space-ten*3 0;

  h3.h3 {
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 1px;
    border-bottom: 1px solid $gray-70;
    padding: 0 0 $space-ten+5;
    margin: 0 0 $space-ten*2;
  }

  .summary-card-carousel {
    margin: 0 0 $space-ten*8;
  }

  .summary-card__content {
    h4.h4 {
      font-size: 23px;
      font-weight: normal;
      letter-spacing: 1px;
    }
  }

  @include maxbp($medium) {
    .slick-arrow {
      display: none !important;
    }
  }

  @include large {
    margin-bottom: 40px;
  }
}

.modal--collection-gallery {
  padding: $space-ten/2;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 80px;
  // transform: translateY(-50%);
  max-width: none;
  background: transparent;
  transition-property: height;
  transition-duration: 1s;

  .modal__body {
    margin-bottom: 0;
    height: 100%;

    .video {
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  .modal_header {
    margin: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 100;

    .modal__close {
      background: $delta-red;
      top: 0;
      right: 0;
      padding: 2px;
      height: 30px;
      width: 30px;
      line-height: 35px;
      border-radius: 100%;

      .icon {
        fill: $white;
      }
    }
  }
}

.path-kitchen,
.path-bathroom {
  .main {
    padding: 0;

    @include maxbp($xl) {
      padding: 0 $space-ten*2;
    }
  }

  .featured-banner {
    img {
      width: 100%;
    }
  }

  @include breakpoint($old-desktop) {
    .featured-banner-content {
      margin-left: 0;
      max-width: 100%;
      min-height: 534px;
      width: 100%;
      position: absolute;
      top: 18%;
    }
  }

  @include breakpoint($large) {
    .breadcrumb-block {
      position: relative;

      .breadcrumb {
        position: absolute;
        max-width: 1440px;
        margin: 0 auto;
        padding: 20px 24px 0 24px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  @include maxbp($large) {
    .breadcrumb-block {
      position: absolute;
      top: 15px;
      z-index: 5;
      text-align: left;
      width: 100%;
    }
  }

  .page-desktop-pagination {
    @include breakpoint($large) {
      display: flex;
    }

    @include maxbp($large) {
      display: none;
    }
  }

  .page-mobile-pagination {
    @include breakpoint($large) {
      display: none;
    }

    @include maxbp($large) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .mobile_pagination {
      @include maxbp($large) {
        font-size: 20px;
        padding-right: 10px;

        &:nth-of-type(1) {
          padding-left: 20px;
        }

        &:nth-of-type(2) {
          padding-right: 20px;
        }
      }
    }
  }

  .pagination-bottom {
    @include breakpoint($large) {
      display: flex;
      justify-content: flex-end;
    }

    @include maxbp($large) {
      display: flex;
      justify-content: center;
    }
  }

  .pagination-dots {
    @include breakpoint($large) {
      padding: 15px 9px 1px 9px;
    }
  }

  .pager__link {
    border: 0;

    @include maxbp($large) {
      background: $delta-red;
      border: $delta-red;
      color: $white;
    }
  }
}

#collection-video-modal {
  max-width: 90%;
  margin: 0 auto;

  .modal--collection-gallery {
    top: 50px;
    max-width: 900px;
    max-height: 500px;
  }

  @include maxbp($large) {
    top: 50px;
  }
}
