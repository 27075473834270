.hotspot-banner {
  position: relative;
  margin: 0;
}

.hotspot-banner__gradient {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0.7) 100%);
}

.hotspot-banner__content {
  font-size: $font-size-lg;

  @include maxbp($large) {
    background-color: #f5f5f7;
    text-align: center;
    padding: 0 !important;
    height: 0;
  }

  @include maxbp($large) {
    .hotspot-banner .circle-red-content {
      margin-top: 0% !important;
    }
  }

  @include large {
    color: #fff;
  }

  @media (max-width: 550px) {
    .circle-red {
      margin-top: 20px;
    }
  }
}

.hotspot-banner__title {
  margin-bottom: 12px;
  font-size: 2.565625rem;
  color: inherit;

  @include large {
    font-size: 3.653125rem;
    margin-bottom: 16px;
  }
}

.hotspot-banner__caption {
  margin-top: 0;
  margin-bottom: 24px;

  @include large {
    margin-bottom: 32px;
  }
}

@include maxbp($large) {
  .circle-red {
    width: 30.5%;
    margin-left: 30.5%;
  }

  .circle-red-content {
    margin-top: 2%;
  }
}
