.innovation-section {
  margin: 40px 0;

  @include large {
    margin: 80px 0;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    text-align: center;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 2.0275rem;
  }

  .featured-card {
    min-height: 420px;
    padding: 10px;
  }

  .featured-innoslider__item {
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.95, 0.95);
    -ms-transform: scale(0.95, 0.95);
    -o-transform: scale(0.95, 0.95);
    transform: scale(0.95, 0.95);
    background-color: white;
    box-shadow: 0 0 18px #ccc;

    &.slick-current {
      -webkit-transform: scale(1.1, 1.1) !important;
      -moz-transform: scale(1.1, 1.1) !important;
      -ms-transform: scale(1.1, 1.1) !important;
      -o-transform: scale(1.1, 1.1) !important;
      transform: scale(1.1, 1.1) !important;
      z-index: 200;
      -webkit-transition: all ease-in-out 0.15s;
      -o-transition: all ease-in-out 0.15s;
      transition: all ease-in-out 0.15s;
    }
  }

  .featured-innoslider {
    @include large {
      padding: 0;
    }
  }
}

.innovation-navigation {
  .tabs__nav {
    border-bottom: 0;
  }
}

.innovation-card-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  .innovation-card {
    width: 31%;
    margin: 1%;
    margin: 0.75%;
    padding: 0 18px;

    @include maxbp($grid-md) {
      padding: 16px;
    }

    @include maxbp($grid-md) {
      width: 100%;
      margin: 8px 0;
    }
  }
}

.innovation-card__img + .innovation-card__title {
  padding-left: 0;
}

.innovation-faq {
  display: flex;

  .faq-grid {
    flex-grow: 1;
  }
}

.innovation-faq__img {
  display: none;
  max-width: 30%;

  @include medium {
    display: block;
    margin-left: 20px;
  }

  @include large {
    width: 100%;
    max-width: 100%;
  }

  @include xl {
    margin-left: 40px;
    max-width: 100%;
  }
}

.innovation-landing {
  @include large {
    .main {
      padding: 0;
    }

    article {
      > .grid-container {
        padding: 0;
      }
    }
  }

  .featured-innoslider {
    .slick-arrow.slick-prev {
      left: -22px;
    }

    .slick-arrow.slick-next {
      right: -22px;
    }
  }

  .innovation-section {
    .h2 {
      text-align: center;
    }

    .innovation-card {
      .innovation-card__img {
        max-height: 254px;
      }
    }
  }

  .homepage_video .home-button_playback {
    color: #ac0535;
  }

  .banner-block {
    .page-title {
      p {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }

    .banner-content {
      margin-left: 0;
      line-height: 1.2;
      font-size: 16px;
      width: 100%;
    }
  }

  @include large {
    .grid-container--medium {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .innovation-card__overlay {
    p {
      font-weight: 200;
      font-size: 13px;
      color: #888;
      line-height: 1.4em;
    }
  }
}
