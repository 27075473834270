.star-rating {
  font-size: 8px;
  width: 13em;
  height: 2.4em;
  background-image: url("../images/icons/src/star-rating.svg");
  background-repeat: no-repeat;
  background-size: 26em 4.8em;
  margin-left: -4px;
}

.star-rating--1 {
  background-position: -10.4em 0;
}

.star-rating--2 {
  background-position: -7.8em 0;
}

.star-rating--3 {
  background-position: -5.2em 0;
}

.star-rating--4 {
  background-position: -2.6em 0;
}

.star-rating--1-5 {
  background-position: -10.4em -2.4em;
}

.star-rating--2-5 {
  background-position: -7.8em -2.4em;
}

.star-rating--3-5 {
  background-position: -5.2em -2.4em;
}

.star-rating--4-5 {
  background-position: -2.6em -2.4em;
}

.star-rating--5 {
  background-position: 0 0;
}
