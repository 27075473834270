.numeric-stepper {
  display: flex;
}

.numeric-stepper__input {
  width: 48px;
  height: 32px;
  font-size: $font-size-lg;
  border-color: $gray-60;
  border-style: solid;
  border-width: 1px 0 1px 0;
  text-align: center;
}

.numeric-stepper__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  font-family: $font-bold2;
  font-size: $font-size-lg;
  line-height: 32px;
  font-weight: 700;
  user-select: none;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $gray-70;
  border-radius: 2px;
  border: 0;
  color: $gray-10;
}
