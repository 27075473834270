.designbeyond {
  @include wrapper;

  .qidl-wrapper {
    max-width: $max-width-text;
    width: 100%;
    margin: 0 auto;

    .grid-col-md-3 {
      padding: 0 $space-ten/2;
    }
  }

  .iwlt-wrapper {
    max-width: $max-width-text;
    width: 100%;
    margin: $space-ten*5 auto 0;

    .iwlt-title {
      font-family: $font-body;

      h6.h6 {
        margin: 0 0 $space-ten;
      }
    }
  }
}

//COOPER HEWITT page
.page-cooperhewitt {
  .main-content {
    @include maxbp($grid-md) {
      padding: 10px;
    }
  }

  .layout__region--first {
    text-align: center;
  }

  .field-values-in > {
    div:nth-child(1) {
      .layout__region--first {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .field_right_section {
        h2 {
          text-transform: capitalize;
          margin-bottom: 0;
          letter-spacing: 0;
          font-size: 32px;
          color: $gray-30;
        }
      }
    }

    div:nth-child(4) {
      .two-column-section {
        padding-top: 80px;

        .layout__region--first,
        .layout__region--second {
          @include maxbp($old-desktop) {
            flex: 0 1 100%;
            text-align: center;
          }
        }

        .catalog-grid__item {
          width: 100%;

          h2 {
            text-transform: capitalize;
            font-family: $font-roman;
            letter-spacing: 0;
            margin-bottom: 10px;
            font-size: 32px;
          }
        }
      }
    }
  }

  .banner-block {
    @include maxbp($old-desktop) {
      min-height: auto;
    }

    .grid-container {
      @include maxbp($grid-md) {
        padding-bottom: 0 !important;
      }

      @media (min-width: $ipad-only) and (max-width: $old-desktop) {
        padding-bottom: 60px;
      }
    }
  }

  h2.text-center {
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 32px;
    color: $gray-30;
    margin-top: 60px;
  }
}
