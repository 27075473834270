.search-radio-block {
  margin: -20px 0 0;

  .form-item {
    margin: 0;
  }

  ul.form-item--radios {
    padding: 0;
    margin-bottom: 0;

    li {
      font-size: 15px;

      .custom-input__label {
        padding-left: 25px;
      }
    }
  }

  .pr-search-input-wrapper {
    .pr-search-input {
      border-radius: 4px;
      border: 1px solid $gray-60;
      padding: 7px 10px;
    }

    .js-form-item.form-item {
      position: relative;
      display: block;
      margin-bottom: 4px;

      .serach-input {
        border: 1px solid $gray-70 !important;
        width: 90%;
        border-radius: 5px;
      }
    }
  }

  .js-form-item.form-item {
    position: relative;
    display: block;
    margin-bottom: 4px;

    input[type="radio"].form-radio {
      position: absolute;
      z-index: -1;
      left: 0;
      opacity: 0;
    }

    input[type="radio"].form-radio:disabled ~ label.option.form-item__label {
      color: $gray-50;
    }

    input[type="radio"].form-radio:checked ~ label.option.form-item__label {
      &::before {
        border-color: $gray-10;
      }

      &::after {
        opacity: 1;
        transform: translate(0, -50%) scale(0.7);
      }
    }

    input[type="radio"].form-radio:disabled ~ .label.option.form-item__label {
      &::before {
        background: $gray-60;
        border-color: $gray-60;
      }
    }

    label.option.form-item__label {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      user-select: none;
      padding-left: 30px;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        content: '';
      }

      &::before {
        border: 1px solid $gray-60;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &::after {
        opacity: 0;
        transform: translate(0, -50%) scale(0.3);
        transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);
        background-size: 16px 16px;
        background-color: $gray-10;
      }
    }
  }
}

.node--type-press-release .main-content--with-sidebar-left {
  img {
    float: right;
    padding: 10px 0 10px 10px;
  }
}
