.com-popup {
  @include wrapper;

  padding-top: $space-ten*2 !important;
  padding-bottom: $space-ten*2 !important;

  .com-title-wrapper {
    display: block;
    overflow: hidden;

    .com-title-block {
      float: left;

      @include maxbp($medium) {
        width: calc(100% - 160px);
      }

      .com-popup-title {
        h4.h4 {
          font-size: 35px;
          text-transform: none;
          letter-spacing: 1px;
          font-family: $font-body;
          font-weight: normal;
        }
      }
    }

    .com-popup-button {
      float: right;

      @include maxbp($medium) {
        width: 140px;
      }

      .com-popup-button-compare,
      .com-popup-close {
        display: inline-block;
        vertical-align: middle;
      }

      .com-popup-button-compare {
        @include maxbp($medium) {
          //display: none;
        }
      }
    }
  }

  .com-pop-block-list {
    text-align: center;
    clear: both;
    display: block;
    overflow: hidden;
    margin: $space-ten 0 0;
  }
}

.dialog-off-canvas-main-canvas {
  overflow: hidden !important;
}

.ui-widget.ui-widget-content {
  &.compare-popup {
    max-width: 100% !important;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 1000001;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    border: 1px solid #c5c5c5;
    min-height: 75vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;

    @include maxbp($std_desktop) {
      height: 92vh !important;
      overflow: scroll;
    }

    a.button {
      color: $white;
      padding: 5px 15px 6px;
      text-transform: none;
      letter-spacing: normal;
    }

    a.cta-link {
      color: $delta-red;
    }

    a.add_another_product {
      padding: 0 0 30px;
      display: block;
      text-decoration: none;
    }

    .ui-widget-header {
      display: none;
    }

    .ui-front.ui-dialog-content {
      height: auto !important;
    }
  }

  &.compare-products {
    height: 100% !important;
    width: 100% !important;
    overflow: auto !important;
    top: $space-ten !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    background: none;
    border: none !important;
    z-index: 1000001 !important;
    -ms-overflow-style: none;
    bottom: 10px !important;

    a {
      color: $delta-red;

      &.button--primary {
        color: $white;
      }
    }

    .ui-widget-header {
      width: 60% !important;
      margin: auto;
      padding: 0;
      position: absolute;
      border: none;
      left: 0;
      right: 0;
      z-index: 10000;

      @include maxbp($large) {
        width: 96% !important;
      }

      .ui-dialog-title {
        display: none;
      }

      .ui-dialog-titlebar-close {
        position: absolute;
        right: 4px;
        top: 2px;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
      }
    }

    .ui-front.ui-dialog-content {
      padding: 0;
      width: 60% !important;
      height: auto !important;
      background: $white;
      left: 0;
      right: 0;
      margin: auto;

      @include maxbp($large) {
        width: 96% !important;
      }
    }
  }
}
