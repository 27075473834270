.installation-support {
  .title-desc-link-image {
    .tdli-title {
      h3 {
        .field-values-in {
          @include maxbp($r_1100) {
            font-size: 36px;
          }

          @include maxbp($medium) {
            font-size: 26px;
          }
        }
      }
    }

    @include maxbp($r_1100) {
      padding-bottom: 48px !important;
    }
  }
}

.installation-support-container {
  margin: 0 auto;
  padding: 0 75px;

  @include maxbp($medium) {
    padding: 0;
  }

  &.ins-supp__image--left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 116px;

    @include maxbp($r_1100) {
      flex-direction: column;
      margin-bottom: 64px;
    }

    @include maxbp($medium) {
      flex-direction: column;
      margin-bottom: 88px;
    }
  }

  .installation-support__block-left {
    width: 50%;

    @include maxbp($r_1100) {
      width: 100%;
      margin-bottom: 32px;
    }

    @include maxbp($medium) {
      width: 100%;
      margin-bottom: 32px;
    }

    .ins-block__image--right {
      width: 100%;
      margin-left: 44px;

      @include maxbp($medium) {
        margin-left: 0;
      }
    }
  }

  &.ins-supp__image--right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 65px;
    margin-bottom: 106px;

    @include maxbp($r_1100) {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 96px;
    }

    @include maxbp($medium) {
      flex-direction: column;
      margin-bottom: 86px;
    }
  }

  .installation-support__block-right {
    width: 50%;

    @include maxbp($r_1100) {
      width: 100%;
    }

    .ins-block-right__image {
      width: 353px;

      @include maxbp($r_1100) {
        width: 253px;
        position: relative;
        margin: 0 auto;

        .img {
          margin: 0 auto;
        }
      }

      @include maxbp($medium) {
        width: 100%;
      }
    }

    &.ins-supp__block-pos--right {
      max-width: 423px;
      margin: 0 auto;

      @include maxbp($r_1100) {
        max-width: 100%;
        text-align: center;
      }

      @include maxbp($medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
      }
    }

    @include maxbp($medium) {
      width: 100%;
    }

    &.ins-supp__block-pos--left {
      padding-left: 125px;
      padding-right: 96px;

      .ins-block-right__cta-image {
        display: none;
      }

      @include maxbp($r_1100) {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }

      @include maxbp($medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .ins-block-right__text {
      font-size: 25px;
      margin-top: 34px;
      margin-bottom: 34px;
      letter-spacing: -0.5px;
      line-height: 30px;
      max-width: 416px;

      @include maxbp($r_1100) {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 24px;
        max-width: 100%;
      }

      @include maxbp($medium) {
        font-size: 19px;
        margin-top: 16px;
        margin-bottom: 5px;
        padding-right: 0;
        line-height: 27px;
        letter-spacing: -0.04px;
        text-align: left;
      }
    }

    .ins-block-right__cta {
      a {
        background-color: $gray-30;
        border-radius: 2px;
        padding: 12px 44px;

        @include maxbp($medium) {
          padding: 14px 100px;
        }
      }
    }

    .ins-block-right__cta-image {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .appstore__cta-image {
        picture {
          img {
            width: 171px;

            @include maxbp($medium) {
              width: 166px;
            }
          }
        }

        @include maxbp($r_1100) {
          margin-right: 16px;
        }

        @include maxbp($medium) {
          margin-right: 16px;
        }
      }

      .playstore__cta-image {
        width: 50%;

        picture {
          img {
            width: 183px;

            @include maxbp($medium) {
              width: 166px;
            }
          }
        }

        @include maxbp($r_1100) {
          width: auto;
        }
      }

      @include maxbp($r_1100) {
        justify-content: center;
      }
    }
  }

  @include maxbp($medium) {
    margin-left: 8px;
    margin-right: 8px;
  }
}
