.pdp-prod-name {
  color: $gray-10;
  font-size: 37px;
  letter-spacing: 1px;
  line-height: 43px;
  font-family: $font-light;
  width: 100%;
  margin: 0 0 23.45px;

  @include maxbp($grid-xl) {
    width: 100%;
  }

  @include maxbp($medium) {
    font-size: 36.49px;
    line-height: 41.05px;
    margin: 0 0 $space-ten;
  }
}
